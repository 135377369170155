.confirmation-popup
  .modal__body
    padding: 24px 30px 32px


  &__content
    font-size: 14px
    line-height: 1.5
    .text-color-secondary
      color: var(--colorTextSecondary)
    >*+*
      margin-top: 12px
    p
      font-size: 14px
      line-height: 1.5
      font-weight: 400
    a
      color: var(--colorPrimary)
      &:hover
        text-decoration: underline
    strong
    b
      font-weight: 600
    ul
      padding: 0 0 0 15px
      list-style-type: disc
      li+li
        margin-top: 5px
    ol
      padding: 0 0 0 15px
      li+li
        margin-top: 5px

  &__btns
    display: flex
    align-items: center
    justify-content: flex-end
    margin-top: 32px
    >*+*
      margin-left: 12px


// .over-limit-modal
//   text-align: center

//   &__body
//     padding: 24px 20px 48px

//   &__paragraph
//     max-width: 90%
//     margin: 5px auto
//     font-size: 16px
//     line-height: 24px
//     color: #323232
//     text-align: center

//   &__ul
//     margin: 15px auto
//     max-width: 90%
//     list-style: disc

//   &__link
//     color: #1da2ff

//   &__actions
//     width: 100%
//     display: flex
//     justify-content: center

//   &__button
//     margin: 0 15px
