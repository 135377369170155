.billing-grid
  width: 100%
  // padding: 20px 24px

  &__head
  &__row
    display: grid
    grid-template-columns: 170px 115px 1fr 168px

  &__row
    &.is-divided
      border-top: 1px solid var(--colorBorder)

  &__head
    width: 100%
    // margin-bottom: 8px

  &__body
    width: 100%
    text-align: left
    background-color: #f8f9ff
    border-radius: 4px
    box-shadow: 0 2px 4px 0 #f4f5fd
    +.billing-grid__body
      margin-top: 12px

  &__cell
    display: flex
    align-items: center
    // gap: 6px
    min-height: 43px
    padding: 6px 10px
    font-size: 14px
    text-align: left
    &.is-heading
      padding: 10px 12px
      // font-size: 16px
      font-weight: 500
      color: var(--colorTextSecondary)
      // background-color: var(--colorBgGray)
    &.is-bold
      font-weight: 600
    &.align-center
      justify-content: center
      text-align: center
    &.align-end
      justify-content: flex-end
      text-align: right
    &.gap-x
      gap: 5px
    .size-s
      font-size: 12px

  &__row-menu
    --dropdownItemPadding: 0
    --dropdownItemFS: 14px
    --dropdownItemLH: 20px
    .el-dropdown-menu__item
      >*
        color: inherit
        padding: 8px 16px

  &__row-menu-trigger
    width: 26px
    height: 26px
    font-size: 16px
    color: var(--colorTextSecondary)
    border-radius: 4px
    border: 1px solid var(--colorBorder)
    transition: all .2s
    &.is-highlighted
    &:hover
      background-color: var(--colorBgGray)
    &:active
      background-color: var(--colorGray-20)

  // &__head
  //   width: 100%
  //   padding: 15px 30px
  //   background-color: #EFF3FB
  //   border-radius: 4px

  // &__head-img
  //   margin-bottom: 5px

  // &__head-title
  //   font-size: 16px
  //   line-height: normal
  //   font-weight: 500

  // &__head-desc
  //   font-size: 16px
  //   line-height: normal
  //   & .is-highlighted
  //     color: colorPrimary
  //     font-weight: 500

  // &__table
  //   margin-top: 20px

  // &__table-row
  //   display: flex
  //   align-items: center
  //   justify-content: space-between
  //   padding: 10px 0
  //   text-align: left
  //   &:nth-of-type(n+2)
  //     border-top: 1px solid #E4E9F1

  // &__table-row-title
  //   width: 60%
  //   font-size: 16px
  //   line-height: 24px
  //   padding-right: 15px

  // &__table-row-value
  //   width: 10%
  //   font-size: 16px
  //   line-height: 24px

  // &__table-row-btn-wrap
  //   display: flex
  //   justify-content: flex-end
  //   width: 30%

  // &__table-row-btn
  //   display: flex
  //   align-items: center
  //   height: 26px
  //   padding: 0 12px
  //   font-size: 12px
  //   font-weight: 600
  //   color: #fff
  //   text-transform: uppercase
  //   text-align: center
  //   border-radius: 4px
  //   background-color: colorPrimary
  //   transition: all .2s ease
  //   &:hover
  //     background-color: colorPrimaryHover
  //   &:disabled
  //     opacity 0.5

  // &__footnote
  //   display: flex
  //   align-items: center
  //   justify-content: center
  //   margin-top: 30px
  //   font-size: 12px
  //   color: #616A78
  //   & a
  //     text-decoration: underline
  //     &:hover
  //       text-decoration: none

  // &__paragraph
  //   margin: 0 0 20px
  //   font-size: 14px
  //   line-height: 22px
