.pricing__plans
  & .highlighted
    color: colorPrimary

  & .plans
    position: relative
    display: flex
    justify-content: space-between
    margin-top: 20px

    .plan
      position: relative
      display: flex
      flex-direction: column
      justify-content: space-between
      width: calc(50% - 25px)
      padding: 40px 40px 30px
      background-color: #fff
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08)
      text-align: center
      cursor: default

      &__body
        // padding-bottom: 35px

      &__type
        font-size: 24px
        line-height: 1.71
        font-weight: bold

      &__price-wrap
        display: flex
        align-items: flex-start
        justify-content: center
        margin-top: 10px
        padding-right: 17px
        font-weight: 600

      &__price-currency
        margin-right: 5px
        font-size: 24px
        line-height: 40px

      &__price
        font-size: 54px
        line-height: 54px

      &__price-period
        align-self: flex-end
        font-size: 16px

      &__type-label
        margin-top: 10px
        font-size: 14px
        line-height: normal

      &__btn
        margin: 25px 0 0
        padding: 5px 20px
        font-size: 14px
        font-weight: 600
        line-height: 30px
        letter-spacing: 0.3px
        text-transform: uppercase
        color: #fff
        background-color: colorPrimary
        border-radius: 4px
        transition: background-color .3s ease
        &:hover
          background-color: colorPrimaryHover
      &__label
        margin-top: 35px
        font-weight: 600
        line-height: normal
        color: #a8b7cf
        &.has-no-indent
          margin-top: 0
