.side-form
  position: relative

  &__block-wrapper
    width: 100%

  &__back-btn
    cursor: pointer
    display: flex
    align-items: center
    margin-bottom: 30px
    font-size: 14px
    line-height: 14px
    font-weight: 600
    color: colorLightBlue
    transition: color .2s
    &:hover
      color: colorPrimary

  &__back-btn-icon
    font-size: 14px
    transform: translateY(-1px)
    margin-right: 8px

  &__section
    width: 100%
    &+.side-form__section
      margin-top: 30px

  &__section-title
    margin-bottom: 16px
    font-size: 14px
    line-height: 18px
    font-weight: 600
    color: #323232
    text-transform: uppercase

  &__section-subtitle
    margin-bottom: 10px
    font-size: 13px
    line-height: 16px
    font-weight: 600
    color: #323232
    color: colorDarkerGray

  &__item
    position: relative
    width: 100%
    &+.side-form__item
      margin-top: 20px
    &.is-justified
      display: flex
      align-items: center
      justify-content: space-between

  &__item-label
    padding-right: 10px
    font-size: 12px
    line-height: 18px
    font-weight: 500
    color: #465164
    &.placement-top
      margin-bottom: 6px

  &__item-hint
    margin-top: 2px
    margin-bottom: 8px
    font-size: 12px
    line-height: 14px
    font-weight: 400
    color: #798496
    &.placement-bottom
      margin-bottom: 0
      margin-top: 8px
    &.is-link
      font-size: 14px
      color: colorPrimary
      a:hover
        color: colorPrimaryHover

  &__item-msgs
    position: absolute
    top: 100%
    left: 0
    .side-form__item-msg
      position: static

  &__item-msg
    position: absolute
    top: 100%
    left: 0
    width: 100%
    padding-top: 3px
    font-size: 10px
    line-height: 15px
    font-weight: 500
    color: colorDanger

  &__item-disabled
    pointer-events: none
    opacity: 0.5

  &__footnote
    font-size: 14px
    line-height: 20px
    color: #59677F
    code
      font-size: 12px
      color: #616A78

  &__btns
    display: flex
    flex-direction: column
    & .btn+.btn
    & .pro-option__wrapper
      margin-top: 8px
      & .btn
        width: 100%
    & + .side-form__section
    & + .side-form__item
      margin-top: 40px

  &__text-btn
    display: flex
    align-items: center
    font-size: 14px
    color: #59677F
    font-weight: 500
    transition: all .1s ease
    &.is-filled
      padding: 6px 12px
      background-color: #F3F5F8
      border-radius: 4px
    &:hover
      color: colorPrimary

  &__text-btn-icon
    font-size: 16px
    margin-right: 8px

  &__checkbox-label
    font-size: 16px
    line-height: 22px
    font-weight: 400
    color: #323232

  &__switcher-wrap
    display: flex
    align-items: center
    justify-content: space-between

  &__switcher-label
    padding-right: 10px
    font-size: 12px
    line-height: 18px
    font-weight: 500
    color: #465164

  &__filter-item
    cursor: pointer
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    padding: 7px 0
    &.is-active:before
      background-color: #E9F6FF
      opacity: 1
    &:not(.is-active):hover:before
      opacity: 1
    &:before
      content: ''
      position: absolute
      top: 0
      left: -30px
      width: calc(100% + 60px)
      height: 100%
      background-color: #f3f9fe
      opacity: 0
      transition: opacity .1s ease

  &__filter-item-title
    position: relative
    padding-right: 20px
    font-size: 16px
    line-height: 22px
    color: #323232

  &__filter-item-value
    position: relative
    padding: 5px 10px 4px
    font-size: 14px
    line-height: 16px
    font-weight: 500
    background-color: #ecf1fb
    border-radius: 100px

  &__draggable-wrap
    +.side-form__btns
      margin-top: 15px

  &__subforms-switcher
    width: 100%

  &__subforms-switcher-item
    cursor: pointer
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    padding: 15px 0
    color: #323232
    &:hover
      &:before
        opacity: 1
    &:before
      pointer-events: none
      content: ''
      position: absolute
      top: 0
      left: -30px
      z-index: 0
      width: calc(100% + 60px)
      height: 100%
      background-color: #E9F6FF
      opacity: 0
      transition: all .2s ease

  &__subforms-switcher-item-title
    position: relative
    font-size: 14px
    line-height: 20px
    font-weight: 600
    text-transform: uppercase

  &__subforms-switcher-item-icon
    position: relative
    flex: none
    font-size: 16px
    line-height: 16px
    color: #a8b7cf
    transform: rotate(-90deg)
    transition: all .2s
