.tile-constructor
  display: flex

  &__form-fields
    width: 100%

  &__field
    position: relative
    margin-bottom: 18px

  &__field-label
    margin-bottom: 6px
    font-size: 14px
    line-height: 19px
    font-weight: 500
    color: #59677F
  &__hint
    a
      display: inline-block
      margin-top: 5px
      font-size: 14px
      color: colorPrimary
      &:hover
        color: colorPrimaryHover
