.el-message
  top: 60px
  display: flex
  align-items: flex-start
  width: 100%
  max-width: 620px
  min-width: auto
  min-height: auto
  padding: 20px
  border-radius: 8px
  border: 1px solid transparent

  &.is-closable
    .el-message__content
      padding-right: 40px

  // &--warning
  //   background-color: #fef4e6
  //   border-color: var(--colorWarningNoticeBorder)

  &--error
    background-color: var(--colorDanger)

  &--info
    width: fit-content
    background-color: var(--colorText)
    .el-message__content
      color: #fff

  // &--success
  //   background-color: #74D6A5

  &__icon
    margin-right: 16px
    font-size: 22px
    color: #fff
    &.el-icon-error
    &.el-icon-info
      color: #fff
      &:before
        content: '\e964'
        font-family: 'icomoon'

  &__content
    padding: 0
    font-size: 16px
    line-height: 22px
    font-weight: 400
    color: #fff
    &>*+*
      margin-top: 6px
    a
      text-decoration: underline
      &:hover
        text-decoration: none
    h5
      font-size: 16px
      line-height: 22px
      font-weight: 600
    p:not(:first-child)
      margin-top: 6px
    ~/.el-message--error &
    ~/.el-message--success &
    ~/.el-message--warning &
      color: #fff

  &__closeBtn
    top: 20px
    right: 20px
    font-size: 24px
    color: #fff
    transform: none
    transition: all .2s
    &:hover
      color: #fff
      opacity: .7
