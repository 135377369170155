.plan-cards
  display: flex
  flex-direction: column
  align-items: center
  gap: 32px
  width: 100%
  padding: 24px
  overflow: hidden

  &__type-switcher
    display: flex
    align-items: center
    width: fit-content
    border: 1px solid var(--colorPrimary)
    border-radius: 4px
    overflow: hidden

  &__type-switcher-item
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    min-width: 180px
    height: 44px
    padding: 0 12px
    font-size: 16px
    font-weight: 600
    color: var(--colorPrimary)
    text-align: center
    text-transform: uppercase
    transition: all .2s
    &:not(.is-active):hover
      background-color: var(--colorBgBlue)
    // +mobile()
    //   width: 50%
    //   min-width: auto
    &.is-active
      background-color: var(--colorPrimary)
      color: #fff

  &__items-wrap
    width: 100%
    --bottomPadding: 32px
    --featureHeight: 64px

  &__items
    display: grid
    grid-template-columns: repeat(3,1fr)
    gap: 16px

  &__payment-switcher
    display: flex
    align-items: center
    gap: 16px

  &__payment-switcher-item
    cursor: pointer
    display: flex
    align-items: center
    gap: 8px
    input
      display: none
    &.is-active .plan-cards__payment-switcher-item-indicator
      border-color: var(--colorPrimary)
      &:after
        opacity: 1

  &__payment-switcher-item-indicator
    position: relative
    flex: none
    width: 20px
    height: 20px
    border-radius: 50%
    border: 1px solid #ACB9CD
    transition: border-color .2s
    &:after
      content: ''
      absCenter()
      width: 10px
      height: 10px
      border-radius: 50%
      background-color: var(--colorPrimary)
      opacity: 0
      transition: opacity .2s

  &__payment-switcher-item-title
    font-size: 16px
    font-weight: 500

  &__payment-switcher-item-label
    padding: 5px 8px
    font-size: 14px
    line-height: 14px
    font-weight: 600
    color: #fff
    border-radius: 4px
    background-color: var(--colorDanger)

.plan-cards-items-enter-active, .plan-cards-items-leave-active
  transition: all .6s
  .plan-card
    transition: all .3s ease
  .plan-card:nth-of-type(2)
    transition-delay: .1s
  .plan-card:nth-of-type(3)
    transition-delay: .2s

.plan-cards-items-enter, .plan-cards-items-leave-to
  .plan-card
    opacity: 0
    transform: translateY(70px)
