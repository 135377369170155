.manage-error
  display: flex
  flex-direction: column
  justify-content: center
  position: fixed
  top: 0
  left: 0
  z-index: 10
  width: 100%
  height: 100%
