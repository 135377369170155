.el-tabs.el-tabs--card
  & .el-tabs

    &__header
      margin: 0
      border-bottom: none

    &__content
      padding: 40px 0 0
      background-color: #fff
      border: 1px solid #F4F4F4
      box-shadow: 0 4px 10px 0 rgba(0,0,0,.08)
      text-align: center

    &__item
      position: relative
      height: 50px
      padding: 0 50px 0 50px !important
      font-size: 14px
      line-height: 50px
      font-weight: 600
      border-color: #F4F4F4
      outline: none !important
      box-shadow: none !important
      transition: all .3s ease
      &:hover
        color: #c9c9c9
        background-color: rgba(255,255,255,.3)

      &.is-active
        color: colorBlack
        background: #fff
        // box-shadow: -2px 4px 10px 0 rgba(0,0,0,.08)

      &.is-disabled
        padding: 0 50px 0 50px
        color: #CAD3E0

      &:last-child
        padding: 0 50px 0 50px

    &__nav
      border: 1px solid #F4F4F4
      border-bottom: none
      // overflow: hidden
