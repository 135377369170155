.sheet-samples

  &__list
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr))
    grid-gap: 48px 30px

  &__item
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 16px

  &__preview
    position: relative
    width: 100%
    height: 360px
    display: flex
    flex-direction: column
    align-items: flex-start
    border-radius: 4px
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07)
    overflow: hidden

  &__preview-header
    flex: none
    display: flex
    align-items: center
    width: 100%
    height: 15px
    padding-left: 10px
    background-color: var(--colorBorder)

  &__preview-header-circle
    width: 6px
    height: 6px
    margin-right: 4px
    background-color: var(--colorGrayLight)
    border-radius: 50%

  &__preview-inner
    // cursor: pointer
    position: relative
    flex: 1
    width: 100%
    background-size: cover
    background-position: top left
    background-repeat: no-repeat
    overflow: hidden
    transition: background-position 3s ease
    &:hover
      background-position: bottom

  &__item-btns
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 16px
    width: 100%

  &__item-title
    headingS(700)
    color: var(--colorText)

  // &__preview-inner-overlay
  //   pointer-events: none
  //   position: absolute
  //   top: 0
  //   left: 0
  //   display: flex
  //   align-items: center
  //   justify-content: center
  //   width: 100%
  //   height: 100%
  //   font-size: 40px
  //   color: var(--colorTextSecondary)
  //   background-color: alpha(#e4e9f1, .45)
  //   opacity: 0
  //   transition: all .35s ease
