.source-options
  position: relative
  margin-bottom: 50px

  &__row
    display: flex

  &__button
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: 40px
    height: 40px
    text-align center
    font-size: 14px
    line-height: 40px
    font-weight: 600
    color: #fff
    text-transform: uppercase
    border-radius: 0 4px 4px 0
    background-color: #0f9d58
    transition: all .3s ease
    &:hover
      background-color: #1c7748
    .icon-loader
      font-size: 16px
      animation: loader-rotation 1.5s linear infinite

  &__link
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 40px
    font-size: 14px
    line-height: 14px
    font-weight: 600
    color: #fff
    text-transform: uppercase
    border-radius: 4px 0 0 4px
    background-color: #0f9d58
    transition: all .3s ease
    margin-right 1px
    cursor pointer
    &:hover
      background-color: #1c7748
    &:disabled
      opacity: 0.5

  &__link-icon
    display: inline-block
    margin-right: 12px
    font-size: 20px

  &__change-btn
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    margin-top: 20px
    font-size: 16px
    font-weight: 600
    color: colorDarkerGray
    &:hover
      color: colorPrimaryHover

  &__change-btn-icon
    margin-right: 17px
    font-size: 20px

  &__form-wrapper
    margin-top: 35px

  &__form-back
    cursor: pointer
    display: flex
    align-items: center
    font-size: 14px
    line-height: 14px
    font-weight: 600
    color: colorLightBlue
    transition: color .3s ease
    &:hover
      color: colorPrimary

  &__form-back-icon
    font-size: 14px
    transform: translateY(-1px)
    margin-right: 8px
