.plan-card
  width: 100%
  min-width: 0
  padding: 16px 16px
  border: 1px solid #D2DAE7
  background-color: #fff
  border-radius: 16px
  &--highlighted
    background-color: #E9F6FF
    border-color: var(--colorPrimary)
    .plan-card__price-wrap
      color: var(--colorPrimary)

  &__meta
    display: flex
    flex-direction: column
    align-items: center
    text-align: center

  &__title
    font-family: $PTSans
    font-size: 32px
    line-height: 1.5
    font-weight: bold

  &__subtitle
    height: 44px
    margin-top: 4px
    font-size: 14px
    line-height: 22px
    lineClamp(2)

  &__price-wrap
    margin-top: 18px
    font-family: $PTSans
    font-weight: bold
    sup
      top: -0.7em
      &:empty
        display: none

  &__price-currency
    font-size: 20px

  &__price
    font-size: 36px
    line-height: 44px
    sup
      font-size: 20px

  &__price-period
    font-size: 20px
    color: #798496

  &__cta-btn
    width: 100%
    margin-top: 18px

  &__legend
    margin-top: 18px

  &__legend-item
    display: flex
    align-items: center
    justify-content: flex-start
    gap: 8px
    width: 100%
    min-height: 24px
    +.plan-card__legend-item
      margin-top: 8px

  &__legend-item-title
    width: 100%
    font-size: 14px
    text-align: left

  &__legend-item-value
    flex: none
    order: 2
    font-family: $PTSans
    font-size: 18px
    font-weight: bold

  &__legend-item-icon
    flex: none
    width: 18px
