.appsumo-page
  &__wrapp
    display: flex
    align-items: center
  &__logo
    max-width 400px
    margin-bottom 85px
  &__container
    margin: auto
    max-width: 760px
    padding: 0 20px
    text-align center
  &__title
    font-weight: bold
    font-size: 24px
    line-height: 30px
    margin-bottom 16px
  &__text
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 22px
    color: #313131
    & a
      color: #1DA2FF
    & .highlighted
      color: #1DA2FF
  &__success-button
    margin-top: 40px
    height: 40px
    padding: 0 24px
    background: #1DA2FF
    border-radius: 4px
    font-style: normal
    font-weight: 600
    font-size: 14px
    text-align: center
    letter-spacing: 0.3px
    text-transform: uppercase
    color: #FFFFFF



.appsumo-deal-over
  width: 404px
  margin: 30px auto
  padding: 24px 24px 30px
  background-color: #EFF3FB
  p
    font-weight: 600
    line-height: 24px
  &-details
    text-align: justify
    font-size: 14px
    line-height: 20px
    margin-top: 12px
    font-weight: normal
    a
      color: colorPrimary


.appsumo-codes-form
  width: 404px
  margin: 30px auto
  padding: 24px 24px 30px
  background-color: #EFF3FB
  & form
    display: flex
    width: 100%
    justify-content: stretch
  &__input-wrapper
    display: flex
    margin: 16px
  &__input
    width: 100%
    height 40px
    padding: 0 14px
    background: #fff
    border: 1px solid #E4E9F1
    box-sizing: border-box
    border-radius: 4px
    &::placeholder
      color: #A8B7CF

  &__valid-codes-list
    margin: 20px 0 0
    & p
      margin-bottom: 12px
  &__submit
    height: 40px
    padding: 0 24px
    background: #1DA2FF
    border-radius: 4px
    font-style: normal
    font-weight: 600
    font-size: 14px
    text-align: center
    letter-spacing: 0.3px
    text-transform: uppercase
    color: #FFFFFF
    &:disabled
      opacity 0.5
      pointer-events: none

  &__error-message
    color: colorDanger
    font-size: 12px
    margin-top: 20px
  &__success-message
    color: #208b54
    font-size: 12px
    margin-top: 20px
