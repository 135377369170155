.draggable
  display: flex
  flex-direction: column

  &__item
    position: relative
    display: flex
    align-items: center
    width: 100%
    padding: 10px 0
    &:not(.hover-disabled):hover:before
      opacity: 1
    &:before
      pointer-events: none
      content: ''
      position: absolute
      top: 0
      left: -30px
      z-index: 0
      width: calc(100% + 60px)
      height: 100%
      background-color: #E9F6FF
      opacity: 0
      transition: all .2s ease
    &.full-width-bg:before
      width: 100%
      left: 0
    &.hover-disabled
      padding: 0
      +.draggable__item
        margin-top: 15px
      .draggable__item-trigger:hover
        color: colorPrimary

  &__item-trigger
    position: relative
    cursor: grab
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: 20px
    height: 20px
    font-size: 16px
    color: #C1C8D3

  &__item-input-wrap
    width: 100%
    padding-left: 10px

  &__item-title-wrap
    cursor: pointer
    position: relative
    display: flex
    align-items: center
    max-width: 100%
    padding-left: 10px
    overflow: hidden
    &.is-inactive
      cursor: default
      opacity 0.6
    &.no-padding
      padding-left: 0
    &.is-column
      flex-direction: column
      align-items: flex-start
    &.cursor-default
      cursor: default

  &__item-title-img
    flex: none
    height: 24px
    margin-right: 10px

  &__item-title-icon
    flex: none
    width: 16px
    margin-right: 10px
    font-size: 16px
    line-height: 16px
    color: #717E93

  &__item-title
    max-width: 100%
    font-size: 16px
    line-height: 22px
    color: #323232
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  &__item-subtitle
    max-width: 100%
    font-size: 12px
    line-height: 18px
    font-weight: 500
    color: #a8b7cf
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  &__item-controls
    position: relative
    flex: none
    display: flex
    align-items: center
    margin-left: auto
    padding-left: 10px

  &__item-control
    cursor: pointer
    flex: none
    display: flex
    align-items: center
    justify-content: center
    width: 24px
    height: 24px
    font-size: 18px
    color: #A8B7CF
    transition: all .2s ease
    &:hover
      color: colorPrimary
    &+.draggable__item-control
      margin-left: 5px
    &.dropdown-opened
      color: colorPrimary
    & i:focus
      outline: none

  &__dropdown-menu
    min-width: 180px
    & .el-dropdown-menu__item
      padding: 7px 15px
      font-size: 14px
      line-height: 18px
      color: #323232
      &:hover
        color: #323232
    & .popper__arrow
      display: none
    &.el-popper[x-placement^=bottom]
      margin-top: 0
    &.el-popper[x-placement^=top]
      margin-bottom: 0
