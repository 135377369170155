.va-bottom
  vertical-align: bottom
.va-middle
  vertical-align: middle
.va-top
  vertical-align: top

.flex
  display: flex

.align-items-center
  align-items: center
.align-items-start
  align-items: flex-start
.align-items-end
  align-items: flex-end

.justify-content-center
  justify-content: center
.justify-content-start
  justify-content: flex-start
.justify-content-end
  justify-content: flex-end
.justify-content-between
  justify-content: space-between

.is-link
  color: var(--colorPrimary)
  text-decoration: underline
  &:hover
    text-decoration: none

// Spacers
.mt-auto
  margin-top: auto
.mr-auto
  margin-right: auto
.ml-auto
  margin-left: auto
.mb-auto
  margin-bottom: auto

for index in 2 4 8 12 16 20 24 28 32 36 40 44 48 52 56 60 64 68 72 76 80 84 88 92 96 100 104 108 112 116 120
  .mt-{index}
    margin-top: index px
  .mr-{index}
    margin-right: index px
  .mb-{index}
    margin-bottom: index px
  .ml-{index}
    margin-left: index px

  .p-{index}
    padding: index px
  .pt-{index}
    padding-top: index px
  .pr-{index}
    padding-right: index px
  .pb-{index}
    padding-bottom: index px
  .pl-{index}
    padding-left: index px

  .gap-{index}
    gap: index px
  .row-gap-{index}
    row-gap: index px
  .column-gap-{index}
    column-gap: index px
