.integrations-modal
  & .modal
    width: 100%
    max-width: 700px

  &__inner
    padding: 0 40px 50px

  &__head
    display: flex
    align-items: center

  &__head-icon
    max-width: 80px
    max-height: 50px
    margin-right: 16px

  &__head-title
    margin-right: 24px
    font-size: 24px
    line-height: 30px
    font-weight: bold
    color: #323232

  &__head-status
    display: flex
    align-items: center
    height: 24px
    padding: 0 12px
    font-size: 12px
    line-height: 14px
    color: #A8B7CF
    font-weight: 600
    border-radius: 4px
    background-color: #F2F4F8
    &.is-active
      background-color: colorPrimary
      color: #fff

  &__body
    margin-top: 25px

  &__img
    width: 100%
    max-height: 500px
    margin-bottom: 25px

  &__content
    position: relative
    display: flex

  &__content-left-side
    width: calc(100% - 240px)

  &__content-right-side
    flex: none
    width: 210px
    padding-left: 30px

  &__form
    margin-top: 25px

  &__form-item
    width: 100%
    &+.integrations-modal__form-item
      margin-top: 20px

  &__form-btn
    margin-top: 20px

  &__cta
    display: flex
    flex-direction: column
    align-items: flex-start
    width: 100%
    padding: 18px 16px
    background-color: #EFF4FA
    border-radius: 4px

  &__cta-item
    cursor: pointer
    display: flex
    align-items: center
    font-size: 14px
    line-height: 20px
    &+.integrations-modal__cta-item
      margin-top: 5px
    &.is-highlighted
      color: colorPrimary
      font-weight: 500
    &:hover
      color: colorPrimaryHover

  &__cta-item-icon
    margin-right: 8px

  &__footnote
    margin-top: 25px
    & p
      font-size: 14px
      line-height: 21px
      color: #323232
      &+p
        margin-top: 25px
      & a
        position: relative
        display: inline-block
        color: colorPrimary
        font-weight: 500
        &:after
          content: '→'
          display: inline-block
          margin-left: 3px
          vertical-align: text-top

  &__about
    color: #323232

    & h1
      margin: 20px 0 20px
      font-size: 30px
      line-height: 40px
      font-weight: 700

    & h2
      margin: 14px 0
      font-size: 26px
      line-height: 34px
      font-weight: 600

    h3
    h4
    h5
    h6
      margin: 10px 0
      font-size: 20px
      line-height: 28px
      font-weight: 600

    & p
      margin: 10px 0 18px
      font-size: 14px
      line-height: 22px
      &:only-of-type
      &:last-child
        margin: 0

    & a
      color: #1da2ff
      &:hover
        text-decoration: underline

    & details
      margin: 10px 0
      &[open]
        & > summary:before
          transform: none
      & > summary
        position: relative
        list-style: none
        padding-left: 15px
        &:before
          content: '\e909'
          position: absolute
          top: -1px
          left: 0
          font-size: 9px
          font-family: 'icomoon'
          transform: rotate(-90deg)
          transition: transform .2s ease
        &::-webkit-details-marker
          display: none
      & summary
        width: fit-content
        cursor: pointer
        font-size: 14px
        line-height: 20px
        font-weight: 500
        color: #1da2ff
        border-bottom: 1px dashed currentColor
        transition: color .2s ease
        &:hover
          color: #0470bb
        &:focus
          outline: none

    & blockquote
      position: relative
      padding: 0 0 0 25px
      margin: 10px 0 20px
      font-size: 14px
      line-height: normal
      font-weight: 500
      color: inherit
      border-left: none
      &:before
        content: ''
        position: absolute
        top: 0
        left: 0
        width: 3px
        height: 100%
        background-color: #323232

    & hr
      position: relative
      display: block
      width: 100%
      height: 1px
      margin: 30px auto
      background-color: #E4E9F1
      border: none
      overflow: visible

    & img
      box-sizing: border-box
      display: block
      margin: 0 0 20px
      max-width: 100%
      border-style: none
      background-color: transparent

    & figure
      margin: 10px 0 20px
      & img
        margin: 0

    & figcaption
      margin-top: 8px
      font-size: 12px
      line-height: 14px
      color: #798496
      text-align: left

    & input
      -webkit-appearance: auto

    & ul
      list-style: disc

    & ul
    & ol
      padding-left: 20px
      margin: 10px 0 18px
      & li
        font-size: 14px
        line-height: 22px
        &:nth-of-type(n+2)
          margin-top: 5px
        & p
          margin: 0
        & ul
          margin: 0

    & .table-wrap
      width: 100%
      max-width: 100%
      margin: 10px 0 20px
      border-right: 1px solid #E4E9F1
      border-left: 1px solid #E4E9F1
      overflow-x: scroll
      & table tr td:last-of-type
      & table tr th:last-of-type
        border-right: none

    & table
      // display: block
      width: 100%
      // max-width: 100%
      margin-left: -1px
      border-spacing: 0
      // overflow: scroll
      & code
        padding: 2px 5px 3px
        color: #333
        background-color: #efefef
        border-radius: 4px
      & caption
        margin-bottom: 5px
        font-size: 12px
        line-height: 14px
        color: #798496
        text-align: left
      & th
      & td
        max-width: 100%
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        padding: 5px 8px
      & th
        font-size: 16px
        font-weight: 500
        color: #798496
        text-align: left
        border: 1px solid #E4E9F1
        &:nth-of-type(n+2)
          border-left: none
      & td
        font-size: 14px
        font-weight: 400
        color: #323232
        text-align: left
        border: 1px solid #E4E9F1
        border-top: none
        &:nth-of-type(n+2)
          border-left: none

    & iframe
      border 0
      margin-bottom 18px

    // & p
    //   font-size: 16px
    //   line-height: 24px
    //   color: #323232
    //   & a
    //     color: colorPrimary
    //     &:hover
    //       text-decoration: underline
    //   &+p
    //     margin-top: 20px
