.checkbox
  display: flex
  align-items: center
  position: relative
  cursor: pointer
  user-select: none

  &__indicator
    display: flex
    align-items: center
    justify-content: center
    width: 20px
    height: 20px
    padding-top: 3px
    border: 1px solid colorPrimary
    border-radius: 4px
    transition: all .2s ease
    &:after
      content: '\e93f'
      font-family: 'icomoon'
      font-size: 10px
      line-height: 12px
      color: #fff
      opacity: 0
      transition: all .2s ease

  &__title
    margin-left: 10px
    padding-top: 1px
    font-size: 14px
    line-height: normal
    color: #323232
    transition: all .2s ease

  & input[type="checkbox"]
    display: none

  & input:checked ~ .checkbox__title
    color: colorPrimary

  & input:checked ~ .checkbox__indicator
    background-color: colorPrimary

  & input:checked ~ .checkbox__indicator:after
    opacity: 1
