.ui-switcher
  align-items: center

  &--full-width
    display: flex
    justify-content: space-between
    gap: 16px
    width: 100%

  &--inline
    display: inline-flex
    width: fit-content

  &__label
    font-size: 12px
    line-height: 18px
    font-weight: 500
    color: #465164

  &__trigger
    position: relative
    display: block
    flex: none
    width: 40px
    height: 24px
    border-radius: 100px
    border: 1px solid #e4e9f1
    cursor: pointer
    transition: all .3s ease
    &.selected
      border-color: #B1DCFF
      & .ui-switcher__trigger-indicator
        border-color: colorPrimary
        background-color: colorPrimary
        transform: translateX(16px)

  &__trigger-indicator
    position: absolute
    top: -1px
    left: -1px
    display: block
    width: 24px
    height: 24px
    background-color: #A8B7CF
    border: 1px solid #A8B7CF
    border-radius: 50%
    transition: all .3s ease

  &__trigger-input
    display: none
