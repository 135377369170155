.n-form-field
  position: relative
  width: 100%

  &__top
    margin-bottom: 6px

  &__label
    font-size: 12px
    line-height: 18px
    font-weight: 500
    color: #465164
    +.n-form-field__helper
      margin-left: 4px

  &__helper
    vertical-align: middle

  &__error-message
    // position: absolute
    // top: 100%
    // left: 0
    margin-top: 2px
    font-size: 12px
    line-height: 16px
    font-weight: 400
    color: var(--colorDanger)

  &__hint
    margin-top: 3px
    font-size: 11px
    line-height: 16px
    font-weight: 400
    color: #717E93
    a
      color: var(--colorPrimary)
      &:hover
        color: var(--colorPrimaryHover)

  &__control
    position: relative

    & .v-input.v-text-field
      display: flex
      align-items: center
      min-height: 40px
      border: 1px solid #e4e9f1
      border-radius: 4px
      &:not(.v-select) .v-input__control
        min-height: 38px
      & .v-text-field__slot input
        padding: 0 13px
        border: none
      & textarea
        padding: 10px 15px
        font-size: 14px
        line-height: 20px
        border: none
      & .v-text-field__suffix
        padding-right: 10px
        font-size: 14px
        color: #717E93

    & .color-picker
      position: absolute
      top: 11px
      right: 10px

  & .field-icon
    margin-right: 10px
    width: 16px

  & .field-name
    display: flex
    align-items: center
    padding-bottom: 2px
    font-size: 16px
    font-weight: 600
    color: colorDarkerGray
    & .icon
      margin-right: 10px
      color: colorLightBlue

  & .list-title
    margin-bottom: 20px
    padding: 0 20px
    font-size: 14px
    line-height: 18px
    font-weight: 600
    color: colorBlacks

  & .v-select
    display: flex
    align-items: center
    border-radius: 4px
    border: 1px solid #E4E9F1

    & .v-input__control
      padding: 4px 13px
      min-height: 30px

    & .v-select__selections input
      padding: 0
      border: none
      &::placeholder
        color: #A8B7CF

  & .v-select__slot
    align-items: center
    & .v-input__append-inner
      align-self: center
