.profile-tab
  position: relative
  color: #323232
  padding-bottom: 35px
  &.no-bottom-indent
    padding-bottom: 0

  &__container
    width: 100%
    max-width: 440px
    margin: 0 auto

  &__title
    padding: 0 50px
    font-size: 24px
    line-height: 34px
    font-weight: 700

  &__subtitle
    margin-top: 5px
    font-size: 14px
    line-height: 24px
    color: #A8B7CF

  &__desc
    padding: 0 50px
    margin-top: 15px
    font-size: 16px
    line-height: 26px
    & .is-highlighted
      color: colorPrimary
      font-weight: 500

  &__form
    width: 100%
    margin: 35px auto 0
    padding-bottom: 5px

  &__form-field
    display: flex
    align-items: center
    justify-content: space-between
    padding-bottom: 10px

  &__form-field-control
    width: 70%

  &__form-field-label
    font-size: 14px
    font-weight: 600

  &__switcher-wrapp
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 16px
    &.disabled
      opacity: .4
      pointer-events: none

  &__switcher-label
    font-size: 14px
    line-height: 20px
    +mobile()
      font-size: 10px

  &__button
    margin-top: 30px
    padding: 11px 43px
    font-size: 14px
    color: #fff
    font-weight: 500
    text-transform: uppercase
    background-color: colorPrimary
    border-radius: 4px
    transition: all .3s ease
    &:hover
      background-color: colorPrimaryHover

  &__divider
    margin: 30px auto
    height: 1px
    background-color: #E4E9F1

  &__danger
    width: 100%

  &__danger-title
    font-size: 14px
    line-height: 20px

  &__danger-btn
    width: fit-content
    font-size: 14px
    line-height: 20px
    color: #A8B7CF
    font-weight: 500
    border-bottom: 1px dashed currentColor
    transition: all .2s
    &:hover
      color: colorDanger

  // &__danger-btn
  //   width: fit-content
  //   padding: 11px 25px
  //   font-size: 14px
  //   color: colorDanger
  //   border: 1px solid colorDanger
  //   text-transform: uppercase
  //   border-radius: 4px
  //   transition: all .2s
  //   &:hover
  //     background-color: colorDanger
  //     color: #fff
