.v-select
  width: 100%
  position: relative
  &:hover
    & .dropdown-toggle
      border-color: #c0c4cc
      & .open-indicator
        &:after
          color: #c0c4cc
  &.open
    & .dropdown-toggle
      border-color: #c0c4cc
      border-bottom-left-radius: 4px
      border-bottom-right-radius: 4px
      & .open-indicator
        &:after
          color: #c0c4cc

  &.disabled
    pointer-events: none
    & .open-indicator
      pointer-events: none
    & .dropdown-toggle .selected-tag
      opacity: .5

  &.single
    & .dropdown-toggle
      & .selected-tag
        background-color: transparent
        color: colorBlack
        border: none
        font-weight: 500
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        width: 100%

  &.clearless
    & .dropdown-toggle
      & .clear
        display: block

  & .highlight
    &:not(:last-child)
      margin-bottom: 12px


  & .dropdown-toggle
    cursor: pointer
    width: 100%
    height: auto
    padding: 6px 45px 6px 10px
    // padding-right: 45px
    // padding-top: 6px
    min-height: 40px
    border-color: #E4E9F1
    border-radius: 4px
    transition: all .3s ease

    & input.form-control
      height: 0
      opacity: 0
      position: absolute

    & .form-control
      height: auto
      font-size: 12px
      line-height: 12px
      font-weight: 600

    & .clear
      display: none
      position: absolute
      top: 50%
      right: 30px
      transform: translateY(-50%)
      width: 10px
      height: 10px
      & span
        display: none
      &:after
      &:before
        content: ''
        position: absolute
        background-color: #E4E9F1
        transition: background-color .3s ease
      &:after
        top: 4px
        left: 0
        width: 10px
        height: 2px
        transform: rotate(-45deg)
      &:before
        top: 0
        left: 4px
        width: 2px
        height: 10px
        transform: rotate(-45deg)
      &:hover
        &:before
        &:after
          background-color: #f56c6c

    & .open-indicator
      display: block
      position: absolute
      top: 50%
      right: 10px
      transform: translateY(-50%)
      width: 15px
      height: 15px
      font-style: unset
      &:before
        content: none
      &:after
        content: '\e925'
        position: absolute
        top: 2px
        left: 2px
        font-family: 'icomoon'
        font-size: 12px
        color: #E4E9F1
        transition: all .3s ease

    & .selected-tag
      pointer-events: none
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      padding: 0 10px
      margin: 0
      font-family: $Montserrat
      font-size: 12px
      line-height: 12px
      font-weight: 500
      color: #fff
      opacity: 1
      letter-spacing: 0
      animation: selected-tag-appearence .3s ease
      & .close
        display: flex
        align-items: center
        width: 10px
        height: 10px
        margin-left: 5px
        font-size: 13px
        line-height: 16px
        opacity: 1
        text-shadow: none
        &:hover
          & span
            color: #fff
            z-index: 1
            &:before
              opacity: 1
        & span
          position: relative
          width: 10px
          height: 10px
          line-height: 10px
          // color: #67c23a
          color: #476d9d
          letter-spacing: 0
          font-weight: 500
          transition: all .3s ease
          &:before
            content: ''
            position: absolute
            top: -1px
            left: -2px
            width: 14px
            height: 13px
            // background-color: #67c23a
            background-color: #476d9d
            border-radius: 50%
            opacity: 0
            z-index: -1
            transition: all .3s ease

  & .dropdown-menu
      position: absolute
      top: calc(100% + 5px)
      left: 0
      z-index: 3
      width: 100%
      padding: 10px
      border: 1px solid #E4E9F1
      border-radius: 4px
      background-color: rgba(255,255,255,.95)
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12)
      user-select: none
      & li
        position: relative
        cursor: pointer
        margin-bottom: 12px
        color: colorBlack
        &:before
          content: ''
          position: absolute
          top: 12px
          right: 10px
          width: 5px
          height: 5px
          background-color: rgba(0,0,0,.05)
          border-radius: 50%
          opacity: 0
          transition: all .15s ease
          z-index: 0
        &:after
          content: ''
          position: absolute
          top: 0
          left: -5px
          width: calc(100% + 10px)
          height: 100%
          background-color: rgba(0,0,0,.01)
          border-radius: 4px
          opacity: 0
          transition: all .15s ease
          z-index: 0
        &:hover
          &:after
            opacity: 1
        &.highlight
          &:before
            opacity: .5
          & a
            color: colorBlack
            background: transparent
        &.active
          &:before
            opacity: 1
            background-color: rgba(71,109,157,.35)
            border: 1px solid rgba(71,109,157,.5)
          & a
            // color: #67c23a
            color: #476d9d
          &>a
            background: none
        & a
          position: relative
          z-index: 1
          padding: 5px 0
          font-size: 12px
          line-height: 22px
          font-weight: 600
          background: transparent

        &:last-of-type
          margin-bottom: 0

& .v-select.multiselect
  & .dropdown-toggle
    & .selected-tag
      margin: 2px 2px 2px 0
      color: #476d9d
      font-weight: 500
      background-color: rgba(143,203,245,.35)
      border-color: rgba(143,203,245,.5)
      // background-color: rgba(71,109,157,.35)
      // border: 1px solid rgba(71,109,157,.5)
      pointer-events: auto
