.workspaces-manage
  padding: 0 50px
  text-align: left

  &__head
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 32px

  &__title.title-editor
    --font-size: 24px
    --line-height: 30px
    --font-weight: bold
    --field-height: 30px
    --icon-size: 16px
    &.is-editing
      width: 100%
      .title-editor__form
        border-bottom-color: var(--colorPrimary)
    .title-editor__form
      border-bottom: 1px dashed transparent

  &__body
    &>*+*
      margin-top: 16px

  &__search
    display: grid
    grid-template-columns: 1fr auto
    grid-gap: 16px

  &__counter
    width: 100%
    padding: 10px 20px
    font-size: 14px
    line-height: 22px
    background-color: var(--colorBgGray)
    border-radius: 4px
    span
      font-weight: 600
      color: var(--colorPrimary)

  &__members
    margin-top: 8px

  &__member
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    padding: 16px 40px 16px 0
    &:not(:last-of-type)
      border-bottom: 1px solid var(--colorBorder)
    &:hover .workspaces-manage__member-delete-btn
      pointer-events: all
      opacity: 1

  &__member-details
    display: flex
    align-items: center

  &__member-avatar
    flex: none

  &__member-meta
    margin-left: 12px
    &>*+*
      margin-top: 2px

  &__member-meta-title
    font-size: 16px
    line-height: 22px
    font-weight: 600

  &__member-meta-subtitle
    display: flex
    align-items: center
    font-size: 14px
    line-height: 20px

  &__member-meta-subtitle-icon
    margin-left: 8px
    font-size: 12px
    color: var(--colorTextSecondary)
    transition: all .2s
    &:disabled
      cursor: not-allowed
      opacity: .5
    &:not(:disabled):hover
      color: var(--colorPrimary)

  &__member-status
    flex: none
    width: 130px
    font-size: 16px
    line-height: 22px
    color: var(--colorTextSecondary)

  &__member-delete-btn
    pointer-events: none
    cursor: pointer
    absTR(50%, 12px)
    font-size: 20px
    color: var(--colorTextSecondary)
    transform: translateY(-50%)
    opacity: 0
    transition: opacity .2s
    &:hover
      color: var(--colorDanger)
