screen = {
  laptop: 1280px,
  tablet: 767px,
  mobile: 500px
}

mobile()
  @media screen and (max-width screen.mobile)
    {block}

tablet()
  @media (min-width screen.mobile) and (max-width screen.tablet)
    {block}
tablet-max()
  @media screen and (max-width screen.tablet)
    {block}

laptop()
  @media (min-width screen.tablet) and (max-width screen.laptop)
    {block}
laptop-max()
  @media screen and (max-width screen.laptop)
    {block}

desktop()
  @media screen and (min-width screen.laptop)
    {block}

flexGrid(amountPerRow rightIndent topIndent)
  calcucatedRightIndent = ((rightIndent * (amountPerRow - 1)) / amountPerRow)
  width: 'calc(%s - %spx)' % ( round((100% / amountPerRow), 3) calcucatedRightIndent )
  margin-right: rightIndent px
  &:nth-of-type({amountPerRow}n)
    margin-right: 0
  &:nth-of-type(n+{amountPerRow + 1})
    margin-top: topIndent px

hover()
  @media(hover: hover)
    &:hover
      { block }

mediaHover()
  @media(hover: hover)
    { block }

fallback(name)
  @supports not ({name})
    { block }

lineClamp(val)
  display: -webkit-box
  -webkit-line-clamp: val
  -webkit-box-orient: vertical
  overflow: hidden

bgImg()
  background-size: cover
  background-position: top left
  background-repeat: no-repeat

bgImgCenter()
  background-size: cover
  background-position: center
  background-repeat: no-repeat

absCenter()
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
absTL(top = 0,left = 0)
  position: absolute
  top: top
  left: left
absTR(top = 0,right = 0)
  position: absolute
  top: top
  right: right
absBL(bottom = 0,left = 0)
  position: absolute
  bottom: bottom
  left: left
absBR(bottom = 0,right = 0)
  position: absolute
  bottom: bottom
  right: right

ellipsis(maxWidth = 100%)
  max-width: maxWidth
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

mw(maxWidth)
  width: 100%
  max-width: maxWidth px

scrollbar()
  scrollbar-width: thin
  scrollbar-color: var(--colorBorder) var(--colorBg)
  -webkit-overflow-scrolling: touch
  &::-webkit-scrollbar
    width: 8px
  &::-webkit-scrollbar-track
    background-color: var(--colorBg)
  &::-webkit-scrollbar-thumb
    background-color: var(--colorBorder)
    border-radius: 20px
    border: 2px solid var(--colorBg)
