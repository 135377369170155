.el-select-dropdown
  // Menu
  --selectMenuBorder: 1px solid var(--colorBorder)
  --selectMenuPaddings: 12px 0
  --selectMenuBorderRadius: 4px
  --selectMenuShadow: var(--shadowPopover)
  // Item
  --selectItemHeight: auto
  --selectItemPadding: 8px 16px
  --selectItemFS: 16px
  --selectItemLH: 20px
  --selectItemFW: 400
  --selectItemColor: var(--colorText)
  --selectItemWhiteSpace: normal
  --selectItemTextAlign: left
  --selectActiveItemFW: 400
  --selectActiveItemColor: var(--colorPrimary)
  --selectItemHover: var(--colorPopoverItemBg--hover)
  --selectDisabledItemColor: var(--colorTextSecondary)


.el-select-dropdown
  padding: var(--selectMenuPaddings)
  border-radius: var(--selectMenuBorderRadius)
  border: var(--selectMenuBorder)
  border-top-color: var(--colorPrimary)
  box-shadow: var(--selectMenuShadow)
  overflow: hidden

  &__list
    padding: 0

  &__item
    width: 100%
    height: var(--selectItemHeight)
    padding: var(--selectItemPadding)
    font-size: var(--selectItemFS)
    line-height: var(--selectItemLH)
    font-weight: var(--selectItemFW)
    color: var(--selectItemColor)
    text-align: var(--selectItemTextAlign)
    white-space: var(--selectItemWhiteSpace)
    &:not(.is-disabled).hover
    &:not(.is-disabled):hover
      background-color: var(--selectItemHover)
    &.is-disabled
      color: var(--selectDisabledItemColor)
    &.selected
      position: relative
      font-weight: var(--selectActiveItemFW)
      color: var(--selectActiveItemColor)
      ~/.is-multiple &
        color: var(--colorText)
      &.hover
      &:hover
        ~/.is-multiple &
          background-color: var(--selectItemHover)

    ~/.is-multiple &:after
      content: ''
      absTR(50%, 20px)
      display: flex
      align-items: center
      justify-content: center
      width: 16px
      height: 16px
      border-radius: 4px
      border: 1px solid var(--colorPrimary)
      transform: translateY(-50%)
    &.selected
      ~/.is-multiple &:before
        content: none
      ~/.is-multiple &:after
        content: '\e93f'
        font-family: 'icomoon'
        // font-family: var(--ffMaterial)
        font-size: 8px
        color: #fff
        outline: 2px solid #88ceff
        background-color: var(--colorPrimary)
        border-color: var(--colorPrimary)

  .popper__arrow
    display: none
