.widget-mode-dissalowed .errorPage {
  position: relative;
  height: auto;
  padding: 80px 24px;
}
.errorPage {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #fcfcff;
  animation: manage-error-appearence 0.3s ease;
}
.errorPage.widget-mode-dissalowed {
  position: relative;
  height: auto;
  padding: 80px 24px;
}
.errorPage__logo {
  cursor: pointer;
}
.errorPage__logo svg {
  width: 120px;
  height: 90px;
}
.errorPage__container {
  position: relative;
  padding: 0 20px;
  text-align: center;
}
.errorPage__title {
  margin-top: -80px;
  font-family: 'AdamantXBold', sans-serif;
  font-size: 55px;
  line-height: 65px;
  color: #6b7085;
  margin-bottom: 35px;
}
@media screen and (max-width: 768px) {
  .errorPage__title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 30px;
    line-height: 40px;
  }
}
.errorPage__number {
  font-family: 'AdamantXBold', sans-serif;
  font-size: 144px;
  line-height: 144px;
  color: #ececec;
}
@media screen and (max-width: 768px) {
  .errorPage__number {
    font-size: 70px;
    line-height: 70px;
  }
}
.errorPage__paragraph {
  font-family: 'AvenirNext', sans-serif;
  font-size: 16px;
  line-height: 30px;
  color: #a8b7cf;
}
.errorPage__paragraph a {
  color: #5aa7ff;
}
@media screen and (max-width: 768px) {
  .errorPage__paragraph {
    font-size: 14px;
    line-height: 24px;
  }
}
.errorPage__home-link {
  display: inline-block;
  margin-top: 50px;
  padding: 14px 22px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  color: #8cdcff;
  border-radius: 4px;
  border: 1px solid currentColor;
  transition: all 0.3s ease;
}
@media screen and (max-width: 768px) {
  .errorPage__home-link {
    margin-top: 30px;
  }
}
.errorPage__home-link:hover {
  color: #5aa7ff;
}
.container_sheet-error {
  margin: 40px auto;
  max-width: 650px;
}
.container_sheet-error h2 {
  margin-bottom: 28px;
}
.container_sheet-error p {
  line-height: 24px;
  margin-bottom: 12px;
}
.container_sheet-error ul {
  list-style-type: auto;
  margin-left: 20px;
  line-height: 28px;
}
.container_sheet-error a {
  color: #1da2ff;
}
.container_sheet-error .container_sheet-error-button {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  background-color: #1da2ff;
  border-radius: 4px;
  padding: 12px 20px;
  margin: 20px 0 20px;
  display: block;
  min-width: 250px;
  min-height: 44px;
}
.container_sheet-error .container_sheet-error-text {
  margin: 20px 0 20px;
}
.container_sheet-error .spread-form {
  margin-top: 32px;
}
.container_sheet-error .spread-form__form-elements {
  margin-left: 0;
  max-width: 100%;
}
/*# sourceMappingURL=../@shared/error-page-component/src/errorPage.css.map */