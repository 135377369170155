.fields-builder
  width: 100%
  padding: 30px 40px 40px

  &__item
    position: relative
    +.fields-builder__item
      margin-top: 16px
    &.is-flex-row
      display: flex
      align-items: flex-end
    &.is-multiline
      flex-wrap: wrap
      margin: -8px -4px
      .fields-builder__item-control
        margin: 8px 4px

  &__item-control
    width: 100%
    +.fields-builder__item-control
      margin-left: 8px
    &.is-half
      width: calc(50% - 8px)
    &.is-third
      width: calc(33.333% - 8px)
    &.is-three-quarters
      width: calc(75% - 8px)
    &.is-quater
      width: calc(25% - 8px)

  &__item-delete-btn
    margin-left: 8px
    font-size: 12px
    color: #A8B7CF
    &:hover
      color: colorDanger

  &__item-add-btn
    cursor: pointer
    font-size: 16px
    line-height: 22px
    font-weight: 500
    color: #1DA2FF
    &.for-draggable-items
      margin-left: 30px
    &:hover
      color: colorPrimaryHover

  &__item-title
    display: flex
    align-items: center
    margin-bottom: 10px
    font-size: 14px
    line-height: 18px
    font-weight: 600
    color: #313131
    &.space-between
      display: flex
      align-items: center
      justify-content: space-between

  &__item-title-btn
    cursor: pointer
    margin-left: 20px
    font-size: 14px
    line-height: 18px
    font-weight: 500
    color: #A8B7CF
    &:hover
      color: colorPrimary
    &.is-danger
      color: colorDanger
      &:hover
        color: colorDangerHover

  &__item-msg
    position: absolute
    top: 100%
    left: 0
    font-size: 11px
    line-height: 16px
    font-weight: 400
    color: colorDanger
    &.for-draggable
      padding-left: 30px
    &.is-flex-row
      display: flex
    p+p
      &:before
        content: ', '
        display: inline

  &__draggable
    .draggable
      +.fields-builder__item-add-btn
        margin-top: 20px

  &__control-toolbar
    flex: none
    display: flex
    padding-left: 8px

  &__control-toolbar-item
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 40px
    font-size: 16px
    color: #A8B7CF
    border: 1px solid #E4E9F1
    border-radius: 4px
    +.fields-builder__control-toolbar-item
      // margin-left: 16px
      margin-left: 8px
    &>[class*="icon-"]
      cursor: pointer
    .icon-circle-close
      &:hover
        color: colorDanger

  &__validations
    margin-top: 40px

  &__validations-title
    margin-bottom: 20px
    font-size: 18px
    line-height: 20px
    font-weight: 600
    color: #323232
    // text-align: center

  &__footer
    margin-top: 34px

  &__footer-btns
    display: flex
    align-items: center
    justify-content: center

  &__footer-btn
    +.fields-builder__footer-btn
      margin-left: 20px
    &.is-loading
      opacity: 0.5
      pointer-events: none

  &__footer-msg
    display: flex
    align-items: flex-start
    width: fit-content
    max-width: 300px
    margin: 15px auto 0
    padding: 12px 16px
    margin-top: 15px
    font-size: 14px
    line-height: 18px
    font-weight: 400
    color: #313131
    background-color: alpha(#ff4d4d, 0.2)
    border: 1px solid colorDanger
    border-radius: 4px

  &__footer-msg-icon
    margin-top: 2px
    color: colorDanger
    margin-right: 8px
