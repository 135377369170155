.page-ai-creator
  display: flex
  width: 100%
  padding-bottom: 0

  &__left
    display: flex
    justify-content: center
    align-items: center
    padding: 32px 24px
    width: 40%

  &__right
    display: flex
    flex-direction: column
    width: 60%
    min-width: 800px

  &__left-container
    mw(500)

  &__title
    margin-bottom: 40px
    font-family: $PTSans
    font-size: 56px
    line-height: 1.2
    font-weight: bold

  &__subtitle
    textL(500)

  &__form-wrap
    width: 100%
    margin-top: 24px

  &__footnote
    margin-top: 40px
    textM()
    color: #798496

  &__hero
    position: relative
    width: 100%
    flex: 1
    background: linear-gradient(290deg, #9DD4FF, #1E9DFF)
    overflow: hidden
    // bgImgCenter()

  &__hero-img
    width: 100%
    max-width: 627px
    max-height: none
    absCenter()

  &__flow-wrap
    display: flex
    flex-direction: column
    flex: 1
    width: 100%
    min-height: 0
    max-height: calc(100vh - var(--headerHeight))
    overflow: hidden
    background: linear-gradient(290deg, #9DD4FF, #1E9DFF)

  &__result-wrap
    flex: 1
    width: 100%
