.ai-creator-form
  width: 100%

  &__hint
    margin-bottom: 24px
    textL(500)
    b
      font-weight: bold

  &__prompt
    textM(400)
    b
      font-weight: bold

  &__tags
    display: flex
    flex-wrap: wrap
    gap: 8px
    margin-top: 16px

  &__textarea
    width: 100%
    height: 300px
    padding: 12px 16px
    textM(400)
    border: 1px solid var(--colorControlBorder)
    border-radius: 4px
    resize: none
    outline: none
    transition: all .2s
    +mediaHover()
      &:not(:disabled):not(:focus):hover
        border-color: var(--colorControlBorder--hover)
    &:not(:disabled):focus
      border-color: var(--colorControlBorder--focus)
    &:disabled
      cursor: not-allowed
      color: var(--colorTextSecondary)
      background-color: #EFF3FB
    &::placeholder
      color: var(--colorTextSecondary)

  &__tag
    cursor: pointer
    display: inline-flex
    align-items: center
    width: fit-content
    height: var(--height-control-s)
    padding: 0 8px
    font-size: 14px
    line-height: 16px
    font-weight: 500
    color: #465164
    white-space: nowrap
    background-color: var(--colorGrayLight-15)
    border-radius: 4px
    border: 1px solid transparent
    transition: all .2s
    +mediaHover()
      &:not(.is-disabled):not(.is-active):hover
        background-color: var(--colorGrayLight-35)
    &:not(.is-disabled):not(.is-active):active
      background-color: var(--colorGrayLight-55)
    &.is-active
      background-color: #798496
      color: #fff
    &.is-disabled
      cursor: not-allowed
      opacity: .5

  &__btns
    display: flex
    align-items: center
    gap: 8px
    margin-top: 40px
