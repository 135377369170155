// @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Material+Icons')
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900')

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/avenir/AvenirNextCyr-UltraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/avenir/AvenirNextCyr-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/avenir/AvenirNextCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/avenir/AvenirNextCyr-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/avenir/AvenirNextCyr-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/avenir/AvenirNextCyr-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AdamantXBold';
  src: url('../fonts/adamant/PFAdamantPro-XBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/pt-sans/PTSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


@font-face
  font-family: 'Material Icons'
  font-style: normal
  font-weight: normal
  src: url('../fonts/material/MaterialIconsOutlined-Regular.otf') format('opentype')
  font-display: block

@font-face
  font-family: 'Material Icons Filled'
  font-style: normal
  font-weight: normal
  src: url('../fonts/material/MaterialIcons-Regular.ttf') format('truetype')
  font-display: block

.material-icons
  font-family: 'Material Icons'
  font-weight: normal
  font-style: normal
  font-size: inherit  /* Preferred icon size */
  display: inline-block
  line-height: 1
  text-transform: none
  letter-spacing: normal
  word-wrap: normal
  white-space: nowrap
  // direction: ltr
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale
  /* Support for IE. */
  font-feature-settings: 'liga'

.material-icons-filled
  font-family: 'Material Icons Filled'
  font-weight: normal
  font-style: normal
  font-size: inherit  /* Preferred icon size */
  display: inline-block
  line-height: 1
  text-transform: none
  letter-spacing: normal
  word-wrap: normal
  white-space: nowrap
  // direction: ltr
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale
  /* Support for IE. */
  font-feature-settings: 'liga'
