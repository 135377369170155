.menu
  position: fixed
  top: 0
  right: 0
  z-index: 10
  display: flex
  flex-direction: column
  justify-content: center
  width: 300px
  height: 100%
  padding: 50px 30px
  background-color: colorBgGray
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1)
  transform: translateX(100%)
  opacity: 0
  transition: all .3s ease
  &.is-opened
    opacity: 1
    transform: translateX(0)

  &__nav
    display: flex
    flex-direction: column
    align-items: flex-start

  &__nav-item
    margin-bottom: 20px
    font-size: 25px
    line-height: 35px
    font-weight: 600
    transition: color .3s ease
    &:hover
      color: colorLightBlue
    &:last-of-type
      margin-bottom: 0

  &__auth
    margin-top: 50px

  &__signin
    padding: 10px 30px
    font-size: 14px
    font-weight: 600
    color: #fff
    border-radius: 4px
    background-color: colorPrimary
    transition: background-color .3s ease
    &:hover
      background-color: colorPrimaryHover

  &__profile-info
    display: flex
    justify-content: space-between

  &__signout
    width: 48%
    padding: 8px 0
    font-size: 14px
    font-weight: 600
    color: colorLightBlue
    border-radius: 4px
    border: 1px solid colorLightBlue
    transition: all .3s ease
    &:hover
      background-color: colorLightBlue
      color: #fff

  &__account
    display: flex
    align-items: center
    justify-content: center
    width: 48%
    font-size: 14px
    color: #fff
    font-weight: 600
    background-color: colorPrimary
    border-radius: 4px
    transition: all .3s ease
    &:hover
      background-color: colorPrimaryHover
