.code-textarea
  width: 100%
  font-size: 14px
  background-color: var(--bgColor)
  border-radius: 4px
  --bgColor: #f9f9f9
  --activeLineBg: var(--colorPrimary-07)

  .cm-editor
    height: 255px
    border: 1px solid var(--colorControlBorder)
    border-radius: 4px
    overflow: hidden
    transition: border-color .2s
    &:hover
      border-color: var(--colorControlBorder--hover)
    &.cm-focused
      outline: none
      border-color: var(--colorControlBorder--focus)

  .cm-content
    // background-color: var(--bgColor)
    .cm-activeLine
      background-color: var(--activeLineBg)

  // .cm-selectionBackground
  //   background-color: red

  .cm-gutters
    background-color: var(--bgColor)
    color: var(--colorTextSecondary)
    border-right-color: var(--colorControlBorder)
    .cm-activeLineGutter
      background-color: var(--activeLineBg)
    .cm-lineNumbers .cm-gutterElement
      min-width: 25px
    .cm-gutterElement
      span[title="Fold line"]
        display: block
        line-height: 12px
