.v-text-field
  margin: 0
  padding: 0

  &__details .v-messages
    min-height: auto

  & .v-label
    font-size: 16px
    color: colorDarkerGray

    // &.v-label--active
    //   top: 10px

  & input
    font-size: 14px
    max-height: 42px
    &::placeholder
       color: #ACB9CD !important
    &:not([role="combobox"])
      border-bottom: 1px solid colorGray

  & .v-input__prepend-inner
  & .v-input__append-inner
    margin-top: 0


.theme--light.v-input:not(.v-input--is-disabled) input, .theme--light.v-input:not(.v-input--is-disabled) textarea
  color: colorBlack
  text-overflow: ellipsis
.v-text-field > .v-input__control > .v-input__slot:after
.v-text-field > .v-input__control > .v-input__slot:before
  content: none
