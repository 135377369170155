.onboarding
  --onboardingTriggerSize: 60px
  --onboardingTriggerIndent: 24px
  position: fixed
  bottom: 24px
  right: 24px
  z-index: 5
  width: 60px
  height: 60px

  &__progress
    cursor: pointer
    width: 100%
    height: 100%
    user-select: none
    border-radius: 50%
    box-shadow: 0px 22px 17px rgba(0, 0, 0, 0.04), 0px 6px 5px rgba(0, 0, 0, 0.02)
    transition: box-shadow .2s
    &:hover
      box-shadow: 0px 22px 17px rgba(0, 0, 0, 0.06), 0px 6px 5px rgba(0, 0, 0, 0.03)

  &__progress-bar
    pointer-events: none
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    path
      stroke-linecap: square
    .el-progress-circle__path
      stroke: var(--colorPrimary)

  &__progress-inner
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    text-align: center
    background-color: #F1F8FF
    border-radius: 50%

  &__progress-value
    font-size: 14px
    line-height: 16px
    font-weight: 700
    color: var(--colorPrimary)

  &__progress-title
    font-size: 8px
    line-height: 12px
    color: #465164

  &__popup
    position: fixed
    bottom: var(--onboardingTriggerIndent)
    right: calc(var(--onboardingTriggerSize) + var(--onboardingTriggerIndent) + 16px)
    width: 320px
    max-height: calc(100vh - var(--headerHeight) - var(--onboardingTriggerIndent) * 2 )
    overflow: auto
