.aside-select
  position: relative
  // padding-bottom: 10px
  &.paddingless
    padding-bottom: 0

  &__field-icon
    display none
    position: absolute
    bottom: 12px
    right: 0
    font-size: 10px
    color: colorLightBlue

  & .field-icon
    margin-right: 10px
    width: 16px

  & .field-name
    display: flex
    align-items: center
    padding-bottom: 2px
    font-size: 16px
    font-weight: 600
    color: colorDarkerGray
    & .icon
      margin-right: 10px
      color: colorLightBlue

  & .list-title
    margin-bottom: 20px
    padding: 0 20px
    font-size: 14px
    line-height: 18px
    font-weight: 600
    color: colorBlacks

  & .v-select
    display: flex
    align-items: center
    border-radius: 4px
    border: 1px solid #E4E9F1

    & .v-input__control
      padding: 5px 13px
      min-height: 30px

    & .v-select__selections input
      padding: 0
      border: none
      &::placeholder
        color: #A8B7CF

  & .v-select__slot
    align-items: center
    & .v-input__append-inner
      align-self: center
