screen = {
  laptop: 1280px,
  tablet: 768px,
  mobile: 500px
}

mobile()
  @media screen and (max-width screen.mobile)
    {block}

tablet()
  @media (min-width screen.mobile) and (max-width screen.tablet)
    {block}
tablet-max()
  @media screen and (max-width screen.tablet)
    {block}

laptop()
  @media (min-width screen.tablet) and (max-width screen.laptop)
    {block}
laptop-max()
  @media screen and (max-width screen.laptop)
    {block}
