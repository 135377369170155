.spreadviews-filters
  width: 100%
  color: #323232

  &__section
    +.spreadviews-filters__section
      margin-top: 16px
      padding-top: 16px
      border-top: 1px solid colorGray

  &__section-head
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    margin-bottom: 16px

  &__section-title
    font-size: 16px
    line-height: 20px
    font-weight: 600

  &__section-head-btn
    flex: none
    display: flex
    align-items: center
    justify-content: center
    width: 20px
    height: 20px
    font-size: 10px
    padding-left: 1px
    color: #616A78
    border: 1px solid #A8B7CF
    border-radius: 2px
    transition: all .2s
    &:hover
      background-color: #E4E9F1
      border-color: #E4E9F1

  &__add-form
    margin-bottom: 3px
    transition: all .2s

  &__add-form-input-wrap
    display: flex
    align-items: center

  &__add-form-input
    width: 100%
    height: 30px
    padding: 0 12px 0 22px
    font-size: 16px
    line-height: 30px
    color: #323232
    transition: opacity .2s
    &::placeholder
      color: #A8B7CF
    &[readonly]
      cursor: not-allowed
      opacity: .5

  &__add-form-btn
    flex: none
    font-size: 16px
    color: #A8B7CF
    transition: color .2s
    &:not(:disabled):hover
      color: colorDanger
    &:disabled
      cursor: not-allowed
    &.icon-loader
      animation: loader-rotation 1s infinite linear

  &__add-form-msg
    padding-left: 22px
    font-size: 10px
    line-height: 16px
    color: colorDanger

  &__list
    width: 100%

  &__item
    cursor: pointer
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    border-radius: 4px
    border: 1px solid transparent
    overflow: hidden
    transition: all .2s
    &:hover
      background-color: #f1f8ff
      .spreadviews-filters__item-drag-trigger
        opacity: 1
      .spreadviews-filters__item-dropdown
        opacity: 1
    &.is-disabled
      pointer-events: none
      opacity: .5
    &.is-active
      background-color: #f1f8ff
      border-color: #E4E9F1

  &__item-left
    display: flex
    align-items: center
    width: 100%
    padding: 10px 36px 10px 22px
    overflow: hidden

  &__item-drag-trigger
    cursor: grab
    position: absolute
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    width: 22px
    height: 100%
    font-size: 12px
    color: #A8B7CF
    opacity: 0
    transition: all .2s

  &__item-title
    max-width: 100%
    font-size: 16px
    line-height: 24px
    font-weight: 400
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  &__item-edit-form
    position: relative
    width: 100%

  &__item-edit-input
    width: 100%
    height: 24px
    padding: 0 8px 0 0
    font-size: 16px
    line-height: 24px
    font-weight: 400
    color: #323232
    border-bottom: 1px dashed #1da2ff
    border-radius: 0
    &::placeholder
      color: #A8B7CF

  &__item-edit-msg
    font-size: 10px
    line-height: 16px
    color: colorDanger

  &__item-counter
    flex: none
    margin-left: 3px
    padding: 4px 8px
    font-size: 12px
    line-height: 12px
    font-weight: 500
    color: #616A78
    background-color: #EFF3FB
    border-radius: 2px

  &__item-dropdown
    position: absolute
    top: 50%
    right: 6px
    transform: translateY(-50%)
    opacity: 0
    transition: all .2s

  &__item-dropdown-trigger
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: 24px
    height: 24px
    font-size: 16px
    color: #A8B7CF
    border-radius: 2px
    background-color: transparent
    transition: all .2s
    &:hover
      color: #616A78
      background-color: #D5E5F5

  &__item-dropdown-menu.el-popper
    display: flex
    flex-direction: column
    align-items: flex-start
    min-width: 120px
    .popper__arrow
      display: none
