.sorting-constructor
  padding-top: 10px

  &__item
    position: relative
    margin-bottom: 18px

  &__item-label
    margin-bottom: 6px
    font-size: 12px
    line-height: 18px
    font-weight: 500
    color: #465164

  &__link
    display: inline-block
    margin-top: 5px
    font-size: 14px
    color: colorPrimary
    &:hover
      color: colorPrimaryHover

  &__highlighted
    background:white
    padding: 10px
    margin-left:-10px
    width:calc(100% + 20px)
    border-radius:5px
    border: 1px solid #e4e9f1
  
  &__select-error
    width: 100%
    padding-top: 5px
    font-size: 10px
    line-height: 15px
    font-weight: 500
    color: colorDanger
    animation: collapsable-field-error-appearence .5s ease
