<template>
  <div v-if="showPhCta" class="ph-launch-badge">
    <span class="ph-live-dot"></span>
    <a :href="phLink">We are live on Product Hunt now! 🚀</a>
  </div>
</template>
<script>
export default {
  name: 'PhLaunchBadge',
  data () {
    return {
      showPhCta: false,
      phLink: 'https://www.producthunt.com/posts/spreadsimple-2-0'
    }
  },
  computed: {
    phLaunshIsOn () {
      const baseDate = '2025-04-09'
      const start = new Date(`${baseDate}T00:01:00-07:00`)
      const end = new Date(`${baseDate}T23:59:00-07:00`)
      const now = new Date()
      return now >= start && now <= end
    }
  },
  created () {
    const isStg = process.env.NODE_ENV !== 'production'

    if (this.phLaunshIsOn) {
      this.showPhCta = true
    } else if (isStg) {
      this.showPhCta = true
    }
  }
}
</script>

<style>
.ph-launch-badge {
  margin-right: 20px;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #f63;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-header__right-side .ph-launch-badge {
  margin-right: 0;
}

.ph-launch-badge a {
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  color: #f63;
  line-height: 1;
}
.ph-live-dot {
  width: 8px;
  height: 8px;
  background-color: #08da08;
  border-radius: 100%;
  margin-right: 8px;

  animation: blink 1s step-start 0s infinite;
}
@keyframes blink {
  50% {
    opacity: 0.0;
  }
}

</style>
