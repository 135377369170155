.ai-widget
  width: 100%

  &__form
    width: 100%

  &__form-input
    width: 100%
    height: 200px
    padding: 12px 16px
    font-size: 14px
    line-height: 1.5
    font-weight: 500
    color: var(--colorText)
    background-color: #fff
    border: 1px solid var(--colorControlBorder)
    border-radius: 4px
    outline: none
    resize: none
    transition: all .2s
    &::placeholder
      color: var(--colorControlPlaceholderColor)
    +mediaHover()
      &:not(:disabled):hover
        border-color: var(--colorControlBorder--hover)
    &:not(:disabled):focus
      border-color: var(--colorControlBorder--focus)
    &:disabled
      cursor: not-allowed
      opacity: .65
      border-color: var(--colorControlBorder--disabled)

  &__form-btn
    margin-top: 8px

  &__flow
    display: flex
    flex-direction: column
    gap: 16px
    margin-top: 32px

  &__flow-item
    width: 100%
    padding: 12px 16px 16px
    background-color: #fff
    border: 1px solid var(--colorControlBorder)
    border-radius: 4px
    box-shadow: 0 2px 4px 0 #f4f5fd

  &__flow-item-meta
    display: flex
    flex-direction: column
    gap: 2px
    margin-bottom: 12px

  // &__flow-item-label
  //   box-shadow: 0 2px 4px 0 #f4f5fd

  &__flow-item-title
    font-family: $PTSans
    headingS()

  &__flow-item-subtitle
    textM(500)
    color: var(--colorTextSecondary)


  &__flow-loader
    font-family: Consolas, Menlo, Monaco, monospace
    font-size: 50px
    color: var(--colorTextTertiary)
    font-weight: bold
    opacity: 0.8
    &:before
      content: "{"
      display: inline-block
      animation: flow-loader-pulse 0.4s alternate infinite ease-in-out
    &:after
      content: "}"
      display: inline-block
      animation: flow-loader-pulse 0.4s 0.3s alternate infinite ease-in-out


 @keyframes flow-loader-pulse
  to
    transform: scale(0.8)
    opacity: 0.5
