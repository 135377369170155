.onboarding-popup
  padding: 24px
  border: 1px solid #E4E9F1
  border-radius: 4px
  background-color: #F1F8FF
  box-shadow: 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725)
  >*+*
    margin-top: 16px

  &__head
    padding-right: 38px
    >*+*
      margin-top: 16px

  &__head-title
    font-size: 18px
    line-height: 24px
    font-weight: 700

  &__head-string
    font-size: 14px
    line-height: 20px
    b
      font-weight: 600

  &__btns
    &-wrapper
      display: flex
      justify-content: space-between
    >*+*
      margin-top: 16px

  &__collapse-btn
    position: absolute
    top: 24px
    right: 24px
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: 20px
    height: 20px
    margin-top: 0
    border-radius: 2px
    color: #717E93
    border: 1px solid #ACB9CD
    transition: all .2s
    &:hover
      color: #fff
      background-color: #ACB9CD
    &:after
      content: ''
      display: block
      width: 8px
      height: 2px
      background-color: currentColor

  &__close-btn
    position: absolute
    top: 24px
    right: 24px
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: 26px
    height: 26px
    margin-top: 0
    font-size: 14px
    border-radius: 2px
    color: #717E93
    transition: all .2s
    &:hover
      color: #fff
      background-color: #ACB9CD

  &__stepper-wrap
    width: 100%

  &__stepper
    display: grid
    grid-template-columns: repeat(10, 1fr)
    grid-gap: 4px
    width: 100%

  &__stepper-item
    width: 100%
    height: 8px
    background-color: #ACB9CD
    transition: background-color .2s
    &.is-active
      background-color: var(--colorPrimary)
    &:first-child
      border-radius: 4px 0 0 4px
    &:last-child
      border-radius: 0 4px 4px 0

  &__stepper-value
    margin-top: 16px
    font-size: 16px
    line-height: 24px
    font-weight: 700
    color: var(--colorPrimary)

  &__body
    font-size: 14px
    line-height: 20px
    b
      font-weight: 600
    h4
      font-size: 20px
      line-height: 24px
      font-weight: 700
    h5
      font-size: 18px
      line-height: 22px
      font-weight: 700
    h6
      font-size: 16px
      line-height: 20px
      font-weight: 700
    a
      color: var(--colorPrimary)
      text-decoration: underline
      &:hover
        text-decoration: none
    img
      display: block
      margin-left: auto
      margin-right: auto
      max-height: 400px
    >*+*
      margin-top: 12px
