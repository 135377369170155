.header
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 11
  width 100%
  background-color colorBgGray
  box-shadow:  none
  transition: all .3s ease
  &.for-manage
    position: fixed
  &.scrolled
    box-shadow:  0px 5px 5px 0px rgba(50, 50, 50, 0.05)
  &.has-border-bottom
    border-bottom: 1px solid #E9ECEF

  &__container
    position: relative
    // z-index: 9
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    width 100%
    height: var(--headerHeight)
    // max-width 1280px
    margin 0 auto
    padding 0 var(--containerSidePadding)
    text-align left
    +mobile()
      flex-direction: column
      align-items: flex-start
      height: 110px
      padding: 20px

  &__logo
    display: flex
    align-items: center
    font-size: 12px
    line-height: 12px
    font-weight: 500
    text-transform uppercase
    img
      max-width: 160px
      &.is-ai-creator
        max-width: 212px

  &__right-side
    display: flex
    align-items: center
    padding-left: 50px

  &__search-wrap
    display: flex
    align-items: center
    margin-right: 19px

  &__search-input
    width: 0
    overflow: hidden
    transition: all .3s ease
    &::placeholder
      font-size: 16px
      color: colorLightBlue
      opacity: 0
      transition: opacity .3s ease
    &.expanded
      width: 220px
      margin-left: 10px
      padding-left: 0
      &::placeholder
        opacity: 1
        transition-delay: .3s

  &__search-btn-icon
    cursor: pointer
    width: 20px
    font-size: 16px
    line-height: 20px
    color: colorBlack

  &__menu-btn
    cursor: pointer
    position: relative
    z-index: 11
    width: 16px
    height: 10px

    &:before
    &:after
      content: ''
      position: absolute
      left: 0
      width: 16px
      height: 2px
      border-radius: 1px
      background-color: colorBlack
      transition: all .3s ease
    &:before
      top: 0
    &:after
      bottom: 0

    &.menu-opened
      z-index: 11
      &:before
        top: 3px
        transform: rotate(-45deg)
      &:after
        bottom: 5px
        transform: rotate(45deg)


.header-trialing-bar
  display: flex
  justify-content: space-between
  align-items: center
  background-color: #FFEFC7
  color: #323232
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 30px
  padding: 0 20px
  overflow hidden
  white-space: nowrap
  text-overflow: ellipsis
  &__close-btn
    color: #CAD3E0
    transition: all .3s ease
    &:hover
      color: #000000
  &.is-maintenance
    white-space: inherit
  &.is-discount
    background-color: white
    white-space: inherit
    & .timer-wrapper
      color: red
  &.is-absolute
    position absolute
    top: 0
    left: 0
    width: 100%
  &.is-small
    display: block
    margin-right: 20px
    border-radius: 5px
    padding: 0 15px

  & .highlighted
    color: #1DA2FF
    cursor: pointer

.header-resubscribe-bar
  display: flex
  align-items center
  background-color: var(--colorPrimary)
  color: white
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 17px
  padding: 4px 56px 4px var(--containerSidePadding)
  overflow hidden
  white-space: normal
  text-overflow: ellipsis
  position: relative
  span
    margin-right: 30px
  &__btn
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    height: 40px
    padding: 0 20px
    font-size: 14px
    line-height: 17px
    font-weight: 600
    color: #fff
    text-transform: uppercase
    text-align: center
    border-radius: 4px
    transition: all .2s
    color: var(--colorPrimary)
    background-color: #fff
    border: 1px solid var(--colorPrimary)
    white-space: nowrap
    &:hover
      // color: #fff
      background-color: var(--colorBtnSecondaryBg--hover)
      border: 1px solid var(--colorPrimary)
  &__close-btn
    position: absolute
    top: calc(50% - 12px)
    right: 16px
    display: flex
    align-items: center
    justify-content: center
    width: 24px
    height: 24px
    font-size: 10px
    border-radius: 50%
    background-color: var(--colorDanger)
    color: #fff
    box-shadow: 0px 16px 31px rgba(10, 35, 94, 0.24), 0px 3px 5px rgba(10, 35, 94, 0.1)


.header-affiliate-link
  a
    font-size: 14px
    font-weight: 500
    margin-left: 10px
    margin-right: 20px
