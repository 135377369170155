.modal-cancelation-result
  color: colorBlack
  padding: 17px 30px 38px
  text-align: left

  &__paragraph
    padding: 10px

    &--accent
      font-weight: 700

  &__actions
    display: flex
    align-items: center
    justify-content: flex-end

  &__button
    margin: 0 20px
    padding: 5px 20px
    font-size: 14px
    font-weight: 600
    line-height: 30px
    letter-spacing: 0.3px
    text-transform: uppercase
    color: #fff
    background-color: colorPrimary
    border-radius: 4px
    transition: background-color .3s ease
    &:disabled
      opacity: 0.5
      &:hover
        background-color: colorPrimary
    &:hover
      background-color: colorPrimaryHover
    &.negative
      color: #798496
      border: 1px solid #CAD3E0
      background-color: transparent
      &:hover
        color: #fff
        background-color: #CAD3E0
