.title-editor
  position: relative
  width: fit-content
  max-width: 100%

  --font-size: 13px
  --line-height: 18px
  --font-weight: 600
  --field-height: 18px
  --icon-size: 12px
  --icon-color: var(--colorTextTertiary)
  --icon-color--hover: var(--colorText)

  &__form
    display: flex
    align-items: center
    gap: 4px
    width: 100%

  &__value-saved
    font-size: var(--font-size)
    line-height: var(--line-height)
    font-weight: var(--font-weight)
    ellipsis()

  &__input
    width: 100%
    height: var(--field-height)
    font-size: var(--font-size)
    line-height: var(--line-height)
    font-weight: var(--font-weight)
    color: var(--colorText)

  &__btn-edit
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: var(--field-height)
    height: var(--field-height)
    font-size: var(--icon-size)
    line-height: 1
    color: var(--icon-color)
    text-align: center
    transition: all .2s
    &:hover
      color: var(--icon-color--hover)

  &__error
    position: absolute
    bottom: auto
    top: calc(100% + 5px)
    left: 0
    font-size: 12px
    color: var(--danger)
    transform: translateY(0)
