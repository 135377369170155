.integrations
  width: 100%
  max-width: 1000px
  margin: 0 auto
  padding: 25px 30px 15px
  // overflow: scroll

  &__head
    display: flex
    margin-bottom: 25px

  &__head-search-wrap
    position: relative
    width: 100%
    max-width: 475px
    margin-left: auto

  &__head-search
    width: 100%
    height: 40px
    padding: 0 60px 0 15px
    font-size: 16px
    line-height: 20px
    font-weight: 400
    color: #323232
    background-color: #fff
    border: 1px solid #F2F2F7
    border-radius: 4px
    transition: box-shadow .2s ease
    &::placeholder
      color: #798496
    &:hover
    &:focus
      box-shadow: 0 4px 10px 0 rgba(0,0,0,.05)

  &__head-search-btn
    position: absolute
    top: 0
    right: 0
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 100%
    color: #A8B7CF
    &:before
      transform: scale(-1,1)
    &:hover
      color: colorPrimary
      &+.integrations__head-search
        box-shadow: 0 4px 10px 0 rgba(0,0,0,.05)

  &__list
    display: flex
    flex-wrap: wrap
    &.is-centered
      justify-content: center

  &__not-found
    margin: 250px 0 0
    & .is-centered
      text-align: center
      margin: auto
    & .btn
      margin: 15px auto 0


  &__item
    cursor: pointer
    display: flex
    flex-direction: column
    background-color: #fff
    border-radius: 4px
    box-shadow: 0px 10px 20px rgba(43, 50, 68, 0.08)
    overflow: hidden
    transition: transform .2s ease
    &:not(.is-disabled):hover
      transform: translateY(-10px)
    &.is-disabled
      cursor: default
      opacity: 0.8
      box-shadow: 0px 2px 4px rgba(43, 50, 68, 0.06)
    &:not(.three-in-row):not(.is-single)
      +desktop()
        flexGrid(4,16,16)
      +laptop()
        flexGrid(3,16,15)
    &.three-in-row
      flexGrid(3,16,16)
    &.is-single
      width: 270px

  &__item-img-bg
    display: flex
    align-items: center
    justify-content: center
    flex: none
    height: 160px
    position: relative

  &__item-img-wrap
    display: flex
    align-items: center
    justify-content: center
    width: 130px
    height: 130px
    padding: 20px
    border-radius: 50%
    background-color: #fff

  &__item-img
    max-height: 100%

  &__item-body
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    height: 100%
    padding: 12px 20px 20px

  &__item-content
    width: 100%

  &__item-title
    font-size: 16px
    line-height: 24px
    font-weight: 500
    color: #323232

  &__item-paragraph
    margin-top: 6px
    font-size: 14px
    line-height: 22px
    color: #798496

  &__item-status
    display: flex
    align-items: center
    height: 24px
    margin-top: 10px
    // margin-right: 10px
    padding: 0 12px
    font-size: 12px
    line-height: 14px
    color: #A8B7CF
    font-weight: 600
    border-radius: 4px
    background-color: #F2F4F8
    &.is-active
      background-color: colorPrimary
      color: #fff

  &__item-pro-status
    position: absolute
    top: 10px
    right: 10px
    display: flex
    align-items: center
    background-color: #03da8d
    color: #fff
    height: 24px
    padding: 0 12px
    font-size: 12px
    line-height: 14px
    font-weight: 600
    border-radius: 4px
