.el-popper
  --popperPlacementBottomIndent: 5px
  --popperPlacementTopIndent: 5px
  --popperPlacementlLeftIndent: 5px
  --popperPlacementRightIndent: 5px

  --popperBg: var(--colorPopoverBg)
  --popperBorderColor: var(--colorBorder)

  &[x-placement^=bottom]
    margin-top: var(--popperPlacementBottomIndent)
    .popper__arrow
      border-bottom-color: var(--popperBorderColor)
      &:after
        border-bottom-color: var(--popperBg)
  &[x-placement^=top]
    margin-bottom: var(--popperPlacementTopIndent)
    .popper__arrow
      border-top-color: var(--popperBorderColor)
      &:after
        border-top-color: var(--popperBg)
  &[x-placement^=left]
    margin-right: var(--popperPlacementLeftIndent)
    .popper__arrow
      right: -7px
      border-left-color: var(--popperBorderColor)
      &:after
        border-left-color: var(--popperBg)
  &[x-placement^=right]
    margin-left: var(--popperPlacementRightIndent)
    .popper__arrow
      left: -7px
      border-right-color: var(--popperBorderColor)
      &:after
        border-right-color: var(--popperBg)
