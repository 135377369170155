.switcher
  position: relative
  display: block
  flex: none
  width: 40px
  height: 24px
  border-radius: 100px
  border: 1px solid #e4e9f1
  cursor: pointer
  transition: all .3s ease
  &.selected
    border-color: #B1DCFF
    & .switcher__indicator
      border-color: colorPrimary
      background-color: colorPrimary
      transform: translateX(16px)

  &__indicator
    position: absolute
    top: -1px
    left: -1px
    display: block
    width: 24px
    height: 24px
    background-color: #A8B7CF
    border: 1px solid #A8B7CF
    border-radius: 50%
    transition: all .3s ease

  &__input
    display: none
