.el-dialog__wrapper
  // Dialog wrapper
  --dialogWrapPaddings: 20px
  // Dialog window
  --dialogWidth: 100%
  --dialogMaxWidth: 1000px
  --dialogHeight: auto
  --dialogMargins: 0 auto
  --dialogBgColor: var(--colorPopoverBg)
  --dialogTextColor: var(--colorText)
  --dialogBRadius: 4px
  --dialogBoxShadow: none
  // Dialog title
  --dialogTitlePaddingRight: 40px
  --dialogTitleFS: 24px
  --dialogTitleLH: 32px
  --dialogTitleFW: 600
  --dialogTitleColor: inherit
  // Dialog inner
  --dialogHeaderPaddings: 0
  --dialogBodyPaddings: 48px 32px 32px
  // Body
  --dialogBodyFS: 16px
  --dialogBodyLH: 22px
  --dialogBodyFW: 400
  --dialogBodyWordBreak: break-word
  // Close btn
  --dialogCloseTop: 16px
  --dialogCloseRight: 16px
  --dialogCloseIconSize: 24px
  --dialogCloseColor: #CAD3E0
  --dialogCloseColorHover: var(--colorPrimary)
  --dialogCloseTransition: all .2s


.el-dialog
  position: relative
  width: var(--dialogWidth)
  max-width: var(--dialogMaxWidth)
  height: var(--dialogHeight)
  margin: var(--dialogMargins)
  background: var(--dialogBgColor)
  border-radius: var(--dialogBRadius)
  box-shadow: var(--dialogBoxShadow)

  &__wrapper
    padding: var(--dialogWrapPaddings)

  &__header
    padding: var(--dialogHeaderPaddings)

  &__title
    padding-right: var(--dialogTitlePaddingRight)
    font-size: var(--dialogTitleFS)
    line-height: var(--dialogTitleLH)
    font-weight: var(--dialogTitleFW)
    color: var(--dialogTitleColor)

  &__headerbtn
    top: var(--dialogCloseTop)
    right: var(--dialogCloseRight)
    display: flex
    align-items: center
    justify-content: center
    z-index: 1
    font-size: var(--dialogCloseIconSize)
    line-height: normal
    color: var(--dialogCloseColor)
    transition: all .2s
    &:before
      content: '\e5cd'
      font-size: inherit
      color: inherit
      font-family: 'Material Icons'
    &:hover
      color: var(--dialogCloseColorHover)

    // .el-dialog__close
    //   color: var(--dialogCloseColor)
    // &:focus
    // &:hover
    //   background-color: colorPrimaryBleachedLight
    //   .el-dialog__close
    //     color: var(--dialogCloseColor)

  &__close
    display: none
    // transition: var(--dialogCloseTransition)
    // &:before
    //   content: '\e5cd'
    //   font-family: var(--ffMaterial)

  &__body
    padding: var(--dialogBodyPaddings)
    font-size: var(--dialogBodyFS)
    line-height: var(--dialogBodyLH)
    font-weight: var(--dialogBodyFW)
    color: var(--dialogTextColor)
    word-break: var(--dialogBodyWordBreak)

.v-modal
  --dialogWrapperColor: alpha(#1C1F22, .8)
  background: var(--dialogWrapperColor)
  opacity: 1
