.form-field
  display: flex
  align-items: center
  padding-bottom: 30px
  &:last-of-type
    padding-bottom: 0
  &.padingless
    padding-bottom: 0

  &__label
    width: 46%
    padding-right: 10px
    font-size: 14px
    line-height: 20px
    font-weight: 600
    color: colorBlack

  &__control
    position: relative
    display: flex
    width: 54%
    &.align-end
      justify-content: flex-end
    &.full-width
      width: 100%
    &.picker-inside .v-text-field__slot input
      padding-right: 30px

  &__validated-control
    width: 100%

  &__error
    position: absolute
    top: 100%
    left: 0
    width: 100%
    // padding-top: 5px
    font-size: 10px
    line-height: 15px
    font-weight: 500
    color: colorDanger
    animation: collapsable-field-error-appearence .5s ease
