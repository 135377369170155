.settings
  --settingsSideIndent: 30px

.settings
  position: relative
  width: 100%
  height: 100%
  padding-bottom: 100px
  // overflow-y: scroll
  // -webkit-overflow-scrolling: touch

  &__head
    display: flex
    flex-direction: column
    gap: 8px
    padding: 24px var(--settingsSideIndent) 10px var(--settingsSideIndent)

  &__head-top
    display: flex
    align-items: center
    justify-content: space-between
    gap: 12px
    width: 100%

  &__title
    font-size: 24px
    line-height: 34px
    font-weight: 700
    color: var(--colorText)

  &__head-bottom
    textS()
    color: var(--colorTextSecondary)

  &__close-sidebar
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    font-size: 13px
    color: var(--colorGrayLight)
    border-radius: 6px
    transition: all .2s
    &:hover
      color: var(--colorTextSecondary)
      background-color: var(--ghost-hover)

  &__head-bottom
    width: 100%

  &__body
    position: relative
    padding: 30px var(--settingsSideIndent) 0

  &__form
    width: 100%

  &__disabling-section
    position: relative
    &:after
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: #fff
      opacity: .7
      transition: all .3s ease
    &.enabled
      &:after
        z-index: -1
        opacity: 0
        animation: disabling-section-appear .5s ease

  &__back-btn
    display: flex
    align-items: center
    margin-bottom: 25px
    font-size: 14px
    color: #ACB9CD
    font-weight: 600
    transition: color .2s ease
    &:hover
      color: colorPrimary

  &__back-btn-icon
    font-size: 18px
    margin-right: 8px
    transform: translateY(-1px)

  &__switcher
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 30px

  &__switcher-label
    width: 40%
    padding-right: 10px
    font-size: 14px
    line-height: 20px
    font-weight: 600
    color: colorBlack
