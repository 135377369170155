.template-preview
  display: flex
  flex-direction: column
  align-items: flex-start
  width: 100%
  // @media (prefers-color-scheme: dark)
  //   background-color: #222

  &__header
    display: flex
    align-items: center
    flex: none
    width: 100%
    height: 50px
    background-color: #fff
    border-bottom: 1px solid var(--colorBorder)
    // @media (prefers-color-scheme: dark)
    //   background-color: #35363A
    //   border-bottom-color: transparent

  &__header-container
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    padding: 0 20px
    max-width: none

  &__header-logo
    display: flex
    align-items: flex-start
    width: 140px

  &__header-logo-img-text
    fill: #444
    // @media (prefers-color-scheme: dark)
    //   fill: #fff

  &__header-right
    display: flex
    align-items: center
    gap: 24px

  &__header-view-switcher
    display: flex
    align-items: center
    gap: 2px

  &__header-radio
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: 32px
    height: 32px
    color: #c8c8c8
    border-radius: 4px
    transition: all .2s
    &.is-active
      background-color: var(--colorPrimary)
      color: #fff

  &__header-radio-icon
    display: flex
    align-items: center
    justify-content: center
    font-size: 18px

  &__header-cta-btn.btn
    width: fit-content

  &__desktop
    display: flex
    width: 100%
    flex: 1

  &__iframe
    flex: 1
    width: 100%

  &__phone-wrap
    display: flex
    flex: 1
    width: 100%
    max-width: 395px
    margin: 0 auto
    padding: 24px 0

  &__phone
    position: relative
    display: flex
    width: 100%
    color: var(--colorBorder)
    border: 8px solid currentColor
    border-radius: 40px
    overflow: hidden
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 24px rgba(0, 0, 0, 0.12)
    // @media (prefers-color-scheme: dark)
    //   color: #44454a
    //   box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3), 0px 2px 24px rgba(0, 0, 0, 0.3)
    &:after
      content: ''
      absTL(0, 50%)
      transform: translateX(-50%)
      width: 150px
      height: 12px
      background-color: currentColor
      border-radius: 0 0 8px 8px

  &__phone-iframe
    display: block
    width: 100%

  &__nocontent
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    flex: 1
    color: #222
    // @media (prefers-color-scheme: dark)
    //   color: white
    & div
      margin-bottom: 20px
