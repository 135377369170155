.promo-code
  padding: 24px 20px 33px
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  width 100%

  &__title
    font-size: 23px
    line-height: 45px
    font-weight: 700
    color: #323232
    text-align: center
    margin-bottom: 20px

  &__wrapp
    display: flex
    align-items: center

  &__field
    display: flex
    width: 100%
    max-width: 450px
    margin: 10px auto 0
    font-size: 12px

  &__input-wrapp
    display: flex
    align-items: center
    width: 100%
    height: 40px
    padding: 0 16px
    border: 1px solid colorPrimary
    border-radius: 4px

  &__input
    width: 100%
    color: colorBlack
    transition: all .3s ease
    &:hover
      color: #000

  &__button
    margin-top: 32px
    padding: 10px 23px
    font-size: 14px
    color: #fff
    font-weight: 500
    text-transform: uppercase
    background-color: colorPrimary
    background-size: 200%
    background-position: top left
    border-radius: 4px
    transition: all .3s ease
    &:disabled
      opacity 0.5
    &:hover
      background-position: center
      background-color: colorPrimaryHover

  &__error
    margin-top: 10px
    font-size: 12px
    line-height: 20px
    color: colorDanger
    text-align: left

  &__success
    margin-top: 10px
    font-size: 12px
    line-height: 20px
    color: #208b54
    text-align: left
