.ui-msg-box
  display: flex
  align-items: flex-start
  gap: var(--gap)
  width: 100%
  border: 1px solid

  &--size-m
    --gap: 6px
    --iconSize: 18px
    --fontSize: var(--font-size-text-s)
    padding: 8px 12px
    border-radius: 6px

  &--size-s
    --gap: 6px
    --iconSize: 14px
    --fontSize: var(--font-size-text-xs)
    padding: 6px 8px
    border-radius: 4px

  &--color-success
    color: var(--colorSuccess)
    background-color: var(--colorSuccess-10)
    border-color: var(--colorSuccess)
  &--color-primary
    color: var(--colorPrimary)
    background-color: var(--colorPrimary-10)
    border-color: var(--colorPrimary)
  &--color-secondary
    color: var(--colorGray)
    background-color: var(--colorGrayLight-15)
    border-color: var(--colorGrayLight)
  &--color-danger
    color: var(--colorDanger)
    background-color: var(--colorDanger-10)
    border-color: var(--colorDanger)
  &--color-warning
    color: var(--warning)
    background-color: var(--warning-alpha-10)
    border-color: var(--warning)

  &__icon
    flex: none
    margin-top: 1px
    font-size: var(--iconSize)

  &__title
    flex: 1
    font-size: var(--fontSize)
    line-height: 1.4
    font-weight: 500
    a:not([class]), button:not([class])
      color: inherit
      font-weight: 500
      text-decoration: underline
      +hover()
        text-decoration: none
    // color: var(--colorText)
