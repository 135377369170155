.aside
  position: relative
  display: flex
  flex: none
  height: calc(100vh - 69px)
  transition: 0.3s ease
  &.fixed
    position: fixed
    top: 69px
    left: 0
    z-index: 9
  &.opened
    transform: translateX(0)

  &__close
    cursor: pointer
    position: absolute
    top: 29px
    right: 30px
    z-index: 2
    display: flex
    align-items: center
    padding: 5px
    font-size: 14px
    color: colorLightBlue
    transition: 0.3s ease-out
    &:hover
      color: colorDanger

  &__body
    width: 0
    height: 100%
    background-color: colorBgGray
    border-right: 1px solid colorGray
    overflow-y: auto
    scrollbar-width: thin
    scrollbar-color: #d9dfe9 #fff
    -webkit-overflow-scrolling: touch
    transition: all .3s ease
    &::-webkit-scrollbar
      width: 8px
    &::-webkit-scrollbar-track
      background-color: #fff
    &::-webkit-scrollbar-thumb
      background-color: #d9dfe9
      border-radius: 20px
      border: 2px solid #fff
    &.opened
      width: 360px
      & .aside__body-inner
        opacity: 1
        pointer-events: all
        transition-delay: .2s
      & .aside-save
        left: 70px

  &__body-inner
    pointer-events: none
    opacity: 0
    transition: all .3s ease

  &__controls-wrap
    position: relative
    z-index: 2
    flex: none
    width: 70px
    background-color: colorBgGray
    border-right: 1px solid colorBorder
    transition: all .3s ease

  &__validation-message
    position: absolute
    top: 0
    left: 0
    width: 100%
    padding: 5px 20px 5px 40px
    font-size: 10px
    font-weight: 700
    color: colorDanger
    background-color: rgba(245, 107, 107, 0.2)
    border-bottom: 1px solid rgba(245, 107, 107, 0.4)
