.access-manager
  &__spread-form-btns-wrapper
    position: absolute
    bottom: 2px
    right: 2px
    height: 36px
    display: flex
    justify-content: space-between
    align-items: center
    background-color: colorBgGray
  &__spread-form__add-btn
    font-size: 12px
    font-weight: 500
    color: colorPrimary
    text-transform: uppercase
    transition: all .3s ease
    padding: 8px
    &:hover:enabled
      color: colorPrimary
  &__refresh-btn
    color: colorLightBlue
    padding: 8px
    font-size: 12px
    &:hover:enabled
      color: colorPrimary
  &__mb-8
    margin-bottom: 8px
  &__mb-10
    font-size: 14px
    line-height: 20px
    font-weight: 500
    color: #465164
  &__group-builder
    width: 100%
    height: fit-content
    padding: 24px 64px
  &__wrapper
    padding: 12px 0 12px 0
    &--center
      padding: 24px 0 0
      text-align: center
  &__link
    cursor: copy
