body
  font-family $Avenir
  color: colorBlack
  font-size: 16px
  // line-height: 1.4
  background-color: #FCFCFF
  &.is-fixed
    overflow: hidden

[v-cloak]
  display: none

.main
  min-height: 100vh
  position: relative
  &.empty
    & .page-wrapper
      padding: 0
  &:has(.header):has(.header-msg-box)
    padding-top: 62px

.layout
  display: block

.container
  position relative
  width: 100%
  max-width 1280px
  padding 0 var(--containerSidePadding)
  margin 0 auto

.page-wrapper
  width: 100%
  min-height: 100vh
  margin: 0 auto
  padding: 70px 0 100px
  overflow: hidden
  +mobile()
    padding-top: 110px

.aside-save.ui-btn
  position: fixed
  bottom: 0
  left: -360px
  z-index: 1
  width: 360px
  border-radius: 0

.aside-message
  margin-bottom: 30px
  font-size: 12px
  line-height: 18px
  font-weight: 500
  color: colorDanger
  padding: 7px
  background-color: rgba(199,0,57,.1)
  border-radius: 4px
  text-align: center

.aside-field-label
  margin-bottom: 6px
  font-size: 12px
  line-height: 18px
  font-weight: 500
  color: #465164

.aside-input
  &.with-colopicker
    & .v-input.v-text-field
      padding-right: 25px
  & .v-input.v-text-field
    display: flex
    align-items: center
    min-height: 40px
    border: 1px solid #e4e9f1
    border-radius: 4px
    & .v-text-field__slot input
      padding: 0 13px
      border: none

.textarea
  display: block
  width: 100%
  height: 160px
  padding: 16px
  font-family monospace
  font-size: 12px
  line-height: normal
  color: colorBlack
  background-color: transparent
  border: 1px solid colorGray
  border-radius: 4px
  resize: none
  outline: none

video
  max-width: 100%

.phone-input.vue-tel-input
  width: 100%
  height: 40px
  color: #323232
  border-radius: 4px
  border: 1px solid #e4e9f1
  &:focus-within
    box-shadow: none
    border-color: #e4e9f1

  & .vti
    &__dropdown
      &.open
      &:hover
        background-color: #e4e9f1
      &:focus
        outline: none
    &__dropdown-list
      width: 410px
      border-radius: 4px
      border: 1px solid #e4e9f1
      border-top-color: #1da2ff
      box-shadow: 0 6px 16px 0 rgba(0,0,0,0.08)
      &.below
        top: 43px
    &__dropdown-item
      padding: 6px 15px
      font-size: 14px
      line-height: 20px
      & strong
        font-weight: 600
      &.highlighted
        background-color: #e4e9f1
      & .vti__flag
        margin-right: 10px
        font-size: 0
        line-height: 0
        transform: translateY(1px)
    &__selection
      font-size: 10px
    &__flag
      margin-left: 0
    &__input
      font-size: 14px
      border-radius: 0 4px 4px 0
      &::placeholder
        color: #ACB9CD

.input
  width: 100%
  height: 40px
  padding: 0 13px
  font-size: 14px
  color: #323232
  border: 1px solid #e4e9f1
  border-radius: 4px
  &::placeholder
    color: #e4e9f1

.route
  &__manage-sheet-page
  &__manage-sheet-page-static
    & .page-wrapper
      padding: 0

.is-link
  color: var(--colorPrimary)
  &:hover
    text-decoration: underline

.archived
  opacity: 0.5
  &--disabled
    pointer-events: none

.text-color-primary
  color: var(--colorPrimary)
.text-color-secondary
  color: var(--colorTextSecondary)
.text-color-danger
  color: var(--colorDanger)
.text-color-success
  color: var(--colorSuccess)

.text-weight-400
  font-weight: 400
.text-weight-500
  font-weight: 500
.text-weight-600
  font-weight: 600
.text-weight-700
  font-weight: 700


table.table
  border-collapse: collapse
  border-spacing: 0
  &.table--layout-fixed
    table-layout: fixed
  & thead td
    color: var(--colorText)
    font-weight: 500
  & tbody td
    font-weight: 500
    white-space: normal
    word-break: break-word
  td
    padding: 8px 8px
    border: 1px solid var(--colorBorder)

.try-ai-btn
  display: inline-flex
  align-items: center
  width: fit-content
  height: 32px
  padding: 0 12px
  font-size: 16px
  font-weight: 600
  color: #fff
  border-radius: 20px
  background: linear-gradient(135deg, #1DA2FF, #90CFFF);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: all .2s
  // background-position: 100% 100%
  +hover()
    background-position: 0 100%;
