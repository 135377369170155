.page-form

  &__theme-picker
    margin-bottom: 60px

  &__titles
    margin-bottom: 40px

  &__uploaders
    margin-bottom: 50px

  &__uploader
    margin-bottom: 16px
    &-label
      font-size: 14px
      line-height: 20px
      font-weight: 600
      color: colorBlack
      margin-bottom 10px
    &:last-of-type
      margin-bottom: 0

  &__hint
    font-size: 12px
    line-height: 14px
    font-weight: 400
    color: colorDarkerGray
    margin-bottom 10px
    margin-top: -5px


  &__layouts
    margin-bottom: 50px

  &__text-editor
    margin-bottom: 50px
    &:last-of-type
      margin-bottom: 0
