.profile-dropdown

  &__trigger
    cursor: pointer
    display: flex
    align-items: center
    outline: none !important

  &__avatar
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 40px
    font-size: 20px
    line-height: 21px
    color: #fff
    user-select: none
    border-radius: 50%
    background-color: #A37E7E

  &__trigger-icon
    margin-left: 10px
    font-size: 12px
    color: colorIcon
    transition: all .2s ease
    &.is-dropdown-opened
      transform: rotate(180deg)

  &__menu.el-dropdown-menu
    width: 230px
    --dropdownPaddings: 0
    --dropdownItemPadding: 8px 20px
    --dropdownItemFS: 14px
    --dropdownItemLH: 18px
    --dropdownItemFW: 600
    .popper__arrow
      display: none

  &__menu-header
    width: 100%
    padding: 15px 20px
    font-size: 14px
    line-height: 14px
    color: #59677F
    border-bottom: 1px solid colorBorder
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  &__menu-nav
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 10px 0
    .el-dropdown-menu__item.is-trigger-inside
      padding: 0
      >*
        display: inline-block
        width: 100%
        padding: 8px 20px

  &__menu-footer
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 10px 0
    border-top: 1px solid colorBorder

  &__menu-footer-item
    &.is-logout
      display: flex
      align-items: center
      justify-content: space-between
      i
        font-size: 14px
        color: colorIcon
