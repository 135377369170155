.v-select
  cursor: pointer

  &.v-select--chips .v-select__selections
    min-height: auto

  & .v-select__selections
    padding-right: 15px
    overflow: hidden
    // & input
    //   display: none

  & .v-select__selections .v-chip
    font-size: 14px
    line-height: 16px
    border-radius: 4px
    font-weight: 500
    color: colorBlack
    background-color: #eff1f7

  & .v-select__selections .v-chip__content
    height: auto
    min-height: 32px
    white-space: normal

  & .v-select__selections .v-chip--removable .v-chip__content
    padding: 4px 8px 4px 12px

  & .v-select__selections .v-chip__close
    cursor: pointer
    position: relative
    color: colorLightBlue
    transition: color .3s ease
    &:hover
      color: var(--colorDanger)
    & i
      display: none
    &:before
      content: '\e93e'
      display: block
      font-family: 'icomoon'
      font-size: 8px

  & .v-select__slot
    // min-height: 30px
    & .v-input__append-inner
      & .v-input__icon
        width: 16px
        height 16px
        min-width: 16px
        & .material-icons
          font-size: 20px
          // font-weight 300
          color: colorLightBlue
      // &
      // display: none

  & .v-select__selection--comma
    margin: 0
    font-size: 14px
    font-weight: 400
    color: colorBlack

.v-menu__content
  margin-top: 10px
  padding: 20px 0
  background-color: #fff
  border-radius: 2px
  border: 1px solid colorGray
  border-top: 1px solid colorPrimary
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08)

  & .v-select-list.v-card
    color: colorBlack
    box-shadow: none
    background-color: transparent
    border: none

    & .v-list
      padding: 0
      border-radius: 0

      &--disabled
        opacity: 0.5

      &__tile
        margin-bottom: 5px
        padding: 0 20px
        height: 36px
        font-size: 16px
        font-weight: 400
        color: colorBlack
        &:hover
          background-color: rgba(29, 162, 255, 0.05)
        &--active
          font-weight: 500
          background-color: rgba(29, 162, 255, 0.05)
        &__mask
          color: colorBlack
          background-color: rgba(29, 162, 255, 0.15)

      &__tile__action
        min-width: auto
        & .v-input__slot
          margin: 0
        & .v-input--selection-controls__input
          position: relative
          width: 16px
          height: 16px
          margin-right: 17px
          & i
            display: none
            color: colorPrimary
          &:before
            content: ''
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            border-radius: 2px
            background-color: transparent
            border: 2px solid #95ccfa
            transition: all .3s ease
          &:after
            content: '\e93f'
            position: absolute
            top: 5px
            left: 5px
            margin-top: 1px
            font-family: 'icomoon'
            font-size: 9px
            color: #fff
            transition: all .3s ease
        & .v-input--selection-controls.v-input--is-label-active .v-input--selection-controls__input
          &:after
            opacity: 1
          &:before
            background-color: colorPrimary


        & .v-input--selection-controls__ripple
          display: none
