.uploader
  position: relative
  width: 100%
  color: colorDarkerGray
  display: flex
  align-items: center
  justify-content: space-between

  &__btn
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 50px
    font-size: 16px
    font-weight: 600
    color: colorPrimary
    border-radius: 2px
    border: 1px solid colorGray
    transition: all .3s ease

    &:hover
      background-color: colorPrimary
      border-color: colorPrimary
      color: #fff

    &.is-loading
      cursor: not-allowed
      opacity: .3
      &:hover
        background-color: #fff
        border-color: colorGray
        color: colorPrimary

  &__btn-icon
    margin-left: 10px

  &__file-name
    width: 100%
    margin-bottom: 15px
    font-size: 12px
    line-height: 1.5
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  &__label
    margin-bottom: 3px
    font-size: 12px
    line-height: 1.4
    color: colorDarkerGray

  &__preview-wrap
    flex: none
    width: 150px
    margin-right: 15px

  &__preview
    width: 100%
    height: 90px
    border-radius: 2px
    border: 1px solid colorGray
    background-color: colorGray
    background-position: center
    background-repeat: no-repeat
    background-size: contain

  &__controls-wrap
    overflow: hidden

  &__controls
    display: flex
    align-items: center
    justify-content: center

  &__change
  &__delete
    cursor: pointer
    margin: 0 15px
    font-size: 20px
    color: colorLightBLue
    transition: color .3s ease

    &:hover
      color: colorPrimary

    &.is-highlighted
      color: colorPrimary

  &__status
    position: absolute
    top: 100%
    left: 0
    width: 100%
