.modal-help
  width: 100%
  padding: 16px 50px 30px

  &__list
    display: flex
    font-size: 16px

  &__step
    display: flex
    flex-direction: column
    width: 33.333%
    margin-right: 48px
    &:last-of-type
      margin-right: 0

  &__step-title
    margin-bottom: 5px
    font-weight: 600
    line-height: 24px

  &__step-paragraph
    min-height: 44px
    margin-bottom: 15px
    color: #798496

  &__img-wrap
    display: flex
    align-items: center
    &:after
      content: ''
      width: 0
      height: 0
      border-top: 7px solid transparent
      border-bottom: 7px solid transparent
      border-left: 9px solid #a8b7cf
    &.no-arrow:after
      border-left-color: transparent

  &__step-img
    margin-right: 20px

  &__link
    color: colorPrimary
    font-weight: 500

  &__form
    margin-top: 15px
