.fs-editor
  position: fixed
  top: 0
  left: 0
  z-index: 11
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  background-color: #fff
  overflow: hidden

  &__head
    display: flex
    flex: none
    width: 100%
    height: 50px
    border-bottom: 1px solid #E4E9F1

  &__head-title
    font-size: 14px
    font-weight: 600

  &__head-left-side
    display: flex
    align-items: center
    width: 100%
    padding-left: 40px
    border-right: 1px solid #E4E9F1

  &__head-right-side
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    min-width: 780px
    padding-left: 40px

  &__head-close-btn-wrap
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: 50px
    height: 100%
    border-left: 1px solid #E4E9F1

  &__head-close-btn
    width: 32px
    height: 32px
    font-size: 14px
    color: #59677F
    border-radius: 50%
    transition: all .2s ease
    &:hover
      background-color: #EEF1F5

  &__body
    display: flex
    justify-content: space-between
    width: 100%
    height: 100%
    overflow: hidden

    & .v-md-editor.sv-md-editor

      &.v-md-editor--editable .v-md-editor__editor-wrapper
        border-right-color: #E4E9F1
      & .v-md-editor__editor-wrapper
        flex: auto
        width: 50%
        padding-left: 25px
      & .v-md-editor__preview-wrapper
        flex: auto
        width: 50%
        min-width: 780px
      & .v-md-editor__main
        margin-top: 0
        border: none
      & .v-md-editor__toolbar
        position: relative
        &:after
          content: ''
          display: block
          width: 100%
          min-width: 780px
      & .v-md-editor__toolbar-left
        width: 100%
        max-width: 50%
        padding: 25px 15px 15px 40px
        border-right: 1px solid #E4E9F1
      & .v-md-editor__toolbar-right
        display: none
        width: 100%
        min-width: 780px
        margin: 0

      & .v-md-editor-preview.github-markdown-body
        max-width: 780px
        margin: 0 auto
        padding: 0 40px 50px
        font-family: inherit
        color: #323232
        word-break: normal
