.login-page
  display flex
  text-align: center
  justify-content: center
  align-items: center

  &__container
    max-width: 662px
    width: 100%

  &__title
    font-size: 32px
    font-weight: bold
    line-height: 1.41
    letter-spacing: -0.8px
    margin-bottom: 24px

  &__description
    max-width: 520px
    font-size: 16px
    color: #a8b7cf
    margin: 0 auto 64px

  &__button-wrap
    min-height: 50px
    margin-bottom: 24px
    display: flex
    justify-content: center
    min-height: 50px
    flex-direction: column
    align-items: center

  &__checkbox
    position: relative
    display: flex
    align-items: center
    font-size: 16px
    line-height: 1.31
    margin-top: 54px

  &__checkbox-wrap
    position: relative
    display: flex
    align-items: center
    margin-right: 16px

  &__checkbox-input
    width: 20px
    height: 20px
    border: 1px solid #1da2ff
    border-radius: 4px
    transition: all .3s ease
    &:hover
      cursor: pointer
    &:checked
      background-color: #1da2ff
    &:checked + .login-page__checkbox-icon
      opacity: 1

  &__checkbox-icon
    opacity: 0
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -45%)
    font-size: 10px
    color: #fff
    cursor: pointer

  &__terms
    font-size: 14px
    letter-spacing: -0.3px
    margin-top: 12px
    margin-bottom: 12px

  &__terms-link
    color: #1da2ff

  &__error-message
    margin-top: 10px
    font-size: 10px
    line-height: 20px
    font-weight: 700
    color: rgba(245, 107, 107, 0.4)
