@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?p2mpnt');
  src:  url('fonts/icomoon.eot?p2mpnt#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?p2mpnt') format('truetype'),
    url('fonts/icomoon.woff?p2mpnt') format('woff'),
    url('fonts/icomoon.svg?p2mpnt#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-spread:before {
  content: "\e900";
}
.icon-sheets:before {
  content: "\e901";
}
.icon-desktop:before {
  content: "\e902";
}
.icon-check:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e904";
}
.icon-back-arrow:before {
  content: "\e905";
}
.icon-arrow:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e907";
}
.icon-sort-up:before {
  content: "\e908";
}
.icon-sort-down:before {
  content: "\e909";
}
.icon-tile-view:before {
  content: "\e90a";
}
.icon-list-view:before {
  content: "\e90b";
}
.icon-carret-down:before {
  content: "\e90c";
}
.icon-picture:before {
  content: "\e90d";
}
.icon-external-link:before {
  content: "\e90e";
}
.icon-warning:before {
  content: "\e90f";
}
.icon-plus:before {
  content: "\e910";
}
.icon-page:before {
  content: "\e911";
}
.icon-edit:before {
  content: "\e912";
}
.icon-duplicate:before {
  content: "\e913";
}
.icon-code:before {
  content: "\e914";
}
.icon-delete:before {
  content: "\e915";
}
.icon-edit-pencil:before {
  content: "\e916";
}
.icon-save:before {
  content: "\e917";
}
.icon-pin:before {
  content: "\e918";
}
.icon-phone:before {
  content: "\e919";
}
.icon-email:before {
  content: "\e91a";
}
.icon-loader:before {
  content: "\e91b";
}
.icon-google-logo:before {
  content: "\e91c";
}
.icon-facebook-logo:before {
  content: "\e91d";
}
.icon-option:before {
  content: "\e91e";
}
.icon-table-view:before {
  content: "\e91f";
}
.icon-theme:before {
  content: "\e920";
}
.icon-tiles:before {
  content: "\e921";
}
.icon-search-sort:before {
  content: "\e922";
}
.icon-refresh-arrow:before {
  content: "\e923";
}
.icon-bold-arrow:before {
  content: "\e924";
}
.icon-arrow-down:before {
  content: "\e925";
}
.icon-pencil-new:before {
  content: "\e926";
}
.icon-delete-new:before {
  content: "\e927";
}
.icon-direct-arrow-vertical:before {
  content: "\e928";
}
.icon-chain:before {
  content: "\e929";
}
.icon-direct-arrow-horizontal:before {
  content: "\e92a";
}
.icon-manage-options:before {
  content: "\e92b";
}
.icon-edit-google-sheet:before {
  content: "\e92c";
}
.icon-tile-view-new:before {
  content: "\e92d";
}
.icon-search-new:before {
  content: "\e92e";
}
.icon-table-view-new:before {
  content: "\e92f";
}
.icon-active-border:before {
  content: "\e930";
}
.icon-aside-content:before {
  content: "\e931";
}
.icon-aside-page:before {
  content: "\e932";
}
.icon-duplicate2:before {
  content: "\e933";
}
.icon-link2:before {
  content: "\e934";
}
.icon-order-symbol:before {
  content: "\e935";
}
.icon-preview-desktop:before {
  content: "\e936";
}
.icon-preview-fs:before {
  content: "\e937";
}
.icon-preview-mobile:before {
  content: "\e938";
}
.icon-square-border:before {
  content: "\e939";
}
.icon-preview-small:before {
  content: "\e93a";
}
.icon-delete-bin:before {
  content: "\e93b";
}
.icon-upload2:before {
  content: "\e93c";
}
.icon-aside-settings:before {
  content: "\e93d";
}
.icon-close2:before {
  content: "\e93e";
}
.icon-check2:before {
  content: "\e93f";
}
.icon-minus-btn:before {
  content: "\e940";
}
.icon-plus-btn:before {
  content: "\e941";
}
.icon-cart:before {
  content: "\e942";
}
.icon-card-small:before {
  content: "\e943";
}
.icon-card-large:before {
  content: "\e944";
}
.icon-video:before {
  content: "\e945";
}
.icon-sort_up:before {
  content: "\e946";
}
.icon-sort_down:before {
  content: "\e947";
}
.icon-sort-default:before {
  content: "\e948";
}
.icon-form-button:before {
  content: "\e949";
}
.icon-exit:before {
  content: "\e94a";
}
.icon-seo-icon:before {
  content: "\e94b";
}
.icon-checkout:before {
  content: "\e94c";
}
.icon-tiles-view2:before {
  content: "\e94d";
}
.icon-list-view2:before {
  content: "\e94e";
}
.icon-list-view-small:before {
  content: "\e94f";
}
.icon-popup-order-form:before {
  content: "\e950";
}
.icon-popup-det-page:before {
  content: "\e951";
}
.icon-pages-links:before {
  content: "\e952";
}
.icon-draggable:before {
  content: "\e953";
}
.icon-new-page:before {
  content: "\e954";
}
.icon-eye-hide:before {
  content: "\e955";
}
.icon-gear:before {
  content: "\e956";
}
.icon-cubics:before {
  content: "\e957";
}
.icon-notion:before {
  content: "\e958";
}
.icon-expand:before {
  content: "\e959";
}
.icon-addons:before {
  content: "\e95a";
}
.icon-info-circle:before {
  content: "\e95b";
}
.icon-circle-close:before {
  content: "\e95c";
}
.icon-crown:before {
  content: "\e95e";
}
.icon-dots:before {
  content: "\e95f";
}
.icon-diamond:before {
  content: "\e95d";
}
.icon-external-link2:before {
  content: "\e960";
}
.icon-pencil2:before {
  content: "\e961";
}
.icon-search-fat:before {
  content: "\e962";
}
.icon-tags:before {
  content: "\e963";
}
.icon-warning-diamond:before {
  content: "\e964";
}
.icon-page-single-vt-layout:before {
  content: "\e965";
}
.icon-page-single-hz-layout:before {
  content: "\e966";
}
.icon-page-single-comb-layout:before {
  content: "\e967";
}
.icon-cart-to-list:before {
  content: "\e968";
}
.icon-team:before {
  content: "\e969";
}
.icon-horizontal-view:before {
  content: "\e96a";
}
.icon-table-view-style:before {
  content: "\e96b";
}
.icon-gear-2:before {
  content: "\e96c";
}
.icon-edit-containered:before {
  content: "\e96d";
}
.icon-logo:before {
  content: "\e96e";
}
