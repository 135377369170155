@import 'parts/global/normalize.css';
a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:active,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}
button {
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
button:hover,
button:active,
button:focus,
button:visited {
  outline: none;
}
input {
  border: none;
  background-color: transparent;
  padding: 0;
  -webkit-appearance: none;
}
input:hover,
input:active,
input:focus,
input:visited {
  outline: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:active,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}
button {
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
button:hover,
button:active,
button:focus,
button:visited {
  outline: none;
}
input {
  border: none;
  background-color: transparent;
  padding: 0;
  -webkit-appearance: none;
}
input:hover,
input:active,
input:focus,
input:visited {
  outline: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: normal;
}
img {
  max-width: 100%;
  max-height: 100%;
}
* {
  box-sizing: border-box;
}
code {
  font-size: inherit;
}
@import '../icomoon/style.css';
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
@font-face {
  font-family: 'AvenirNext';
  src: url("../fonts/avenir/AvenirNextCyr-UltraLight.ttf") format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirNext';
  src: url("../fonts/avenir/AvenirNextCyr-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirNext';
  src: url("../fonts/avenir/AvenirNextCyr-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirNext';
  src: url("../fonts/avenir/AvenirNextCyr-Demi.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirNext';
  src: url("../fonts/avenir/AvenirNextCyr-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirNext';
  src: url("../fonts/avenir/AvenirNextCyr-Heavy.ttf") format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AdamantXBold';
  src: url("../fonts/adamant/PFAdamantPro-XBold.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PT Sans';
  src: url("../fonts/pt-sans/PTSans-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/material/MaterialIconsOutlined-Regular.otf") format('opentype');
  font-display: block;
}
@font-face {
  font-family: 'Material Icons Filled';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/material/MaterialIcons-Regular.ttf") format('truetype');
  font-display: block;
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: inherit /* Preferred icon size */;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
/* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
/* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
/* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
/* Support for IE. */
  font-feature-settings: 'liga';
}
.material-icons-filled {
  font-family: 'Material Icons Filled';
  font-weight: normal;
  font-style: normal;
  font-size: inherit /* Preferred icon size */;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
/* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
/* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
/* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
/* Support for IE. */
  font-feature-settings: 'liga';
}
:root {
  --containerSidePadding: 20px;
  --headerHeight: 70px;
  --height-control-xxs: 20px;
  --height-control-xs: 24px;
  --height-control-s: 32px;
  --height-control-m: 40px;
  --height-control-l: 50px;
}
:root {
  --colorBg: #fcfcff;
  --colorBgSecondary: #fff;
  --colorBgGray: #eff3fb;
  --colorText: #323232;
  --colorTextSecondary: #717e93;
  --colorTextTertiary: #a8b7cf;
  --colorPrimary: #1da2ff;
  --colorPrimary-07: rgba(29,162,255,0.07);
  --colorPrimary-10: rgba(29,162,255,0.1);
  --colorPrimary-20: rgba(29,162,255,0.2);
  --colorPrimary--hover: #1891e5;
  --colorDanger: #ef4444;
  --colorDanger-10: rgba(239,68,68,0.1);
  --colorDanger--hover: #dc2626;
  --colorSuccess: #22c55e;
  --colorSuccess-10: rgba(34,197,94,0.1);
  --colorGray: #717e93;
  --colorGray-20: rgba(113,126,147,0.2);
  --colorGray-10: rgba(113,126,147,0.1);
  --colorGrayLight: #acb9cd;
  --colorGrayLight-15: rgba(172,185,205,0.15);
  --colorGrayLight-35: rgba(172,185,205,0.35);
  --colorGrayLight-55: rgba(172,185,205,0.55);
  --colorGrayDark: #465164;
  --colorBorder: #e4e9f1;
  --colorControlColor: var(--colorText);
  --colorControlColor--disabled: #717e93;
  --colorControlPlaceholderColor: #a8b7cf;
  --colorControlBg: #fff;
  --colorControlBg--disabled: #fff;
  --colorControlBorder: #e4e9f1;
  --colorControlBorder--hover: #c9ced5;
  --colorControlBorder--focus: var(--colorPrimary);
  --colorControlBorder--disabled: #e4e9f1;
  --colorControlIconColor: #000;
  --colorControlChevronColor: #717e93;
  --colorPopoverBg: #fff;
  --colorPopoverItemBg--hover: #f4faff;
  --sizeControlHeightDefault: 40px;
  --sizeControlHeightSmall: 32px;
  --shadowPopover: 0px 16px 31px rgba(10,35,94,0.24), 0px 3px 5px rgba(10,35,94,0.1);
  --colorBtnPrimaryText: #fff;
  --colorBtnPrimaryText--hover: #fff;
  --colorBtnPrimaryText--disabled: #fff;
  --colorBtnPrimaryBg: var(--colorPrimary);
  --colorBtnPrimaryBg--hover: var(--colorPrimary--hover);
  --colorBtnPrimaryBg--disabled: #79c8ff;
  --colorBtnPrimaryBorder: transparent;
  --colorBtnPrimaryBorder--hover: transparent;
  --colorBtnPrimaryBorder--disabled: transparent;
  --colorBtnSecondaryText: var(--colorTextSecondary);
  --colorBtnSecondaryText--hover: var(--colorTextSecondary);
  --colorBtnSecondaryText--disabled: var(--colorTextSecondary);
  --colorBtnSecondaryBg: transparent;
  --colorBtnSecondaryBg--hover: #eff3fb;
  --colorBtnSecondaryBg--disabled: rgba(239,243,251,0.3);
  --colorBtnSecondaryBorder: var(--colorControlBorder);
  --colorBtnSecondaryBorder--hover: var(--colorControlBorder--hover);
  --colorBtnSecondaryBorder--disabled: var(--colorControlBorder);
  --colorBtnSecondaryBlueText: var(--colorPrimary);
  --colorBtnSecondaryBlueText--hover: var(--colorPrimary);
  --colorBtnSecondaryBlueText--disabled: #a8b7cf;
  --colorBtnSecondaryBlueBg: transparent;
  --colorBtnSecondaryBlueBg--hover: #d9ecf9;
  --colorBtnSecondaryBlueBg--disabled: rgba(217,236,249,0.3);
  --colorBtnSecondaryBlueBorder: var(--colorPrimary);
  --colorBtnSecondaryBlueBorder--hover: var(--colorPrimary);
  --colorBtnSecondaryBlueBorder--disabled: #a8b7cf;
  --colorBtnDangerText: #fff;
  --colorBtnDangerText--hover: #fff;
  --colorBtnDangerText--disabled: #fff;
  --colorBtnDangerBg: var(--colorDanger);
  --colorBtnDangerBg--hover: var(--colorDanger--hover);
  --colorBtnDangerBg--disabled: #f48484;
  --colorBtnDangerBorder: transparent;
  --colorBtnDangerBorder--hover: transparent;
  --colorBtnDangerBorder--disabled: transparent;
}
:root {
  --primary: #1da2ff;
  --primary-hover: #1891e5;
  --primary-active: #1480cc;
  --primary-text-inside: #fff;
  --primary-alpha-40: rgba(29,162,255,0.4);
  --semitransparent-blue: rgba(29,162,255,0.2);
  --semitransparent-blue-hover: rgba(29,162,255,0.4);
  --semitransparent-blue-active: rgba(29,162,255,0.6);
  --semitransparent-blue-text-inside: #1da2ff;
  --semitransparent-blue-alpha-40: rgba(29,162,255,0.4);
  --secondary: #f1f5f9;
  --secondary-hover: #e2e8f0;
  --secondary-active: #cbd5e1;
  --secondary-text-inside: #475569;
  --secondary-alpha-60: rgba(241,245,249,0.6);
  --secondaryBlue: #edf8ff;
  --secondaryBlue-hover: #d9ecf9;
  --secondaryBlue-active: #c8e8fe;
  --secondaryBlue-text-inside: #1da2ff;
  --secondaryBlue-alpha-80: rgba(237,248,255,0.8);
  --green: #0f9d58;
  --green-hover: #0f9d58;
  --green-active: #0f9d58;
  --green-text-inside: #fff;
  --green-alpha-40: rgba(15,157,88,0.4);
  --ghost: transparent;
  --ghost-hover: #e2e8f0;
  --ghost-active: #cbd5e1;
  --ghost-text-inside: var(--colorText);
  --ghost-alpha-40: rgba(226,232,240,0.4);
  --ghost-secondary-text-inside: var(--colorTextSecondary);
  --success: #22c55e;
  --success-hover: #16a34a;
  --success-active: #15803d;
  --success-text-inside: #fff;
  --success-alpha-40: rgba(34,197,94,0.4);
  --info: #0ea5e9;
  --info-hover: #0284c7;
  --info-active: #0369a1;
  --info-text-inside: #fff;
  --info-alpha-40: rgba(14,165,233,0.4);
  --warning: #f7a835;
  --warning-hover: #f79e1d;
  --warning-active: #f59812;
  --warning-text-inside: #fff;
  --warning-alpha-10: rgba(247,168,53,0.1);
  --warning-alpha-40: rgba(247,168,53,0.4);
  --help: #a855f7;
  --help-hover: #9333ea;
  --help-active: #7e22ce;
  --help-text-inside: #fff;
  --help-alpha-40: rgba(168,85,247,0.4);
  --danger: #ef4444;
  --danger-hover: #dc2626;
  --danger-active: #b91c1c;
  --danger-text-inside: #fff;
  --danger-alpha-40: rgba(239,68,68,0.4);
  --contrast: #020617;
  --contrast-hover: #1e293b;
  --contrast-active: #334155;
  --contrast-text-inside: #fff;
  --contrast-alpha-40: rgba(2,6,23,0.4);
}
:root {
  --font-size-heading-xxs: 12px;
  --font-size-heading-xs: 16px;
  --font-size-heading-s: 20px;
  --font-size-heading-m: 24px;
  --font-size-heading-l: 28px;
  --font-size-heading-xl: 32px;
  --font-size-heading-xxl: 36px;
  --font-size-heading-xxxl: 40px;
  --font-size-text-xxs: 10px;
  --font-size-text-xs: 12px;
  --font-size-text-s: 14px;
  --font-size-text-m: 16px;
  --font-size-text-l: 18px;
  --font-size-text-xl: 20px;
  --font-size-text-xxl: 24px;
  --font-size-text-xxxl: 28px;
}
:root {
  --spacer-0: 0;
  --spacer-2: 2px;
  --spacer-4: 4px;
  --spacer-8: 8px;
  --spacer-12: 12px;
  --spacer-16: 16px;
  --spacer-20: 20px;
  --spacer-24: 24px;
  --spacer-28: 28px;
  --spacer-32: 32px;
  --spacer-36: 36px;
  --spacer-40: 40px;
  --spacer-44: 44px;
  --spacer-48: 48px;
  --spacer-52: 52px;
  --spacer-56: 56px;
  --spacer-60: 60px;
  --spacer-64: 64px;
  --spacer-68: 68px;
  --spacer-72: 72px;
  --spacer-76: 76px;
  --spacer-80: 80px;
}
.fade1-enter-active,
.fade1-leave-active {
  transition: opacity 0.15s ease;
}
.fade1-enter,
.fade1-leave-to {
  opacity: 0;
}
.fade3-enter-active,
.fade3-leave-active {
  transition: opacity 0.1s ease;
}
.fade3-enter,
.fade3-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.customize-window-appear-enter-active,
.customize-window-appear-leave-active {
  transition: opacity 0.5s ease;
}
.customize-window-appear-enter,
.customize-window-appear-leave-to {
  opacity: 0;
}
.form-field-error-fade-enter-active,
.form-field-error-fade-leave-active {
  transition: opacity 0.3s ease;
}
.form-field-error-fade-enter,
.form-field-error-fade-leave-to {
  opacity: 0;
}
@-moz-keyframes spread-form-message-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes spread-form-message-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes spread-form-message-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes spread-form-message-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes manage-error-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes manage-error-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes manage-error-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes manage-error-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes manage-modal-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes manage-modal-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes manage-modal-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes manage-modal-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes collapsable-field-error-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes collapsable-field-error-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes collapsable-field-error-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes collapsable-field-error-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes selected-tag-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes selected-tag-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes selected-tag-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes selected-tag-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes tile-wrapp-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes tile-wrapp-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes tile-wrapp-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes tile-wrapp-appearence {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes tile-image-preloader-back {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes tile-image-preloader-back {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes tile-image-preloader-back {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes tile-image-preloader-back {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes tile-image-preloader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(365deg);
  }
}
@-webkit-keyframes tile-image-preloader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(365deg);
  }
}
@-o-keyframes tile-image-preloader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(365deg);
  }
}
@keyframes tile-image-preloader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(365deg);
  }
}
@-moz-keyframes disabling-section-appear {
  0% {
    z-index: 1;
    opacity: 0.7;
  }
  99% {
    z-index: 1;
    opacity: 0.01;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
@-webkit-keyframes disabling-section-appear {
  0% {
    z-index: 1;
    opacity: 0.7;
  }
  99% {
    z-index: 1;
    opacity: 0.01;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
@-o-keyframes disabling-section-appear {
  0% {
    z-index: 1;
    opacity: 0.7;
  }
  99% {
    z-index: 1;
    opacity: 0.01;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
@keyframes disabling-section-appear {
  0% {
    z-index: 1;
    opacity: 0.7;
  }
  99% {
    z-index: 1;
    opacity: 0.01;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
@-moz-keyframes loading-blink {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.7;
  }
}
@-webkit-keyframes loading-blink {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.7;
  }
}
@-o-keyframes loading-blink {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.7;
  }
}
@keyframes loading-blink {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.7;
  }
}
@-moz-keyframes loader-rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader-rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader-rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader-rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@import 'parts/blocks/v-select.css';
.n-form-field {
  position: relative;
  width: 100%;
}
.n-form-field__top {
  margin-bottom: 6px;
}
.n-form-field__label {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #465164;
}
.n-form-field__label +.n-form-field__helper {
  margin-left: 4px;
}
.n-form-field__helper {
  vertical-align: middle;
}
.n-form-field__error-message {
  margin-top: 2px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--colorDanger);
}
.n-form-field__hint {
  margin-top: 3px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  color: #717e93;
}
.n-form-field__hint a {
  color: var(--colorPrimary);
}
.n-form-field__hint a:hover {
  color: var(--colorPrimaryHover);
}
.n-form-field__control {
  position: relative;
}
.n-form-field__control .v-input.v-text-field {
  display: flex;
  align-items: center;
  min-height: 40px;
  border: 1px solid #e4e9f1;
  border-radius: 4px;
}
.n-form-field__control .v-input.v-text-field:not(.v-select) .v-input__control {
  min-height: 38px;
}
.n-form-field__control .v-input.v-text-field .v-text-field__slot input {
  padding: 0 13px;
  border: none;
}
.n-form-field__control .v-input.v-text-field textarea {
  padding: 10px 15px;
  font-size: 14px;
  line-height: 20px;
  border: none;
}
.n-form-field__control .v-input.v-text-field .v-text-field__suffix {
  padding-right: 10px;
  font-size: 14px;
  color: #717e93;
}
.n-form-field__control .color-picker {
  position: absolute;
  top: 11px;
  right: 10px;
}
.n-form-field .field-icon {
  margin-right: 10px;
  width: 16px;
}
.n-form-field .field-name {
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
  color: #798496;
}
.n-form-field .field-name .icon {
  margin-right: 10px;
  color: #a8b7cf;
}
.n-form-field .list-title {
  margin-bottom: 20px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: colorBlacks;
}
.n-form-field .v-select {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e4e9f1;
}
.n-form-field .v-select .v-input__control {
  padding: 4px 13px;
  min-height: 30px;
}
.n-form-field .v-select .v-select__selections input {
  padding: 0;
  border: none;
}
.n-form-field .v-select .v-select__selections input::placeholder {
  color: #a8b7cf;
}
.n-form-field .v-select__slot {
  align-items: center;
}
.n-form-field .v-select__slot .v-input__append-inner {
  align-self: center;
}
.v-input__slot {
  margin-bottom: 0;
}
.v-select {
  cursor: pointer;
}
.v-select.v-select--chips .v-select__selections {
  min-height: auto;
}
.v-select .v-select__selections {
  padding-right: 15px;
  overflow: hidden;
}
.v-select .v-select__selections .v-chip {
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  font-weight: 500;
  color: #323232;
  background-color: #eff1f7;
}
.v-select .v-select__selections .v-chip__content {
  height: auto;
  min-height: 32px;
  white-space: normal;
}
.v-select .v-select__selections .v-chip--removable .v-chip__content {
  padding: 4px 8px 4px 12px;
}
.v-select .v-select__selections .v-chip__close {
  cursor: pointer;
  position: relative;
  color: #a8b7cf;
  transition: color 0.3s ease;
}
.v-select .v-select__selections .v-chip__close:hover {
  color: var(--colorDanger);
}
.v-select .v-select__selections .v-chip__close i {
  display: none;
}
.v-select .v-select__selections .v-chip__close:before {
  content: '\e93e';
  display: block;
  font-family: 'icomoon';
  font-size: 8px;
}
.v-select .v-select__slot .v-input__append-inner .v-input__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
}
.v-select .v-select__slot .v-input__append-inner .v-input__icon .material-icons {
  font-size: 20px;
  color: #a8b7cf;
}
.v-select .v-select__selection--comma {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #323232;
}
.v-menu__content {
  margin-top: 10px;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #e4e9f1;
  border-top: 1px solid #1da2ff;
  box-shadow: 0 6px 16px 0 rgba(0,0,0,0.08);
}
.v-menu__content .v-select-list.v-card {
  color: #323232;
  box-shadow: none;
  background-color: transparent;
  border: none;
}
.v-menu__content .v-select-list.v-card .v-list {
  padding: 0;
  border-radius: 0;
}
.v-menu__content .v-select-list.v-card .v-list--disabled {
  opacity: 0.5;
}
.v-menu__content .v-select-list.v-card .v-list__tile {
  margin-bottom: 5px;
  padding: 0 20px;
  height: 36px;
  font-size: 16px;
  font-weight: 400;
  color: #323232;
}
.v-menu__content .v-select-list.v-card .v-list__tile:hover {
  background-color: rgba(29,162,255,0.05);
}
.v-menu__content .v-select-list.v-card .v-list__tile--active {
  font-weight: 500;
  background-color: rgba(29,162,255,0.05);
}
.v-menu__content .v-select-list.v-card .v-list__tile__mask {
  color: #323232;
  background-color: rgba(29,162,255,0.15);
}
.v-menu__content .v-select-list.v-card .v-list__tile__action {
  min-width: auto;
}
.v-menu__content .v-select-list.v-card .v-list__tile__action .v-input__slot {
  margin: 0;
}
.v-menu__content .v-select-list.v-card .v-list__tile__action .v-input--selection-controls__input {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 17px;
}
.v-menu__content .v-select-list.v-card .v-list__tile__action .v-input--selection-controls__input i {
  display: none;
  color: #1da2ff;
}
.v-menu__content .v-select-list.v-card .v-list__tile__action .v-input--selection-controls__input:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: transparent;
  border: 2px solid #95ccfa;
  transition: all 0.3s ease;
}
.v-menu__content .v-select-list.v-card .v-list__tile__action .v-input--selection-controls__input:after {
  content: '\e93f';
  position: absolute;
  top: 5px;
  left: 5px;
  margin-top: 1px;
  font-family: 'icomoon';
  font-size: 9px;
  color: #fff;
  transition: all 0.3s ease;
}
.v-menu__content .v-select-list.v-card .v-list__tile__action .v-input--selection-controls.v-input--is-label-active .v-input--selection-controls__input:after {
  opacity: 1;
}
.v-menu__content .v-select-list.v-card .v-list__tile__action .v-input--selection-controls.v-input--is-label-active .v-input--selection-controls__input:before {
  background-color: #1da2ff;
}
.v-menu__content .v-select-list.v-card .v-list__tile__action .v-input--selection-controls__ripple {
  display: none;
}
.v-text-field {
  margin: 0;
  padding: 0;
}
.v-text-field__details .v-messages {
  min-height: auto;
}
.v-text-field .v-label {
  font-size: 16px;
  color: #798496;
}
.v-text-field input {
  font-size: 14px;
  max-height: 42px;
}
.v-text-field input::placeholder {
  color: #acb9cd !important;
}
.v-text-field input:not([role="combobox"]) {
  border-bottom: 1px solid #e4e9f1;
}
.v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  margin-top: 0;
}
.theme--light.v-input:not(.v-input--is-disabled) input,
.theme--light.v-input:not(.v-input--is-disabled) textarea {
  color: #323232;
  text-overflow: ellipsis;
}
.v-text-field > .v-input__control > .v-input__slot:after,
.v-text-field > .v-input__control > .v-input__slot:before {
  content: none;
}
.el-checkbox {
  --checkWidth: fit-content;
  --checkInnerWidth: 20px;
  --checkInnerHeight: 20px;
  --checkInnerDisabledColor: var(--colorTextTertiary);
  --checkInnerBorder: 1px solid var(--colorGrayLight);
  --checkInnerBorder--hover: 1px solid var(--colorPrimary);
  --checkInnerBorder--active: 1px solid rgba(0,0,0,0.2);
  --checkInnerDisabledBorder: 1px solid var(--colorGrayLight);
  --checkInnerBorderRadius: 2px;
  --checkInnerBg: transparent;
  --checkInnerActiveBg: var(--colorPrimary);
  --checkInnerDisabbledBg: transparent;
  --checkLabelLeftIndent: 10px;
  --checkLabelFS: 14px;
  --checkLabelLH: 18px;
  --checkLabelFW: 400;
  --checkLabelColor: var(--colorText);
  --checkLabelActiveColor: var(--colorText);
  --checkLabelHoverColor: var(--colorText);
  --checkLabelDisabledColor: var(--colorTextSecondary);
  --checkLabelTransition: color 0.2s;
}
.el-checkbox +.el-checkbox {
  margin: 0;
}
.el-checkbox {
  display: flex;
  align-items: center;
  width: var(--checkWidth);
}
.el-checkbox:not(.is-disabled):hover .el-checkbox__input:not(.is-checked) .el-checkbox__inner {
  border: var(--checkInnerBorder--hover) !important;
}
.el-checkbox:not(.is-disabled):hover .el-checkbox__label {
  color: var(--checkLabelHoverColor);
}
.el-checkbox.is-disabled {
  cursor: not-allowed;
}
.el-checkbox__label {
  padding-left: var(--checkLabelLeftIndent);
  font-size: var(--checkLabelFS);
  line-height: var(--checkLabelLH);
  font-weight: var(--checkLabelFW);
  color: var(--checkLabelColor);
  transition: var(--checkLabelTransition);
}
.el-checkbox.is-checked .el-checkbox__label {
  color: var(--checkLabelActiveColor);
}
.el-checkbox__inner {
  display: block;
  width: var(--checkInnerWidth);
  height: var(--checkInnerHeight);
  border-radius: var(--checkInnerBorderRadius);
  background-color: var(--checkInnerBg);
  border: var(--checkInnerBorder);
}
.el-checkbox__inner:after {
  top: 2px;
  left: 6px;
  width: 4px;
  height: 9px;
  border-width: 2px;
}
.el-checkbox__input.is-disabled .el-checkbox__inner {
  border: var(--checkInnerDisabledBorder);
  background-color: var(--colorControlBg--disabled);
}
.el-checkbox__input.is-disabled .el-checkbox__inner:after {
  border-color: var(--checkInnerDisabledColor);
}
.el-checkbox__input.is-disabled +span.el-checkbox__label {
  color: var(--checkLabelDisabledColor);
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: var(--checkInnerActiveBg);
  border: var(--checkInnerBorder--active);
}
.el-checkbox__input.is-checked.is-focus .el-checkbox__inner {
  border: var(--checkInnerBorder--active);
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border: var(--checkInnerBorder);
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-date-editor .el-input__inner:focus,
.el-date-editor .el-input__inner:hover {
  border-color: #dcdfe6;
}
.el-dialog__wrapper {
  --dialogWrapPaddings: 20px;
  --dialogWidth: 100%;
  --dialogMaxWidth: 1000px;
  --dialogHeight: auto;
  --dialogMargins: 0 auto;
  --dialogBgColor: var(--colorPopoverBg);
  --dialogTextColor: var(--colorText);
  --dialogBRadius: 4px;
  --dialogBoxShadow: none;
  --dialogTitlePaddingRight: 40px;
  --dialogTitleFS: 24px;
  --dialogTitleLH: 32px;
  --dialogTitleFW: 600;
  --dialogTitleColor: inherit;
  --dialogHeaderPaddings: 0;
  --dialogBodyPaddings: 48px 32px 32px;
  --dialogBodyFS: 16px;
  --dialogBodyLH: 22px;
  --dialogBodyFW: 400;
  --dialogBodyWordBreak: break-word;
  --dialogCloseTop: 16px;
  --dialogCloseRight: 16px;
  --dialogCloseIconSize: 24px;
  --dialogCloseColor: #cad3e0;
  --dialogCloseColorHover: var(--colorPrimary);
  --dialogCloseTransition: all 0.2s;
}
.el-dialog {
  position: relative;
  width: var(--dialogWidth);
  max-width: var(--dialogMaxWidth);
  height: var(--dialogHeight);
  margin: var(--dialogMargins);
  background: var(--dialogBgColor);
  border-radius: var(--dialogBRadius);
  box-shadow: var(--dialogBoxShadow);
}
.el-dialog__wrapper {
  padding: var(--dialogWrapPaddings);
}
.el-dialog__header {
  padding: var(--dialogHeaderPaddings);
}
.el-dialog__title {
  padding-right: var(--dialogTitlePaddingRight);
  font-size: var(--dialogTitleFS);
  line-height: var(--dialogTitleLH);
  font-weight: var(--dialogTitleFW);
  color: var(--dialogTitleColor);
}
.el-dialog__headerbtn {
  top: var(--dialogCloseTop);
  right: var(--dialogCloseRight);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: var(--dialogCloseIconSize);
  line-height: normal;
  color: var(--dialogCloseColor);
  transition: all 0.2s;
}
.el-dialog__headerbtn:before {
  content: '\e5cd';
  font-size: inherit;
  color: inherit;
  font-family: 'Material Icons';
}
.el-dialog__headerbtn:hover {
  color: var(--dialogCloseColorHover);
}
.el-dialog__close {
  display: none;
}
.el-dialog__body {
  padding: var(--dialogBodyPaddings);
  font-size: var(--dialogBodyFS);
  line-height: var(--dialogBodyLH);
  font-weight: var(--dialogBodyFW);
  color: var(--dialogTextColor);
  word-break: var(--dialogBodyWordBreak);
}
.v-modal {
  --dialogWrapperColor: rgba(28,31,34,0.8);
  background: var(--dialogWrapperColor);
  opacity: 1;
}
.el-dropdown-menu {
  --dropdownPaddings: 6px 0;
  --dropdownBg: #fff;
  --dropdownBorderRadius: 4px;
  --dropdownBorderColor: var(--colorBorder);
  --dropdownBorder: 1px solid var(--dropdownBorderColor);
  --dropdownBoxShadow: 0px 16px 31px rgba(10,35,94,0.24), 0px 3px 5px rgba(10,35,94,0.1);
  --dropdownPlacementBottomIndent: 10px;
  --dropdownPlacementTopIndent: 10px;
  --dropdownPlacementLeftIndent: 5px;
  --dropdownPlacementRightIndent: 5px;
  --dropdownItemWidth: 100%;
  --dropdownItemHeight: auto;
  --dropdownItemMargin: 0;
  --dropdownItemPadding: 8px 12px;
  --dropdownItemFS: 16px;
  --dropdownItemLH: 20px;
  --dropdownItemFW: 500;
  --dropdownItemColor: var(--colorText);
  --dropdownItemTextAlign: left;
  --dropdownItemTransition: all 0.2s;
  --dropdownItemDividedBorder: 1px solid var(--colorBorder);
  --dropdownItemHoverBg: var(--colorPopoverItemBg--hover);
  --dropdownItemHoverColor: var(--colorText);
  --dropdownItemDisabledColor: var(--colorTextTertiary);
  --dropdownItemDangerColor: var(--colorDanger);
  --dropdownItemDangerHoverBg: var(--colorPopoverItemBg--hover);
  --dropdownItemDangerHoverColor: var(--colorDanger);
  --dropdownItemSecondaryColor: var(--colorTextSecondary);
  --dropdownItemSecondaryHoverColor: var(--colorTextSecondary);
}
.el-dropdown-menu {
  padding: var(--dropdownPaddings);
  background-color: var(--dropdownBg);
  border-radius: var(--dropdownBorderRadius);
  border: var(--dropdownBorder);
  box-shadow: var(--dropdownBoxShadow);
}
.el-dropdown-menu[x-placement^=bottom] {
  margin-top: var(--dropdownPlacementBottomIndent);
}
.el-dropdown-menu[x-placement^=bottom] .popper__arrow {
  border-bottom-color: var(--dropdownBorderColor);
}
.el-dropdown-menu[x-placement^=bottom] .popper__arrow:after {
  border-bottom-color: var(--dropdownBg);
}
.el-dropdown-menu[x-placement^=top] {
  margin-bottom: var(--dropdownPlacementTopIndent);
}
.el-dropdown-menu[x-placement^=top] .popper__arrow {
  border-top-color: var(--dropdownBorderColor);
}
.el-dropdown-menu[x-placement^=top] .popper__arrow:after {
  border-top-color: var(--dropdownBg);
}
.el-dropdown-menu[x-placement^=left] {
  margin-right: var(--dropdownPlacementLeftIndent);
}
.el-dropdown-menu[x-placement^=left] .popper__arrow {
  right: -7px;
  border-left-color: var(--dropdownBorderColor);
}
.el-dropdown-menu[x-placement^=left] .popper__arrow:after {
  border-left-color: var(--dropdownBg);
}
.el-dropdown-menu[x-placement^=right] {
  margin-left: var(--dropdownPlacementRightIndent);
}
.el-dropdown-menu[x-placement^=right] .popper__arrow {
  left: -7px;
  border-right-color: var(--dropdownBorderColor);
}
.el-dropdown-menu[x-placement^=right] .popper__arrow:after {
  border-right-color: var(--dropdownBg);
}
.el-dropdown-menu .popper__arrow {
  display: none;
}
.el-dropdown-menu__item {
  width: var(--dropdownItemWidth);
  height: var(--dropdownItemHeight);
  margin: var(--dropdownItemMargin);
  padding: var(--dropdownItemPadding);
  font-size: var(--dropdownItemFS);
  line-height: var(--dropdownItemLH);
  font-weight: var(--dropdownItemFW);
  color: var(--dropdownItemColor);
  text-align: var(--dropdownItemTextAlign);
  transition: var(--dropdownItemTransition);
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: var(--dropdownItemHoverBg);
  color: var(--dropdownItemHoverColor);
}
.el-dropdown-menu__item.is-danger {
  color: var(--dropdownItemDangerColor);
}
.el-dropdown-menu__item.is-danger:hover {
  background-color: var(--dropdownItemDangerHoverBg);
  color: var(--dropdownItemDangerHoverColor);
}
.el-dropdown-menu__item.is-secondary {
  color: var(--dropdownItemSecondaryColor);
}
.el-dropdown-menu__item.is-secondary:hover {
  color: var(--dropdownItemSecondaryHoverColor);
}
.el-dropdown-menu__item.is-disabled {
  pointer-events: all;
  cursor: not-allowed;
  color: var(--dropdownItemDisabledColor);
}
.el-dropdown-menu__item.is-disabled > * {
  pointer-events: none;
}
.el-dropdown-menu__item.is-wrapper {
  padding: 0;
}
.el-dropdown-menu__item.is-wrapper >button,
.el-dropdown-menu__item.is-wrapper >a {
  display: block;
  width: 100%;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  text-align: inherit;
  padding: var(--dropdownItemPadding);
}
.el-dropdown-menu__item--divided {
  border-top: var(--dropdownItemDividedBorder);
}
.el-dropdown-menu__item--divided:before {
  content: none;
}
.el-input {
  --inputHeight: var(--sizeControlHeightDefault);
  --inputLeftPadding: 10px;
  --inputRightPadding: 10px;
  --inputBgColor: var(--colorControlBg);
  --inputBgColorDisabled: var(--colorControlBg--disabled);
  --inputTransition: all 0.2s;
  --inputIconColor: var(--colorControlIconColor);
  --inputPrefixIconWidth: 24px;
  --inputPrefixIconSize: 24px;
  --inputPrefixIconRightIndent: 8px;
  --inputPrefixIconLeftIndent: 10px;
  --inputSuffixIconWidth: 24px;
  --inputSuffixIconSize: 24px;
  --inputSuffixIconRightIndent: 10px;
  --inputSuffixIconLeftIndent: 8px;
  --inputClearBtnSize: 24px;
  --inputClearBtnColor: var(--colorControlIconColor);
  --inputClearBtnColorHover: var(--colorDanger);
  --inputFS: 16px;
  --inputLH: normal;
  --inputFW: 400;
  --inputTextAlign: left;
  --inputTextColor: var(--colorControlColor);
  --inputTextColorDisabled: var(--colorControlColor--disabled);
  --inputPlaceholderFS: 16px;
  --inputPlaceholderLH: normal;
  --inputPlaceholderFW: 400;
  --inputPlaceholderColor: var(--colorControlPlaceholderColor);
  --inputBorderColor: var(--colorControlBorder);
  --inputBorder: 1px solid var(--inputBorderColor);
  --inputBorderColorDisabled: var(--colorControlBorder--disabled);
  --inputBorderColorHover: var(--colorControlBorder--hover);
  --inputBorderColorFocus: var(--colorControlBorder--focus);
  --inputBRadiusTLeft: 4px;
  --inputBRadiusTRight: 4px;
  --inputBRadiusBRight: 4px;
  --inputBRadiusBLeft: 4px;
  --smallInputHeight: var(--sizeControlHeightSmall);
  --smallInputLeftPadding: 10px;
  --smallInputRightPadding: 10px;
  --smallInputFS: 14px;
  --smallInputLH: normal;
  --smallInputPlaceholderFS: 14px;
  --smallInputPlaceholderLH: normal;
  --smallInputSuffixIconRightIndent: 8px;
}
.el-input__inner {
  position: relative;
  width: 100%;
  height: var(--inputHeight);
  padding: 0 var(--inputRightPadding) 0 var(--inputLeftPadding);
  font-size: var(--inputFS);
  line-height: var(--inputLH);
  font-weight: var(--inputFW);
  color: var(--inputTextColor);
  text-align: var(--inputTextAlign);
  background-color: var(--inputBgColor);
  border: var(--inputBorder);
  border-radius: var(--inputBRadiusTLeft) var(--inputBRadiusTRight) var(--inputBRadiusBRight) var(--inputBRadiusBLeft);
  transition: var(--inputTransition);
}
.el-input__inner::placeholder {
  font-size: var(--inputPlaceholderFS);
  line-height: var(--inputPlaceholderLH);
  font-weight: var(--inputPlaceholderFW);
  color: var(--inputPlaceholderColor);
}
.el-input__inner:not(:focus):hover {
  z-index: 1;
  border-color: var(--inputBorderColorHover);
}
.el-input__inner:focus {
  z-index: 2;
  border-color: var(--inputBorderColorFocus);
}
.el-input.is-disabled .el-input__inner {
  border-color: var(--inputBorderColorDisabled);
  color: var(--inputTextColorDisabled);
  background-color: var(--inputBgColorDisabled);
}
.el-input.has-double-suffix .el-input__inner {
  padding-right: calc(var(--inputSuffixIconLeftIndent) + var(--inputSuffixIconWidth) * 2 + var(--inputSuffixIconRightIndent));
}
.el-input .el-input__clear {
  font-size: var(--inputClearBtnSize);
  color: var(--inputClearBtnColor);
}
.el-input .el-input__clear:hover {
  color: var(--inputClearBtnColorHover);
}
.el-input__suffix,
.el-input__prefix {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--inputIconColor);
}
.el-input__prefix {
  pointer-events: none;
  left: var(--inputPrefixIconLeftIndent);
}
.el-input__prefix .ui-icon {
  --iconSize: var(--inputPrefixIconSize);
}
.el-input--prefix .el-input__inner {
  padding-left: calc(var(--inputPrefixIconLeftIndent) + var(--inputPrefixIconWidth) + var(--inputPrefixIconRightIndent));
}
.el-input__suffix {
  pointer-events: none;
  right: var(--inputSuffixIconRightIndent);
}
.el-input__suffix .ui-icon {
  --iconSize: var(--inputSuffixIconSize);
}
.el-input--suffix:not(.has-double-suffix) .el-input__inner {
  padding-right: calc(var(--inputSuffixIconLeftIndent) + var(--inputSuffixIconWidth) + var(--inputSuffixIconRightIndent));
}
.el-input__suffix-inner {
  pointer-events: none;
  display: flex;
  align-items: center;
}
.el-input__icon {
  width: var(--inputPrefixIconWidth);
  height: auto;
  font-size: var(--inputPrefixIconSize);
  line-height: normal;
}
.el-input__icon:not(.el-icon-circle-close) {
  pointer-events: none;
}
.el-input__icon.el-icon-circle-close {
  pointer-events: all;
}
.el-input__icon.el-icon-circle-close:hover {
  color: var(--inputClearBtnColorHover);
}
.el-input.is-disabled .el-input__icon.el-icon-circle-close {
  pointer-events: none;
}
.el-input--small .el-input__inner {
  height: var(--smallInputHeight);
  padding-left: var(--smallInputLeftPadding);
  padding-right: var(--smallInputRightPadding);
  font-size: var(--smallInputFS);
  line-height: var(--smallInputLH);
}
.el-input--small .el-input__inner::placeholder {
  font-size: var(--smallInputPlaceholderFS);
  line-height: var(--smallInputPlaceholderLH);
}
.el-input--small .el-input__suffix {
  right: var(--smallInputSuffixIconRightIndent);
}
.el-input--small .el-input__icon {
  line-height: normal;
}
.el-message {
  top: 60px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 620px;
  min-width: auto;
  min-height: auto;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid transparent;
}
.el-message.is-closable .el-message__content {
  padding-right: 40px;
}
.el-message--error {
  background-color: var(--colorDanger);
}
.el-message--info {
  width: fit-content;
  background-color: var(--colorText);
}
.el-message--info .el-message__content {
  color: #fff;
}
.el-message__icon {
  margin-right: 16px;
  font-size: 22px;
  color: #fff;
}
.el-message__icon.el-icon-error,
.el-message__icon.el-icon-info {
  color: #fff;
}
.el-message__icon.el-icon-error:before,
.el-message__icon.el-icon-info:before {
  content: '\e964';
  font-family: 'icomoon';
}
.el-message__content {
  padding: 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #fff;
}
.el-message__content>*+* {
  margin-top: 6px;
}
.el-message__content a {
  text-decoration: underline;
}
.el-message__content a:hover {
  text-decoration: none;
}
.el-message__content h5 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}
.el-message__content p:not(:first-child) {
  margin-top: 6px;
}
.el-message.el-message--error .el-message__content,
.el-message.el-message--success .el-message__content,
.el-message.el-message--warning .el-message__content {
  color: #fff;
}
.el-message__closeBtn {
  top: 20px;
  right: 20px;
  font-size: 24px;
  color: #fff;
  transform: none;
  transition: all 0.2s;
}
.el-message__closeBtn:hover {
  color: #fff;
  opacity: 0.7;
}
.el-popover {
  --popoverWidth: fit-content;
  --popoverMinWidth: auto;
  --popoverPaddings: 8px;
  --popoverFS: 12px;
  --popoverLH: 16px;
  --popoverFW: 400;
  --popoverColor: var(--colorText);
  --popoverTextAlign: left;
  --popoverWordBreak: break-word;
  --popoverBg: var(--colorPopoverBg);
  --popoverBorderRadius: 4px;
  --popoverBorderColor: var(--colorBorder);
  --popoverBorder: 1px solid var(--popoverBorderColor);
  --popoverShadow: var(--shadowPopover);
}
.el-popover {
  width: var(--popoverWidth) !important;
  min-width: var(--popoverMinWidth);
  padding: var(--popoverPaddings);
  font-size: var(--popoverFS);
  line-height: var(--popoverLH);
  font-weight: var(--popoverFW);
  color: var(--popoverColor);
  text-align: var(--popoverTextAlign);
  word-break: var(--popoverWordBreak);
  border: var(--popoverBorder);
  border-radius: var(--popoverBorderRadius);
  background: var(--popoverBg);
  box-shadow: var(--popoverShadow);
}
.el-popper {
  --popperPlacementBottomIndent: 5px;
  --popperPlacementTopIndent: 5px;
  --popperPlacementlLeftIndent: 5px;
  --popperPlacementRightIndent: 5px;
  --popperBg: var(--colorPopoverBg);
  --popperBorderColor: var(--colorBorder);
}
.el-popper[x-placement^=bottom] {
  margin-top: var(--popperPlacementBottomIndent);
}
.el-popper[x-placement^=bottom] .popper__arrow {
  border-bottom-color: var(--popperBorderColor);
}
.el-popper[x-placement^=bottom] .popper__arrow:after {
  border-bottom-color: var(--popperBg);
}
.el-popper[x-placement^=top] {
  margin-bottom: var(--popperPlacementTopIndent);
}
.el-popper[x-placement^=top] .popper__arrow {
  border-top-color: var(--popperBorderColor);
}
.el-popper[x-placement^=top] .popper__arrow:after {
  border-top-color: var(--popperBg);
}
.el-popper[x-placement^=left] {
  margin-right: var(--popperPlacementLeftIndent);
}
.el-popper[x-placement^=left] .popper__arrow {
  right: -7px;
  border-left-color: var(--popperBorderColor);
}
.el-popper[x-placement^=left] .popper__arrow:after {
  border-left-color: var(--popperBg);
}
.el-popper[x-placement^=right] {
  margin-left: var(--popperPlacementRightIndent);
}
.el-popper[x-placement^=right] .popper__arrow {
  left: -7px;
  border-right-color: var(--popperBorderColor);
}
.el-popper[x-placement^=right] .popper__arrow:after {
  border-right-color: var(--popperBg);
}
.el-select-dropdown {
  --selectMenuBorder: 1px solid var(--colorBorder);
  --selectMenuPaddings: 12px 0;
  --selectMenuBorderRadius: 4px;
  --selectMenuShadow: var(--shadowPopover);
  --selectItemHeight: auto;
  --selectItemPadding: 8px 16px;
  --selectItemFS: 16px;
  --selectItemLH: 20px;
  --selectItemFW: 400;
  --selectItemColor: var(--colorText);
  --selectItemWhiteSpace: normal;
  --selectItemTextAlign: left;
  --selectActiveItemFW: 400;
  --selectActiveItemColor: var(--colorPrimary);
  --selectItemHover: var(--colorPopoverItemBg--hover);
  --selectDisabledItemColor: var(--colorTextSecondary);
}
.el-select-dropdown {
  padding: var(--selectMenuPaddings);
  border-radius: var(--selectMenuBorderRadius);
  border: var(--selectMenuBorder);
  border-top-color: var(--colorPrimary);
  box-shadow: var(--selectMenuShadow);
  overflow: hidden;
}
.el-select-dropdown__list {
  padding: 0;
}
.el-select-dropdown__item {
  width: 100%;
  height: var(--selectItemHeight);
  padding: var(--selectItemPadding);
  font-size: var(--selectItemFS);
  line-height: var(--selectItemLH);
  font-weight: var(--selectItemFW);
  color: var(--selectItemColor);
  text-align: var(--selectItemTextAlign);
  white-space: var(--selectItemWhiteSpace);
}
.el-select-dropdown__item:not(.is-disabled).hover,
.el-select-dropdown__item:not(.is-disabled):hover {
  background-color: var(--selectItemHover);
}
.el-select-dropdown__item.is-disabled {
  color: var(--selectDisabledItemColor);
}
.el-select-dropdown__item.selected {
  position: relative;
  font-weight: var(--selectActiveItemFW);
  color: var(--selectActiveItemColor);
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: var(--colorText);
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover,
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected:hover {
  background-color: var(--selectItemHover);
}
.el-select-dropdown.is-multiple .el-select-dropdown__item:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid var(--colorPrimary);
  transform: translateY(-50%);
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected:before {
  content: none;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected:after {
  content: '\e93f';
  font-family: 'icomoon';
  font-size: 8px;
  color: #fff;
  outline: 2px solid #88ceff;
  background-color: var(--colorPrimary);
  border-color: var(--colorPrimary);
}
.el-select-dropdown .popper__arrow {
  display: none;
}
.el-select {
  --selectChevronColor: var(--colorControlChevronColor);
  --selectBorderColor: var(--colorControlBorder);
  --selectBorderColorHover: var(--colorControlBorder--hover);
  --selectBorderColorFocus: var(--colorControlBorder--focus);
  --selectChevronSize: 20px;
  --smallSelectRightPadding: 24px;
  --smallSelectChevronSize: 16px;
  --selectTagMargin: 2px;
  --selectTagFS: 12px;
  --selectTagLH: 27px;
  --selectTagFW: 500;
  --selectTagColor: var(--colorText);
  --selectTagBg: var(--colorBorder);
  --selectTagBorder: 1px solid transparent;
  --selectTagBRadius: 4px;
  --selectTagCloseFS: 16px;
  --selectTagCloseColor: var(--colorTextSecondary);
  --selectTagCloseColorHover: var(--colorDanger);
  --selectTagCloseBg: transparent;
  --selectTagCloseBgHover: transparent;
}
.el-select {
  width: 100%;
}
.el-select .el-input:hover .el-input__inner {
  border-color: var(--selectBorderColorHover);
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: var(--selectBorderColorFocus);
}
.el-select .el-input.is-focus .el-input__inner:hover {
  border-color: var(--selectBorderColorFocus);
}
.el-select .el-input__inner:focus {
  border-color: var(--selectBorderColor);
}
.el-select .el-input__inner:focus:hover {
  border-color: var(--selectBorderColorHover);
}
.el-select .el-input .el-select__caret {
  font-size: var(--selectChevronSize);
  color: var(--selectChevronColor);
}
.el-select--small .el-input--small.el-input .el-input__inner {
  padding-right: var(--smallSelectRightPadding);
}
.el-select--small .el-input--small.el-input .el-select__caret {
  font-size: var(--smallSelectChevronSize);
}
.el-select__tags {
  padding-left: 10px;
  z-index: 3;
}
.el-select__tags>span {
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
}
.el-select .el-tag {
  margin: var(--selectTagMargin);
  font-size: var(--selectTagFS);
  line-height: var(--selectTagLH);
  font-weight: var(--selectTagFW);
  color: var(--selectTagColor);
  background-color: var(--selectTagBg);
  border-radius: var(--selectTagBRadius);
}
.el-select .el-tag.el-tag.el-tag--info {
  border: var(--selectTagBorder);
}
.el-select .el-tag--small {
  height: 28px;
  padding: 0 10px 0 6px;
}
.el-select .el-tag--mini {
  height: 24px;
  padding: 0 8px;
}
.el-select .el-tag__close.el-icon-close {
  font-size: var(--selectTagCloseFS);
  color: var(--selectTagCloseColor);
  background-color: var(--selectTagCloseBg);
}
.el-select .el-tag__close.el-icon-close:hover {
  color: var(--selectTagCloseColorHover);
  background-color: var(--selectTagCloseBgHover);
}
.el-tabs.el-tabs--card .el-tabs__header {
  margin: 0;
  border-bottom: none;
}
.el-tabs.el-tabs--card .el-tabs__content {
  padding: 40px 0 0;
  background-color: #fff;
  border: 1px solid #f4f4f4;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.08);
  text-align: center;
}
.el-tabs.el-tabs--card .el-tabs__item {
  position: relative;
  height: 50px;
  padding: 0 50px 0 50px !important;
  font-size: 14px;
  line-height: 50px;
  font-weight: 600;
  border-color: #f4f4f4;
  outline: none !important;
  box-shadow: none !important;
  transition: all 0.3s ease;
}
.el-tabs.el-tabs--card .el-tabs__item:hover {
  color: #c9c9c9;
  background-color: rgba(255,255,255,0.3);
}
.el-tabs.el-tabs--card .el-tabs__item.is-active {
  color: #323232;
  background: #fff;
}
.el-tabs.el-tabs--card .el-tabs__item.is-disabled {
  padding: 0 50px 0 50px;
  color: #cad3e0;
}
.el-tabs.el-tabs--card .el-tabs__item:last-child {
  padding: 0 50px 0 50px;
}
.el-tabs.el-tabs--card .el-tabs__nav {
  border: 1px solid #f4f4f4;
  border-bottom: none;
}
.el-tooltip__popper.is-light {
  color: #444;
  border-color: #e4e9f1;
}
.el-tooltip__popper.is-light[x-placement^=top] .popper__arrow {
  border-top-color: #e4e9f1;
}
.ui-icon {
  --iconSize: inherit;
}
.ui-icon {
  width: 1em;
  height: 1em;
  font-size: var(--iconSize);
  color: inherit;
}
.tag {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
}
.tag--clickable {
  cursor: pointer;
}
.tag--color-success {
  color: var(--colorSuccess);
  background-color: var(--colorSuccess-10);
}
.tag--color-primary {
  color: var(--colorPrimary);
  background-color: var(--colorPrimary-10);
}
.tag--color-secondary {
  color: var(--colorGray);
  background-color: var(--colorGrayLight-15);
}
.tag--color-danger {
  color: var(--colorDanger);
  background-color: var(--colorDanger-10);
}
.tag--size-xxs {
  height: var(--height-control-xxs);
  padding: 0 5px;
  font-size: 12px;
  line-height: 14px;
}
.tag--size-xs {
  height: var(--height-control-xs);
  padding: 0 8px;
  font-size: 14px;
  line-height: 18px;
}
.tag--size-s {
  height: var(--height-control-s);
  padding: 0 8px;
  font-size: 14px;
  line-height: 16px;
}
.tag--size-m {
  height: var(--height-control-m);
  padding: 0 10px;
  font-size: 16px;
  line-height: 18px;
}
.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
}
.btn:not(.btn--transparent):not(.btn--text-only) {
  background-color: #1da2ff;
  border-color: #1da2ff;
  color: #fff;
}
.btn:not(.btn--transparent):not(.btn--text-only):not(:disabled):not(.is-loading):hover {
  background-color: #1891e5;
}
.btn.btn--transparent {
  color: #1da2ff;
  border-color: #e4e9f1;
}
.btn.btn--transparent:not(:disabled):not(.is-loading):not(.btn--danger):hover {
  background-color: #e4e9f1;
}
.btn.btn--transparent.btn--danger {
  border-color: #ef4444;
  color: #ef4444;
}
.btn.btn--transparent.btn--danger:hover {
  background-color: #ef4444;
  color: #fff;
}
.btn.btn--text-only {
  width: auto;
  height: auto;
  padding: 0;
  color: #798496;
  text-transform: uppercase;
}
.btn.btn--text-only:not(:disabled):not(.is-loading):hover {
  color: #1da2ff;
}
.btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.btn.is-loading {
  cursor: wait;
  opacity: 0.7;
}
.btn.btn--uppercase {
  text-transform: uppercase;
}
.btn--prefix-icon:before {
  font-family: 'icomoon';
  margin-right: 9px;
}
.btn--suffix-icon:after {
  font-family: 'icomoon';
  margin-left: 9px;
}
.btn--icon-new-page:before {
  content: '\e954';
  font-size: 14px;
}
.btn--icon-link2:before {
  content: '\e934';
  font-size: 14px;
}
.btn--icon-cubics:before {
  content: '\e957';
  font-size: 14px;
}
.ui-btn {
  --primary-btn-Color: var(--primary-text-inside);
  --primary-btn-Bg: var(--primary);
  --primary-btn-Bg--hover: var(--primary-hover);
  --primary-btn-Bg--active: var(--primary-active);
  --primary-btn-BorderColor: transparent;
  --primary-btn-ShadowColor: transparent;
  --primary-btn-ShadowColor--focus: var(--primary-alpha-40);
  --semitransparent-blue-btn-Color: var(--semitransparent-blue-text-inside);
  --semitransparent-blue-btn-Bg: var(--semitransparent-blue);
  --semitransparent-blue-btn-Bg--hover: var(--semitransparent-blue-hover);
  --semitransparent-blue-btn-Bg--active: var(--semitransparent-blue-active);
  --semitransparent-blue-btn-BorderColor: transparent;
  --semitransparent-blue-btn-ShadowColor: transparent;
  --semitransparent-blue-btn-ShadowColor--focus: var(--semitransparent-blue-alpha-40);
  --secondary-btn-Color: var(--secondary-text-inside);
  --secondary-btn-Bg: var(--secondary);
  --secondary-btn-Bg--hover: var(--secondary-hover);
  --secondary-btn-Bg--active: var(--secondary-active);
  --secondary-btn-BorderColor: var(--colorControlBorder);
  --secondary-btn-BorderColor--hover: var(--colorControlBorder--hover);
  --secondary-btn-BorderColor--active: var(--colorControlBorder--hover);
  --secondary-btn-ShadowColor: transparent;
  --secondary-btn-ShadowColor--focus: var(--secondary-alpha-60);
  --secondaryBlue-btn-Color: var(--secondaryBlue-text-inside);
  --secondaryBlue-btn-Bg: transparent;
  --secondaryBlue-btn-Bg--hover: var(--secondaryBlue-hover);
  --secondaryBlue-btn-Bg--active: var(--secondaryBlue-active);
  --secondaryBlue-btn-BorderColor: #d2dae7;
  --secondaryBlue-btn-BorderColor--hover: #d2dae7;
  --secondaryBlue-btn-BorderColor--active: #d2dae7;
  --secondaryBlue-btn-ShadowColor: transparent;
  --secondaryBlue-btn-ShadowColor--focus: var(--secondaryBlue-alpha-80);
  --secondaryTextBlue-btn-Color: var(--secondaryBlue-text-inside);
  --secondaryTextBlue-btn-Bg: transparent;
  --secondaryTextBlue-btn-Bg--hover: var(--secondaryBlue-hover);
  --secondaryTextBlue-btn-Bg--active: var(--secondaryBlue-active);
  --secondaryTextBlue-btn-BorderColor: var(--colorBorder);
  --secondaryTextBlue-btn-BorderColor--hover: var(--colorBorder);
  --secondaryTextBlue-btn-BorderColor--active: var(--colorBorder);
  --secondaryTextBlue-btn-ShadowColor: transparent;
  --secondaryTextBlue-btn-ShadowColor--focus: var(--secondaryBlue-alpha-80);
  --green-btn-Color: var(--green-text-inside);
  --green-btn-Bg: var(--green);
  --green-btn-Bg--hover: var(--green-hover);
  --green-btn-Bg--active: var(--green-active);
  --green-btn-BorderColor: transparent;
  --green-btn-ShadowColor: transparent;
  --green-btn-ShadowColor--focus: var(--green-alpha-40);
  --ghost-btn-Color: var(--ghost-text-inside);
  --ghost-btn-Bg: var(--ghost);
  --ghost-btn-Bg--hover: var(--ghost-hover);
  --ghost-btn-Bg--active: var(--ghost-active);
  --ghost-btn-BorderColor: transparent;
  --ghost-btn-ShadowColor: transparent;
  --ghost-btn-ShadowColor--focus: var(--ghost-alpha-40);
  --ghostSecondary-btn-Color: var(--ghost-secondary-text-inside);
  --ghostSecondary-btn-Color--hover: var(--colorText);
  --ghostSecondary-btn-Color--active: var(--colorText);
  --ghostSecondary-btn-Bg: var(--ghost);
  --ghostSecondary-btn-Bg--hover: var(--ghost-hover);
  --ghostSecondary-btn-Bg--active: var(--ghost-active);
  --ghostSecondary-btn-BorderColor: transparent;
  --ghostSecondary-btn-ShadowColor: transparent;
  --ghostSecondary-btn-ShadowColor--focus: var(--ghost-alpha-40);
  --success-btn-Color: var(--success-text-inside);
  --success-btn-Bg: var(--success);
  --success-btn-Bg--hover: var(--success-hover);
  --success-btn-Bg--active: var(--success-active);
  --success-btn-BorderColor: transparent;
  --success-btn-ShadowColor: transparent;
  --success-btn-ShadowColor--focus: var(--success-alpha-40);
  --info-btn-Color: var(--info-text-inside);
  --info-btn-Bg: var(--info);
  --info-btn-Bg--hover: var(--info-hover);
  --info-btn-Bg--active: var(--info-active);
  --info-btn-BorderColor: transparent;
  --info-btn-ShadowColor: transparent;
  --info-btn-ShadowColor--focus: var(--info-alpha-40);
  --warning-btn-Color: var(--warning-text-inside);
  --warning-btn-Bg: var(--warning);
  --warning-btn-Bg--hover: var(--warning-hover);
  --warning-btn-Bg--active: var(--warning-active);
  --warning-btn-BorderColor: transparent;
  --warning-btn-ShadowColor: transparent;
  --warning-btn-ShadowColor--focus: var(--warning-alpha-40);
  --help-btn-Color: var(--help-text-inside);
  --help-btn-Bg: var(--help);
  --help-btn-Bg--hover: var(--help-hover);
  --help-btn-Bg--active: var(--help-active);
  --help-btn-BorderColor: transparent;
  --help-btn-ShadowColor: transparent;
  --help-btn-ShadowColor--focus: var(--help-alpha-40);
  --danger-btn-Color: var(--danger-text-inside);
  --danger-btn-Bg: var(--danger);
  --danger-btn-Bg--hover: var(--danger-hover);
  --danger-btn-Bg--active: var(--danger-active);
  --danger-btn-BorderColor: transparent;
  --danger-btn-ShadowColor: transparent;
  --danger-btn-ShadowColor--focus: var(--danger-alpha-40);
  --contrast-btn-Color: var(--contrast-text-inside);
  --contrast-btn-Bg: var(--contrast);
  --contrast-btn-Bg--hover: var(--contrast-hover);
  --contrast-btn-Bg--active: var(--contrast-active);
  --contrast-btn-BorderColor: transparent;
  --contrast-btn-ShadowColor: transparent;
  --contrast-btn-ShadowColor--focus: var(--contrast-alpha-40);
}
.ui-btn {
  --btnWidth: fit-content;
  --btnHeight: 1em;
  --btnPaddingLeft: 0.5em;
  --btnPaddingRight: 0.5em;
  --btnFF: var(--ffMain);
  --btnFS: 0.4em;
  --btnLH: normal;
  --btnFW: 500;
  --btnLetterSpacing: 0;
  --btnBorderWidth: 1px;
  --btnBorder: var(--btnBorderWidth) solid var(--btnBorderColor);
  --btnBorderRadius: 6px;
  --btnBoxShadowColor: transparent;
  --btnOpacityDisabled: 0.6;
  --btnTransition: all 0.2s;
  --btnPrependSize: 0.425em;
  --btnPrependMargin: 0.25em;
  --btnAppendSize: 0.425em;
  --btnAppendMargin: 0.25em;
  --btnLoadingIconSize: 0.425em;
  --btnLoadingIconMargin: 0.25em;
  --btnBadgeMargin: 0.6em;
  --btnSingleIconSize: 0.5em;
  --btnIconColor: inherit;
}
.ui-btn {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: var(--btnWidth);
  height: var(--btnHeight);
  padding-left: var(--btnPaddingLeft);
  padding-right: var(--btnPaddingRight);
  font-family: var(--btnFF);
  font-size: var(--btnSize);
  color: var(--btnColor);
  background-color: var(--btnBg);
  border-radius: var(--btnBorderRadius);
  border: var(--btnBorder);
  box-shadow: 0 0 0 3px var(--btnShadowColor);
  transition: var(--btnTransition);
}
.ui-btn:disabled,
.ui-btn.ui-btn--disabled {
  cursor: not-allowed;
  opacity: var(--btnOpacityDisabled);
}
.ui-btn:disabled[href],
.ui-btn.ui-btn--disabled[href] {
  pointer-events: none;
}
.ui-btn.ui-btn--loading {
  cursor: wait;
}
.ui-btn.ui-btn--size-xs {
  --btnSize: var(--height-control-xs);
  --btnBorderRadius: 4px;
}
.ui-btn.ui-btn--size-s {
  --btnSize: var(--height-control-s);
  --btnBorderRadius: 4px;
}
.ui-btn.ui-btn--size-m {
  --btnSize: var(--height-control-m);
  --btnBorderRadius: 4px;
}
.ui-btn.ui-btn--size-l {
  --btnSize: var(--height-control-l);
  --btnFS: 0.36em;
  --btnBorderRadius: 6px;
}
.ui-btn.ui-btn--icon-only {
  --btnWidth: var(--btnHeight);
  --btnPaddingLeft: 0;
  --btnPaddingRight: 0;
  --btnLoadingIconMargin: 0;
}
.ui-btn.ui-btn--type-primary {
  --btnColor: var(--primary-btn-Color);
  --btnBg: var(--primary-btn-Bg);
  --btnBorderColor: var(--primary-btn-BorderColor);
  --btnShadowColor: var(--primary-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-primary:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--primary-btn-Bg--hover);
  }
}
.ui-btn.ui-btn--type-primary:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-primary:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--primary-btn-Bg--active);
}
.ui-btn.ui-btn--type-primary:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--primary-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-semitransparent-blue {
  --btnColor: var(--semitransparent-blue-btn-Color);
  --btnBg: var(--semitransparent-blue-btn-Bg);
  --btnBorderColor: var(--semitransparent-blue-btn-BorderColor);
  --btnShadowColor: var(--semitransparent-blue-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-semitransparent-blue:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--semitransparent-blue-btn-Bg--hover);
  }
}
.ui-btn.ui-btn--type-semitransparent-blue:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-semitransparent-blue:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--semitransparent-blue-btn-Bg--active);
}
.ui-btn.ui-btn--type-semitransparent-blue:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--semitransparent-blue-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-secondary {
  --btnColor: var(--secondary-btn-Color);
  --btnBg: var(--secondary-btn-Bg);
  --btnBorderColor: var(--secondary-btn-BorderColor);
  --btnShadowColor: var(--secondary-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-secondary:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--secondary-btn-Bg--hover);
    --btnBorderColor: var(--secondary-btn-BorderColor--hover);
  }
}
.ui-btn.ui-btn--type-secondary:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-secondary:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--secondary-btn-Bg--active);
  --btnBorderColor: var(--secondary-btn-BorderColor--active);
}
.ui-btn.ui-btn--type-secondary:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--secondary-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-secondary-blue {
  --btnColor: var(--secondaryBlue-btn-Color);
  --btnBg: var(--secondaryBlue-btn-Bg);
  --btnBorderColor: var(--secondaryBlue-btn-BorderColor);
  --btnShadowColor: var(--secondaryBlue-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-secondary-blue:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--secondaryBlue-btn-Bg--hover);
    --btnBorderColor: var(--secondaryBlue-btn-BorderColor--hover);
  }
}
.ui-btn.ui-btn--type-secondary-blue:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-secondary-blue:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--secondaryBlue-btn-Bg--active);
  --btnBorderColor: var(--secondaryBlue-btn-BorderColor--active);
}
.ui-btn.ui-btn--type-secondary-blue:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--secondaryBlue-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-secondary-text-blue {
  --btnColor: var(--secondaryTextBlue-btn-Color);
  --btnBg: var(--secondaryTextBlue-btn-Bg);
  --btnBorderColor: var(--secondaryTextBlue-btn-BorderColor);
  --btnShadowColor: var(--secondaryTextBlue-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-secondary-text-blue:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--secondaryTextBlue-btn-Bg--hover);
    --btnBorderColor: var(--secondaryTextBlue-btn-BorderColor--hover);
  }
}
.ui-btn.ui-btn--type-secondary-text-blue:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-secondary-text-blue:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--secondaryTextBlue-btn-Bg--active);
  --btnBorderColor: var(--secondaryTextBlue-btn-BorderColor--active);
}
.ui-btn.ui-btn--type-secondary-text-blue:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--secondaryTextBlue-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-green {
  --btnColor: var(--green-btn-Color);
  --btnBg: var(--green-btn-Bg);
  --btnBorderColor: var(--green-btn-BorderColor);
  --btnShadowColor: var(--green-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-green:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--green-btn-Bg--hover);
  }
}
.ui-btn.ui-btn--type-green:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-green:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--green-btn-Bg--active);
}
.ui-btn.ui-btn--type-green:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--green-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-ghost {
  --btnColor: var(--ghost-btn-Color);
  --btnBg: var(--ghost-btn-Bg);
  --btnBorderColor: var(--ghost-btn-BorderColor);
  --btnShadowColor: var(--ghost-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-ghost:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--ghost-btn-Bg--hover);
  }
}
.ui-btn.ui-btn--type-ghost:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-ghost:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--ghost-btn-Bg--active);
}
.ui-btn.ui-btn--type-ghost:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--ghost-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-ghost-secondary {
  --btnColor: var(--ghostSecondary-btn-Color);
  --btnBg: var(--ghostSecondary-btn-Bg);
  --btnBorderColor: var(--ghostSecondary-btn-BorderColor);
  --btnShadowColor: var(--ghostSecondary-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-ghost-secondary:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--ghostSecondary-btn-Bg--hover);
    --btnColor: var(--ghostSecondary-btn-Color--hover);
  }
}
.ui-btn.ui-btn--type-ghost-secondary:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-ghost-secondary:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--ghostSecondary-btn-Bg--active);
  --btnColor: var(--ghostSecondary-btn-Color--active);
}
.ui-btn.ui-btn--type-ghost-secondary:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--ghostSecondary-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-success {
  --btnColor: var(--success-btn-Color);
  --btnBg: var(--success-btn-Bg);
  --btnBorderColor: var(--success-btn-BorderColor);
  --btnShadowColor: var(--success-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-success:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--success-btn-Bg--hover);
  }
}
.ui-btn.ui-btn--type-success:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-success:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--success-btn-Bg--active);
}
.ui-btn.ui-btn--type-success:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--success-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-info {
  --btnColor: var(--info-btn-Color);
  --btnBg: var(--info-btn-Bg);
  --btnBorderColor: var(--info-btn-BorderColor);
  --btnShadowColor: var(--info-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-info:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--info-btn-Bg--hover);
  }
}
.ui-btn.ui-btn--type-info:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-info:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--info-btn-Bg--active);
}
.ui-btn.ui-btn--type-info:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--info-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-warning {
  --btnColor: var(--warning-btn-Color);
  --btnBg: var(--warning-btn-Bg);
  --btnBorderColor: var(--warning-btn-BorderColor);
  --btnShadowColor: var(--warning-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-warning:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--warning-btn-Bg--hover);
  }
}
.ui-btn.ui-btn--type-warning:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-warning:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--warning-btn-Bg--active);
}
.ui-btn.ui-btn--type-warning:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--warning-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-help {
  --btnColor: var(--help-btn-Color);
  --btnBg: var(--help-btn-Bg);
  --btnBorderColor: var(--help-btn-BorderColor);
  --btnShadowColor: var(--help-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-help:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--help-btn-Bg--hover);
  }
}
.ui-btn.ui-btn--type-help:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-help:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--help-btn-Bg--active);
}
.ui-btn.ui-btn--type-help:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--help-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-danger {
  --btnColor: var(--danger-btn-Color);
  --btnBg: var(--danger-btn-Bg);
  --btnBorderColor: var(--danger-btn-BorderColor);
  --btnShadowColor: var(--danger-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-danger:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--danger-btn-Bg--hover);
  }
}
.ui-btn.ui-btn--type-danger:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-danger:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--danger-btn-Bg--active);
}
.ui-btn.ui-btn--type-danger:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--danger-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--type-contrast {
  --btnColor: var(--contrast-btn-Color);
  --btnBg: var(--contrast-btn-Bg);
  --btnBorderColor: var(--contrast-btn-BorderColor);
  --btnShadowColor: var(--contrast-btn-ShadowColor);
}
@media (hover: hover) {
  .ui-btn.ui-btn--type-contrast:not(.ui-btn--disabled):not(.ui-btn--loading):hover {
    --btnBg: var(--contrast-btn-Bg--hover);
  }
}
.ui-btn.ui-btn--type-contrast:not(.ui-btn--disabled):not(.ui-btn--loading):active,
.ui-btn.ui-btn--type-contrast:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted {
  --btnBg: var(--contrast-btn-Bg--active);
}
.ui-btn.ui-btn--type-contrast:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible {
  --btnShadowColor: var(--contrast-btn-ShadowColor--focus);
}
.ui-btn.ui-btn--full-width {
  width: 100%;
}
.ui-btn.ui-btn--round {
  --btnBorderRadius: 50%;
}
.ui-btn.ui-btn--inline {
  display: inline-flex;
}
.ui-btn.ui-btn--wide {
  --btnPaddingLeft: 0.75em;
  --btnPaddingRight: 0.75em;
}
.ui-btn.ui-btn--uppercase .ui-btn__text {
  text-transform: uppercase;
}
.ui-btn__loading-icon {
  flex: none;
  font-size: var(--btnLoadingIconSize);
  margin-right: var(--btnLoadingIconMargin);
  animation: ui-btn-loader-spin 1.5s linear infinite;
}
.ui-btn__prepend-icon.ui-icon {
  --iconSize: var(--btnPrependSize);
  flex: none;
  margin-right: var(--btnPrependMargin);
  color: var(--btnIconColor);
}
.ui-btn__append-icon.ui-icon {
  --iconSize: var(--btnAppendSize);
  flex: none;
  margin-left: var(--btnAppendMargin);
  color: var(--btnIconColor);
}
.ui-btn__append-img {
  margin-left: var(--btnAppendMargin);
}
.ui-btn__single-icon.ui-icon {
  --iconSize: var(--btnSingleIconSize);
  display: block;
  flex: none;
  font-size: var(--btnSingleIconSize);
  color: var(--btnIconColor);
}
.ui-btn__text {
  font-size: var(--btnFS);
  line-height: var(--btnLH);
  font-weight: var(--btnFW);
  letter-spacing: var(--btnLetterSpacing);
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@-moz-keyframes ui-btn-loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes ui-btn-loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes ui-btn-loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ui-btn-loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ui-flex {
  display: flex;
}
.ui-flex--none {
  flex: none;
}
.ui-flex--wrap {
  flex-wrap: wrap;
}
.ui-flex--direction-column {
  flex-direction: column;
}
.ui-flex--items-start {
  align-items: flex-start;
}
.ui-flex--items-end {
  align-items: flex-end;
}
.ui-flex--items-center {
  align-items: center;
}
.ui-flex--items-stretch {
  align-items: stretch;
}
.ui-flex--justify-start {
  justify-content: flex-start;
}
.ui-flex--justify-end {
  justify-content: flex-end;
}
.ui-flex--justify-center {
  justify-content: center;
}
.ui-flex--justify-space-around {
  justify-content: space-around;
}
.ui-flex--justify-space-between {
  justify-content: space-between;
}
.ui-helper {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
}
.ui-helper--direction-row {
  flex-direction: row;
  text-align: left;
}
.ui-helper--direction-row-reverse {
  flex-direction: row-reverse;
  text-align: right;
}
.ui-helper__label {
  font-size: var(--font-size-text-s);
  line-height: 1.4;
  font-weight: 400;
  color: #465164;
}
.ui-helper__popover-wrap {
  display: inline-flex;
  align-items: center;
}
.ui-helper__popover-wrap .el-popover__reference-wrapper {
  display: inline-flex;
  align-items: center;
}
.ui-helper__popover-trigger,
.ui-helper__icon {
  font-size: 18px;
  color: var(--colorTextTertiary);
}
.ui-helper__popover-trigger {
  cursor: pointer;
  outline: none !important;
  transition: color 0.2s;
}
.ui-helper__popover-trigger--highlighted {
  color: var(--colorTextSecondary);
}
@media (hover: hover) {
  .ui-helper__popover-trigger:not(.ui-helper__popover-trigger--highlighted):hover {
    color: var(--colorTextSecondary);
  }
}
.ui-helper__popover--type-tooltip {
  max-width: 250px;
}
.ui-helper__popover--type-video {
  --popoverPaddings: 0;
  --popoverBorder: none;
  max-width: 360px;
  overflow: hidden;
}
.ui-helper__popover-vieo-wrap {
  width: 100%;
}
.ui-helper__popover-video {
  width: 100%;
  min-height: 200px;
}
.ui-helper__popover-video-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 16px;
}
.ui-helper__popover-video-label {
  font-size: var(--font-size-text-s);
  line-height: 1.4;
  font-weight: 400;
}
.ui-helper__popover-video-btns {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}
.ui-helper__popover-video-btns >* {
  flex: 1;
}
.ui-helper__popover-close {
  position: absolute;
  top: 6px;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: var(--height-control-xs);
  height: var(--height-control-xs);
  border-radius: 4px;
  background-color: rgba(168,183,207,0.45);
  color: var(--colorText);
  backdrop-filter: blur(3px);
  transition: all 0.2s;
}
@media (hover: hover) {
  .ui-helper__popover-close:hover {
    background-color: rgba(168,183,207,0.65);
  }
}
.ui-helper__popover-close:active {
  background-color: rgba(168,183,207,0.85);
}
body {
  font-family: 'AvenirNext', sans-serif;
  color: #323232;
  font-size: 16px;
  background-color: #fcfcff;
}
body.is-fixed {
  overflow: hidden;
}
[v-cloak] {
  display: none;
}
.main {
  min-height: 100vh;
  position: relative;
}
.main.empty .page-wrapper {
  padding: 0;
}
.main:has(.header):has(.header-msg-box) {
  padding-top: 62px;
}
.layout {
  display: block;
}
.container {
  position: relative;
  width: 100%;
  max-width: 1280px;
  padding: 0 var(--containerSidePadding);
  margin: 0 auto;
}
.page-wrapper {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 70px 0 100px;
  overflow: hidden;
}
@media screen and (max-width: 500px) {
  .page-wrapper {
    padding-top: 110px;
  }
}
.aside-save.ui-btn {
  position: fixed;
  bottom: 0;
  left: -360px;
  z-index: 1;
  width: 360px;
  border-radius: 0;
}
.aside-message {
  margin-bottom: 30px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #ef4444;
  padding: 7px;
  background-color: rgba(199,0,57,0.1);
  border-radius: 4px;
  text-align: center;
}
.aside-field-label {
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #465164;
}
.aside-input.with-colopicker .v-input.v-text-field {
  padding-right: 25px;
}
.aside-input .v-input.v-text-field {
  display: flex;
  align-items: center;
  min-height: 40px;
  border: 1px solid #e4e9f1;
  border-radius: 4px;
}
.aside-input .v-input.v-text-field .v-text-field__slot input {
  padding: 0 13px;
  border: none;
}
.textarea {
  display: block;
  width: 100%;
  height: 160px;
  padding: 16px;
  font-family: monospace;
  font-size: 12px;
  line-height: normal;
  color: #323232;
  background-color: transparent;
  border: 1px solid #e4e9f1;
  border-radius: 4px;
  resize: none;
  outline: none;
}
video {
  max-width: 100%;
}
.phone-input.vue-tel-input {
  width: 100%;
  height: 40px;
  color: #323232;
  border-radius: 4px;
  border: 1px solid #e4e9f1;
}
.phone-input.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: #e4e9f1;
}
.phone-input.vue-tel-input .vti__dropdown.open,
.phone-input.vue-tel-input .vti__dropdown:hover {
  background-color: #e4e9f1;
}
.phone-input.vue-tel-input .vti__dropdown:focus {
  outline: none;
}
.phone-input.vue-tel-input .vti__dropdown-list {
  width: 410px;
  border-radius: 4px;
  border: 1px solid #e4e9f1;
  border-top-color: #1da2ff;
  box-shadow: 0 6px 16px 0 rgba(0,0,0,0.08);
}
.phone-input.vue-tel-input .vti__dropdown-list.below {
  top: 43px;
}
.phone-input.vue-tel-input .vti__dropdown-item {
  padding: 6px 15px;
  font-size: 14px;
  line-height: 20px;
}
.phone-input.vue-tel-input .vti__dropdown-item strong {
  font-weight: 600;
}
.phone-input.vue-tel-input .vti__dropdown-item.highlighted {
  background-color: #e4e9f1;
}
.phone-input.vue-tel-input .vti__dropdown-item .vti__flag {
  margin-right: 10px;
  font-size: 0;
  line-height: 0;
  transform: translateY(1px);
}
.phone-input.vue-tel-input .vti__selection {
  font-size: 10px;
}
.phone-input.vue-tel-input .vti__flag {
  margin-left: 0;
}
.phone-input.vue-tel-input .vti__input {
  font-size: 14px;
  border-radius: 0 4px 4px 0;
}
.phone-input.vue-tel-input .vti__input::placeholder {
  color: #acb9cd;
}
.input {
  width: 100%;
  height: 40px;
  padding: 0 13px;
  font-size: 14px;
  color: #323232;
  border: 1px solid #e4e9f1;
  border-radius: 4px;
}
.input::placeholder {
  color: #e4e9f1;
}
.route__manage-sheet-page .page-wrapper,
.route__manage-sheet-page-static .page-wrapper {
  padding: 0;
}
.is-link {
  color: var(--colorPrimary);
}
.is-link:hover {
  text-decoration: underline;
}
.archived {
  opacity: 0.5;
}
.archived--disabled {
  pointer-events: none;
}
.text-color-primary {
  color: var(--colorPrimary);
}
.text-color-secondary {
  color: var(--colorTextSecondary);
}
.text-color-danger {
  color: var(--colorDanger);
}
.text-color-success {
  color: var(--colorSuccess);
}
.text-weight-400 {
  font-weight: 400;
}
.text-weight-500 {
  font-weight: 500;
}
.text-weight-600 {
  font-weight: 600;
}
.text-weight-700 {
  font-weight: 700;
}
table.table {
  border-collapse: collapse;
  border-spacing: 0;
}
table.table.table--layout-fixed {
  table-layout: fixed;
}
table.table thead td {
  color: var(--colorText);
  font-weight: 500;
}
table.table tbody td {
  font-weight: 500;
  white-space: normal;
  word-break: break-word;
}
table.table td {
  padding: 8px 8px;
  border: 1px solid var(--colorBorder);
}
.try-ai-btn {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  height: 32px;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  background: linear-gradient(135deg, #1da2ff, #90cfff);
  background-size: 200% 200%;
  background-position: 100% 0;
  transition: all 0.2s;
}
@media (hover: hover) {
  .try-ai-btn:hover {
    background-position: 0 100%;
  }
}
.access-manager__spread-form-btns-wrapper {
  position: absolute;
  bottom: 2px;
  right: 2px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fcfcff;
}
.access-manager__spread-form__add-btn {
  font-size: 12px;
  font-weight: 500;
  color: #1da2ff;
  text-transform: uppercase;
  transition: all 0.3s ease;
  padding: 8px;
}
.access-manager__spread-form__add-btn:hover:enabled {
  color: #1da2ff;
}
.access-manager__refresh-btn {
  color: #a8b7cf;
  padding: 8px;
  font-size: 12px;
}
.access-manager__refresh-btn:hover:enabled {
  color: #1da2ff;
}
.access-manager__mb-8 {
  margin-bottom: 8px;
}
.access-manager__mb-10 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #465164;
}
.access-manager__group-builder {
  width: 100%;
  height: fit-content;
  padding: 24px 64px;
}
.access-manager__wrapper {
  padding: 12px 0 12px 0;
}
.access-manager__wrapper--center {
  padding: 24px 0 0;
  text-align: center;
}
.access-manager__link {
  cursor: copy;
}
.ai-creator-flow {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex: 1;
  min-height: 0;
  width: 100%;
  padding: 24px;
}
.ai-creator-flow:before {
  content: '';
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom, #1e9dff 5%, transparent 50%);
  opacity: 0.8;
}
.ai-creator-flow__test-btns {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 3;
}
.ai-creator-flow__test-btns button {
  padding: 8px;
  cursor: pointer;
}
@media (hover: hover) {
  .ai-creator-flow__test-btns button:hover {
    background-color: rgba(255,255,255,0.6);
  }
}
.ai-creator-flow__msgs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.ai-creator-flow__msg {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: fit-content;
  padding: 12px 24px 16px 16px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(10,35,94,0.08);
}
.ai-creator-flow__msg-icon {
  font-size: 16px;
  line-height: 1;
  margin-top: 4px;
}
.ai-creator-flow__msg-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ai-creator-flow__msg-title {
  font-size: var(--font-size-text-m);
  line-height: 1.5;
  font-weight: 500;
}
.ai-creator-flow__msg-string {
  font-size: var(--font-size-text-m);
  line-height: 1.5;
  font-weight: 600;
}
.ai-creator-flow__msg-img {
  width: 240px;
  aspect-ratio: 16/9;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@supports not (aspect-ratio: 1) {
  .ai-creator-flow__msg-img {
    height: 150px;
  }
}
.ai-creator-flow__msg-color {
  display: flex;
  align-items: center;
  gap: 6px;
}
.ai-creator-flow__msg-color-preview {
  width: 20px;
  height: 20px;
  border-radius: 2px;
}
.ai-creator-flow__msg-color-title {
  font-size: var(--font-size-text-m);
  line-height: 1.5;
  font-weight: 600;
}
.ai-creator-msgs-move,
.ai-creator-msgs-enter-active,
.ai-creator-msgs-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.ai-creator-msgs-enter,
.ai-creator-msgs-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}
.ai-creator-msgs-leave-active {
  position: absolute;
}
.ai-creator-form {
  width: 100%;
}
.ai-creator-form__hint {
  margin-bottom: 24px;
  font-size: var(--font-size-text-l);
  line-height: 1.5;
  font-weight: 500;
}
.ai-creator-form__hint b {
  font-weight: bold;
}
.ai-creator-form__prompt {
  font-size: var(--font-size-text-m);
  line-height: 1.5;
  font-weight: 400;
}
.ai-creator-form__prompt b {
  font-weight: bold;
}
.ai-creator-form__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}
.ai-creator-form__textarea {
  width: 100%;
  height: 300px;
  padding: 12px 16px;
  font-size: var(--font-size-text-m);
  line-height: 1.5;
  font-weight: 400;
  border: 1px solid var(--colorControlBorder);
  border-radius: 4px;
  resize: none;
  outline: none;
  transition: all 0.2s;
}
@media (hover: hover) {
  .ai-creator-form__textarea:not(:disabled):not(:focus):hover {
    border-color: var(--colorControlBorder--hover);
  }
}
.ai-creator-form__textarea:not(:disabled):focus {
  border-color: var(--colorControlBorder--focus);
}
.ai-creator-form__textarea:disabled {
  cursor: not-allowed;
  color: var(--colorTextSecondary);
  background-color: #eff3fb;
}
.ai-creator-form__textarea::placeholder {
  color: var(--colorTextSecondary);
}
.ai-creator-form__tag {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  width: fit-content;
  height: var(--height-control-s);
  padding: 0 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #465164;
  white-space: nowrap;
  background-color: var(--colorGrayLight-15);
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.2s;
}
@media (hover: hover) {
  .ai-creator-form__tag:not(.is-disabled):not(.is-active):hover {
    background-color: var(--colorGrayLight-35);
  }
}
.ai-creator-form__tag:not(.is-disabled):not(.is-active):active {
  background-color: var(--colorGrayLight-55);
}
.ai-creator-form__tag.is-active {
  background-color: #798496;
  color: #fff;
}
.ai-creator-form__tag.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.ai-creator-form__btns {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 40px;
}
.ai-creator-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100%;
  padding: 24px;
}
.ai-creator-result__radio-wrap {
  flex: none;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding: 4px;
  background-color: #e4e9f1;
  border-radius: 5px;
}
.ai-creator-result__radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 32px;
  border-radius: 5px;
  transition: all 0.2s;
}
.ai-creator-result__radio:not(.is-active):hover {
  background-color: rgba(255,255,255,0.4);
}
.ai-creator-result__radio.is-active {
  background-color: #fff;
}
.ai-creator-result__radio-label {
  font-size: var(--font-size-text-m);
  line-height: 1.5;
  font-weight: 600;
}
.ai-creator-result__radio-icon {
  font-size: 20px;
  color: #798496;
  transition: all 0.2s;
}
.ai-creator-result__website {
  width: 100%;
  flex: 1;
}
.ai-creator-result__sheet-wrap {
  --pricingTableNameColWidth: 320px;
  --pricingTableBorderColor: #d2dae7;
  --pricingTableBorder: 1px solid #d2dae7;
  --pricingTableCellPadding: 8px 10px;
  --pricingTableDecorSize: 24px;
  --pricingTableScrollSize: 4px;
  position: relative;
  max-width: 100%;
  flex: 1;
}
.ai-creator-result__sheet-wrap:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: var(--pricingTableScrollSize);
  height: var(--pricingTableScrollSize);
  background-color: var(--pricingTableBorderColor);
}
.ai-creator-result__sheet-scroll-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: scroll;
  will-change: scroll-position;
  box-shadow: 0px 6px 16px 0 rgba(29,36,56,0.08), 0px 6px 16px 0px rgba(0,0,0,0.05);
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
}
.ai-creator-result__sheet-scroll-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: var(--pricingTableScrollSize);
  height: var(--pricingTableScrollSize);
}
.ai-creator-result__sheet-scroll-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #717e93;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.5);
}
.ai-creator-result__sheet-scroll-container::-webkit-scrollbar-track {
  background-color: var(--pricingTableBorderColor);
  -webkit-box-shadow: 5px 5px 5px -5px rgba(34,60,80,0.1) inset;
}
.ai-creator-result__sheet-scroll-container::-webkit-scrollbar-button {
  display: none;
}
.ai-creator-result__sheet {
  table-layout: fixed;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  border-collapse: separate;
  border-spacing: 0;
}
.ai-creator-result__sheet th,
.ai-creator-result__sheet td {
  width: 100%;
  border-right: var(--pricingTableBorder);
  border-bottom: var(--pricingTableBorder);
  white-space: nowrap;
}
.ai-creator-result__sheet th:last-of-type,
.ai-creator-result__sheet td:last-of-type {
  border-right: none;
}
.ai-creator-result__sheet th:first-of-type,
.ai-creator-result__sheet td:first-of-type {
  width: var(--pricingTableDecorSize);
  min-width: var(--pricingTableDecorSize);
  max-width: var(--pricingTableDecorSize);
  padding: 0;
  font-size: 10px;
  line-height: 1.4;
  font-weight: 400;
  text-align: center;
  background-color: #e9f6ff;
  border-left: var(--pricingTableBorder);
}
.ai-creator-result__sheet th {
  position: sticky;
  top: 0;
  z-index: 3;
  padding: 0;
  vertical-align: top;
  background-color: #fff;
  border-top: var(--pricingTableBorder);
}
.ai-creator-result__sheet th:nth-of-type(1) {
  position: sticky;
  left: 0;
  z-index: 4;
}
.ai-creator-result__sheet th:nth-of-type(1) {
  left: 0;
}
.ai-creator-result__sheet th >div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}
.ai-creator-result__sheet th >div >div {
  width: 100%;
}
.ai-creator-result__sheet th:first-of-type {
  text-align: center;
}
.ai-creator-result__sheet td {
  padding: var(--pricingTableCellPadding);
  background-color: #fff;
}
.ai-creator-result__sheet td:nth-of-type(1) {
  position: sticky;
  left: 0;
  z-index: 2;
}
.ai-creator-result__sheet td:nth-of-type(1) {
  left: 0;
}
.ai-creator-result__sheet-head-decor {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--pricingTableDecorSize);
  font-size: 10px;
  line-height: 1.4;
  font-weight: 400;
  text-align: center;
  background-color: #e9f6ff;
  border-bottom: var(--pricingTableBorder);
}
.ai-creator-result__sheet-head-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: var(--pricingTableCellPadding);
  text-align: start;
}
.ai-creator-result__sheet-head-cell.is-decor {
  padding: 9px 8px;
}
.ai-creator-result__sheet-head-cell p {
  font-weight: bold;
  color: var(--colorPrimary);
}
.ai-widget {
  width: 100%;
}
.ai-widget__form {
  width: 100%;
}
.ai-widget__form-input {
  width: 100%;
  height: 200px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--colorText);
  background-color: #fff;
  border: 1px solid var(--colorControlBorder);
  border-radius: 4px;
  outline: none;
  resize: none;
  transition: all 0.2s;
}
.ai-widget__form-input::placeholder {
  color: var(--colorControlPlaceholderColor);
}
@media (hover: hover) {
  .ai-widget__form-input:not(:disabled):hover {
    border-color: var(--colorControlBorder--hover);
  }
}
.ai-widget__form-input:not(:disabled):focus {
  border-color: var(--colorControlBorder--focus);
}
.ai-widget__form-input:disabled {
  cursor: not-allowed;
  opacity: 0.65;
  border-color: var(--colorControlBorder--disabled);
}
.ai-widget__form-btn {
  margin-top: 8px;
}
.ai-widget__flow {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
}
.ai-widget__flow-item {
  width: 100%;
  padding: 12px 16px 16px;
  background-color: #fff;
  border: 1px solid var(--colorControlBorder);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #f4f5fd;
}
.ai-widget__flow-item-meta {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 12px;
}
.ai-widget__flow-item-title {
  font-family: 'PT Sans', sans-serif;
  font-size: var(--font-size-heading-s);
  line-height: 1.4;
  font-weight: 600;
}
.ai-widget__flow-item-subtitle {
  font-size: var(--font-size-text-m);
  line-height: 1.5;
  font-weight: 500;
  color: var(--colorTextSecondary);
}
.ai-widget__flow-loader {
  font-family: Consolas, Menlo, Monaco, monospace;
  font-size: 50px;
  color: var(--colorTextTertiary);
  font-weight: bold;
  opacity: 0.8;
}
.ai-widget__flow-loader:before {
  content: "{";
  display: inline-block;
  animation: flow-loader-pulse 0.4s alternate infinite ease-in-out;
}
.ai-widget__flow-loader:after {
  content: "}";
  display: inline-block;
  animation: flow-loader-pulse 0.4s 0.3s alternate infinite ease-in-out;
}
@-moz-keyframes flow-loader-pulse {
  to {
    transform: scale(0.8);
    opacity: 0.5;
  }
}
@-webkit-keyframes flow-loader-pulse {
  to {
    transform: scale(0.8);
    opacity: 0.5;
  }
}
@-o-keyframes flow-loader-pulse {
  to {
    transform: scale(0.8);
    opacity: 0.5;
  }
}
@keyframes flow-loader-pulse {
  to {
    transform: scale(0.8);
    opacity: 0.5;
  }
}
.appsumo-page__wrapp {
  display: flex;
  align-items: center;
}
.appsumo-page__logo {
  max-width: 400px;
  margin-bottom: 85px;
}
.appsumo-page__container {
  margin: auto;
  max-width: 760px;
  padding: 0 20px;
  text-align: center;
}
.appsumo-page__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 16px;
}
.appsumo-page__text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #313131;
}
.appsumo-page__text a {
  color: #1da2ff;
}
.appsumo-page__text .highlighted {
  color: #1da2ff;
}
.appsumo-page__success-button {
  margin-top: 40px;
  height: 40px;
  padding: 0 24px;
  background: #1da2ff;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #fff;
}
.appsumo-deal-over {
  width: 404px;
  margin: 30px auto;
  padding: 24px 24px 30px;
  background-color: #eff3fb;
}
.appsumo-deal-over p {
  font-weight: 600;
  line-height: 24px;
}
.appsumo-deal-over-details {
  text-align: justify;
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
  font-weight: normal;
}
.appsumo-deal-over-details a {
  color: #1da2ff;
}
.appsumo-codes-form {
  width: 404px;
  margin: 30px auto;
  padding: 24px 24px 30px;
  background-color: #eff3fb;
}
.appsumo-codes-form form {
  display: flex;
  width: 100%;
  justify-content: stretch;
}
.appsumo-codes-form__input-wrapper {
  display: flex;
  margin: 16px;
}
.appsumo-codes-form__input {
  width: 100%;
  height: 40px;
  padding: 0 14px;
  background: #fff;
  border: 1px solid #e4e9f1;
  box-sizing: border-box;
  border-radius: 4px;
}
.appsumo-codes-form__input::placeholder {
  color: #a8b7cf;
}
.appsumo-codes-form__valid-codes-list {
  margin: 20px 0 0;
}
.appsumo-codes-form__valid-codes-list p {
  margin-bottom: 12px;
}
.appsumo-codes-form__submit {
  height: 40px;
  padding: 0 24px;
  background: #1da2ff;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #fff;
}
.appsumo-codes-form__submit:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.appsumo-codes-form__error-message {
  color: #ef4444;
  font-size: 12px;
  margin-top: 20px;
}
.appsumo-codes-form__success-message {
  color: #208b54;
  font-size: 12px;
  margin-top: 20px;
}
.aside-control {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 75px;
  padding: 0 5px;
  color: #a8b7cf;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  background-color: transparent;
  transition: all 0.3s ease;
}
.aside-control:hover {
  color: #1da2ff;
}
.aside-control.active {
  color: #1da2ff;
  border-bottom: 1px solid #e4e9f1;
  border-top: 1px solid #e4e9f1;
}
.aside-control.active .aside-control__border {
  background-color: #fcfcff;
}
.aside-control__title {
  margin-top: 2px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}
.aside-control__icon {
  font-size: 25px;
}
.aside-control__border {
  position: absolute;
  top: 0;
  right: -1px;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #e4e9f1;
  transition: all 0.3s ease;
}
.aside-select {
  position: relative;
}
.aside-select.paddingless {
  padding-bottom: 0;
}
.aside-select__field-icon {
  display: none;
  position: absolute;
  bottom: 12px;
  right: 0;
  font-size: 10px;
  color: #a8b7cf;
}
.aside-select .field-icon {
  margin-right: 10px;
  width: 16px;
}
.aside-select .field-name {
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
  color: #798496;
}
.aside-select .field-name .icon {
  margin-right: 10px;
  color: #a8b7cf;
}
.aside-select .list-title {
  margin-bottom: 20px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: colorBlacks;
}
.aside-select .v-select {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e4e9f1;
}
.aside-select .v-select .v-input__control {
  padding: 5px 13px;
  min-height: 30px;
}
.aside-select .v-select .v-select__selections input {
  padding: 0;
  border: none;
}
.aside-select .v-select .v-select__selections input::placeholder {
  color: #a8b7cf;
}
.aside-select .v-select__slot {
  align-items: center;
}
.aside-select .v-select__slot .v-input__append-inner {
  align-self: center;
}
.aside {
  position: relative;
  display: flex;
  flex: none;
  height: calc(100vh - 69px);
  transition: 0.3s ease;
}
.aside.fixed {
  position: fixed;
  top: 69px;
  left: 0;
  z-index: 9;
}
.aside.opened {
  transform: translateX(0);
}
.aside__close {
  cursor: pointer;
  position: absolute;
  top: 29px;
  right: 30px;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  color: #a8b7cf;
  transition: 0.3s ease-out;
}
.aside__close:hover {
  color: #ef4444;
}
.aside__body {
  width: 0;
  height: 100%;
  background-color: #fcfcff;
  border-right: 1px solid #e4e9f1;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #d9dfe9 #fff;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease;
}
.aside__body::-webkit-scrollbar {
  width: 8px;
}
.aside__body::-webkit-scrollbar-track {
  background-color: #fff;
}
.aside__body::-webkit-scrollbar-thumb {
  background-color: #d9dfe9;
  border-radius: 20px;
  border: 2px solid #fff;
}
.aside__body.opened {
  width: 360px;
}
.aside__body.opened .aside__body-inner {
  opacity: 1;
  pointer-events: all;
  transition-delay: 0.2s;
}
.aside__body.opened .aside-save {
  left: 70px;
}
.aside__body-inner {
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease;
}
.aside__controls-wrap {
  position: relative;
  z-index: 2;
  flex: none;
  width: 70px;
  background-color: #fcfcff;
  border-right: 1px solid #e4e9f1;
  transition: all 0.3s ease;
}
.aside__validation-message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px 20px 5px 40px;
  font-size: 10px;
  font-weight: 700;
  color: #ef4444;
  background-color: rgba(245,107,107,0.2);
  border-bottom: 1px solid rgba(245,107,107,0.4);
}
.available-websites {
  width: 100%;
  color: #323232;
  font-family: 'Avenir Next Cyr';
}
.available-websites__section {
  margin-top: 16px;
  padding: 16px 0;
}
.available-websites__section-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
.available-websites__section-info {
  padding: 16px 0;
}
.available-websites__section-info--regular {
  font-weight: 600;
}
.available-websites__section-info--archived {
  color: #717e93;
  font-weight: 400;
}
.available-websites__section-progress-bar {
  height: 8px;
  background-color: #e4e9f1;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 16px;
}
.available-websites__section-progress-bar-fill {
  height: inherit;
  background-color: #1da2ff;
}
.available-websites__section-progress-bar-fulfilled {
  background-color: #ff4d4d;
}
.available-websites__section-warning {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.available-websites__section-warning-icon {
  margin-right: 10px;
}
.available-websites__section-warning-message {
  font-size: 12px;
  color: #465164;
}
.avatar {
  --avatarSize: 36px;
  --avatarFS: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: var(--avatarSize);
  height: var(--avatarSize);
  font-size: var(--avatarFS);
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  border-radius: 50%;
  background-color: #ae4eaa;
}
.billing-grid {
  width: 100%;
}
.billing-grid__head,
.billing-grid__row {
  display: grid;
  grid-template-columns: 170px 115px 1fr 168px;
}
.billing-grid__row.is-divided {
  border-top: 1px solid var(--colorBorder);
}
.billing-grid__head {
  width: 100%;
}
.billing-grid__body {
  width: 100%;
  text-align: left;
  background-color: #f8f9ff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #f4f5fd;
}
.billing-grid__body +.billing-grid__body {
  margin-top: 12px;
}
.billing-grid__cell {
  display: flex;
  align-items: center;
  min-height: 43px;
  padding: 6px 10px;
  font-size: 14px;
  text-align: left;
}
.billing-grid__cell.is-heading {
  padding: 10px 12px;
  font-weight: 500;
  color: var(--colorTextSecondary);
}
.billing-grid__cell.is-bold {
  font-weight: 600;
}
.billing-grid__cell.align-center {
  justify-content: center;
  text-align: center;
}
.billing-grid__cell.align-end {
  justify-content: flex-end;
  text-align: right;
}
.billing-grid__cell.gap-x {
  gap: 5px;
}
.billing-grid__cell .size-s {
  font-size: 12px;
}
.billing-grid__row-menu {
  --dropdownItemPadding: 0;
  --dropdownItemFS: 14px;
  --dropdownItemLH: 20px;
}
.billing-grid__row-menu .el-dropdown-menu__item >* {
  color: inherit;
  padding: 8px 16px;
}
.billing-grid__row-menu-trigger {
  width: 26px;
  height: 26px;
  font-size: 16px;
  color: var(--colorTextSecondary);
  border-radius: 4px;
  border: 1px solid var(--colorBorder);
  transition: all 0.2s;
}
.billing-grid__row-menu-trigger.is-highlighted,
.billing-grid__row-menu-trigger:hover {
  background-color: var(--colorBgGray);
}
.billing-grid__row-menu-trigger:active {
  background-color: var(--colorGray-20);
}
.billing {
  position: relative;
}
.billing-modal {
  padding-top: 20px;
}
.billing__title {
  padding: 0 50px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  text-align: center;
}
.billing__desc {
  padding: 0 50px;
  margin-top: 15px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}
.billing__desc .is-highlighted {
  color: #1da2ff;
  font-weight: 500;
}
.billing__body {
  padding: 0 30px 15px;
}
.billing__body .subscribe__button.btn {
  margin: 25px auto 20px;
  display: block;
}
.billing__switcher-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.billing__switcher-title {
  cursor: pointer;
  position: relative;
  display: flex;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: #b3b3b5;
  transition: all 0.3s ease;
}
.billing__switcher-title.is-active {
  color: #313131;
}
.billing__switcher-title-label {
  margin-left: 5px;
  padding: 3px 4px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: #fff;
  background-color: #ef4444;
  border-radius: 4px;
  transform: translateY(-5px);
}
.billing__switcher {
  flex: none;
  margin: 0 20px;
}
.billing__price-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  text-align: center;
}
.billing__price {
  display: flex;
}
.billing__price-currency {
  align-self: flex-start;
  padding-right: 3px;
  font-family: 'PT Sans', sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
}
.billing__price-value {
  font-family: 'PT Sans', sans-serif;
  font-size: 54px;
  line-height: 46px;
  font-weight: bold;
}
.billing__price-period {
  align-self: flex-end;
  padding-left: 3px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
}
.billing__price-label {
  margin-top: 3px;
  font-size: 16px;
  line-height: 24px;
}
.billing__price-total {
  font-size: 16px;
  color: #0f9d58;
}
.billing__footnote {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #616a78;
}
.billing__footnote a {
  text-decoration: underline;
}
.billing__footnote a:hover {
  text-decoration: none;
}
.billing__footnote-icon {
  margin-right: 8px;
}
.billing__list-wrap {
  padding: 30px 60px 40px;
  background-color: #eff3fb;
  text-align: left;
}
.billing__list-title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}
.billing__list {
  display: flex;
  flex-wrap: wrap;
}
.billing__list-item {
  display: flex;
  align-items: flex-start;
  width: 50%;
  padding-right: 10px;
}
.billing__list-item:nth-of-type(n+3) {
  margin-top: 13px;
}
.billing__list-item-title {
  margin-left: 10px;
  font-size: 16px;
  line-height: 26px;
}
.billing__grid-wrap {
  margin-top: 24px;
  margin-bottom: 32px;
}
.billing__btns {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.checkbox__indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding-top: 3px;
  border: 1px solid #1da2ff;
  border-radius: 4px;
  transition: all 0.2s ease;
}
.checkbox__indicator:after {
  content: '\e93f';
  font-family: 'icomoon';
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  opacity: 0;
  transition: all 0.2s ease;
}
.checkbox__title {
  margin-left: 10px;
  padding-top: 1px;
  font-size: 14px;
  line-height: normal;
  color: #323232;
  transition: all 0.2s ease;
}
.checkbox input[type="checkbox"] {
  display: none;
}
.checkbox input:checked ~ .checkbox__title {
  color: #1da2ff;
}
.checkbox input:checked ~ .checkbox__indicator {
  background-color: #1da2ff;
}
.checkbox input:checked ~ .checkbox__indicator:after {
  opacity: 1;
}
.code-editor-form {
  width: 100%;
  padding: 24px 24px 32px;
}
.code-editor-form__item {
  width: 100%;
}
.code-editor-form__btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}
.code-textarea {
  width: 100%;
  font-size: 14px;
  background-color: var(--bgColor);
  border-radius: 4px;
  --bgColor: #f9f9f9;
  --activeLineBg: var(--colorPrimary-07);
}
.code-textarea .cm-editor {
  height: 255px;
  border: 1px solid var(--colorControlBorder);
  border-radius: 4px;
  overflow: hidden;
  transition: border-color 0.2s;
}
.code-textarea .cm-editor:hover {
  border-color: var(--colorControlBorder--hover);
}
.code-textarea .cm-editor.cm-focused {
  outline: none;
  border-color: var(--colorControlBorder--focus);
}
.code-textarea .cm-content .cm-activeLine {
  background-color: var(--activeLineBg);
}
.code-textarea .cm-gutters {
  background-color: var(--bgColor);
  color: var(--colorTextSecondary);
  border-right-color: var(--colorControlBorder);
}
.code-textarea .cm-gutters .cm-activeLineGutter {
  background-color: var(--activeLineBg);
}
.code-textarea .cm-gutters .cm-lineNumbers .cm-gutterElement {
  min-width: 25px;
}
.code-textarea .cm-gutters .cm-gutterElement span[title="Fold line"] {
  display: block;
  line-height: 12px;
}
.collapsable-block__head {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.collapsable-block__title {
  margin-right: 4px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #323232;
  text-transform: uppercase;
}
.collapsable-block__lock-icon {
  display: inline-block;
  color: var(--colorTextTertiary);
  transform: translateY(2px);
}
.collapsable-block__head-icon {
  color: var(--colorTextTertiary);
}
.collapsable-block__head-icon.is-flipped {
  transform: rotate(180deg);
}
.collapsable-block__body {
  display: none;
  padding-top: 20px;
}
.collapsable-block__body.is-active {
  display: block;
}
.color-picker {
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 18px;
  height: 18px;
  border: 1px solid #a8b7cf;
  border-radius: 2px;
}
.color-picker.for-mailchimp .vc-chrome {
  top: auto;
  bottom: 100%;
  right: 100%;
}
.confirmation-popup .modal__body {
  padding: 24px 30px 32px;
}
.confirmation-popup__content {
  font-size: 14px;
  line-height: 1.5;
}
.confirmation-popup__content .text-color-secondary {
  color: var(--colorTextSecondary);
}
.confirmation-popup__content >*+* {
  margin-top: 12px;
}
.confirmation-popup__content p {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}
.confirmation-popup__content a {
  color: var(--colorPrimary);
}
.confirmation-popup__content a:hover {
  text-decoration: underline;
}
.confirmation-popup__content strong,
.confirmation-popup__content b {
  font-weight: 600;
}
.confirmation-popup__content ul {
  padding: 0 0 0 15px;
  list-style-type: disc;
}
.confirmation-popup__content ul li+li {
  margin-top: 5px;
}
.confirmation-popup__content ol {
  padding: 0 0 0 15px;
}
.confirmation-popup__content ol li+li {
  margin-top: 5px;
}
.confirmation-popup__btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
}
.confirmation-popup__btns >*+* {
  margin-left: 12px;
}
.content-form__tiles-constructor-wrap {
  margin-bottom: 48px;
}
.content-form__switchers {
  margin-bottom: 70px;
}
.content-form__switch-field {
  margin-bottom: 20px;
}
.content-form__switcher-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-form__switch-hidden-field {
  margin-top: 20px;
}
.content-form__switcher-label {
  padding-right: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #465164;
}
.content-form__content-tabs__header {
  background: #e4e9f1;
  border-radius: 4px;
  display: flex;
  padding: 2px;
  margin-bottom: 30px;
}
.content-form__content-tabs__header-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  width: 50%;
  padding: 4px;
  cursor: pointer;
}
.content-form__content-tabs__header-title.active {
  background: #fff;
  border-radius: 3px;
}
.content-form__content-tabs__header-title-invalid-icon {
  margin-left: 4px;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 17px;
  color: #f00;
  border: 1px solid #f00;
  border-radius: 30px;
}
.content-form__highlighted {
  background: #fff;
  padding: 10px;
  margin-left: -10px;
  width: calc(100% + 20px);
  border-radius: 5px;
  border: 1px solid #e4e9f1;
}
.cover-layout {
  cursor: pointer;
  position: relative;
  margin-right: 11px;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.cover-layout:last-of-type {
  margin-right: 0;
}
.cover-layout:before {
  content: '\e939';
  position: absolute;
  top: -3px;
  left: -3px;
  font-family: 'icomoon';
  font-size: 47px;
  line-height: 1;
  color: #798496;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.cover-layout.active {
  opacity: 1;
}
.cover-layout.active:before {
  opacity: 1;
}
.cover-layout:hover {
  opacity: 1;
}
.cover-layout.is-fixedheight .cover-layout__icon {
  font-size: 39px;
}
.cover-layout.is-fixedheight:before {
  top: -4px;
  left: -4px;
}
.cover-layout__icon {
  font-size: 41px;
  color: #a8b7cf;
}
.cta-constructor {
  position: relative;
}
.cta-constructor__trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  border-radius: 3px;
  transition: all 0.3s ease;
}
.cta-constructor__trigger.is-secondary {
  border: 1px solid;
  background-color: transparent;
}
.cta-constructor__menu {
  position: absolute;
  top: calc(100% + 12px);
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 20px 20px 30px 20px;
  border-radius: 2px;
  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.15);
  border: 1px solid #e4e9f1;
  background-color: #fff;
}
.cta-constructor__menu:after {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  width: 10px;
  height: 10px;
  border-right: 1px solid #e4e9f1;
  border-top: 1px solid #e4e9f1;
  background-color: #fff;
}
.cta-constructor__menu-title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #323232;
}
.cta-constructor__input {
  width: 100%;
  height: 40px;
  border: 1px solid #e4e9f1;
  border-radius: 4px;
  padding: 0 13px;
  color: #798496;
}
.cta-constructor__input::placeholder {
  opacity: 0.4;
}
.cta-constructor__input-wrap {
  position: relative;
}
.cta-constructor__color-picker {
  position: absolute;
  top: 11px;
  right: 10px;
}
.cta-constructor__color-picker .color-picker {
  position: static;
}
.cta-constructor__radio-wrap {
  margin-top: 25px;
}
.cta-constructor__radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 5px;
  margin-bottom: 8px;
}
.cta-constructor__radio:hover .cta-constructor__radio-indicator {
  border-color: #1da2ff;
}
.cta-constructor__radio.active .cta-constructor__radio-indicator {
  background-color: rgba(29,162,255,0.3);
  border-color: transparent;
}
.cta-constructor__radio.active .cta-constructor__radio-indicator:after {
  opacity: 1;
}
.cta-constructor__radio.active:hover .cta-constructor__radio-indicator {
  border-color: transparent;
}
.cta-constructor__radio-indicator {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 11px;
  border-radius: 50%;
  border: 1px solid rgba(168,183,207,0.51);
  transition: all 0.3s ease;
}
.cta-constructor__radio-indicator:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: #1da2ff;
  border-radius: 50%;
  transition: all 0.3s ease;
  opacity: 0;
}
.cta-constructor__radio-icon {
  margin-right: 10px;
  font-size: 16px;
  color: #798496;
}
.cta-constructor__radio-label {
  font-size: 16px;
  line-height: 18px;
  color: #323232;
}
.cta-constructor__select-wrap {
  margin-top: 25px;
}
.cta-constructor__apply-btn {
  display: block;
  width: 100%;
  height: 40px;
  margin: 25px auto 0;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #1da2ff;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.cta-constructor__apply-btn:hover {
  background-color: #1891e5;
}
.cta-constructor__email-field {
  margin-top: 25px;
}
.cta-constructor__email-field__link {
  color: #1da2ff;
  cursor: pointer;
}
.cta-constructor__label-error {
  margin-top: 25px;
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  color: #ef4444;
}
.custom-option {
  display: flex;
  flex-direction: column;
}
.custom-option__control {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.delete-modal {
  text-align: center;
}
.delete-modal__body {
  padding: 24px 20px 48px;
}
.delete-modal__paragraph {
  max-width: 400px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
  text-align: center;
}
.delete-modal__confirm-form {
  width: 100%;
  max-width: 400px;
  margin: 20px auto 0;
  text-align: left;
}
.delete-modal__confirm-form .delete-modal__field {
  margin-top: 30px;
}
.delete-modal__confirm-form-input {
  width: 100%;
  height: 42px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 42px;
  font-weight: 500;
  color: #323232;
  border: 1px solid #e4e9f1;
  background-color: #fff;
  border-radius: 4px;
  transition: all 0.2s;
}
.delete-modal__confirm-form-input:not(:focus):hover {
  border-color: #ced4df;
}
.delete-modal__confirm-form-input:focus {
  border-color: #1da2ff;
}
.delete-modal__confirm-form-input::placeholder {
  color: #a8b7cf;
}
.delete-modal__field {
  text-align: center;
  width: 100%;
  max-width: 450px;
  margin: 10px auto 0;
  font-size: 12px;
}
.delete-modal__button {
  height: 40px;
  margin: 0 6px;
  padding: 10px 23px 12px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #ef4444;
  background-size: 200%;
  background-position: top left;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.delete-modal__button.disabled {
  opacity: 0.5;
}
.delete-modal__button:hover {
  background-position: center;
  background-color: #e14949;
}
.delete-modal__button.negative {
  color: #798496;
  border: 1px solid #cad3e0;
  background-color: transparent;
}
.delete-modal__button.negative:hover {
  color: #fff;
  background-color: #cad3e0;
}
.domain-field {
  display: flex;
  gap: 2px;
  width: 100%;
  height: var(--height-control-m);
  padding: 0 12px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid var(--colorPrimary);
  transition: all 0.2s;
}
.domain-field.is-disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
.domain-field.is-disabled input {
  cursor: not-allowed;
}
@media (hover: hover) {
  .domain-field:not(.is-disabled):hover {
    border-color: var(--colorPrimary);
  }
}
.domain-field__prepend,
.domain-field__append {
  cursor: not-allowed;
  align-self: center;
  flex: none;
  padding: 3px 5px;
  color: var(--colorPrimary);
  background-color: rgba(177,220,255,0.2);
  border-radius: 4px;
}
.domain-field__input {
  width: 100%;
}
.domain-field__input::placeholder {
  color: var(--colorControlPlaceholderColor);
  transition: opacity 0.2s;
}
.domain-field__input:focus::placeholder {
  opacity: 0;
}
.draggable {
  display: flex;
  flex-direction: column;
}
.draggable__item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}
.draggable__item:not(.hover-disabled):hover:before {
  opacity: 1;
}
.draggable__item:before {
  pointer-events: none;
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  z-index: 0;
  width: calc(100% + 60px);
  height: 100%;
  background-color: #e9f6ff;
  opacity: 0;
  transition: all 0.2s ease;
}
.draggable__item.full-width-bg:before {
  width: 100%;
  left: 0;
}
.draggable__item.hover-disabled {
  padding: 0;
}
.draggable__item.hover-disabled +.draggable__item {
  margin-top: 15px;
}
.draggable__item.hover-disabled .draggable__item-trigger:hover {
  color: #1da2ff;
}
.draggable__item-trigger {
  position: relative;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 20px;
  height: 20px;
  font-size: 16px;
  color: #c1c8d3;
}
.draggable__item-input-wrap {
  width: 100%;
  padding-left: 10px;
}
.draggable__item-title-wrap {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding-left: 10px;
  overflow: hidden;
}
.draggable__item-title-wrap.is-inactive {
  cursor: default;
  opacity: 0.6;
}
.draggable__item-title-wrap.no-padding {
  padding-left: 0;
}
.draggable__item-title-wrap.is-column {
  flex-direction: column;
  align-items: flex-start;
}
.draggable__item-title-wrap.cursor-default {
  cursor: default;
}
.draggable__item-title-img {
  flex: none;
  height: 24px;
  margin-right: 10px;
}
.draggable__item-title-icon {
  flex: none;
  width: 16px;
  margin-right: 10px;
  font-size: 16px;
  line-height: 16px;
  color: #717e93;
}
.draggable__item-title {
  max-width: 100%;
  font-size: 16px;
  line-height: 22px;
  color: #323232;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.draggable__item-subtitle {
  max-width: 100%;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #a8b7cf;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.draggable__item-controls {
  position: relative;
  flex: none;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 10px;
}
.draggable__item-control {
  cursor: pointer;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 18px;
  color: #a8b7cf;
  transition: all 0.2s ease;
}
.draggable__item-control:hover {
  color: #1da2ff;
}
.draggable__item-control+.draggable__item-control {
  margin-left: 5px;
}
.draggable__item-control.dropdown-opened {
  color: #1da2ff;
}
.draggable__item-control i:focus {
  outline: none;
}
.draggable__dropdown-menu {
  min-width: 180px;
}
.draggable__dropdown-menu .el-dropdown-menu__item {
  padding: 7px 15px;
  font-size: 14px;
  line-height: 18px;
  color: #323232;
}
.draggable__dropdown-menu .el-dropdown-menu__item:hover {
  color: #323232;
}
.draggable__dropdown-menu .popper__arrow {
  display: none;
}
.draggable__dropdown-menu.el-popper[x-placement^=bottom] {
  margin-top: 0;
}
.draggable__dropdown-menu.el-popper[x-placement^=top] {
  margin-bottom: 0;
}
.fields-builder {
  width: 100%;
  padding: 30px 40px 40px;
}
.fields-builder__item {
  position: relative;
}
.fields-builder__item +.fields-builder__item {
  margin-top: 16px;
}
.fields-builder__item.is-flex-row {
  display: flex;
  align-items: flex-end;
}
.fields-builder__item.is-multiline {
  flex-wrap: wrap;
  margin: -8px -4px;
}
.fields-builder__item.is-multiline .fields-builder__item-control {
  margin: 8px 4px;
}
.fields-builder__item-control {
  width: 100%;
}
.fields-builder__item-control +.fields-builder__item-control {
  margin-left: 8px;
}
.fields-builder__item-control.is-half {
  width: calc(50% - 8px);
}
.fields-builder__item-control.is-third {
  width: calc(33.333% - 8px);
}
.fields-builder__item-control.is-three-quarters {
  width: calc(75% - 8px);
}
.fields-builder__item-control.is-quater {
  width: calc(25% - 8px);
}
.fields-builder__item-delete-btn {
  margin-left: 8px;
  font-size: 12px;
  color: #a8b7cf;
}
.fields-builder__item-delete-btn:hover {
  color: #ef4444;
}
.fields-builder__item-add-btn {
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #1da2ff;
}
.fields-builder__item-add-btn.for-draggable-items {
  margin-left: 30px;
}
.fields-builder__item-add-btn:hover {
  color: #1891e5;
}
.fields-builder__item-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #313131;
}
.fields-builder__item-title.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fields-builder__item-title-btn {
  cursor: pointer;
  margin-left: 20px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #a8b7cf;
}
.fields-builder__item-title-btn:hover {
  color: #1da2ff;
}
.fields-builder__item-title-btn.is-danger {
  color: #ef4444;
}
.fields-builder__item-title-btn.is-danger:hover {
  color: #dc2626;
}
.fields-builder__item-msg {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  color: #ef4444;
}
.fields-builder__item-msg.for-draggable {
  padding-left: 30px;
}
.fields-builder__item-msg.is-flex-row {
  display: flex;
}
.fields-builder__item-msg p+p:before {
  content: ', ';
  display: inline;
}
.fields-builder__draggable .draggable +.fields-builder__item-add-btn {
  margin-top: 20px;
}
.fields-builder__control-toolbar {
  flex: none;
  display: flex;
  padding-left: 8px;
}
.fields-builder__control-toolbar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 16px;
  color: #a8b7cf;
  border: 1px solid #e4e9f1;
  border-radius: 4px;
}
.fields-builder__control-toolbar-item +.fields-builder__control-toolbar-item {
  margin-left: 8px;
}
.fields-builder__control-toolbar-item>[class*="icon-"] {
  cursor: pointer;
}
.fields-builder__control-toolbar-item .icon-circle-close:hover {
  color: #ef4444;
}
.fields-builder__validations {
  margin-top: 40px;
}
.fields-builder__validations-title {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: #323232;
}
.fields-builder__footer {
  margin-top: 34px;
}
.fields-builder__footer-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fields-builder__footer-btn +.fields-builder__footer-btn {
  margin-left: 20px;
}
.fields-builder__footer-btn.is-loading {
  opacity: 0.5;
  pointer-events: none;
}
.fields-builder__footer-msg {
  display: flex;
  align-items: flex-start;
  width: fit-content;
  max-width: 300px;
  margin: 15px auto 0;
  padding: 12px 16px;
  margin-top: 15px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #313131;
  background-color: rgba(255,77,77,0.2);
  border: 1px solid #ef4444;
  border-radius: 4px;
}
.fields-builder__footer-msg-icon {
  margin-top: 2px;
  color: #ef4444;
  margin-right: 8px;
}
.filters {
  width: 100%;
}
.filters__search {
  --inputPrefixIconWidth: 16px;
  --inputPrefixIconSize: 16px;
}
.filters__section +.filters__section {
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid var(--colorBorder);
}
.filters__section-title {
  margin-bottom: 20px;
  font-size: var(--font-size-text-m);
  line-height: 1.5;
  font-weight: 500;
}
.filters__section-item .el-checkbox__label {
  display: flex;
  align-items: center;
  gap: 6px;
}
.filters__section-item.is-list-view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.filters__footer {
  margin-top: 32px;
}
.form-field {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}
.form-field:last-of-type {
  padding-bottom: 0;
}
.form-field.padingless {
  padding-bottom: 0;
}
.form-field__label {
  width: 46%;
  padding-right: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #323232;
}
.form-field__control {
  position: relative;
  display: flex;
  width: 54%;
}
.form-field__control.align-end {
  justify-content: flex-end;
}
.form-field__control.full-width {
  width: 100%;
}
.form-field__control.picker-inside .v-text-field__slot input {
  padding-right: 30px;
}
.form-field__validated-control {
  width: 100%;
}
.form-field__error {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  color: #ef4444;
  animation: collapsable-field-error-appearence 0.5s ease;
}
.form {
  width: 100%;
}
.form__item {
  width: 100%;
}
.form__item +.form__item {
  margin-top: 18px;
}
.fs-editor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}
.fs-editor__head {
  display: flex;
  flex: none;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e4e9f1;
}
.fs-editor__head-title {
  font-size: 14px;
  font-weight: 600;
}
.fs-editor__head-left-side {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 40px;
  border-right: 1px solid #e4e9f1;
}
.fs-editor__head-right-side {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 780px;
  padding-left: 40px;
}
.fs-editor__head-close-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 50px;
  height: 100%;
  border-left: 1px solid #e4e9f1;
}
.fs-editor__head-close-btn {
  width: 32px;
  height: 32px;
  font-size: 14px;
  color: #59677f;
  border-radius: 50%;
  transition: all 0.2s ease;
}
.fs-editor__head-close-btn:hover {
  background-color: #eef1f5;
}
.fs-editor__body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.fs-editor__body .v-md-editor.sv-md-editor.v-md-editor--editable .v-md-editor__editor-wrapper {
  border-right-color: #e4e9f1;
}
.fs-editor__body .v-md-editor.sv-md-editor .v-md-editor__editor-wrapper {
  flex: auto;
  width: 50%;
  padding-left: 25px;
}
.fs-editor__body .v-md-editor.sv-md-editor .v-md-editor__preview-wrapper {
  flex: auto;
  width: 50%;
  min-width: 780px;
}
.fs-editor__body .v-md-editor.sv-md-editor .v-md-editor__main {
  margin-top: 0;
  border: none;
}
.fs-editor__body .v-md-editor.sv-md-editor .v-md-editor__toolbar {
  position: relative;
}
.fs-editor__body .v-md-editor.sv-md-editor .v-md-editor__toolbar:after {
  content: '';
  display: block;
  width: 100%;
  min-width: 780px;
}
.fs-editor__body .v-md-editor.sv-md-editor .v-md-editor__toolbar-left {
  width: 100%;
  max-width: 50%;
  padding: 25px 15px 15px 40px;
  border-right: 1px solid #e4e9f1;
}
.fs-editor__body .v-md-editor.sv-md-editor .v-md-editor__toolbar-right {
  display: none;
  width: 100%;
  min-width: 780px;
  margin: 0;
}
.fs-editor__body .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body {
  max-width: 780px;
  margin: 0 auto;
  padding: 0 40px 50px;
  font-family: inherit;
  color: #323232;
  word-break: normal;
}
.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 284px;
  height: 50px;
  padding: 0 20px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.88;
  font-weight: 600;
  color: #fff;
  background-color: #1da2ff;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid #1da2ff;
  cursor: pointer;
  transition: all 0.3s ease;
}
.google-button.loading {
  pointer-events: none;
  opacity: 0.5;
}
.google-button:hover {
  background-color: #1891e5;
}
.google-button img {
  margin-right: 15px;
}
.google-button span {
  line-height: 1;
}
.google-button__logo {
  position: relative;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #fff;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  width: 100%;
  background-color: #fcfcff;
  box-shadow: none;
  transition: all 0.3s ease;
}
.header.for-manage {
  position: fixed;
}
.header.scrolled {
  box-shadow: 0px 5px 5px 0px rgba(50,50,50,0.05);
}
.header.has-border-bottom {
  border-bottom: 1px solid #e9ecef;
}
.header__container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--headerHeight);
  margin: 0 auto;
  padding: 0 var(--containerSidePadding);
  text-align: left;
}
@media screen and (max-width: 500px) {
  .header__container {
    flex-direction: column;
    align-items: flex-start;
    height: 110px;
    padding: 20px;
  }
}
.header__logo {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.header__logo img {
  max-width: 160px;
}
.header__logo img.is-ai-creator {
  max-width: 212px;
}
.header__right-side {
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.header__search-wrap {
  display: flex;
  align-items: center;
  margin-right: 19px;
}
.header__search-input {
  width: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}
.header__search-input::placeholder {
  font-size: 16px;
  color: #a8b7cf;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.header__search-input.expanded {
  width: 220px;
  margin-left: 10px;
  padding-left: 0;
}
.header__search-input.expanded::placeholder {
  opacity: 1;
  transition-delay: 0.3s;
}
.header__search-btn-icon {
  cursor: pointer;
  width: 20px;
  font-size: 16px;
  line-height: 20px;
  color: #323232;
}
.header__menu-btn {
  cursor: pointer;
  position: relative;
  z-index: 11;
  width: 16px;
  height: 10px;
}
.header__menu-btn:before,
.header__menu-btn:after {
  content: '';
  position: absolute;
  left: 0;
  width: 16px;
  height: 2px;
  border-radius: 1px;
  background-color: #323232;
  transition: all 0.3s ease;
}
.header__menu-btn:before {
  top: 0;
}
.header__menu-btn:after {
  bottom: 0;
}
.header__menu-btn.menu-opened {
  z-index: 11;
}
.header__menu-btn.menu-opened:before {
  top: 3px;
  transform: rotate(-45deg);
}
.header__menu-btn.menu-opened:after {
  bottom: 5px;
  transform: rotate(45deg);
}
.header-trialing-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffefc7;
  color: #323232;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 30px;
  padding: 0 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header-trialing-bar__close-btn {
  color: #cad3e0;
  transition: all 0.3s ease;
}
.header-trialing-bar__close-btn:hover {
  color: #000;
}
.header-trialing-bar.is-maintenance {
  white-space: inherit;
}
.header-trialing-bar.is-discount {
  background-color: #fff;
  white-space: inherit;
}
.header-trialing-bar.is-discount .timer-wrapper {
  color: #f00;
}
.header-trialing-bar.is-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.header-trialing-bar.is-small {
  display: block;
  margin-right: 20px;
  border-radius: 5px;
  padding: 0 15px;
}
.header-trialing-bar .highlighted {
  color: #1da2ff;
  cursor: pointer;
}
.header-resubscribe-bar {
  display: flex;
  align-items: center;
  background-color: var(--colorPrimary);
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 17px;
  padding: 4px 56px 4px var(--containerSidePadding);
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  position: relative;
}
.header-resubscribe-bar span {
  margin-right: 30px;
}
.header-resubscribe-bar__btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  border-radius: 4px;
  transition: all 0.2s;
  color: var(--colorPrimary);
  background-color: #fff;
  border: 1px solid var(--colorPrimary);
  white-space: nowrap;
}
.header-resubscribe-bar__btn:hover {
  background-color: var(--colorBtnSecondaryBg--hover);
  border: 1px solid var(--colorPrimary);
}
.header-resubscribe-bar__close-btn {
  position: absolute;
  top: calc(50% - 12px);
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 10px;
  border-radius: 50%;
  background-color: var(--colorDanger);
  color: #fff;
  box-shadow: 0px 16px 31px rgba(10,35,94,0.24), 0px 3px 5px rgba(10,35,94,0.1);
}
.header-affiliate-link a {
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 20px;
}
.iframe-code {
  width: 100%;
  padding: 0 30px 30px;
}
.iframe-code__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}
.iframe-code__label {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #465164;
}
.iframe-code__footer {
  display: flex;
  justify-content: flex-end;
}
.iframe-code__copy-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: #a8b7cf;
  transition: all 0.3s ease;
  margin-top: 10px;
}
.iframe-code__copy-button:hover {
  color: #1da2ff;
}
.iframe-code__copy-button-icon {
  margin-left: 8px;
  font-size: 14px;
  line-height: 1;
}
.iframe-code__textarea {
  display: block;
  width: 100%;
  height: 90px;
  padding: 8px;
  font-size: 14px;
  line-height: 20px;
  font-family: monospace;
  line-height: normal;
  color: #3b3b3b;
  background-color: #f6f8fa;
  border: 1px solid #e4e9f1;
  border-radius: 4px;
  resize: none;
  outline: none;
}
.iframe-code__settings {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;
  padding: 0 40px 20px;
}
.iframe-code__settings-img {
  position: absolute;
  top: calc(100% - 15px);
  left: 70px;
  width: calc(100% - 140px);
}
.iframe-code__setting-field {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  color: #798496;
}
.iframe-code__setting-field-icon {
  font-size: 12px;
  color: #a8b7cf;
  line-height: normal;
}
.iframe-code__setting-field-icon.icon-direct-arrow-horizontal {
  margin-left: -10px;
  font-size: 7px;
}
.iframe-code__setting-field-input {
  width: 42px;
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  color: currentColor;
  text-align: center;
}
.integrations-modal .modal {
  width: 100%;
  max-width: 700px;
}
.integrations-modal__inner {
  padding: 0 40px 50px;
}
.integrations-modal__head {
  display: flex;
  align-items: center;
}
.integrations-modal__head-icon {
  max-width: 80px;
  max-height: 50px;
  margin-right: 16px;
}
.integrations-modal__head-title {
  margin-right: 24px;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  color: #323232;
}
.integrations-modal__head-status {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 14px;
  color: #a8b7cf;
  font-weight: 600;
  border-radius: 4px;
  background-color: #f2f4f8;
}
.integrations-modal__head-status.is-active {
  background-color: #1da2ff;
  color: #fff;
}
.integrations-modal__body {
  margin-top: 25px;
}
.integrations-modal__img {
  width: 100%;
  max-height: 500px;
  margin-bottom: 25px;
}
.integrations-modal__content {
  position: relative;
  display: flex;
}
.integrations-modal__content-left-side {
  width: calc(100% - 240px);
}
.integrations-modal__content-right-side {
  flex: none;
  width: 210px;
  padding-left: 30px;
}
.integrations-modal__form {
  margin-top: 25px;
}
.integrations-modal__form-item {
  width: 100%;
}
.integrations-modal__form-item+.integrations-modal__form-item {
  margin-top: 20px;
}
.integrations-modal__form-btn {
  margin-top: 20px;
}
.integrations-modal__cta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 18px 16px;
  background-color: #eff4fa;
  border-radius: 4px;
}
.integrations-modal__cta-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
}
.integrations-modal__cta-item+.integrations-modal__cta-item {
  margin-top: 5px;
}
.integrations-modal__cta-item.is-highlighted {
  color: #1da2ff;
  font-weight: 500;
}
.integrations-modal__cta-item:hover {
  color: #1891e5;
}
.integrations-modal__cta-item-icon {
  margin-right: 8px;
}
.integrations-modal__footnote {
  margin-top: 25px;
}
.integrations-modal__footnote p {
  font-size: 14px;
  line-height: 21px;
  color: #323232;
}
.integrations-modal__footnote p+p {
  margin-top: 25px;
}
.integrations-modal__footnote p a {
  position: relative;
  display: inline-block;
  color: #1da2ff;
  font-weight: 500;
}
.integrations-modal__footnote p a:after {
  content: '→';
  display: inline-block;
  margin-left: 3px;
  vertical-align: text-top;
}
.integrations-modal__about {
  color: #323232;
}
.integrations-modal__about h1 {
  margin: 20px 0 20px;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
}
.integrations-modal__about h2 {
  margin: 14px 0;
  font-size: 26px;
  line-height: 34px;
  font-weight: 600;
}
.integrations-modal__about h3,
.integrations-modal__about h4,
.integrations-modal__about h5,
.integrations-modal__about h6 {
  margin: 10px 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
.integrations-modal__about p {
  margin: 10px 0 18px;
  font-size: 14px;
  line-height: 22px;
}
.integrations-modal__about p:only-of-type,
.integrations-modal__about p:last-child {
  margin: 0;
}
.integrations-modal__about a {
  color: #1da2ff;
}
.integrations-modal__about a:hover {
  text-decoration: underline;
}
.integrations-modal__about details {
  margin: 10px 0;
}
.integrations-modal__about details[open] > summary:before {
  transform: none;
}
.integrations-modal__about details > summary {
  position: relative;
  list-style: none;
  padding-left: 15px;
}
.integrations-modal__about details > summary:before {
  content: '\e909';
  position: absolute;
  top: -1px;
  left: 0;
  font-size: 9px;
  font-family: 'icomoon';
  transform: rotate(-90deg);
  transition: transform 0.2s ease;
}
.integrations-modal__about details > summary::-webkit-details-marker {
  display: none;
}
.integrations-modal__about details summary {
  width: fit-content;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #1da2ff;
  border-bottom: 1px dashed currentColor;
  transition: color 0.2s ease;
}
.integrations-modal__about details summary:hover {
  color: #0470bb;
}
.integrations-modal__about details summary:focus {
  outline: none;
}
.integrations-modal__about blockquote {
  position: relative;
  padding: 0 0 0 25px;
  margin: 10px 0 20px;
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  color: inherit;
  border-left: none;
}
.integrations-modal__about blockquote:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #323232;
}
.integrations-modal__about hr {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  margin: 30px auto;
  background-color: #e4e9f1;
  border: none;
  overflow: visible;
}
.integrations-modal__about img {
  box-sizing: border-box;
  display: block;
  margin: 0 0 20px;
  max-width: 100%;
  border-style: none;
  background-color: transparent;
}
.integrations-modal__about figure {
  margin: 10px 0 20px;
}
.integrations-modal__about figure img {
  margin: 0;
}
.integrations-modal__about figcaption {
  margin-top: 8px;
  font-size: 12px;
  line-height: 14px;
  color: #798496;
  text-align: left;
}
.integrations-modal__about input {
  -webkit-appearance: auto;
}
.integrations-modal__about ul {
  list-style: disc;
}
.integrations-modal__about ul,
.integrations-modal__about ol {
  padding-left: 20px;
  margin: 10px 0 18px;
}
.integrations-modal__about ul li,
.integrations-modal__about ol li {
  font-size: 14px;
  line-height: 22px;
}
.integrations-modal__about ul li:nth-of-type(n+2),
.integrations-modal__about ol li:nth-of-type(n+2) {
  margin-top: 5px;
}
.integrations-modal__about ul li p,
.integrations-modal__about ol li p {
  margin: 0;
}
.integrations-modal__about ul li ul,
.integrations-modal__about ol li ul {
  margin: 0;
}
.integrations-modal__about .table-wrap {
  width: 100%;
  max-width: 100%;
  margin: 10px 0 20px;
  border-right: 1px solid #e4e9f1;
  border-left: 1px solid #e4e9f1;
  overflow-x: scroll;
}
.integrations-modal__about .table-wrap table tr td:last-of-type,
.integrations-modal__about .table-wrap table tr th:last-of-type {
  border-right: none;
}
.integrations-modal__about table {
  width: 100%;
  margin-left: -1px;
  border-spacing: 0;
}
.integrations-modal__about table code {
  padding: 2px 5px 3px;
  color: #333;
  background-color: #efefef;
  border-radius: 4px;
}
.integrations-modal__about table caption {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 14px;
  color: #798496;
  text-align: left;
}
.integrations-modal__about table th,
.integrations-modal__about table td {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 8px;
}
.integrations-modal__about table th {
  font-size: 16px;
  font-weight: 500;
  color: #798496;
  text-align: left;
  border: 1px solid #e4e9f1;
}
.integrations-modal__about table th:nth-of-type(n+2) {
  border-left: none;
}
.integrations-modal__about table td {
  font-size: 14px;
  font-weight: 400;
  color: #323232;
  text-align: left;
  border: 1px solid #e4e9f1;
  border-top: none;
}
.integrations-modal__about table td:nth-of-type(n+2) {
  border-left: none;
}
.integrations-modal__about iframe {
  border: 0;
  margin-bottom: 18px;
}
.integrations {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 25px 30px 15px;
}
.integrations__head {
  display: flex;
  margin-bottom: 25px;
}
.integrations__head-search-wrap {
  position: relative;
  width: 100%;
  max-width: 475px;
  margin-left: auto;
}
.integrations__head-search {
  width: 100%;
  height: 40px;
  padding: 0 60px 0 15px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #323232;
  background-color: #fff;
  border: 1px solid #f2f2f7;
  border-radius: 4px;
  transition: box-shadow 0.2s ease;
}
.integrations__head-search::placeholder {
  color: #798496;
}
.integrations__head-search:hover,
.integrations__head-search:focus {
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.05);
}
.integrations__head-search-btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  color: #a8b7cf;
}
.integrations__head-search-btn:before {
  transform: scale(-1, 1);
}
.integrations__head-search-btn:hover {
  color: #1da2ff;
}
.integrations__head-search-btn:hover+.integrations__head-search {
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.05);
}
.integrations__list {
  display: flex;
  flex-wrap: wrap;
}
.integrations__list.is-centered {
  justify-content: center;
}
.integrations__not-found {
  margin: 250px 0 0;
}
.integrations__not-found .is-centered {
  text-align: center;
  margin: auto;
}
.integrations__not-found .btn {
  margin: 15px auto 0;
}
.integrations__item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 10px 20px rgba(43,50,68,0.08);
  overflow: hidden;
  transition: transform 0.2s ease;
}
.integrations__item:not(.is-disabled):hover {
  transform: translateY(-10px);
}
.integrations__item.is-disabled {
  cursor: default;
  opacity: 0.8;
  box-shadow: 0px 2px 4px rgba(43,50,68,0.06);
}
@media screen and (min-width: 1280px) {
  .integrations__item:not(.three-in-row):not(.is-single) {
    width: calc(25% - 12px);
    margin-right: 16px;
  }
  .integrations__item:not(.three-in-row):not(.is-single):nth-of-type(4n) {
    margin-right: 0;
  }
  .integrations__item:not(.three-in-row):not(.is-single):nth-of-type(n+5) {
    margin-top: 16px;
  }
}
@media (min-width: 767px) and (max-width: 1280px) {
  .integrations__item:not(.three-in-row):not(.is-single) {
    width: calc(33.333% - 10.666666666666666px);
    margin-right: 16px;
  }
  .integrations__item:not(.three-in-row):not(.is-single):nth-of-type(3n) {
    margin-right: 0;
  }
  .integrations__item:not(.three-in-row):not(.is-single):nth-of-type(n+4) {
    margin-top: 15px;
  }
}
.integrations__item.three-in-row {
  width: calc(33.333% - 10.666666666666666px);
  margin-right: 16px;
}
.integrations__item.three-in-row:nth-of-type(3n) {
  margin-right: 0;
}
.integrations__item.three-in-row:nth-of-type(n+4) {
  margin-top: 16px;
}
.integrations__item.is-single {
  width: 270px;
}
.integrations__item-img-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  height: 160px;
  position: relative;
}
.integrations__item-img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 130px;
  padding: 20px;
  border-radius: 50%;
  background-color: #fff;
}
.integrations__item-img {
  max-height: 100%;
}
.integrations__item-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  padding: 12px 20px 20px;
}
.integrations__item-content {
  width: 100%;
}
.integrations__item-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #323232;
}
.integrations__item-paragraph {
  margin-top: 6px;
  font-size: 14px;
  line-height: 22px;
  color: #798496;
}
.integrations__item-status {
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 10px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 14px;
  color: #a8b7cf;
  font-weight: 600;
  border-radius: 4px;
  background-color: #f2f4f8;
}
.integrations__item-status.is-active {
  background-color: #1da2ff;
  color: #fff;
}
.integrations__item-pro-status {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  background-color: #03da8d;
  color: #fff;
  height: 24px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  border-radius: 4px;
}
.login-page {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.login-page__container {
  max-width: 662px;
  width: 100%;
}
.login-page__title {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.41;
  letter-spacing: -0.8px;
  margin-bottom: 24px;
}
.login-page__description {
  max-width: 520px;
  font-size: 16px;
  color: #a8b7cf;
  margin: 0 auto 64px;
}
.login-page__button-wrap {
  min-height: 50px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  min-height: 50px;
  flex-direction: column;
  align-items: center;
}
.login-page__checkbox {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.31;
  margin-top: 54px;
}
.login-page__checkbox-wrap {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 16px;
}
.login-page__checkbox-input {
  width: 20px;
  height: 20px;
  border: 1px solid #1da2ff;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.login-page__checkbox-input:hover {
  cursor: pointer;
}
.login-page__checkbox-input:checked {
  background-color: #1da2ff;
}
.login-page__checkbox-input:checked + .login-page__checkbox-icon {
  opacity: 1;
}
.login-page__checkbox-icon {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  font-size: 10px;
  color: #fff;
  cursor: pointer;
}
.login-page__terms {
  font-size: 14px;
  letter-spacing: -0.3px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.login-page__terms-link {
  color: #1da2ff;
}
.login-page__error-message {
  margin-top: 10px;
  font-size: 10px;
  line-height: 20px;
  font-weight: 700;
  color: rgba(245,107,107,0.4);
}
.manage-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}
.manage-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  width: 100%;
  background-color: #fcfcff;
  border-bottom: 1px solid #e4e9f1;
  box-shadow: none;
  transition: all 0.3s ease;
}
.manage-header.scrolled {
  box-shadow: 0px 5px 5px 0px rgba(50,50,50,0.05);
}
.manage-header__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  min-height: 70px;
  margin: 0 auto;
  padding: 0 15px;
}
.manage-header__left-side,
.manage-header__right-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.manage-header__right-side-slot {
  display: flex;
  align-items: center;
  gap: 12px;
}
.manage-header__logo {
  display: flex;
  align-items: flex-start;
}
.manage-header__controls {
  display: flex;
  align-items: center;
  gap: 15px;
}
.manage-header__meta {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.manage-header__meta-badges {
  display: flex;
  align-items: center;
  gap: 12px;
}
.manage-header__subscribe-cta {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 12px 2px;
  font-size: 12px;
  height: var(--height-control-xxs);
  font-weight: 600;
  color: var(--colorPrimary);
  background-color: var(--colorPrimary-10);
  border: 1px solid var(--colorPrimary);
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.2s;
}
@media (hover: hover) {
  .manage-header__subscribe-cta:hover {
    background-color: var(--colorPrimary-20);
  }
}
.manage-header__subscribe-cta:before {
  content: '';
  position: absolute;
  top: -40px;
  left: -40px;
  transform: rotate(-45deg);
  width: 100%;
  height: 40px;
  background-color: var(--colorPrimary);
  opacity: 0.1;
  filter: blur(7px);
  animation: subscribe-cta-shimmer 1.5s linear infinite;
}
.manage-header__auth {
  position: relative;
  margin-left: 30px;
  padding: 3px 0;
  font-size: 13px;
  font-weight: 600;
  color: #828282;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease;
}
.manage-header__auth:hover {
  border-color: rgba(0,0,0,0.2);
}
.manage-header__menu-btn {
  cursor: pointer;
  position: relative;
  z-index: 11;
  width: 16px;
  height: 10px;
  margin-left: 20px;
}
.manage-header__menu-btn:before,
.manage-header__menu-btn:after {
  content: '';
  position: absolute;
  left: 0;
  width: 16px;
  height: 2px;
  border-radius: 1px;
  background-color: #323232;
  transition: all 0.3s ease;
}
.manage-header__menu-btn:before {
  top: 0;
}
.manage-header__menu-btn:after {
  bottom: 0;
}
.manage-header__menu-btn.menu-opened {
  z-index: 11;
}
.manage-header__menu-btn.menu-opened:before {
  top: 3px;
  transform: rotate(-45deg);
}
.manage-header__menu-btn.menu-opened:after {
  bottom: 5px;
  transform: rotate(45deg);
}
@-moz-keyframes subscribe-cta-shimmer {
  0% {
    top: -40px;
    left: -40px;
  }
  50% {
    top: 30px;
    left: 50px;
  }
  100% {
    top: 30px;
    left: 50px;
  }
}
@-webkit-keyframes subscribe-cta-shimmer {
  0% {
    top: -40px;
    left: -40px;
  }
  50% {
    top: 30px;
    left: 50px;
  }
  100% {
    top: 30px;
    left: 50px;
  }
}
@-o-keyframes subscribe-cta-shimmer {
  0% {
    top: -40px;
    left: -40px;
  }
  50% {
    top: 30px;
    left: 50px;
  }
  100% {
    top: 30px;
    left: 50px;
  }
}
@keyframes subscribe-cta-shimmer {
  0% {
    top: -40px;
    left: -40px;
  }
  50% {
    top: 30px;
    left: 50px;
  }
  100% {
    top: 30px;
    left: 50px;
  }
}
.manage {
  display: flex;
  height: 100vh;
  padding-top: 70px;
  background-color: #eff4fa;
}
.manage__viewer-side {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 25px 20px 15px;
  overflow: hidden;
}
.manage__integrations-wrap {
  width: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.manage__integrations-wrap::-webkit-scrollbar {
  display: none;
}
.manage__viewer-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 6px 14px 0 rgba(0,0,0,0.2);
}
.manage__viewer-wrap.is-desktop-view {
  max-width: 1080px;
}
.manage__viewer-wrap.is-mobile-view {
  max-width: 425px;
}
.sv-md-editor.v-md-editor {
  position: relative;
  box-shadow: none;
  background-color: transparent;
}
.sv-md-editor.v-md-editor .v-md-editor {
  font-family: 'AvenirNext', sans-serif;
}
.sv-md-editor.v-md-editor .v-md-editor__toolbar {
  padding: 0;
  background-color: transparent;
  border: none;
}
.sv-md-editor.v-md-editor .v-md-editor__toolbar-left {
  margin-bottom: -4px;
}
.sv-md-editor.v-md-editor .v-md-editor__toolbar-right {
  display: none;
}
.sv-md-editor.v-md-editor .v-md-editor__toolbar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'AvenirNext', sans-serif !important;
  margin-right: 4px;
  margin-bottom: 4px;
  font-family: inherit;
  color: #59677f;
  border: 1px solid #e4e9f1;
}
.sv-md-editor.v-md-editor .v-md-editor__toolbar-item:not(.v-md-editor__toolbar-item--menu) {
  padding: 0 5px;
}
.sv-md-editor.v-md-editor .v-md-editor__toolbar-item:not(:first-child) {
  margin-left: 0;
}
.sv-md-editor.v-md-editor .v-md-editor__toolbar-item:before {
  font-family: 'v-md-iconfont' !important;
}
.sv-md-editor.v-md-editor .v-md-editor__toolbar-item.text-plus-icon {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
}
.sv-md-editor.v-md-editor .v-md-editor__toolbar-item.text-plus-icon.icon-notion:before {
  margin-right: 7px;
  font-family: 'icomoon' !important;
  font-size: 16px;
  color: #000;
}
.sv-md-editor.v-md-editor .v-md-editor__toolbar-item.icon-cart-to-list:before {
  font-family: 'icomoon' !important;
  font-size: 22px;
  margin: 0 0px 0 2px;
}
.sv-md-editor.v-md-editor .v-md-editor__toolbar-divider {
  margin-left: 6px;
}
.sv-md-editor.v-md-editor .v-md-editor__menu {
  left: auto !important;
  right: 0 !important;
}
.sv-md-editor.v-md-editor .v-md-editor__main {
  margin-top: 5px;
  border: 1px solid #e4e9f1;
  border-radius: 4px;
}
.sv-md-editor.v-md-editor .v-md-textarea-editor pre,
.sv-md-editor.v-md-editor .v-md-textarea-editor textarea {
  padding: 10px 15px 25px;
  font-family: inherit;
  font-size: 14px;
  line-height: 24px;
  color: #323232;
  background-color: transparent;
}
.sv-md-editor.v-md-editor .v-md-textarea-editor pre::placeholder,
.sv-md-editor.v-md-editor .v-md-textarea-editor textarea::placeholder {
  font-size: 16px;
  line-height: 22px;
  color: #acb9cd;
}
.menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  height: 100%;
  padding: 50px 30px;
  background-color: #fcfcff;
  box-shadow: 0 3px 10px 0 rgba(0,0,0,0.1);
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.3s ease;
}
.menu.is-opened {
  opacity: 1;
  transform: translateX(0);
}
.menu__nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.menu__nav-item {
  margin-bottom: 20px;
  font-size: 25px;
  line-height: 35px;
  font-weight: 600;
  transition: color 0.3s ease;
}
.menu__nav-item:hover {
  color: #a8b7cf;
}
.menu__nav-item:last-of-type {
  margin-bottom: 0;
}
.menu__auth {
  margin-top: 50px;
}
.menu__signin {
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  border-radius: 4px;
  background-color: #1da2ff;
  transition: background-color 0.3s ease;
}
.menu__signin:hover {
  background-color: #1891e5;
}
.menu__profile-info {
  display: flex;
  justify-content: space-between;
}
.menu__signout {
  width: 48%;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 600;
  color: #a8b7cf;
  border-radius: 4px;
  border: 1px solid #a8b7cf;
  transition: all 0.3s ease;
}
.menu__signout:hover {
  background-color: #a8b7cf;
  color: #fff;
}
.menu__account {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48%;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  background-color: #1da2ff;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.menu__account:hover {
  background-color: #1891e5;
}
.modal-cancelation-result {
  color: #323232;
  padding: 17px 30px 38px;
  text-align: left;
}
.modal-cancelation-result__paragraph {
  padding: 10px;
}
.modal-cancelation-result__paragraph--accent {
  font-weight: 700;
}
.modal-cancelation-result__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.modal-cancelation-result__button {
  margin: 0 20px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #fff;
  background-color: #1da2ff;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.modal-cancelation-result__button:disabled {
  opacity: 0.5;
}
.modal-cancelation-result__button:disabled:hover {
  background-color: #1da2ff;
}
.modal-cancelation-result__button:hover {
  background-color: #1891e5;
}
.modal-cancelation-result__button.negative {
  color: #798496;
  border: 1px solid #cad3e0;
  background-color: transparent;
}
.modal-cancelation-result__button.negative:hover {
  color: #fff;
  background-color: #cad3e0;
}
.modal-help {
  width: 100%;
  padding: 16px 50px 30px;
}
.modal-help__list {
  display: flex;
  font-size: 16px;
}
.modal-help__step {
  display: flex;
  flex-direction: column;
  width: 33.333%;
  margin-right: 48px;
}
.modal-help__step:last-of-type {
  margin-right: 0;
}
.modal-help__step-title {
  margin-bottom: 5px;
  font-weight: 600;
  line-height: 24px;
}
.modal-help__step-paragraph {
  min-height: 44px;
  margin-bottom: 15px;
  color: #798496;
}
.modal-help__img-wrap {
  display: flex;
  align-items: center;
}
.modal-help__img-wrap:after {
  content: '';
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 9px solid #a8b7cf;
}
.modal-help__img-wrap.no-arrow:after {
  border-left-color: transparent;
}
.modal-help__step-img {
  margin-right: 20px;
}
.modal-help__link {
  color: #1da2ff;
  font-weight: 500;
}
.modal-help__form {
  margin-top: 15px;
}
.modal-pro-options {
  color: #323232;
  padding: 17px 30px 38px;
  text-align: center;
}
.modal-pro-options p {
  padding: 5px 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.modal-pro-options p a {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #1da2ff;
}
.modal-pro-options__button {
  margin: 10px 10px 20px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #fff;
  background-color: #1da2ff;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.modal-pro-options__button:disabled {
  opacity: 0.5;
}
.modal-pro-options__button:disabled:hover {
  background-color: #1da2ff;
}
.modal-pro-options__button:hover {
  background-color: #1891e5;
}
.modal-pro-options__button.negative {
  color: #798496;
  border: 1px solid #cad3e0;
  background-color: transparent;
}
.modal-pro-options__button.negative:hover {
  color: #fff;
  background-color: #cad3e0;
}
.subscribe__item-qty-text {
  flex: none;
  display: inline-block;
  vertical-align: top;
  margin: 10px 5px 0;
  line-height: 40px;
  font-size: 14px;
  font-weight: 600;
}
.subscribe__item-qty {
  flex: none;
  display: inline-block;
  vertical-align: top;
  margin: 10px 10px 20px;
}
.subscribe__item-qty .int-pm {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border: 1px solid;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #cad3e0;
}
.subscribe__item-qty .int-pm .int-pm-btn,
.subscribe__item-qty .int-pm .int-pm-value {
  height: 40px;
  padding: 0;
  line-height: 40px;
  border: none;
}
.subscribe__item-qty .int-pm .int-pm-increment,
.subscribe__item-qty .int-pm .int-pm-decrement {
  position: relative;
  width: 40px;
  font-size: 0px;
}
.subscribe__item-qty .int-pm .int-pm-decrement:before,
.subscribe__item-qty .int-pm .int-pm-increment:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  font-family: 'icomoon';
  font-size: 16px;
}
.subscribe__item-qty .int-pm .int-pm-decrement:before {
  content: '\e940';
}
.subscribe__item-qty .int-pm .int-pm-increment:before {
  content: '\e941';
}
.subscribe__item-qty .int-pm .int-pm-value {
  min-width: 40px;
  outline: none;
}
.subscribe__button {
  white-space: nowrap;
  margin: 0 20px 0 0;
  font-size: 12px;
  font-weight: 600;
  color: #1da2ff;
}
.subscribe__button.btn {
  margin: 25px 10px 20px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #fff;
  background-color: #1da2ff;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.subscribe__button.btn:disabled {
  opacity: 0.5;
}
.subscribe__button.btn.display-block {
  display: block;
  margin: 20px auto 0;
}
.subscribe__button.btn:hover {
  background-color: #1891e5;
}
.subscribe__button.btn.negative {
  color: #798496;
  background-color: transparent;
}
.subscribe__button.btn.negative:hover {
  color: #fff;
  background-color: #cad3e0;
}
.manage-header .subscribe__button {
  margin: 0 0 0 20px;
  font-size: 13px;
  padding: 3px 0;
}
.wrap_pro_option {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-video {
  padding: 17px 30px 38px;
}
.modal-video__video {
  width: 100%;
  max-height: 255px;
  border-radius: 5px;
}
.modal-video__button {
  display: block;
  margin: 25px auto 0;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #fff;
  background-color: #1da2ff;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.modal-video__button:hover {
  background-color: #1891e5;
}
.modal {
  position: relative;
  width: 100%;
  max-width: var(--maxWidth);
  margin: var(--top) auto auto;
  padding-top: var(--modalPaddingTop);
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}
.modal__wrapp {
  --maxWidth: 620px;
  --top: 10vh;
  --modalPaddingTop: 40px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 40px 20px;
  background-color: rgba(55,59,70,0.7);
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  animation: manage-modal-appearence 0.5s ease;
}
.modal__wrapp.is-wide .modal {
  max-width: 800px;
}
.modal__wrapp.is-whatsup-setup>.modal {
  max-width: 450px;
  padding-bottom: 16px;
}
.modal__wrapp--fullscreen {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.modal__wrapp--fullscreen .modal {
  flex: 1;
  min-height: 0;
  margin-top: 0;
  overflow: auto;
}
@media screen and (max-width: 500px) {
  .modal__wrapp {
    padding: 0 5px;
  }
}
.modal__close {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.modal__close:hover {
  background-color: #f2f2f7;
}
.modal__close:hover .icon {
  color: #798496;
}
.modal__close .icon {
  font-size: 13px;
  color: #a4b4cb;
  transition: color 0.3s ease;
}
.modal__title {
  padding: 0 30px;
  font-size: 23px;
  line-height: 35px;
  font-weight: 700;
  color: #323232;
  text-align: center;
}
.modal__title.align-left {
  text-align: left;
}
.modal__about {
  padding: 0 20px;
  margin-top: 20px;
  text-align: center;
}
.modal__about h1,
.modal__about h2,
.modal__about h3,
.modal__about h4,
.modal__about h5,
.modal__about h6 {
  border: none;
  color: inherit;
}
.modal__about h1 {
  margin: 32px 0 32px;
  font-size: 42px;
  line-height: 52px;
  font-weight: 700;
}
.modal__about h2 {
  margin: 25px 0;
  font-size: 26px;
  line-height: 34px;
  font-weight: 600;
}
.modal__about h3 {
  margin: 20px 0;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
}
.modal__about h4 {
  margin: 15px 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
.modal__about h5 {
  margin: 10px 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.modal__about h6 {
  margin: 10px 0;
  font-size: 10px;
  line-height: 18px;
  font-weight: 600;
}
.modal__about p {
  margin: 10px 0 18px;
  font-size: 16px;
  line-height: 24px;
}
.modal__about p:only-of-type,
.modal__about p:last-of-type {
  margin: 0;
}
.modal__about p a {
  color: #1da2ff;
}
.modal__about ul {
  list-style: disc;
}
.modal__about ul,
.modal__about ol {
  padding-left: 20px;
  margin: 10px 0 18px;
}
.modal__about ul li,
.modal__about ol li {
  font-size: 18px;
  line-height: 28px;
}
.modal__about ul li:nth-of-type(n+2),
.modal__about ol li:nth-of-type(n+2) {
  margin-top: 5px;
}
.modal__about ul li p,
.modal__about ol li p {
  margin: 0;
}
.modal__about ul li ul,
.modal__about ol li ul {
  margin: 0;
}
.modal__error-message {
  display: block;
  color: #f00;
  text-align: center;
}
.msg-dialog {
  --dialogMaxWidth: 500px;
  --dialogTitlePaddingRight: 0;
  text-align: center;
}
.msg-dialog__body>*+* {
  margin-top: 24px;
}
.msg-dialog__title {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
}
.msg-dialog__string {
  font-size: 16px;
  line-height: 22px;
}
.msg-dialog__string span {
  font-weight: 600;
  color: var(--colorPrimary);
}
.msg-dialog__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.msg-dialog__btns>*+* {
  margin-left: 8px;
}
.nps-popup {
  position: fixed;
  bottom: 24px;
  right: 100px;
  width: 390px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: var(--colorBgSecondary);
  box-shadow: var(--shadowPopover);
}
.nps-popup__head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.nps-popup__title-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.nps-popup__close-btn {
  transform: translateY(3px);
}
.nps-popup__title {
  font-size: var(--font-size-text-l);
  line-height: 1.5;
  font-weight: 700;
}
.nps-popup__subtitle {
  font-size: var(--font-size-text-s);
  line-height: 1.4;
  font-weight: 500;
}
.nps-popup__form {
  width: 100%;
  margin-top: 24px;
}
.nps-popup__form-item {
  width: 100%;
}
.nps-popup__form-item-label-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 4px;
}
.nps-popup__form-item-label {
  font-size: var(--font-size-text-xs);
  line-height: 1.3;
  font-weight: 500;
  color: var(--colorTextSecondary);
}
.nps-popup__form-submit-btn {
  margin-top: 24px;
}
.nps-popup__radio-group {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}
.nps-popup__radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--height-control-s);
  font-size: var(--font-size-text-l);
  line-height: 1.5;
  font-weight: 500;
  color: var(--secondary-text-inside);
  text-align: center;
  background-color: var(--secondary);
  border-radius: 4px;
  transition: all 0.2s;
}
@media (hover: hover) {
  .nps-popup__radio:not(.is-active):hover {
    background-color: var(--secondary-hover);
  }
}
.nps-popup__radio:not(.is-active):active {
  background-color: var(--secondary-active);
}
.nps-popup__radio.is-active {
  background-color: var(--primary);
  color: var(--primary-text-inside);
}
.nps-popup-enter-active,
.nps-popup-leave-active {
  transition: all 0.3s ease-in-out;
}
.nps-popup-enter,
.nps-popup-leave-to {
  opacity: 0;
  transform: translateY(100px);
}
.onboarding-popup {
  padding: 24px;
  border: 1px solid #e4e9f1;
  border-radius: 4px;
  background-color: #f1f8ff;
  box-shadow: 0px 22.3363px 17.869px rgba(0,0,0,0.042), 0px 6.6501px 5.32008px rgba(0,0,0,0.028);
}
.onboarding-popup >*+* {
  margin-top: 16px;
}
.onboarding-popup__head {
  padding-right: 38px;
}
.onboarding-popup__head >*+* {
  margin-top: 16px;
}
.onboarding-popup__head-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
.onboarding-popup__head-string {
  font-size: 14px;
  line-height: 20px;
}
.onboarding-popup__head-string b {
  font-weight: 600;
}
.onboarding-popup__btns-wrapper {
  display: flex;
  justify-content: space-between;
}
.onboarding-popup__btns >*+* {
  margin-top: 16px;
}
.onboarding-popup__collapse-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 20px;
  height: 20px;
  margin-top: 0;
  border-radius: 2px;
  color: #717e93;
  border: 1px solid #acb9cd;
  transition: all 0.2s;
}
.onboarding-popup__collapse-btn:hover {
  color: #fff;
  background-color: #acb9cd;
}
.onboarding-popup__collapse-btn:after {
  content: '';
  display: block;
  width: 8px;
  height: 2px;
  background-color: currentColor;
}
.onboarding-popup__close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 26px;
  height: 26px;
  margin-top: 0;
  font-size: 14px;
  border-radius: 2px;
  color: #717e93;
  transition: all 0.2s;
}
.onboarding-popup__close-btn:hover {
  color: #fff;
  background-color: #acb9cd;
}
.onboarding-popup__stepper-wrap {
  width: 100%;
}
.onboarding-popup__stepper {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 4px;
  width: 100%;
}
.onboarding-popup__stepper-item {
  width: 100%;
  height: 8px;
  background-color: #acb9cd;
  transition: background-color 0.2s;
}
.onboarding-popup__stepper-item.is-active {
  background-color: var(--colorPrimary);
}
.onboarding-popup__stepper-item:first-child {
  border-radius: 4px 0 0 4px;
}
.onboarding-popup__stepper-item:last-child {
  border-radius: 0 4px 4px 0;
}
.onboarding-popup__stepper-value {
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: var(--colorPrimary);
}
.onboarding-popup__body {
  font-size: 14px;
  line-height: 20px;
}
.onboarding-popup__body b {
  font-weight: 600;
}
.onboarding-popup__body h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}
.onboarding-popup__body h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}
.onboarding-popup__body h6 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}
.onboarding-popup__body a {
  color: var(--colorPrimary);
  text-decoration: underline;
}
.onboarding-popup__body a:hover {
  text-decoration: none;
}
.onboarding-popup__body img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 400px;
}
.onboarding-popup__body >*+* {
  margin-top: 12px;
}
.onboarding {
  --onboardingTriggerSize: 60px;
  --onboardingTriggerIndent: 24px;
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 5;
  width: 60px;
  height: 60px;
}
.onboarding__progress {
  cursor: pointer;
  width: 100%;
  height: 100%;
  user-select: none;
  border-radius: 50%;
  box-shadow: 0px 22px 17px rgba(0,0,0,0.04), 0px 6px 5px rgba(0,0,0,0.02);
  transition: box-shadow 0.2s;
}
.onboarding__progress:hover {
  box-shadow: 0px 22px 17px rgba(0,0,0,0.06), 0px 6px 5px rgba(0,0,0,0.03);
}
.onboarding__progress-bar {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.onboarding__progress-bar path {
  stroke-linecap: square;
}
.onboarding__progress-bar .el-progress-circle__path {
  stroke: var(--colorPrimary);
}
.onboarding__progress-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #f1f8ff;
  border-radius: 50%;
}
.onboarding__progress-value {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: var(--colorPrimary);
}
.onboarding__progress-title {
  font-size: 8px;
  line-height: 12px;
  color: #465164;
}
.onboarding__popup {
  position: fixed;
  bottom: var(--onboardingTriggerIndent);
  right: calc(var(--onboardingTriggerSize) + var(--onboardingTriggerIndent) + 16px);
  width: 320px;
  max-height: calc(100vh - var(--headerHeight) - var(--onboardingTriggerIndent) * 2);
  overflow: auto;
}
.page-form__theme-picker {
  margin-bottom: 60px;
}
.page-form__titles {
  margin-bottom: 40px;
}
.page-form__uploaders {
  margin-bottom: 50px;
}
.page-form__uploader {
  margin-bottom: 16px;
}
.page-form__uploader-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #323232;
  margin-bottom: 10px;
}
.page-form__uploader:last-of-type {
  margin-bottom: 0;
}
.page-form__hint {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #798496;
  margin-bottom: 10px;
  margin-top: -5px;
}
.page-form__layouts {
  margin-bottom: 50px;
}
.page-form__text-editor {
  margin-bottom: 50px;
}
.page-form__text-editor:last-of-type {
  margin-bottom: 0;
}
.paginator {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paginator__item,
.paginator__prev,
.paginator__next {
  width: 40px;
  height: 40px;
}
.paginator__item {
  color: #a2a9b4;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.paginator__item.is-active {
  color: #000;
}
.paginator__prev,
.paginator__next {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
.paginator__prev:disabled,
.paginator__next:disabled {
  opacity: 0.5;
}
.paginator__prev:before,
.paginator__next:before {
  content: '';
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid;
}
.paginator__prev {
  transform: rotate(180deg);
}
.plan-card {
  width: 100%;
  min-width: 0;
  padding: 16px 16px;
  border: 1px solid #d2dae7;
  background-color: #fff;
  border-radius: 16px;
}
.plan-card--highlighted {
  background-color: #e9f6ff;
  border-color: var(--colorPrimary);
}
.plan-card--highlighted .plan-card__price-wrap {
  color: var(--colorPrimary);
}
.plan-card__meta {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.plan-card__title {
  font-family: 'PT Sans', sans-serif;
  font-size: 32px;
  line-height: 1.5;
  font-weight: bold;
}
.plan-card__subtitle {
  height: 44px;
  margin-top: 4px;
  font-size: 14px;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.plan-card__price-wrap {
  margin-top: 18px;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
}
.plan-card__price-wrap sup {
  top: -0.7em;
}
.plan-card__price-wrap sup:empty {
  display: none;
}
.plan-card__price-currency {
  font-size: 20px;
}
.plan-card__price {
  font-size: 36px;
  line-height: 44px;
}
.plan-card__price sup {
  font-size: 20px;
}
.plan-card__price-period {
  font-size: 20px;
  color: #798496;
}
.plan-card__cta-btn {
  width: 100%;
  margin-top: 18px;
}
.plan-card__legend {
  margin-top: 18px;
}
.plan-card__legend-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  min-height: 24px;
}
.plan-card__legend-item +.plan-card__legend-item {
  margin-top: 8px;
}
.plan-card__legend-item-title {
  width: 100%;
  font-size: 14px;
  text-align: left;
}
.plan-card__legend-item-value {
  flex: none;
  order: 2;
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
}
.plan-card__legend-item-icon {
  flex: none;
  width: 18px;
}
.plan-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 24px;
  overflow: hidden;
}
.plan-cards__type-switcher {
  display: flex;
  align-items: center;
  width: fit-content;
  border: 1px solid var(--colorPrimary);
  border-radius: 4px;
  overflow: hidden;
}
.plan-cards__type-switcher-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  height: 44px;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 600;
  color: var(--colorPrimary);
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s;
}
.plan-cards__type-switcher-item:not(.is-active):hover {
  background-color: var(--colorBgBlue);
}
.plan-cards__type-switcher-item.is-active {
  background-color: var(--colorPrimary);
  color: #fff;
}
.plan-cards__items-wrap {
  width: 100%;
  --bottomPadding: 32px;
  --featureHeight: 64px;
}
.plan-cards__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
.plan-cards__payment-switcher {
  display: flex;
  align-items: center;
  gap: 16px;
}
.plan-cards__payment-switcher-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}
.plan-cards__payment-switcher-item input {
  display: none;
}
.plan-cards__payment-switcher-item.is-active .plan-cards__payment-switcher-item-indicator {
  border-color: var(--colorPrimary);
}
.plan-cards__payment-switcher-item.is-active .plan-cards__payment-switcher-item-indicator:after {
  opacity: 1;
}
.plan-cards__payment-switcher-item-indicator {
  position: relative;
  flex: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #acb9cd;
  transition: border-color 0.2s;
}
.plan-cards__payment-switcher-item-indicator:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--colorPrimary);
  opacity: 0;
  transition: opacity 0.2s;
}
.plan-cards__payment-switcher-item-title {
  font-size: 16px;
  font-weight: 500;
}
.plan-cards__payment-switcher-item-label {
  padding: 5px 8px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: #fff;
  border-radius: 4px;
  background-color: var(--colorDanger);
}
.plan-cards-items-enter-active,
.plan-cards-items-leave-active {
  transition: all 0.6s;
}
.plan-cards-items-enter-active .plan-card,
.plan-cards-items-leave-active .plan-card {
  transition: all 0.3s ease;
}
.plan-cards-items-enter-active .plan-card:nth-of-type(2),
.plan-cards-items-leave-active .plan-card:nth-of-type(2) {
  transition-delay: 0.1s;
}
.plan-cards-items-enter-active .plan-card:nth-of-type(3),
.plan-cards-items-leave-active .plan-card:nth-of-type(3) {
  transition-delay: 0.2s;
}
.plan-cards-items-enter .plan-card,
.plan-cards-items-leave-to .plan-card {
  opacity: 0;
  transform: translateY(70px);
}
.pricing__plans .highlighted {
  color: #1da2ff;
}
.pricing__plans .plans {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.pricing__plans .plans .plan {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(50% - 25px);
  padding: 40px 40px 30px;
  background-color: #fff;
  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.08);
  text-align: center;
  cursor: default;
}
.pricing__plans .plans .plan__type {
  font-size: 24px;
  line-height: 1.71;
  font-weight: bold;
}
.pricing__plans .plans .plan__price-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
  padding-right: 17px;
  font-weight: 600;
}
.pricing__plans .plans .plan__price-currency {
  margin-right: 5px;
  font-size: 24px;
  line-height: 40px;
}
.pricing__plans .plans .plan__price {
  font-size: 54px;
  line-height: 54px;
}
.pricing__plans .plans .plan__price-period {
  align-self: flex-end;
  font-size: 16px;
}
.pricing__plans .plans .plan__type-label {
  margin-top: 10px;
  font-size: 14px;
  line-height: normal;
}
.pricing__plans .plans .plan__btn {
  margin: 25px 0 0;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #fff;
  background-color: #1da2ff;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.pricing__plans .plans .plan__btn:hover {
  background-color: #1891e5;
}
.pricing__plans .plans .plan__label {
  margin-top: 35px;
  font-weight: 600;
  line-height: normal;
  color: #a8b7cf;
}
.pricing__plans .plans .plan__label.has-no-indent {
  margin-top: 0;
}
.pro-option {
  position: relative;
}
.pro-option__wrapper {
  position: relative;
}
.pro-option__wrapper.is-active {
  cursor: pointer;
}
.pro-option.is-not-pro {
  pointer-events: none;
}
.pro-option.is-not-pro textarea,
.pro-option.is-not-pro button,
.pro-option.is-not-pro input {
  pointer-events: none;
}
.pro-option.is-not-pro:after {
  content: '';
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  font-family: 'AvenirNext', sans-serif;
  color: #323232;
  position: absolute;
  top: 0;
  left: -10px;
  width: calc(100% + 20px);
  height: 100%;
  background-color: #fcfcff;
  background-image: url("/img/lock.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 36px;
  opacity: 0.6;
  transition: all 0.2s;
}
.cta-pro .pro-option.is-not-pro:after {
  background-color: #fff;
  background-position: -2px -1px;
  background-size: 20px;
  padding-left: 24px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'AvenirNext', sans-serif;
}
.domain-modal__field .pro-option.is-not-pro:after {
  background-color: #fff;
  background-position: -3px -3px;
  background-size: 26px;
  padding-left: 24px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'AvenirNext', sans-serif;
}
.page-form__uploader .pro-option.is-not-pro:after {
  background-size: 36px;
}
.profile-dropdown__trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none !important;
}
.profile-dropdown__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 21px;
  color: #fff;
  user-select: none;
  border-radius: 50%;
  background-color: #a37e7e;
}
.profile-dropdown__trigger-icon {
  margin-left: 10px;
  font-size: 12px;
  color: #a8b7cf;
  transition: all 0.2s ease;
}
.profile-dropdown__trigger-icon.is-dropdown-opened {
  transform: rotate(180deg);
}
.profile-dropdown__menu.el-dropdown-menu {
  width: 230px;
  --dropdownPaddings: 0;
  --dropdownItemPadding: 8px 20px;
  --dropdownItemFS: 14px;
  --dropdownItemLH: 18px;
  --dropdownItemFW: 600;
}
.profile-dropdown__menu.el-dropdown-menu .popper__arrow {
  display: none;
}
.profile-dropdown__menu-header {
  width: 100%;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 14px;
  color: #59677f;
  border-bottom: 1px solid #e4e9f1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.profile-dropdown__menu-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
}
.profile-dropdown__menu-nav .el-dropdown-menu__item.is-trigger-inside {
  padding: 0;
}
.profile-dropdown__menu-nav .el-dropdown-menu__item.is-trigger-inside >* {
  display: inline-block;
  width: 100%;
  padding: 8px 20px;
}
.profile-dropdown__menu-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
  border-top: 1px solid #e4e9f1;
}
.profile-dropdown__menu-footer-item.is-logout {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-dropdown__menu-footer-item.is-logout i {
  font-size: 14px;
  color: #a8b7cf;
}
.profile-tab {
  position: relative;
  color: #323232;
  padding-bottom: 35px;
}
.profile-tab.no-bottom-indent {
  padding-bottom: 0;
}
.profile-tab__container {
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
}
.profile-tab__title {
  padding: 0 50px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}
.profile-tab__subtitle {
  margin-top: 5px;
  font-size: 14px;
  line-height: 24px;
  color: #a8b7cf;
}
.profile-tab__desc {
  padding: 0 50px;
  margin-top: 15px;
  font-size: 16px;
  line-height: 26px;
}
.profile-tab__desc .is-highlighted {
  color: #1da2ff;
  font-weight: 500;
}
.profile-tab__form {
  width: 100%;
  margin: 35px auto 0;
  padding-bottom: 5px;
}
.profile-tab__form-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.profile-tab__form-field-control {
  width: 70%;
}
.profile-tab__form-field-label {
  font-size: 14px;
  font-weight: 600;
}
.profile-tab__switcher-wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.profile-tab__switcher-wrapp.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.profile-tab__switcher-label {
  font-size: 14px;
  line-height: 20px;
}
@media screen and (max-width: 500px) {
  .profile-tab__switcher-label {
    font-size: 10px;
  }
}
.profile-tab__button {
  margin-top: 30px;
  padding: 11px 43px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #1da2ff;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.profile-tab__button:hover {
  background-color: #1891e5;
}
.profile-tab__divider {
  margin: 30px auto;
  height: 1px;
  background-color: #e4e9f1;
}
.profile-tab__danger {
  width: 100%;
}
.profile-tab__danger-title {
  font-size: 14px;
  line-height: 20px;
}
.profile-tab__danger-btn {
  width: fit-content;
  font-size: 14px;
  line-height: 20px;
  color: #a8b7cf;
  font-weight: 500;
  border-bottom: 1px dashed currentColor;
  transition: all 0.2s;
}
.profile-tab__danger-btn:hover {
  color: #ef4444;
}
.promo-code {
  padding: 24px 20px 33px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.promo-code__title {
  font-size: 23px;
  line-height: 45px;
  font-weight: 700;
  color: #323232;
  text-align: center;
  margin-bottom: 20px;
}
.promo-code__wrapp {
  display: flex;
  align-items: center;
}
.promo-code__field {
  display: flex;
  width: 100%;
  max-width: 450px;
  margin: 10px auto 0;
  font-size: 12px;
}
.promo-code__input-wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border: 1px solid #1da2ff;
  border-radius: 4px;
}
.promo-code__input {
  width: 100%;
  color: #323232;
  transition: all 0.3s ease;
}
.promo-code__input:hover {
  color: #000;
}
.promo-code__button {
  margin-top: 32px;
  padding: 10px 23px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #1da2ff;
  background-size: 200%;
  background-position: top left;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.promo-code__button:disabled {
  opacity: 0.5;
}
.promo-code__button:hover {
  background-position: center;
  background-color: #1891e5;
}
.promo-code__error {
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
  color: #ef4444;
  text-align: left;
}
.promo-code__success {
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
  color: #208b54;
  text-align: left;
}
.publish-modal {
  text-align: center;
}
.publish-modal__body {
  padding: 24px 20px;
}
.publish-modal__helper {
  font-size: 12px;
  line-height: 20px;
  color: #a8b7cf;
}
.publish-modal__field {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 520px;
  margin: 10px auto 0;
  font-size: 12px;
}
.publish-modal__input-side {
  width: 100%;
  text-align: left;
}
.publish-modal__input-wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 0 13px 0 8px;
  border: 1px solid #1da2ff;
  border-radius: 4px;
}
.publish-modal__input {
  width: 100%;
  margin-right: 20px;
  padding: 3px 8px;
  border-radius: 4px;
  background-color: #e9f3ff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #323232;
}
.publish-modal__copy-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: #1da2ff;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.publish-modal__copy-button:hover {
  color: #1891e5;
}
.publish-modal__copy-button-icon {
  margin-left: 5px;
  font-size: 13px;
  line-height: 1;
}
.publish-modal__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 90px;
  margin-left: 16px;
  padding: 11px 23px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  background: #1da2ff;
  background-size: 200%;
  background-position: top left;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.publish-modal__button:hover {
  background-position: center;
  background-color: #1891e5;
}
.publish-modal__button.for-published {
  margin-left: 0;
  margin-top: 30px;
}
.publish-modal__button.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.publish-modal__button .icon-loader {
  font-size: 20px;
  animation: loader-rotation 1.5s linear infinite;
}
.publish-modal__back-step {
  margin-top: 10px;
  font-size: 10px;
  line-height: 14px;
  color: #a8b7cf;
}
.publish-modal__back-step-button {
  font-weight: 500;
  color: #1da2ff;
  border-bottom: 1px dashed currentColor;
  transition: all 0.3s ease;
}
.publish-modal__back-step-button:hover {
  color: #1891e5;
}
.publish-modal__footer {
  width: 100%;
  height: 75px;
  padding: 28px 0 0;
  background-color: #7cc6ff;
  transition: all 0.3s ease;
}
.publish-modal__footer.opened {
  height: 192px;
}
.publish-modal__footer-title {
  font-size: 12px;
  line-height: 14px;
  color: #fff;
}
.publish-modal__footer-open-button {
  color: currentColor;
  font-weight: 600;
}
.publish-modal__footer-open-button span {
  border-bottom: 1px solid currentColor;
}
.publish-modal__footer-open-button-icon {
  display: inline-block;
  margin-left: 8px;
  font-size: 8px;
  transition: all 0.3s ease;
}
.publish-modal__footer-open-button-icon.rotated {
  transform: rotate(180deg);
}
.publish-modal__iframe-code-wrapp {
  max-width: 450px;
  margin: 30px auto 0;
}
.publish-modal__published-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 60px 35px;
}
.publish-modal__result-field {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #e4e9f1;
}
.publish-modal__result-field-url {
  width: 100%;
  font-size: 16px;
  line-height: 16px;
  color: #323232;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.publish-modal__result-field-copy {
  flex: none;
  margin-left: 10px;
  font-size: 16px;
  color: #a8b7cf;
  transition: color 0.2s ease;
}
.publish-modal__result-field-copy:hover {
  color: #1da2ff;
}
.publish-modal__status-message {
  text-align: left;
  font-size: 12px;
  margin: 10px 0;
}
.publish-modal__status-message-button {
  font-weight: 500;
  color: #1da2ff;
  border-bottom: 1px dashed currentColor;
  transition: all 0.3s ease;
}
.publish-modal__status-message-button:hover {
  color: #1891e5;
}
.publish-modal__status-message-button:disabled {
  color: #323232;
}
.publish-modal__status-message li {
  padding-left: 30px;
  position: relative;
}
.publish-modal__status-message li.no-icon {
  padding-left: 0;
}
.publish-modal__status-message li.no-icon:before {
  content: none;
}
.publish-modal__status-message li:before {
  content: '!';
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 40px;
  background-color: #1da2ff;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
}
.publish-modal__notice {
  padding: 10px;
  border-radius: 5px;
  background: #f7f7f8;
  text-align: left;
}
.radio {
  cursor: pointer;
}
.radio__input {
  display: none;
}
.radio__input:checked+.radio__indicator {
  border-color: #1da2ff;
}
.radio__input:checked+.radio__indicator:after {
  opacity: 1;
}
.radio__indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 2px solid #a8b7cf;
  border-radius: 50%;
  transition: all 0.2s;
}
.radio__indicator:hover {
  border-color: #1da2ff;
}
.radio__indicator:after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #1da2ff;
  opacity: 0;
  transition: opacity 0.2s;
}
.redirect-rules {
  width: 100%;
}
.redirect-rules__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
}
.redirect-rules__item +.redirect-rules__item {
  border-top: 1px solid var(--colorBorder);
}
.redirect-rules__item-left {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 10px;
  overflow: hidden;
}
.redirect-rules__item-label {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--colorText);
}
.redirect-rules__item-label-divider {
  flex: none;
  font-size: 12px;
  margin: 0 10px;
  color: #a8b7cf;
  transform: scale(-1, 1);
}
.redirect-rules__item-label {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.redirect-rules__item-status {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--colorTextSecondary);
}
.redirect-rules__item-btn {
  cursor: pointer;
  flex: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 18px;
  color: #a8b7cf;
  transition: all 0.2s;
}
.redirect-rules__item-btn:hover {
  color: #1da2ff;
}
.settings-form__field-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #323232;
}
.settings-form__name-wrap {
  margin-bottom: 50px;
}
.settings-form__iframe-wrap {
  margin-bottom: 30px;
}
.settings-form__analytics-wrap {
  margin-bottom: 30px;
}
.settings-form__analytics-wrap p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #323232;
  margin-bottom: 8px;
}
.settings-form__title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #323232;
  margin-bottom: 10px;
}
.settings-form__settings-wrap {
  margin-bottom: 30px;
  position: relative;
}
.settings-form__settings-wrap.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.settings-form__settings-wrap__input {
  width: 100%;
  height: 40px;
  padding: 0 13px;
  font-size: 14px;
  color: #798496;
  border: 1px solid #e4e9f1;
  border-radius: 4px;
}
.settings-form__settings-wrap__input::placeholder {
  opacity: 0.4;
  font-size: 14px;
}
.settings-form__settings-wrap__label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #323232;
  margin-bottom: 8px;
}
.settings-form__settings-wrap .form-field__error {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: 5px;
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  color: #ef4444;
  animation: collapsable-field-error-appearence 0.5s ease;
}
.settings-form__textarea {
  display: block;
  width: 100%;
  height: 160px;
  padding: 16px;
  font-size: 12px;
  line-height: normal;
  color: #323232;
  background-color: transparent;
  border: 1px solid #e4e9f1;
  border-radius: 2px;
  resize: none;
  outline: none;
  font-family: monospace;
}
.settings {
  --settingsSideIndent: 30px;
}
.settings {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
}
.settings__head {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px var(--settingsSideIndent) 10px var(--settingsSideIndent);
}
.settings__head-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}
.settings__title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: var(--colorText);
}
.settings__head-bottom {
  font-size: var(--font-size-text-s);
  line-height: 1.4;
  font-weight: 400;
  color: var(--colorTextSecondary);
}
.settings__close-sidebar {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 13px;
  color: var(--colorGrayLight);
  border-radius: 6px;
  transition: all 0.2s;
}
.settings__close-sidebar:hover {
  color: var(--colorTextSecondary);
  background-color: var(--ghost-hover);
}
.settings__head-bottom {
  width: 100%;
}
.settings__body {
  position: relative;
  padding: 30px var(--settingsSideIndent) 0;
}
.settings__form {
  width: 100%;
}
.settings__disabling-section {
  position: relative;
}
.settings__disabling-section:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.7;
  transition: all 0.3s ease;
}
.settings__disabling-section.enabled:after {
  z-index: -1;
  opacity: 0;
  animation: disabling-section-appear 0.5s ease;
}
.settings__back-btn {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  font-size: 14px;
  color: #acb9cd;
  font-weight: 600;
  transition: color 0.2s ease;
}
.settings__back-btn:hover {
  color: #1da2ff;
}
.settings__back-btn-icon {
  font-size: 18px;
  margin-right: 8px;
  transform: translateY(-1px);
}
.settings__switcher {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.settings__switcher-label {
  width: 40%;
  padding-right: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #323232;
}
.sheet-samples__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 48px 30px;
}
.sheet-samples__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.sheet-samples__preview {
  position: relative;
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  box-shadow: 0px 2px 20px rgba(0,0,0,0.07);
  overflow: hidden;
}
.sheet-samples__preview-header {
  flex: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 15px;
  padding-left: 10px;
  background-color: var(--colorBorder);
}
.sheet-samples__preview-header-circle {
  width: 6px;
  height: 6px;
  margin-right: 4px;
  background-color: var(--colorGrayLight);
  border-radius: 50%;
}
.sheet-samples__preview-inner {
  position: relative;
  flex: 1;
  width: 100%;
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  overflow: hidden;
  transition: background-position 3s ease;
}
.sheet-samples__preview-inner:hover {
  background-position: bottom;
}
.sheet-samples__item-btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
}
.sheet-samples__item-title {
  font-size: var(--font-size-heading-s);
  line-height: 1.4;
  font-weight: 700;
  color: var(--colorText);
}
.side-form {
  position: relative;
}
.side-form__block-wrapper {
  width: 100%;
}
.side-form__back-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: #a8b7cf;
  transition: color 0.2s;
}
.side-form__back-btn:hover {
  color: #1da2ff;
}
.side-form__back-btn-icon {
  font-size: 14px;
  transform: translateY(-1px);
  margin-right: 8px;
}
.side-form__section {
  width: 100%;
}
.side-form__section+.side-form__section {
  margin-top: 30px;
}
.side-form__section-title {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #323232;
  text-transform: uppercase;
}
.side-form__section-subtitle {
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  color: #323232;
  color: #798496;
}
.side-form__item {
  position: relative;
  width: 100%;
}
.side-form__item+.side-form__item {
  margin-top: 20px;
}
.side-form__item.is-justified {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.side-form__item-label {
  padding-right: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #465164;
}
.side-form__item-label.placement-top {
  margin-bottom: 6px;
}
.side-form__item-hint {
  margin-top: 2px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #798496;
}
.side-form__item-hint.placement-bottom {
  margin-bottom: 0;
  margin-top: 8px;
}
.side-form__item-hint.is-link {
  font-size: 14px;
  color: #1da2ff;
}
.side-form__item-hint.is-link a:hover {
  color: #1891e5;
}
.side-form__item-msgs {
  position: absolute;
  top: 100%;
  left: 0;
}
.side-form__item-msgs .side-form__item-msg {
  position: static;
}
.side-form__item-msg {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: 3px;
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  color: #ef4444;
}
.side-form__item-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.side-form__footnote {
  font-size: 14px;
  line-height: 20px;
  color: #59677f;
}
.side-form__footnote code {
  font-size: 12px;
  color: #616a78;
}
.side-form__btns {
  display: flex;
  flex-direction: column;
}
.side-form__btns .btn+.btn,
.side-form__btns .pro-option__wrapper {
  margin-top: 8px;
}
.side-form__btns .btn+.btn .btn,
.side-form__btns .pro-option__wrapper .btn {
  width: 100%;
}
.side-form__btns + .side-form__section,
.side-form__btns + .side-form__item {
  margin-top: 40px;
}
.side-form__text-btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #59677f;
  font-weight: 500;
  transition: all 0.1s ease;
}
.side-form__text-btn.is-filled {
  padding: 6px 12px;
  background-color: #f3f5f8;
  border-radius: 4px;
}
.side-form__text-btn:hover {
  color: #1da2ff;
}
.side-form__text-btn-icon {
  font-size: 16px;
  margin-right: 8px;
}
.side-form__checkbox-label {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #323232;
}
.side-form__switcher-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.side-form__switcher-label {
  padding-right: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #465164;
}
.side-form__filter-item {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 0;
}
.side-form__filter-item.is-active:before {
  background-color: #e9f6ff;
  opacity: 1;
}
.side-form__filter-item:not(.is-active):hover:before {
  opacity: 1;
}
.side-form__filter-item:before {
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  width: calc(100% + 60px);
  height: 100%;
  background-color: #f3f9fe;
  opacity: 0;
  transition: opacity 0.1s ease;
}
.side-form__filter-item-title {
  position: relative;
  padding-right: 20px;
  font-size: 16px;
  line-height: 22px;
  color: #323232;
}
.side-form__filter-item-value {
  position: relative;
  padding: 5px 10px 4px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  background-color: #ecf1fb;
  border-radius: 100px;
}
.side-form__draggable-wrap +.side-form__btns {
  margin-top: 15px;
}
.side-form__subforms-switcher {
  width: 100%;
}
.side-form__subforms-switcher-item {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  color: #323232;
}
.side-form__subforms-switcher-item:hover:before {
  opacity: 1;
}
.side-form__subforms-switcher-item:before {
  pointer-events: none;
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  z-index: 0;
  width: calc(100% + 60px);
  height: 100%;
  background-color: #e9f6ff;
  opacity: 0;
  transition: all 0.2s ease;
}
.side-form__subforms-switcher-item-title {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.side-form__subforms-switcher-item-icon {
  position: relative;
  flex: none;
  font-size: 16px;
  line-height: 16px;
  color: #a8b7cf;
  transform: rotate(-90deg);
  transition: all 0.2s;
}
.sorting-constructor {
  padding-top: 10px;
}
.sorting-constructor__item {
  position: relative;
  margin-bottom: 18px;
}
.sorting-constructor__item-label {
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #465164;
}
.sorting-constructor__link {
  display: inline-block;
  margin-top: 5px;
  font-size: 14px;
  color: #1da2ff;
}
.sorting-constructor__link:hover {
  color: #1891e5;
}
.sorting-constructor__highlighted {
  background: #fff;
  padding: 10px;
  margin-left: -10px;
  width: calc(100% + 20px);
  border-radius: 5px;
  border: 1px solid #e4e9f1;
}
.sorting-constructor__select-error {
  width: 100%;
  padding-top: 5px;
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  color: #ef4444;
  animation: collapsable-field-error-appearence 0.5s ease;
}
.source-options {
  position: relative;
  margin-bottom: 50px;
}
.source-options__row {
  display: flex;
}
.source-options__button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 14px;
  line-height: 40px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0 4px 4px 0;
  background-color: #0f9d58;
  transition: all 0.3s ease;
}
.source-options__button:hover {
  background-color: #1c7748;
}
.source-options__button .icon-loader {
  font-size: 16px;
  animation: loader-rotation 1.5s linear infinite;
}
.source-options__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px 0 0 4px;
  background-color: #0f9d58;
  transition: all 0.3s ease;
  margin-right: 1px;
  cursor: pointer;
}
.source-options__link:hover {
  background-color: #1c7748;
}
.source-options__link:disabled {
  opacity: 0.5;
}
.source-options__link-icon {
  display: inline-block;
  margin-right: 12px;
  font-size: 20px;
}
.source-options__change-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #798496;
}
.source-options__change-btn:hover {
  color: #1891e5;
}
.source-options__change-btn-icon {
  margin-right: 17px;
  font-size: 20px;
}
.source-options__form-wrapper {
  margin-top: 35px;
}
.source-options__form-back {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: #a8b7cf;
  transition: color 0.3s ease;
}
.source-options__form-back:hover {
  color: #1da2ff;
}
.source-options__form-back-icon {
  font-size: 14px;
  transform: translateY(-1px);
  margin-right: 8px;
}
.spread-form {
  position: relative;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .spread-form {
    flex-direction: column;
  }
}
.spread-form__title {
  font-family: 'AdamantXBold', sans-serif;
  font-size: 36px;
  line-height: 46px;
}
@media screen and (max-width: 767px) {
  .spread-form__title {
    font-size: 24px;
    line-height: 34px;
  }
}
.spread-form__paragraph {
  margin-top: 30px;
  font-size: 16px;
  line-height: 26px;
}
@media screen and (max-width: 767px) {
  .spread-form__paragraph {
    margin-top: 15px;
    font-size: 14px;
    line-height: 24px;
  }
}
.spread-form__form-elements {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 625px;
  margin: 0 auto;
}
.spread-form__form-elements.for-add-page {
  height: 50px;
}
.spread-form__input {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #323232;
  border: 1px solid #e4e9f1;
  background-color: #fff;
  border-right: none;
  border-radius: 4px 0 0 4px;
  text-overflow: ellipsis;
}
.spread-form__input::placeholder {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #a8b7cf;
}
.spread-form__sidebar-input input {
  padding-right: 60px;
}
.spread-form__add-button {
  flex: none;
  width: 140px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  background-color: #1da2ff;
  border-radius: 0 4px 4px 0;
  transition: all 0.3s ease;
}
.spread-form__add-button:hover {
  background-color: #1891e5;
}
.spread-form__add-button.loading {
  pointer-events: none;
}
.spread-form__add-button:disabled {
  opacity: 0.2;
  pointer-events: none;
}
.spread-form .icon-loader {
  font-size: 24px;
  animation: loader-rotation 2s linear infinite;
}
.spread-form__sidebar-add-btn {
  position: absolute;
  top: 7px;
  right: 0;
  font-size: 14px;
  font-weight: 500;
  color: #a8b7cf;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.spread-form__sidebar-add-btn:hover {
  color: #1da2ff;
}
.spread-form__messages-wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 625px;
  margin: 4px auto 0;
}
.spread-form__message {
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 18px;
  color: #7cc6ff;
}
.spread-form__message.error {
  color: #ef4444;
}
.spreadview {
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 16px 31px rgba(10,35,94,0.12), 0px 3px 5px rgba(10,35,94,0.06);
}
.spreadview.is-active {
  z-index: 12;
}
.spreadview__manage-plans-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  height: 26px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #fff !important;
  background-color: #444c58;
  border-radius: 4px;
  transition: all 0.2s;
}
.spreadview__manage-plans-btn i {
  font-size: 14px;
}
.spreadview__manage-plans-btn:hover,
.spreadview__manage-plans-btn.is-menu-opened {
  background-color: #1da2ff;
}
.spreadview__plan-tag {
  margin-bottom: 6px;
}
.spreadview__img {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 4px 4px 0 0;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  overflow: hidden;
}
.spreadview__img.is-archived .spreadview__dropdown.for-overlay {
  opacity: 1;
}
.spreadview__img.is-archived .spreadview__overlay {
  background-color: rgba(47,98,149,0.9);
}
.spreadview__img.is-archived .spreadview__overlay:after {
  content: '\e179';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Material Icons';
  color: #fff;
  font-size: 64px;
}
.spreadview__upgrade-btn {
  position: absolute;
  top: 16px;
  left: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 26px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #fff !important;
  background-color: #444c58;
  border-radius: 4px;
  transition: all 0.2s;
}
.spreadview__upgrade-btn:before {
  content: '\e95d';
  font-family: 'icomoon';
  font-size: 12px;
  margin-right: 8px;
}
.spreadview__upgrade-btn:hover {
  background-color: #1da2ff;
}
.spreadview__overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(28,31,34,0.8);
  background-color: rgba(107,138,167,0.5);
  backdrop-filter: blur(4px);
  opacity: 0;
  transition: all 0.2s;
}
.spreadview:hover .spreadview__overlay,
.spreadview.hover .spreadview__overlay {
  opacity: 1;
}
.spreadview__edit-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  border-radius: 4px;
  background-color: #1da2ff;
  transition: all 0.2s;
}
.spreadview__edit-btn:hover {
  background-color: #1891e5;
}
.spreadview__dropdown.for-overlay {
  position: absolute;
  top: 16px;
  right: 16px;
}
.spreadview__dropdown.for-manage-plans {
  position: absolute;
  top: 16px;
  left: 16px;
}
.spreadview__dropdown-trigger {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  transition: all 0.2s;
}
.spreadview__dropdown-trigger:not(.for-tag-list):hover,
.spreadview__dropdown-trigger:not(.for-tag-list).is-menu-opened {
  background-color: rgba(255,255,255,0.3);
}
.spreadview__dropdown-menu.el-dropdown-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 240px;
  --dropdownItemPadding: 0;
}
.spreadview__dropdown-menu.el-dropdown-menu .popper__arrow {
  display: none;
}
.spreadview__dropdown-menu.el-dropdown-menu.for-tag-list {
  width: 140px;
}
.spreadview__dropdown-menu.el-dropdown-menu.for-manage-plans {
  width: 190px;
}
.spreadview__dropdown-menu-divider {
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background-color: #e4e9f1;
}
.spreadview__dropdown-item {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: left;
  color: inherit;
}
.spreadview__dropdown-item.subscribe__button {
  margin-right: 0;
  font: inherit;
  color: #323232 !important;
}
.spreadview__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 16px 20px;
}
.spreadview__title.title-editor {
  --font-size: 16px;
  --line-height: 22px;
  --font-weight: 500;
  --field-height: 22px;
  --icon-size: 14px;
  --icon-color: var(--colorText);
  --icon-color--hover: var(--colorPrimary);
  width: 100%;
  margin-bottom: 6px;
}
.spreadview__title.title-editor.is-editing .title-editor__form {
  border-bottom-color: var(--colorPrimary);
}
.spreadview__title.title-editor .title-editor__form {
  border-bottom: 1px dashed transparent;
}
.spreadview__title.title-editor .title-editor__error {
  position: static;
  margin-top: 2px;
}
.spreadview__title.title-editor:not(.is-editing) .title-editor__btn-edit {
  opacity: 0;
}
.spreadview__title.title-editor:not(.is-editing):hover .title-editor__btn-edit {
  opacity: 1;
}
.spreadview__title-link {
  cursor: pointer;
  transition: color 0.2s;
}
.spreadview__title-link.is-disabled {
  cursor: not-allowed;
}
@media (hover: hover) {
  .spreadview__title-link:not(.is-disabled):hover {
    color: var(--colorPrimary);
  }
}
.spreadview__link {
  display: flex;
  align-items: center;
  max-width: 100%;
  color: #000;
  transition: color 0.2s;
}
.spreadview__link:hover {
  color: #1da2ff;
}
.spreadview__link-title {
  max-width: 100%;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.spreadview__link-icon {
  flex: none;
  margin-left: 6px;
}
.spreadview__indicator {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #a8b7cf;
}
.spreadview__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
}
.spreadview__tags-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.spreadview__tags-wrap.is-archived {
  opacity: 0.5;
}
.spreadview__tags {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}
.spreadview__tags-icon {
  margin-right: 8px;
  color: #a8b7cf;
}
.spreadview__tag {
  max-width: 100%;
  padding: 4px 8px;
  margin-right: 8px;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
  color: #616a78;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
  background-color: #eff3fb;
}
.spreadview__tags-add-btn {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  font-size: 10px;
  color: #616a78;
  white-space: nowrap;
  border: 1px solid #e4e9f1;
  border-radius: 2px;
  transition: all 0.2s;
}
.spreadview__tags-add-btn:not(.is-counter):hover {
  background-color: #e4e9f1;
  border-color: #e4e9f1;
}
.spreadview__tags-add-btn.is-counter {
  width: auto;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 500;
  background-color: #eff3fb;
  border-color: #eff3fb;
}
.spreadview__tags-add-btn.is-counter:hover {
  background-color: #1da2ff;
  border-color: #1da2ff;
  color: #fff;
}
.spreadview__tag-popover-menu {
  width: 240px;
  padding: 12px 0;
  max-height: 345px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #d9dfe9 #fff;
  -webkit-overflow-scrolling: touch;
}
.spreadview__tag-popover-menu::-webkit-scrollbar {
  width: 8px;
}
.spreadview__tag-popover-menu::-webkit-scrollbar-track {
  background-color: #fff;
}
.spreadview__tag-popover-menu::-webkit-scrollbar-thumb {
  background-color: #d9dfe9;
  border-radius: 20px;
  border: 2px solid #fff;
}
.spreadview__tag-popover-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all 0.2s;
}
.spreadview__tag-popover-item:hover .spreadview__dropdown {
  opacity: 1;
}
.spreadview__tag-popover-item-checkbox.el-checkbox {
  width: 100%;
  padding: 10px 14px;
  overflow: hidden;
  transition: all 0.2s;
}
.spreadview__tag-popover-item-checkbox.el-checkbox:hover {
  background-color: #f1f8ff;
}
.spreadview__tag-popover-item-checkbox.el-checkbox .el-checkbox__label {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.spreadview__tag-popover-search {
  width: 100%;
  padding: 0 14px;
  margin-bottom: 10px;
}
.spreadview__tag-popover-search-msg {
  font-size: 10px;
  line-height: 16px;
  color: #ef4444;
}
.spreadview__tag-popover-search-input {
  width: 100%;
  height: 40px;
  padding: 0 12px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #e4e9f1;
  background-color: #fff;
  transition: all 0.2s;
}
.spreadview__tag-popover-search-input::placeholder {
  color: #a8b7cf;
}
.spreadview__tag-popover-search-input:not(:focus):hover {
  border-color: #c9ced5;
}
.spreadview__tag-popover-search-input:focus {
  border-color: #1da2ff;
}
.spreadview__tag-popover-add-item {
  width: 100%;
  padding: 10px 14px;
  font-size: 16px;
  font-weight: 400;
  color: #323232;
  text-align: left;
  transition: all 0.2s;
}
.spreadview__tag-popover-add-item:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.spreadview__tag-popover-add-item span {
  color: #1da2ff;
  font-weight: 500;
  word-break: break-word;
}
.spreadview__tag-popover-add-item:not(:disabled):hover {
  background-color: #f1f8ff;
}
.spreadview__revision-indicator {
  --avatarSize: 24px;
  --avatarFS: 10px;
  margin-left: 8px;
}
.spreadviews-filters {
  width: 100%;
  color: #323232;
}
.spreadviews-filters__section +.spreadviews-filters__section {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e4e9f1;
}
.spreadviews-filters__section-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}
.spreadviews-filters__section-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
.spreadviews-filters__section-head-btn {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
  padding-left: 1px;
  color: #616a78;
  border: 1px solid #a8b7cf;
  border-radius: 2px;
  transition: all 0.2s;
}
.spreadviews-filters__section-head-btn:hover {
  background-color: #e4e9f1;
  border-color: #e4e9f1;
}
.spreadviews-filters__add-form {
  margin-bottom: 3px;
  transition: all 0.2s;
}
.spreadviews-filters__add-form-input-wrap {
  display: flex;
  align-items: center;
}
.spreadviews-filters__add-form-input {
  width: 100%;
  height: 30px;
  padding: 0 12px 0 22px;
  font-size: 16px;
  line-height: 30px;
  color: #323232;
  transition: opacity 0.2s;
}
.spreadviews-filters__add-form-input::placeholder {
  color: #a8b7cf;
}
.spreadviews-filters__add-form-input[readonly] {
  cursor: not-allowed;
  opacity: 0.5;
}
.spreadviews-filters__add-form-btn {
  flex: none;
  font-size: 16px;
  color: #a8b7cf;
  transition: color 0.2s;
}
.spreadviews-filters__add-form-btn:not(:disabled):hover {
  color: #ef4444;
}
.spreadviews-filters__add-form-btn:disabled {
  cursor: not-allowed;
}
.spreadviews-filters__add-form-btn.icon-loader {
  animation: loader-rotation 1s infinite linear;
}
.spreadviews-filters__add-form-msg {
  padding-left: 22px;
  font-size: 10px;
  line-height: 16px;
  color: #ef4444;
}
.spreadviews-filters__list {
  width: 100%;
}
.spreadviews-filters__item {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid transparent;
  overflow: hidden;
  transition: all 0.2s;
}
.spreadviews-filters__item:hover {
  background-color: #f1f8ff;
}
.spreadviews-filters__item:hover .spreadviews-filters__item-drag-trigger {
  opacity: 1;
}
.spreadviews-filters__item:hover .spreadviews-filters__item-dropdown {
  opacity: 1;
}
.spreadviews-filters__item.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.spreadviews-filters__item.is-active {
  background-color: #f1f8ff;
  border-color: #e4e9f1;
}
.spreadviews-filters__item-left {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 36px 10px 22px;
  overflow: hidden;
}
.spreadviews-filters__item-drag-trigger {
  cursor: grab;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 100%;
  font-size: 12px;
  color: #a8b7cf;
  opacity: 0;
  transition: all 0.2s;
}
.spreadviews-filters__item-title {
  max-width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.spreadviews-filters__item-edit-form {
  position: relative;
  width: 100%;
}
.spreadviews-filters__item-edit-input {
  width: 100%;
  height: 24px;
  padding: 0 8px 0 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #323232;
  border-bottom: 1px dashed #1da2ff;
  border-radius: 0;
}
.spreadviews-filters__item-edit-input::placeholder {
  color: #a8b7cf;
}
.spreadviews-filters__item-edit-msg {
  font-size: 10px;
  line-height: 16px;
  color: #ef4444;
}
.spreadviews-filters__item-counter {
  flex: none;
  margin-left: 3px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #616a78;
  background-color: #eff3fb;
  border-radius: 2px;
}
.spreadviews-filters__item-dropdown {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.2s;
}
.spreadviews-filters__item-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 24px;
  height: 24px;
  font-size: 16px;
  color: #a8b7cf;
  border-radius: 2px;
  background-color: transparent;
  transition: all 0.2s;
}
.spreadviews-filters__item-dropdown-trigger:hover {
  color: #616a78;
  background-color: #d5e5f5;
}
.spreadviews-filters__item-dropdown-menu.el-popper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 120px;
}
.spreadviews-filters__item-dropdown-menu.el-popper .popper__arrow {
  display: none;
}
.spreadviews {
  display: flex;
}
.spreadviews__container {
  display: flex;
  width: 100%;
  max-width: 1460px;
  margin: 0 auto;
  padding: 75px 20px 0;
}
.spreadviews__left {
  flex: none;
  width: 320px;
  padding-right: 60px;
}
.spreadviews__left>*+* {
  margin-top: 16px;
}
.spreadviews__right {
  width: 100%;
}
.spreadviews__search {
  position: relative;
  width: 100%;
}
.spreadviews__search-input {
  width: 100%;
  height: 40px;
  padding: 0 12px 0 40px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #e4e9f1;
  background-color: #fff;
  transition: all 0.2s;
}
.spreadviews__search-input::placeholder {
  color: #a8b7cf;
}
.spreadviews__search-input:not(:focus):hover {
  border-color: #c9ced5;
}
.spreadviews__search-input:focus {
  border-color: #1da2ff;
}
.spreadviews__search-btn {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 14px;
  transition: all 0.2s;
}
.spreadviews__search-btn:not(:disabled):hover {
  color: #1da2ff;
}
.spreadviews__search-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.spreadviews__left-workspaces-manage {
  margin-bottom: 32px;
}
.spreadviews__left-workspaces-manage>*+* {
  margin-top: 16px;
}
.spreadviews__list-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.spreadviews__title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  color: #323232;
}
.spreadviews__select {
  width: 150px;
}
.spreadviews__select .el-input__inner {
  height: 30px;
  padding-right: 32px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  color: #323232;
  text-align: right;
  background-color: transparent;
  border: none;
}
.spreadviews__select .el-input__suffix {
  display: flex;
  align-items: center;
  right: 0;
}
.spreadviews__select .el-input__suffix-inner {
  display: flex;
  align-items: center;
}
.spreadviews__select .el-input .el-select__caret {
  height: auto;
  color: #323232;
}
.spreadviews__select-menu.el-select-dropdown {
  border: 1px solid #e4e9f1;
  border-radius: 4px;
  box-shadow: 0px 16px 31px rgba(10,35,94,0.24), 0px 3px 5px rgba(10,35,94,0.1);
}
.spreadviews__select-menu.el-select-dropdown .popper__arrow {
  display: none;
}
.spreadviews__select-menu.el-select-dropdown .el-select-dropdown__item {
  width: 100%;
  height: auto;
  padding: 10px 14px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #323232;
  text-align: left;
  white-space: normal;
  transition: all 0.2s;
}
.spreadviews__select-menu.el-select-dropdown .el-select-dropdown__item:not(.is-disabled).hover,
.spreadviews__select-menu.el-select-dropdown .el-select-dropdown__item:not(.is-disabled):hover {
  background-color: #f1f8ff;
}
.spreadviews__select-menu.el-select-dropdown .el-select-dropdown__item.is-disabled {
  color: #e4e9f1;
}
.spreadviews__select-menu.el-select-dropdown .el-select-dropdown__item.selected {
  position: relative;
  font-weight: 400;
  color: #1da2ff;
}
.spreadviews__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 36px;
  width: 100%;
  margin-top: 50px;
}
.spreadviews__list-archive {
  margin-top: 60px;
}
.spreadviews__list-item {
  position: relative;
}
.spreadviews__list-item--archived {
  opacity: 0.5;
}
.spreadviews__add {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 326px;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.spreadviews__add:hover .spreadviews__add-circle {
  transform: scale(15);
  background-color: #e4e9f1;
  transition-delay: 0.2s;
}
.spreadviews__add:hover .spreadviews__add-icon {
  color: #717e93;
  transform: translate(-50%, -50%) scale(3);
  transition-delay: 0.2s;
}
.spreadviews__add-circle {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  font-size: 12px;
  color: #fff;
  border-radius: 50%;
  background-color: #a8b7cf;
  transition: all 0.5s cubic-bezier(0.67, -0.25, 0.11, 1.22);
}
.spreadviews__add-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  color: #fff;
  font-size: 12px;
  transition: all 0.5s cubic-bezier(0.67, -0.25, 0.11, 1.22);
}
.spreadviews__add-bg {
  position: absolute;
  top: 0;
  left: 0;
}
.spreadviews__add-title {
  position: relative;
  margin-top: 80px;
  font-size: 16px;
  font-weight: 500;
  color: #465164;
}
.spreadviews__bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  background-color: rgba(28,31,34,0.85);
}
.spreadviews__try-ai-btn-wrap {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px 12px 0 0;
}
.spreadviews-add-title-animation-enter-active,
.spreadviews-add-title-animation-leave-active {
  transition: all 0.35s ease;
}
.spreadviews-add-title-animation-enter,
.spreadviews-add-title-animation-leave-to {
  opacity: 0;
  transform: scale(0);
}
.spreadviews__short-list {
  display: block;
  margin-bottom: 36px;
  width: 100%;
  margin-top: 50px;
}
.spreadviews__short-list-container {
  max-width: 540px;
  margin: 0 auto;
  padding: 0;
}
.spreadviews__short-list-no-sites {
  --font-size: 16px;
  --line-height: 22px;
  --font-weight: 500;
  --field-height: 22px;
  --icon-size: 14px;
  --icon-color: var(--colorText);
  --icon-color--hover: var(--colorPrimary);
  width: 100%;
  margin-bottom: 20px;
}
.spreadviews__short-list-item {
  position: relative;
  display: flex;
  margin-bottom: 36px;
}
.spreadviews__short-list-item-img {
  min-width: 120px;
  height: 100%;
}
.spreadviews__short-list-item-content {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  padding: 16px 20px;
}
.spreadviews__short-list-item-action {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding: 16px 20px;
}
.spreadviews__short-list-item-action .ui-btn {
  transform: rotateY(180deg);
}
.spreadview__shortcode {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #323232;
  text-align: left;
  background-color: transparent;
}
.switcher {
  position: relative;
  display: block;
  flex: none;
  width: 40px;
  height: 24px;
  border-radius: 100px;
  border: 1px solid #e4e9f1;
  cursor: pointer;
  transition: all 0.3s ease;
}
.switcher.selected {
  border-color: #b1dcff;
}
.switcher.selected .switcher__indicator {
  border-color: #1da2ff;
  background-color: #1da2ff;
  transform: translateX(16px);
}
.switcher__indicator {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  width: 24px;
  height: 24px;
  background-color: #a8b7cf;
  border: 1px solid #a8b7cf;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.switcher__input {
  display: none;
}
.team-dialog {
  --dialogMaxWidth: 620px;
  --dialogTitlePaddingRight: 0;
}
.team-dialog.is-countdown-view {
  --dialogMaxWidth: 415px;
}
.team-dialog.is-countdown-view .el-dialog {
  transition-delay: 0.2s;
}
.team-dialog .el-dialog {
  transition: max-width 0.2s;
}
.team-dialog__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.team-dialog__container>*+* {
  margin-top: 24px;
}
.team-dialog__title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  text-align: center;
}
.team-dialog__users-wrap {
  position: relative;
  display: flex;
  align-items: center;
  height: 38px;
  font-size: 38px;
}
.team-dialog__string {
  font-size: 16px;
  line-height: 22px;
}
.team-dialog__string.align-center {
  width: 100%;
  max-width: 351px;
  text-align: center;
}
.team-dialog__string span {
  font-weight: 600;
  color: var(--colorPrimary);
}
.team-dialog__btns {
  display: flex;
  margin-top: 32px;
}
.team-dialog__btns>*+* {
  margin-left: 8px;
}
.team-dialog__timer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-dialog__timer .value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  line-height: 45px;
  font-weight: bold;
  color: var(--colorPrimary);
}
.team-dialog__timer .el-progress__text {
  display: none;
}
.template-preview-modal .modal {
  display: flex;
  padding-top: 0;
}
.template-preview-modal .modal__body {
  display: flex;
  flex: 1;
  width: 100%;
}
.template-preview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.template-preview__header {
  display: flex;
  align-items: center;
  flex: none;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-bottom: 1px solid var(--colorBorder);
}
.template-preview__header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  max-width: none;
}
.template-preview__header-logo {
  display: flex;
  align-items: flex-start;
  width: 140px;
}
.template-preview__header-logo-img-text {
  fill: #444;
}
.template-preview__header-right {
  display: flex;
  align-items: center;
  gap: 24px;
}
.template-preview__header-view-switcher {
  display: flex;
  align-items: center;
  gap: 2px;
}
.template-preview__header-radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 32px;
  height: 32px;
  color: #c8c8c8;
  border-radius: 4px;
  transition: all 0.2s;
}
.template-preview__header-radio.is-active {
  background-color: var(--colorPrimary);
  color: #fff;
}
.template-preview__header-radio-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.template-preview__header-cta-btn.btn {
  width: fit-content;
}
.template-preview__desktop {
  display: flex;
  width: 100%;
  flex: 1;
}
.template-preview__iframe {
  flex: 1;
  width: 100%;
}
.template-preview__phone-wrap {
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 395px;
  margin: 0 auto;
  padding: 24px 0;
}
.template-preview__phone {
  position: relative;
  display: flex;
  width: 100%;
  color: var(--colorBorder);
  border: 8px solid currentColor;
  border-radius: 40px;
  overflow: hidden;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.12), 0px 2px 24px rgba(0,0,0,0.12);
}
.template-preview__phone:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 12px;
  background-color: currentColor;
  border-radius: 0 0 8px 8px;
}
.template-preview__phone-iframe {
  display: block;
  width: 100%;
}
.template-preview__nocontent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  color: #222;
}
.template-preview__nocontent div {
  margin-bottom: 20px;
}
.text-editor__label {
  width: 40%;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #465164;
}
.text-editor .quillWrapper {
  position: relative;
}
.text-editor .quillWrapper .ql-snow .ql-toolbar button svg,
.text-editor .quillWrapper .ql-snow.ql-toolbar button svg {
  width: 18px;
  height: 18px;
}
.text-editor .quillWrapper .ql-toolbar.ql-snow {
  position: absolute;
  bottom: calc(100% + 12px);
  right: 0;
  width: 60%;
  padding: 0;
  text-align: right;
  border: none;
}
.text-editor .quillWrapper .ql-container.ql-snow {
  margin-top: 10px;
  border-radius: 2px;
  border: 1px solid #e4e9f1 !important;
}
.text-editor .quillWrapper .ql-container.ql-snow .ql-tooltip {
  left: 0 !important;
  max-width: 300px;
  margin: 0 5px;
}
.text-editor .quillWrapper .ql-container.ql-snow .ql-tooltip input[type=text] {
  width: 150px;
}
.text-editor .quillWrapper .ql-container.ql-snow .ql-tooltip .ql-preview {
  width: 85px;
}
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-formats {
  margin: 10px 0 0 -10px;
}
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-formats button {
  width: auto;
  height: 18px;
  padding: 0 5px;
  margin-right: 5px;
  border-radius: 4px;
  background-color: transparent;
  transition: all 0.3s ease;
}
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-formats button svg {
  float: none;
}
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-formats button.ql-active,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-formats button:hover {
  background-color: #cad2e0;
}
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.text-editor .quillWrapper .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow .ql-toolbar button:focus .ql-stroke,
.text-editor .quillWrapper .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow .ql-toolbar button:hover .ql-stroke,
.text-editor .quillWrapper .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.text-editor .quillWrapper .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow.ql-toolbar button:focus .ql-stroke,
.text-editor .quillWrapper .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.text-editor .quillWrapper .ql-snow.ql-toolbar button:hover .ql-stroke,
.text-editor .quillWrapper .ql-snow.ql-toolbar button:hover .ql-stroke-miter {
  stroke: #313131;
}
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar button.ql-active .ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar button:focus .ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar button:hover .ql-fill,
.text-editor .quillWrapper .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar button.ql-active .ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar button:focus .ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar button:hover .ql-fill,
.text-editor .quillWrapper .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill {
  fill: #313131;
}
.theme-picker {
  width: 34px;
  height: 34px;
  margin-right: 15px;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}
.theme-picker:last-of-type {
  margin-right: 0;
}
.theme-picker:before {
  content: '\e930';
  position: absolute;
  top: -5px;
  left: -6px;
  font-family: 'icomoon';
  font-size: 44px;
  color: #798496;
  opacity: 0;
  transition: all 0.3s ease;
}
.theme-picker.sv-is-dark {
  color: #323232;
  background: currentColor;
}
.theme-picker.sv-is-blue {
  background: currentColor;
  color: #1da2ff;
}
.theme-picker.sv-is-light {
  color: #f6f8fb;
  background: currentColor;
  border-color: #a8b7cf;
}
.theme-picker.active:before {
  opacity: 1;
}
.theme-picker__input {
  display: none;
}
.tile-constructor {
  display: flex;
}
.tile-constructor__form-fields {
  width: 100%;
}
.tile-constructor__field {
  position: relative;
  margin-bottom: 18px;
}
.tile-constructor__field-label {
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #59677f;
}
.tile-constructor__hint a {
  display: inline-block;
  margin-top: 5px;
  font-size: 14px;
  color: #1da2ff;
}
.tile-constructor__hint a:hover {
  color: #1891e5;
}
.timer-popup {
  position: fixed;
  bottom: 16px;
  left: 16px;
  width: 320px;
  padding: 16px 16px;
  background-color: #323232;
  color: #fff;
  border-radius: 12px;
  box-shadow: 0px 16px 31px rgba(10,35,94,0.24), 0px 3px 5px rgba(10,35,94,0.1);
  z-index: 1;
}
.timer-popup__title {
  padding-right: 30px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #fff;
}
.timer-popup__title a {
  color: var(--colorPrimary);
  text-decoration: underline;
}
.timer-popup__title a:hover {
  text-decoration: none;
}
.timer-popup__percent {
  color: var(--colorDanger);
}
.timer-popup__timer {
  display: block;
  height: 60px;
  margin-bottom: 16px;
  color: #fff;
  font-size: 45px;
  letter-spacing: -3px;
  font-weight: 500;
}
.timer-popup__close-btn {
  position: absolute;
  top: -10px;
  left: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 10px;
  border-radius: 50%;
  background-color: var(--colorDanger);
  color: #fff;
  box-shadow: 0px 16px 31px rgba(10,35,94,0.24), 0px 3px 5px rgba(10,35,94,0.1);
}
.title-editor {
  position: relative;
  width: fit-content;
  max-width: 100%;
  --font-size: 13px;
  --line-height: 18px;
  --font-weight: 600;
  --field-height: 18px;
  --icon-size: 12px;
  --icon-color: var(--colorTextTertiary);
  --icon-color--hover: var(--colorText);
}
.title-editor__form {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
}
.title-editor__value-saved {
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.title-editor__input {
  width: 100%;
  height: var(--field-height);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
  color: var(--colorText);
}
.title-editor__btn-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: var(--field-height);
  height: var(--field-height);
  font-size: var(--icon-size);
  line-height: 1;
  color: var(--icon-color);
  text-align: center;
  transition: all 0.2s;
}
.title-editor__btn-edit:hover {
  color: var(--icon-color--hover);
}
.title-editor__error {
  position: absolute;
  bottom: auto;
  top: calc(100% + 5px);
  left: 0;
  font-size: 12px;
  color: var(--danger);
  transform: translateY(0);
}
.ui-divider {
  width: 100%;
  height: 1px;
  background-color: var(--colorBorder);
}
.ui-msg-box {
  display: flex;
  align-items: flex-start;
  gap: var(--gap);
  width: 100%;
  border: 1px solid;
}
.ui-msg-box--size-m {
  --gap: 6px;
  --iconSize: 18px;
  --fontSize: var(--font-size-text-s);
  padding: 8px 12px;
  border-radius: 6px;
}
.ui-msg-box--size-s {
  --gap: 6px;
  --iconSize: 14px;
  --fontSize: var(--font-size-text-xs);
  padding: 6px 8px;
  border-radius: 4px;
}
.ui-msg-box--color-success {
  color: var(--colorSuccess);
  background-color: var(--colorSuccess-10);
  border-color: var(--colorSuccess);
}
.ui-msg-box--color-primary {
  color: var(--colorPrimary);
  background-color: var(--colorPrimary-10);
  border-color: var(--colorPrimary);
}
.ui-msg-box--color-secondary {
  color: var(--colorGray);
  background-color: var(--colorGrayLight-15);
  border-color: var(--colorGrayLight);
}
.ui-msg-box--color-danger {
  color: var(--colorDanger);
  background-color: var(--colorDanger-10);
  border-color: var(--colorDanger);
}
.ui-msg-box--color-warning {
  color: var(--warning);
  background-color: var(--warning-alpha-10);
  border-color: var(--warning);
}
.ui-msg-box__icon {
  flex: none;
  margin-top: 1px;
  font-size: var(--iconSize);
}
.ui-msg-box__title {
  flex: 1;
  font-size: var(--fontSize);
  line-height: 1.4;
  font-weight: 500;
}
.ui-msg-box__title a:not([class]),
.ui-msg-box__title button:not([class]) {
  color: inherit;
  font-weight: 500;
  text-decoration: underline;
}
@media (hover: hover) {
  .ui-msg-box__title a:not([class]):hover,
  .ui-msg-box__title button:not([class]):hover {
    text-decoration: none;
  }
}
.ui-switcher {
  align-items: center;
}
.ui-switcher--full-width {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}
.ui-switcher--inline {
  display: inline-flex;
  width: fit-content;
}
.ui-switcher__label {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #465164;
}
.ui-switcher__trigger {
  position: relative;
  display: block;
  flex: none;
  width: 40px;
  height: 24px;
  border-radius: 100px;
  border: 1px solid #e4e9f1;
  cursor: pointer;
  transition: all 0.3s ease;
}
.ui-switcher__trigger.selected {
  border-color: #b1dcff;
}
.ui-switcher__trigger.selected .ui-switcher__trigger-indicator {
  border-color: #1da2ff;
  background-color: #1da2ff;
  transform: translateX(16px);
}
.ui-switcher__trigger-indicator {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  width: 24px;
  height: 24px;
  background-color: #a8b7cf;
  border: 1px solid #a8b7cf;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.ui-switcher__trigger-input {
  display: none;
}
.unsplash__btn {
  margin-top: 20px;
}
.unsplash__copyright {
  display: block;
  width: 100%;
  text-align: right;
  padding: 0 10px;
  font-size: 12px;
}
.unsplash__copyright a {
  color: #1da2ff;
}
.unsplash-chips {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 5px;
}
.unsplash-chips.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.unsplash-chips li {
  font-size: 14px;
  line-height: 14px;
  border-radius: 4px;
  font-weight: 500;
  color: #323232;
  background-color: #eff1f7;
  padding: 6px 8px;
  margin: 5px;
  cursor: pointer;
}
.unsplash-chips li:hover {
  background-color: #d8d9dc;
}
.unsplash-modal__body {
  padding: 24px 20px;
}
.unsplash-modal__search {
  display: flex;
  margin: 0 10px 16px;
}
.unsplash-modal__search input {
  height: 40px;
  padding: 0 16px;
  border: 1px solid #1da2ff;
  border-radius: 4px;
  font-size: 12px;
  flex: 1 0;
}
.unsplash-modal__search input::placeholder {
  opacity: 0.5;
}
.unsplash-modal__search button {
  margin-left: 15px;
  padding: 10px 23px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #1da2ff;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.unsplash-modal__search button.loading {
  opacity: 0.5;
  pointer-events: none;
}
.unsplash-modal__results {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.unsplash-modal__result-item {
  border-radius: 5px;
  background-color: #bfbfbf;
  width: calc(33.333% - 20px);
  height: 120px;
  margin: 0 10px 20px;
  position: relative;
  cursor: pointer;
  transition: all 0.25s ease;
}
.unsplash-modal__result-item-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.unsplash-modal__result-item:hover {
  transform: scale(1.03);
}
.unsplash-modal__result-item a {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  color: #fff;
  font-size: 10px;
  padding: 2px 5px;
  background: rgba(0,0,0,0.5);
  width: 100%;
}
.unsplash-modal__result-item a:hover {
  color: #1da2ff;
}
.unsplash-modal__download-section {
  padding: 24px;
  background-color: #f7f7f8;
}
.unsplash-modal__download-section-title {
  text-align: center;
  color: #798496;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
}
.unsplash-modal__download-section form {
  max-width: 200px;
  margin: auto;
}
.unsplash-modal__download-section .uploader__btn {
  background-color: #fff;
}
.unsubscribe-modal {
  padding: 10px 30px 30px;
  color: #323232;
  text-align: left;
}
.unsubscribe-modal__step {
  font-size: 14px;
  font-weight: 500;
  color: #979797;
}
.unsubscribe-modal__content-wrap {
  font-size: 14px;
  line-height: 1.5;
}
.unsubscribe-modal__content-wrap .text-color-secondary {
  color: var(--colorTextSecondary);
}
.unsubscribe-modal__content-wrap >*+* {
  margin-top: 12px;
}
.unsubscribe-modal__content-wrap p {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}
.unsubscribe-modal__content-wrap strong,
.unsubscribe-modal__content-wrap b {
  font-weight: 600;
}
.unsubscribe-modal__content-wrap ul {
  padding: 0 0 0 15px;
  list-style-type: disc;
}
.unsubscribe-modal__content-wrap ul li+li {
  margin-top: 5px;
}
.unsubscribe-modal__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 25px;
}
.unsubscribe-modal__form-paragraph {
  padding: 10px 0;
}
.unsubscribe-modal__form-title {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}
.unsubscribe-modal__form-item {
  width: 100%;
}
.unsubscribe-modal__form-item+.unsubscribe-modal__form-item {
  margin-top: 15px;
}
.unsubscribe-modal__form-item-textarera {
  width: 100%;
  height: 80px;
  margin-top: 10px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 22px;
  color: #323232;
  border: 1px solid #e4e9f1;
  border-radius: 2px;
  resize: none;
}
.unsubscribe-modal__form-item-textarera:focus {
  outline: none;
}
.unsubscribe-modal__form-item-textarera::placeholder {
  color: #798496;
}
.unsubscribe-modal__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}
.unsubscribe-modal__error-msg {
  padding-right: 40px;
  font-size: 14px;
  line-height: 1.5;
  color: var(--colorDanger);
}
.unsubscribe-modal__btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin-top: 24px;
}
.unsubscribe-modal__btns.justify-end {
  display: flex;
  justify-content: flex-end;
}
.unsubscribe-modal__btns.justify-center {
  display: flex;
  justify-content: center;
}
.unsubscribe-modal__transparent-btn {
  font-size: 16px;
  font-weight: 600;
  color: #798496;
}
.unsubscribe-modal__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s ease;
}
.unsubscribe-modal__btn--disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s ease;
  background-color: #e0e0e0;
  height: 44px;
  padding: 0 20px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  margin-left: 20px;
}
.unsubscribe-modal__btn:not(.is-transparent) {
  height: 44px;
  padding: 0 20px;
  border-radius: 4px;
  background-color: #1da2ff;
  color: #fff;
  text-transform: uppercase;
}
.unsubscribe-modal__btn:not(.is-transparent):hover {
  background-color: #1891e5;
}
.unsubscribe-modal__btn.is-transparent {
  color: #798496;
}
.unsubscribe-modal__btn.is-transparent:hover {
  color: #1da2ff;
}
.unsubscribe-modal__btn+.unsubscribe-modal__btn {
  margin-left: 20px;
}
.uploader {
  position: relative;
  width: 100%;
  color: #798496;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploader__btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #1da2ff;
  border-radius: 2px;
  border: 1px solid #e4e9f1;
  transition: all 0.3s ease;
}
.uploader__btn:hover {
  background-color: #1da2ff;
  border-color: #1da2ff;
  color: #fff;
}
.uploader__btn.is-loading {
  cursor: not-allowed;
  opacity: 0.3;
}
.uploader__btn.is-loading:hover {
  background-color: #fff;
  border-color: #e4e9f1;
  color: #1da2ff;
}
.uploader__btn-icon {
  margin-left: 10px;
}
.uploader__file-name {
  width: 100%;
  margin-bottom: 15px;
  font-size: 12px;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.uploader__label {
  margin-bottom: 3px;
  font-size: 12px;
  line-height: 1.4;
  color: #798496;
}
.uploader__preview-wrap {
  flex: none;
  width: 150px;
  margin-right: 15px;
}
.uploader__preview {
  width: 100%;
  height: 90px;
  border-radius: 2px;
  border: 1px solid #e4e9f1;
  background-color: #e4e9f1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.uploader__controls-wrap {
  overflow: hidden;
}
.uploader__controls {
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploader__change,
.uploader__delete {
  cursor: pointer;
  margin: 0 15px;
  font-size: 20px;
  color: colorLightBLue;
  transition: color 0.3s ease;
}
.uploader__change:hover,
.uploader__delete:hover {
  color: #1da2ff;
}
.uploader__change.is-highlighted,
.uploader__delete.is-highlighted {
  color: #1da2ff;
}
.uploader__status {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}
.v-select {
  width: 100%;
  position: relative;
}
.v-select:hover .dropdown-toggle {
  border-color: #c0c4cc;
}
.v-select:hover .dropdown-toggle .open-indicator:after {
  color: #c0c4cc;
}
.v-select.open .dropdown-toggle {
  border-color: #c0c4cc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.v-select.open .dropdown-toggle .open-indicator:after {
  color: #c0c4cc;
}
.v-select.disabled {
  pointer-events: none;
}
.v-select.disabled .open-indicator {
  pointer-events: none;
}
.v-select.disabled .dropdown-toggle .selected-tag {
  opacity: 0.5;
}
.v-select.single .dropdown-toggle .selected-tag {
  background-color: transparent;
  color: #323232;
  border: none;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.v-select.clearless .dropdown-toggle .clear {
  display: block;
}
.v-select .highlight:not(:last-child) {
  margin-bottom: 12px;
}
.v-select .dropdown-toggle {
  cursor: pointer;
  width: 100%;
  height: auto;
  padding: 6px 45px 6px 10px;
  min-height: 40px;
  border-color: #e4e9f1;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.v-select .dropdown-toggle input.form-control {
  height: 0;
  opacity: 0;
  position: absolute;
}
.v-select .dropdown-toggle .form-control {
  height: auto;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
}
.v-select .dropdown-toggle .clear {
  display: none;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
}
.v-select .dropdown-toggle .clear span {
  display: none;
}
.v-select .dropdown-toggle .clear:after,
.v-select .dropdown-toggle .clear:before {
  content: '';
  position: absolute;
  background-color: #e4e9f1;
  transition: background-color 0.3s ease;
}
.v-select .dropdown-toggle .clear:after {
  top: 4px;
  left: 0;
  width: 10px;
  height: 2px;
  transform: rotate(-45deg);
}
.v-select .dropdown-toggle .clear:before {
  top: 0;
  left: 4px;
  width: 2px;
  height: 10px;
  transform: rotate(-45deg);
}
.v-select .dropdown-toggle .clear:hover:before,
.v-select .dropdown-toggle .clear:hover:after {
  background-color: #f56c6c;
}
.v-select .dropdown-toggle .open-indicator {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  font-style: unset;
}
.v-select .dropdown-toggle .open-indicator:before {
  content: none;
}
.v-select .dropdown-toggle .open-indicator:after {
  content: '\e925';
  position: absolute;
  top: 2px;
  left: 2px;
  font-family: 'icomoon';
  font-size: 12px;
  color: #e4e9f1;
  transition: all 0.3s ease;
}
.v-select .dropdown-toggle .selected-tag {
  pointer-events: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #fff;
  opacity: 1;
  letter-spacing: 0;
  animation: selected-tag-appearence 0.3s ease;
}
.v-select .dropdown-toggle .selected-tag .close {
  display: flex;
  align-items: center;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  font-size: 13px;
  line-height: 16px;
  opacity: 1;
  text-shadow: none;
}
.v-select .dropdown-toggle .selected-tag .close:hover span {
  color: #fff;
  z-index: 1;
}
.v-select .dropdown-toggle .selected-tag .close:hover span:before {
  opacity: 1;
}
.v-select .dropdown-toggle .selected-tag .close span {
  position: relative;
  width: 10px;
  height: 10px;
  line-height: 10px;
  color: #476d9d;
  letter-spacing: 0;
  font-weight: 500;
  transition: all 0.3s ease;
}
.v-select .dropdown-toggle .selected-tag .close span:before {
  content: '';
  position: absolute;
  top: -1px;
  left: -2px;
  width: 14px;
  height: 13px;
  background-color: #476d9d;
  border-radius: 50%;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease;
}
.v-select .dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 3;
  width: 100%;
  padding: 10px;
  border: 1px solid #e4e9f1;
  border-radius: 4px;
  background-color: rgba(255,255,255,0.95);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.12);
  user-select: none;
}
.v-select .dropdown-menu li {
  position: relative;
  cursor: pointer;
  margin-bottom: 12px;
  color: #323232;
}
.v-select .dropdown-menu li:before {
  content: '';
  position: absolute;
  top: 12px;
  right: 10px;
  width: 5px;
  height: 5px;
  background-color: rgba(0,0,0,0.05);
  border-radius: 50%;
  opacity: 0;
  transition: all 0.15s ease;
  z-index: 0;
}
.v-select .dropdown-menu li:after {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  width: calc(100% + 10px);
  height: 100%;
  background-color: rgba(0,0,0,0.01);
  border-radius: 4px;
  opacity: 0;
  transition: all 0.15s ease;
  z-index: 0;
}
.v-select .dropdown-menu li:hover:after {
  opacity: 1;
}
.v-select .dropdown-menu li.highlight:before {
  opacity: 0.5;
}
.v-select .dropdown-menu li.highlight a {
  color: #323232;
  background: transparent;
}
.v-select .dropdown-menu li.active:before {
  opacity: 1;
  background-color: rgba(71,109,157,0.35);
  border: 1px solid rgba(71,109,157,0.5);
}
.v-select .dropdown-menu li.active a {
  color: #476d9d;
}
.v-select .dropdown-menu li.active>a {
  background: none;
}
.v-select .dropdown-menu li a {
  position: relative;
  z-index: 1;
  padding: 5px 0;
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  background: transparent;
}
.v-select .dropdown-menu li:last-of-type {
  margin-bottom: 0;
}
.v-select.multiselect .dropdown-toggle .selected-tag {
  margin: 2px 2px 2px 0;
  color: #476d9d;
  font-weight: 500;
  background-color: rgba(143,203,245,0.35);
  border-color: rgba(143,203,245,0.5);
  pointer-events: auto;
}
.vc-chrome {
  position: absolute;
  top: 100%;
  right: 100%;
  z-index: 2;
}
.viewer-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex: none;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background-color: var(--colorBg);
  border-radius: 4px 4px 0 0;
}
.viewer-bar__circles {
  display: flex;
  align-items: center;
  flex: none;
}
.viewer-bar__circle {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-color: #d6dbe4;
  border-radius: 50%;
}
.viewer-bar__circle:last-of-type {
  margin-right: 0;
}
.viewer-bar__field {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  height: 28px;
  padding: 0 15px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid var(--colorBorder);
  border-radius: 4px;
  overflow: hidden;
}
.viewer-bar.is-mobile-view .viewer-bar__field {
  padding: 0 8px;
}
.viewer-bar__input-wrap {
  display: flex;
  align-items: center;
  min-width: 0;
}
.viewer-bar__input-prepend {
  max-width: 100%;
  color: var(--colorPrimary);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.viewer-bar.is-mobile-view .viewer-bar__input-prepend {
  font-size: 10px;
}
.viewer-bar__input-prepend--blur {
  filter: blur(2px);
  user-select: none;
}
.viewer-bar__input {
  font-size: inherit;
  color: var(--colorText);
}
.viewer-bar__input-append {
  padding: 1px 5px;
  color: var(--colorPrimary);
  border-radius: 4px;
  background-color: rgba(177,220,255,0.2);
}
.viewer-bar__submit {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  padding: 2px 14px;
  border-radius: 2px;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  background-color: var(--colorPrimary);
  transition: all 0.3s ease;
}
.viewer-bar.is-mobile-view .viewer-bar__submit {
  width: calc(100% - 10px);
}
.viewer-bar__submit:hover {
  background-color: #1891e5;
}
.viewer-bar__switchers {
  display: flex;
  align-items: center;
  flex: none;
  gap: 4px;
}
.viewer-bar__switcher {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 28px;
  height: 28px;
  font-size: 18px;
  padding-left: 1px;
  color: var(--colorTextSecondary);
  border-radius: 4px;
  transition: all 0.2s;
}
.viewer-bar__switcher input {
  display: none;
}
.viewer-bar__switcher:hover {
  background-color: var(--colorPrimary-10);
}
.viewer-bar__switcher.is-active {
  background-color: var(--colorPrimary);
  color: #fff;
}
.web-preview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e4e9f1;
  overflow: hidden;
  box-shadow: 0px 6px 16px 0 rgba(29,36,56,0.05), 0px 6px 16px 0px rgba(0,0,0,0.03);
}
.web-preview.is-mobile {
  max-width: 420px;
}
.web-preview__topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex: none;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  background-color: #eff3fb;
}
.web-preview__topbar-decor {
  display: flex;
  align-items: center;
  flex: none;
  gap: 8px;
}
.web-preview__topbar-decor-item {
  flex: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #a8b7cf;
}
.web-preview__topbar-field {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 0 12px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #e4e9f1;
  background-color: #fff;
}
.web-preview__topbar-field-prepend {
  font-size: var(--font-size-text-s);
  line-height: 1.4;
  font-weight: 500;
  color: #a8b7cf;
}
.web-preview__topbar-field-value {
  width: 100%;
  max-width: 230px;
  font-size: var(--font-size-text-s);
  line-height: 1.4;
  font-weight: 500;
  border-radius: 4px;
}
.web-preview__topbar-field-value.is-hidden {
  height: 20px;
  background-color: #e4e9f1;
}
.web-preview__topbar-switcher-wrap {
  display: flex;
  align-items: center;
  flex: none;
  gap: 2px;
}
.web-preview__topbar-switcher {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 28px;
  height: 28px;
  font-size: 20px;
  border-radius: 4px;
  transition: all 0.2s;
}
@media (hover: hover) {
  .web-preview__topbar-switcher:hover {
    background-color: #e4e9f1;
  }
}
.web-preview__topbar-switcher:active {
  background-color: #d4dbe5;
}
.web-preview__topbar-switcher.is-active {
  color: var(--colorPrimary);
}
.web-preview__body {
  width: 100%;
  flex: 1;
}
.web-preview__body.is-zoomed iframe {
  zoom: 60%;
}
.web-preview__body >iframe {
  width: 100%;
  height: 100%;
}
.workspaces-manage {
  padding: 0 50px;
  text-align: left;
}
.workspaces-manage__head {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}
.workspaces-manage__title.title-editor {
  --font-size: 24px;
  --line-height: 30px;
  --font-weight: bold;
  --field-height: 30px;
  --icon-size: 16px;
}
.workspaces-manage__title.title-editor.is-editing {
  width: 100%;
}
.workspaces-manage__title.title-editor.is-editing .title-editor__form {
  border-bottom-color: var(--colorPrimary);
}
.workspaces-manage__title.title-editor .title-editor__form {
  border-bottom: 1px dashed transparent;
}
.workspaces-manage__body>*+* {
  margin-top: 16px;
}
.workspaces-manage__search {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 16px;
}
.workspaces-manage__counter {
  width: 100%;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 22px;
  background-color: var(--colorBgGray);
  border-radius: 4px;
}
.workspaces-manage__counter span {
  font-weight: 600;
  color: var(--colorPrimary);
}
.workspaces-manage__members {
  margin-top: 8px;
}
.workspaces-manage__member {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px 16px 0;
}
.workspaces-manage__member:not(:last-of-type) {
  border-bottom: 1px solid var(--colorBorder);
}
.workspaces-manage__member:hover .workspaces-manage__member-delete-btn {
  pointer-events: all;
  opacity: 1;
}
.workspaces-manage__member-details {
  display: flex;
  align-items: center;
}
.workspaces-manage__member-avatar {
  flex: none;
}
.workspaces-manage__member-meta {
  margin-left: 12px;
}
.workspaces-manage__member-meta>*+* {
  margin-top: 2px;
}
.workspaces-manage__member-meta-title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}
.workspaces-manage__member-meta-subtitle {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
}
.workspaces-manage__member-meta-subtitle-icon {
  margin-left: 8px;
  font-size: 12px;
  color: var(--colorTextSecondary);
  transition: all 0.2s;
}
.workspaces-manage__member-meta-subtitle-icon:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.workspaces-manage__member-meta-subtitle-icon:not(:disabled):hover {
  color: var(--colorPrimary);
}
.workspaces-manage__member-status {
  flex: none;
  width: 130px;
  font-size: 16px;
  line-height: 22px;
  color: var(--colorTextSecondary);
}
.workspaces-manage__member-delete-btn {
  pointer-events: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 12px;
  font-size: 20px;
  color: var(--colorTextSecondary);
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s;
}
.workspaces-manage__member-delete-btn:hover {
  color: var(--colorDanger);
}
.workspaces-popover {
  display: block;
  width: 100%;
}
.workspaces-popover__user {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 12px;
}
.workspaces-popover__user-icon {
  flex: none;
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.workspaces-popover__user-meta>*+* {
  margin-top: 3px;
}
.workspaces-popover__user-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  user-select: none;
}
.workspaces-popover__user-title span {
  font-size: 12px;
  font-weight: 400;
}
.workspaces-popover__user-subtitle {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  user-select: none;
}
.workspaces-popover__trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: var(--colorBgSecondary);
  border: 1px solid var(--colorControlBorder);
  border-radius: 4px;
  transition: all 0.2s;
}
.workspaces-popover__trigger:hover {
  border-color: var(--colorControlBorder--hover);
}
.workspaces-popover__trigger-chevron {
  flex: none;
  font-size: 12px;
  transition: all 0.2s;
}
.workspaces-popover__trigger-chevron.is-flipped {
  transform: rotate(180deg);
}
.workspaces-popover__menu {
  --popoverWidth: 260px;
  --popoverPaddings: 4px 0;
}
.workspaces-popover__menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px;
  transition: all 0.2s;
}
.workspaces-popover__menu-item.disabled {
  pointer-events: none;
  background-color: var(--colorPopoverItemBg--hover);
  opacity: 0.5;
}
.workspaces-popover__menu-item:hover {
  background-color: var(--colorPopoverItemBg--hover);
}
.workspaces-popover__menu-item-check {
  flex: none;
  font-size: 12px;
  color: var(--colorPrimary);
}
.sv-content-block {
  font-family: inherit;
}
.sv-content-block .v-md-editor.sv-md-editor {
  background-color: transparent;
  word-break: break-word;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body {
  font-family: inherit;
  color: #323232;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body .v-md-editor__todo-list-checkbox--checked::after {
  top: 1px;
  left: 4px;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body .sv-desc-btn {
  cursor: pointer;
  display: inline-block;
  width: fit-content;
  max-width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
  height: 40px;
  padding: 0 30px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #51535d;
  border-radius: 3px;
  border: none;
  outline: none;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: all 0.3s ease;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body .sv-desc-btn:hover {
  color: #fff;
  opacity: 0.85;
  text-decoration: none;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body .sv-desc-btn:visited,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body .sv-desc-btn:active,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body .sv-desc-btn:focus {
  text-decoration: none;
  color: #fff;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>h1,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>h2,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>h3,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>h5,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>h6,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>p,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>img,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>table,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>ul,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>ol,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>blockquote,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>figure,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body>div>figcaption {
  margin-top: 0;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body h1,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body h2,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body h3,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body h4,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body h5,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body h6 {
  border: none;
  color: inherit;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body h1 {
  margin: 32px 0 32px;
  font-size: 42px;
  line-height: 52px;
  font-weight: 700;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body h2 {
  margin: 25px 0;
  font-size: 26px;
  line-height: 34px;
  font-weight: 600;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body h3 {
  margin: 20px 0;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body h4 {
  margin: 15px 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body h5 {
  margin: 10px 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body h6 {
  margin: 10px 0;
  font-size: 10px;
  line-height: 18px;
  font-weight: 600;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body p {
  margin: 10px 0 18px;
  font-size: 18px;
  line-height: 28px;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body p:only-of-type,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body p:last-of-type {
  margin: 0;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body p a {
  color: #1da2ff;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body blockquote {
  position: relative;
  padding: 0 0 0 25px;
  margin: 10px 0 20px;
  font-size: 20px;
  line-height: normal;
  font-weight: 500;
  color: inherit;
  border-left: none;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body blockquote:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #323232;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body hr {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  margin: 30px auto;
  background-color: #e4e9f1;
  border: none;
  overflow: visible;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body img {
  box-sizing: border-box;
  display: block;
  margin: 0 0 20px;
  max-width: 100%;
  border-style: none;
  background-color: transparent;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body figure {
  margin: 10px 0 20px;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body figure img {
  margin: 0;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body figcaption {
  margin-top: 8px;
  font-size: 12px;
  line-height: 14px;
  color: #798496;
  text-align: left;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body input {
  -webkit-appearance: auto;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body ul {
  list-style: disc;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body ul,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body ol {
  padding-left: 20px;
  margin: 10px 0 18px;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body ul li,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body ol li {
  font-size: 18px;
  line-height: 28px;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body ul li:nth-of-type(n+2),
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body ol li:nth-of-type(n+2) {
  margin-top: 5px;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body ul li p,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body ol li p {
  margin: 0;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body ul li ul,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body ol li ul {
  margin: 0;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body table {
  width: 100%;
  max-width: 100%;
  margin: 10px 0 20px;
  border-spacing: 0;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body table caption {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 14px;
  color: #798496;
  text-align: left;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body table th,
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body table td {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 8px;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body table th {
  font-size: 16px;
  font-weight: 500;
  color: #798496;
  text-align: left;
  border: 1px solid #e4e9f1;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body table th:nth-of-type(n+2) {
  border-left: none;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body table td {
  font-size: 14px;
  font-weight: 400;
  color: #323232;
  text-align: left;
  border: 1px solid #e4e9f1;
  border-top: none;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body table td:nth-of-type(n+2) {
  border-left: none;
}
.sv-content-block .v-md-editor.sv-md-editor .v-md-editor-preview.github-markdown-body iframe {
  border: 0;
  margin-bottom: 18px;
}
.account-page {
  padding-top: 70px;
}
.account-page__container {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 0 20px;
}
.layout-manage {
  position: relative;
}
.layout-manage.clear {
  overflow: hidden;
}
.layout-manage.clear .manage-header {
  transform: translateY(-100%);
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}
.layout-manage.clear .aside__controls {
  left: calc(100% - 70px);
}
.layout-manage.clear .manage__body {
  padding-top: 0;
}
.layout-manage__restore-button {
  z-index: 2;
  position: fixed;
  top: -30px;
  right: -30px;
  width: 80px;
  height: 80px;
  padding: 20px 20px 0 0;
  font-size: 14px;
  color: #fff;
  background: #7cc6ff;
  border-radius: 50%;
  transform-origin: top right;
  transition: all 0.3s ease;
}
.layout-manage__restore-button:hover {
  background-color: #ef4444;
  transform: scale(1.05);
}
.new-view-page {
  display: flex;
  align-items: center;
}
@media (min-width: 500px) and (max-width: 767px) {
  .new-view-page {
    padding: 100px 0 0;
  }
}
@media screen and (max-width: 500px) {
  .new-view-page {
    padding-top: 50px;
  }
}
.new-view-page__sides {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  padding: 0 20px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .new-view-page__sides {
    display: block;
  }
}
.new-view-page__form-side {
  position: relative;
  width: 600px;
  padding-right: 70px;
}
@media (min-width: 767px) and (max-width: 1280px) {
  .new-view-page__form-side {
    flex-shrink: 0;
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .new-view-page__form-side {
    width: 100%;
    padding-right: 0;
  }
}
.new-view-page__form-side-dots {
  position: absolute;
  top: -160px;
  left: -250px;
}
.new-view-page__help-side {
  position: relative;
}
@media (min-width: 767px) and (max-width: 1280px) {
  .new-view-page__help-side {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .new-view-page__help-side {
    width: 100%;
    padding-top: 70px;
  }
}
.new-view-page__help-side:before {
  content: '';
  position: absolute;
  top: 85px;
  left: -100px;
  z-index: -1;
  width: 900px;
  height: 800px;
  background-color: #f7f7f9;
}
@media screen and (max-width: 767px) {
  .new-view-page__help-side:before {
    content: none;
  }
}
.new-view-page__video-wrapp {
  position: relative;
  width: 600px;
  margin: 88px 0 -180px -200px;
  border-radius: 4px;
}
@media screen and (max-width: 767px) {
  .new-view-page__video-wrapp {
    width: 100%;
    margin: 50px 0 0;
  }
}
.new-view-page__video {
  position: relative;
  z-index: 1;
}
.new-view-page__video-wrapp-dots {
  position: absolute;
  top: -40px;
  right: -130px;
}
.page-ai-creator {
  display: flex;
  width: 100%;
  padding-bottom: 0;
}
.page-ai-creator__left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  width: 40%;
}
.page-ai-creator__right {
  display: flex;
  flex-direction: column;
  width: 60%;
  min-width: 800px;
}
.page-ai-creator__left-container {
  width: 100%;
  max-width: 500px;
}
.page-ai-creator__title {
  margin-bottom: 40px;
  font-family: 'PT Sans', sans-serif;
  font-size: 56px;
  line-height: 1.2;
  font-weight: bold;
}
.page-ai-creator__subtitle {
  font-size: var(--font-size-text-l);
  line-height: 1.5;
  font-weight: 500;
}
.page-ai-creator__form-wrap {
  width: 100%;
  margin-top: 24px;
}
.page-ai-creator__footnote {
  margin-top: 40px;
  font-size: var(--font-size-text-m);
  line-height: 1.5;
  font-weight: 400;
  color: #798496;
}
.page-ai-creator__hero {
  position: relative;
  width: 100%;
  flex: 1;
  background: linear-gradient(290deg, #9dd4ff, #1e9dff);
  overflow: hidden;
}
.page-ai-creator__hero-img {
  width: 100%;
  max-width: 627px;
  max-height: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page-ai-creator__flow-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 0;
  max-height: calc(100vh - var(--headerHeight));
  overflow: hidden;
  background: linear-gradient(290deg, #9dd4ff, #1e9dff);
}
.page-ai-creator__result-wrap {
  flex: 1;
  width: 100%;
}
.page-create {
  position: relative;
  padding-top: 70px;
  padding: 70px var(--containerSidePadding) 0;
}
.page-create .spread-form__form-elements {
  max-width: none;
}
.page-create__decor-img {
  position: absolute;
  z-index: -1;
  pointer-events: none;
}
.page-create__decor-img.is-left {
  top: 130px;
  left: 20px;
}
.page-create__decor-img.is-bottom {
  bottom: 0;
  right: 0;
}
.page-create__head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto 50px;
}
.page-create__meta {
  text-align: center;
}
.page-create__meta >*+* {
  margin-top: 24px;
}
.page-create__meta-title {
  font-family: 'AvenirNext', sans-serif;
  font-size: 36px;
  line-height: 45px;
  font-weight: bold;
}
.page-create__meta-string {
  font-size: 16px;
  line-height: 1.5;
  color: var(--colorText);
}
.page-create__meta-string >*+* {
  margin-top: 12px;
}
.page-create__meta-string b {
  font-weight: bold;
}
.page-create__nav {
  display: flex;
  width: fit-content;
  border: 1px solid var(--colorPrimary);
  border-radius: 4px;
  overflow: hidden;
}
.page-create__nav-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;
  height: 38px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--colorText);
  transition: all 0.2s;
}
.page-create__nav-item.is-active {
  color: #fff;
  background-color: var(--colorPrimary);
}
.page-create__nav-item:not(.is-active):hover {
  background-color: var(--colorPrimary-10);
}
.page-create__nav-item:not(.is-active):active {
  background-color: var(--colorPrimary-20);
}
.page-create__nav-item:not(:last-child) {
  border-right: 1px solid var(--colorPrimary);
}
.page-create__nav-item-icon {
  color: var(--colorPrimary);
}
.page-create__form-wrap {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}
.page-create__cards-wrap {
  display: grid;
  grid-template-columns: 240px 1fr;
  gap: 40px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
.page-create__ai-wrap {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.page-create__try-ai-btn {
  vertical-align: middle;
  transform: translateY(-2px);
}
.page-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page-cta__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  text-align: center;
}
.page-cta__container>*+* {
  margin-top: 24px;
}
.page-cta__title {
  font-size: 24px;
  line-height: 40px;
  font-weight: bold;
  letter-spacing: -0.8px;
  margin-top: 0;
}
.page-cta__subtitle {
  font-size: 24px;
  line-height: 40px;
  margin-top: 2px;
  letter-spacing: -0.8px;
}
.page-cta__string {
  font-size: 16px;
  line-height: 24px;
  color: var(--colorTextSecondary);
}
.page-cta__error-msg {
  margin-top: 12px;
  font-size: 12px;
  line-height: 18px;
  color: var(--colorDanger);
}
.route__quiz .header {
  background-color: transparent;
}
.page-quiz {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  background-color: var(--colorBgGray);
  overflow: auto;
}
.page-quiz__container {
  width: 100%;
  max-width: 960px;
  padding: var(--headerHeight) var(--containerSidePadding) 88px;
}
.page-quiz__title {
  font-family: 'PT Sans', sans-serif;
  margin-bottom: 30px;
  font-size: var(--font-size-heading-l);
  line-height: 1.2;
  font-weight: 600;
  text-align: center;
}
.page-quiz__form {
  width: 100%;
}
.page-quiz__form-section {
  padding: 24px 30px;
  background-color: var(--colorBgSecondary);
  border-radius: 4px;
}
.page-quiz__form-item {
  width: 100%;
}
.page-quiz__form-item +.page-quiz__form-item {
  margin-top: 36px;
}
.page-quiz__form-item-title {
  margin-bottom: 20px;
  font-size: var(--font-size-text-l);
  line-height: 1.5;
  font-weight: 600;
}
.page-quiz__form-item-control {
  width: 100%;
  gap: 24px 16px;
}
.page-quiz__form-item-control +.page-quiz__form-item-control {
  margin-top: 20px;
}
.page-quiz__form-item-control.is-grid-view {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.page-quiz__form-item-control.is-list-view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.page-quiz__radio {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}
.page-quiz__radio input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
.page-quiz__radio:not(.is-checked):hover .page-quiz__radio-indicator {
  border-color: var(--colorPrimary);
}
.page-quiz__radio.is-checked .page-quiz__radio-indicator {
  border-color: var(--colorPrimary);
}
.page-quiz__radio.is-checked .page-quiz__radio-indicator:after {
  opacity: 1;
  transform: scale(1);
}
.page-quiz__radio-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--colorGrayLight);
  transition: border-color 0.2s;
}
.page-quiz__radio-indicator:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: var(--colorPrimary);
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s;
}
.page-quiz__radio-title {
  font-size: var(--font-size-text-s);
  line-height: 1.4;
  font-weight: 400;
}
.page-quiz__form-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}
.page-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 32px;
  overflow: hidden;
  background-color: #98a4b9;
}
.page-video__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  min-height: 0;
  flex: 1;
}
.page-video__video {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  box-shadow: var(--shadowPopover);
}
.heading--size-xxs {
  font-size: var(--font-size-heading-xxs);
  line-height: 1;
  font-weight: 600;
}
.heading--size-xs {
  font-size: var(--font-size-heading-xs);
  line-height: 1.2;
  font-weight: 600;
}
.heading--size-s {
  font-size: var(--font-size-heading-s);
  line-height: 1.4;
  font-weight: 600;
}
.heading--size-m {
  font-size: var(--font-size-heading-m);
  line-height: 1.34;
  font-weight: 600;
}
.heading--size-l {
  font-size: var(--font-size-heading-l);
  line-height: 1.2;
  font-weight: 600;
}
.heading--size-xl {
  font-size: var(--font-size-heading-xl);
  line-height: 1.2;
  font-weight: 600;
}
.heading--size-xxl {
  font-size: var(--font-size-heading-xxl);
  line-height: 1.3;
  font-weight: 600;
}
.heading--size-xxxl {
  font-size: var(--font-size-heading-xxxl);
  line-height: 1.4;
  font-weight: 600;
}
.text--size-xxs {
  font-size: var(--font-size-text-xxs);
  line-height: 1.1;
  font-weight: 400;
}
.text--size-xs {
  font-size: var(--font-size-text-xs);
  line-height: 1.3;
  font-weight: 400;
}
.text--size-s {
  font-size: var(--font-size-text-s);
  line-height: 1.4;
  font-weight: 400;
}
.text--size-m {
  font-size: var(--font-size-text-m);
  line-height: 1.5;
  font-weight: 400;
}
.text--size-l {
  font-size: var(--font-size-text-l);
  line-height: 1.5;
  font-weight: 400;
}
.text--size-xl {
  font-size: var(--font-size-text-xl);
  line-height: 1.5;
  font-weight: 400;
}
.text--size-xxl {
  font-size: var(--font-size-text-xxl);
  line-height: 1.5;
  font-weight: 400;
}
.text--size-xxxl {
  font-size: var(--font-size-text-xxxl);
  line-height: 1.5;
  font-weight: 400;
}
.text--align-center {
  text-align: center;
}
.text--align-left {
  text-align: left;
}
.text--align-right {
  text-align: right;
}
.text--weight-900 {
  font-weight: 900;
}
.text--weight-800 {
  font-weight: 800;
}
.text--weight-700 {
  font-weight: 700;
}
.text--weight-600 {
  font-weight: 600;
}
.text--weight-500 {
  font-weight: 500;
}
.text--weight-400 {
  font-weight: 400;
}
.text--weight-300 {
  font-weight: 300;
}
.text--weight-200 {
  font-weight: 200;
}
.text--weight-100 {
  font-weight: 100;
}
.text--color-default {
  color: var(--colorText);
}
.text--color-primary {
  color: var(--primary);
}
.text--color-secondary {
  color: var(--colorTextSecondary);
}
.text--color-tertiary {
  color: var(--colorTextTertiary);
}
.text--color-danger {
  color: var(--colorDanger);
}
.text--color-success {
  color: var(--colorSuccess);
}
.va-bottom {
  vertical-align: bottom;
}
.va-middle {
  vertical-align: middle;
}
.va-top {
  vertical-align: top;
}
.flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-between {
  justify-content: space-between;
}
.is-link {
  color: var(--colorPrimary);
  text-decoration: underline;
}
.is-link:hover {
  text-decoration: none;
}
.mt-auto {
  margin-top: auto;
}
.mr-auto {
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.mt-2 {
  margin-top: 2px;
}
.mr-2 {
  margin-right: 2px;
}
.mb-2 {
  margin-bottom: 2px;
}
.ml-2 {
  margin-left: 2px;
}
.p-2 {
  padding: 2px;
}
.pt-2 {
  padding-top: 2px;
}
.pr-2 {
  padding-right: 2px;
}
.pb-2 {
  padding-bottom: 2px;
}
.pl-2 {
  padding-left: 2px;
}
.gap-2 {
  gap: 2px;
}
.row-gap-2 {
  row-gap: 2px;
}
.column-gap-2 {
  column-gap: 2px;
}
.mt-4 {
  margin-top: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.p-4 {
  padding: 4px;
}
.pt-4 {
  padding-top: 4px;
}
.pr-4 {
  padding-right: 4px;
}
.pb-4 {
  padding-bottom: 4px;
}
.pl-4 {
  padding-left: 4px;
}
.gap-4 {
  gap: 4px;
}
.row-gap-4 {
  row-gap: 4px;
}
.column-gap-4 {
  column-gap: 4px;
}
.mt-8 {
  margin-top: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.p-8 {
  padding: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.gap-8 {
  gap: 8px;
}
.row-gap-8 {
  row-gap: 8px;
}
.column-gap-8 {
  column-gap: 8px;
}
.mt-12 {
  margin-top: 12px;
}
.mr-12 {
  margin-right: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}
.ml-12 {
  margin-left: 12px;
}
.p-12 {
  padding: 12px;
}
.pt-12 {
  padding-top: 12px;
}
.pr-12 {
  padding-right: 12px;
}
.pb-12 {
  padding-bottom: 12px;
}
.pl-12 {
  padding-left: 12px;
}
.gap-12 {
  gap: 12px;
}
.row-gap-12 {
  row-gap: 12px;
}
.column-gap-12 {
  column-gap: 12px;
}
.mt-16 {
  margin-top: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.gap-16 {
  gap: 16px;
}
.row-gap-16 {
  row-gap: 16px;
}
.column-gap-16 {
  column-gap: 16px;
}
.mt-20 {
  margin-top: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.p-20 {
  padding: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.gap-20 {
  gap: 20px;
}
.row-gap-20 {
  row-gap: 20px;
}
.column-gap-20 {
  column-gap: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.p-24 {
  padding: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.gap-24 {
  gap: 24px;
}
.row-gap-24 {
  row-gap: 24px;
}
.column-gap-24 {
  column-gap: 24px;
}
.mt-28 {
  margin-top: 28px;
}
.mr-28 {
  margin-right: 28px;
}
.mb-28 {
  margin-bottom: 28px;
}
.ml-28 {
  margin-left: 28px;
}
.p-28 {
  padding: 28px;
}
.pt-28 {
  padding-top: 28px;
}
.pr-28 {
  padding-right: 28px;
}
.pb-28 {
  padding-bottom: 28px;
}
.pl-28 {
  padding-left: 28px;
}
.gap-28 {
  gap: 28px;
}
.row-gap-28 {
  row-gap: 28px;
}
.column-gap-28 {
  column-gap: 28px;
}
.mt-32 {
  margin-top: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.p-32 {
  padding: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.gap-32 {
  gap: 32px;
}
.row-gap-32 {
  row-gap: 32px;
}
.column-gap-32 {
  column-gap: 32px;
}
.mt-36 {
  margin-top: 36px;
}
.mr-36 {
  margin-right: 36px;
}
.mb-36 {
  margin-bottom: 36px;
}
.ml-36 {
  margin-left: 36px;
}
.p-36 {
  padding: 36px;
}
.pt-36 {
  padding-top: 36px;
}
.pr-36 {
  padding-right: 36px;
}
.pb-36 {
  padding-bottom: 36px;
}
.pl-36 {
  padding-left: 36px;
}
.gap-36 {
  gap: 36px;
}
.row-gap-36 {
  row-gap: 36px;
}
.column-gap-36 {
  column-gap: 36px;
}
.mt-40 {
  margin-top: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.p-40 {
  padding: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.gap-40 {
  gap: 40px;
}
.row-gap-40 {
  row-gap: 40px;
}
.column-gap-40 {
  column-gap: 40px;
}
.mt-44 {
  margin-top: 44px;
}
.mr-44 {
  margin-right: 44px;
}
.mb-44 {
  margin-bottom: 44px;
}
.ml-44 {
  margin-left: 44px;
}
.p-44 {
  padding: 44px;
}
.pt-44 {
  padding-top: 44px;
}
.pr-44 {
  padding-right: 44px;
}
.pb-44 {
  padding-bottom: 44px;
}
.pl-44 {
  padding-left: 44px;
}
.gap-44 {
  gap: 44px;
}
.row-gap-44 {
  row-gap: 44px;
}
.column-gap-44 {
  column-gap: 44px;
}
.mt-48 {
  margin-top: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.p-48 {
  padding: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.gap-48 {
  gap: 48px;
}
.row-gap-48 {
  row-gap: 48px;
}
.column-gap-48 {
  column-gap: 48px;
}
.mt-52 {
  margin-top: 52px;
}
.mr-52 {
  margin-right: 52px;
}
.mb-52 {
  margin-bottom: 52px;
}
.ml-52 {
  margin-left: 52px;
}
.p-52 {
  padding: 52px;
}
.pt-52 {
  padding-top: 52px;
}
.pr-52 {
  padding-right: 52px;
}
.pb-52 {
  padding-bottom: 52px;
}
.pl-52 {
  padding-left: 52px;
}
.gap-52 {
  gap: 52px;
}
.row-gap-52 {
  row-gap: 52px;
}
.column-gap-52 {
  column-gap: 52px;
}
.mt-56 {
  margin-top: 56px;
}
.mr-56 {
  margin-right: 56px;
}
.mb-56 {
  margin-bottom: 56px;
}
.ml-56 {
  margin-left: 56px;
}
.p-56 {
  padding: 56px;
}
.pt-56 {
  padding-top: 56px;
}
.pr-56 {
  padding-right: 56px;
}
.pb-56 {
  padding-bottom: 56px;
}
.pl-56 {
  padding-left: 56px;
}
.gap-56 {
  gap: 56px;
}
.row-gap-56 {
  row-gap: 56px;
}
.column-gap-56 {
  column-gap: 56px;
}
.mt-60 {
  margin-top: 60px;
}
.mr-60 {
  margin-right: 60px;
}
.mb-60 {
  margin-bottom: 60px;
}
.ml-60 {
  margin-left: 60px;
}
.p-60 {
  padding: 60px;
}
.pt-60 {
  padding-top: 60px;
}
.pr-60 {
  padding-right: 60px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pl-60 {
  padding-left: 60px;
}
.gap-60 {
  gap: 60px;
}
.row-gap-60 {
  row-gap: 60px;
}
.column-gap-60 {
  column-gap: 60px;
}
.mt-64 {
  margin-top: 64px;
}
.mr-64 {
  margin-right: 64px;
}
.mb-64 {
  margin-bottom: 64px;
}
.ml-64 {
  margin-left: 64px;
}
.p-64 {
  padding: 64px;
}
.pt-64 {
  padding-top: 64px;
}
.pr-64 {
  padding-right: 64px;
}
.pb-64 {
  padding-bottom: 64px;
}
.pl-64 {
  padding-left: 64px;
}
.gap-64 {
  gap: 64px;
}
.row-gap-64 {
  row-gap: 64px;
}
.column-gap-64 {
  column-gap: 64px;
}
.mt-68 {
  margin-top: 68px;
}
.mr-68 {
  margin-right: 68px;
}
.mb-68 {
  margin-bottom: 68px;
}
.ml-68 {
  margin-left: 68px;
}
.p-68 {
  padding: 68px;
}
.pt-68 {
  padding-top: 68px;
}
.pr-68 {
  padding-right: 68px;
}
.pb-68 {
  padding-bottom: 68px;
}
.pl-68 {
  padding-left: 68px;
}
.gap-68 {
  gap: 68px;
}
.row-gap-68 {
  row-gap: 68px;
}
.column-gap-68 {
  column-gap: 68px;
}
.mt-72 {
  margin-top: 72px;
}
.mr-72 {
  margin-right: 72px;
}
.mb-72 {
  margin-bottom: 72px;
}
.ml-72 {
  margin-left: 72px;
}
.p-72 {
  padding: 72px;
}
.pt-72 {
  padding-top: 72px;
}
.pr-72 {
  padding-right: 72px;
}
.pb-72 {
  padding-bottom: 72px;
}
.pl-72 {
  padding-left: 72px;
}
.gap-72 {
  gap: 72px;
}
.row-gap-72 {
  row-gap: 72px;
}
.column-gap-72 {
  column-gap: 72px;
}
.mt-76 {
  margin-top: 76px;
}
.mr-76 {
  margin-right: 76px;
}
.mb-76 {
  margin-bottom: 76px;
}
.ml-76 {
  margin-left: 76px;
}
.p-76 {
  padding: 76px;
}
.pt-76 {
  padding-top: 76px;
}
.pr-76 {
  padding-right: 76px;
}
.pb-76 {
  padding-bottom: 76px;
}
.pl-76 {
  padding-left: 76px;
}
.gap-76 {
  gap: 76px;
}
.row-gap-76 {
  row-gap: 76px;
}
.column-gap-76 {
  column-gap: 76px;
}
.mt-80 {
  margin-top: 80px;
}
.mr-80 {
  margin-right: 80px;
}
.mb-80 {
  margin-bottom: 80px;
}
.ml-80 {
  margin-left: 80px;
}
.p-80 {
  padding: 80px;
}
.pt-80 {
  padding-top: 80px;
}
.pr-80 {
  padding-right: 80px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pl-80 {
  padding-left: 80px;
}
.gap-80 {
  gap: 80px;
}
.row-gap-80 {
  row-gap: 80px;
}
.column-gap-80 {
  column-gap: 80px;
}
.mt-84 {
  margin-top: 84px;
}
.mr-84 {
  margin-right: 84px;
}
.mb-84 {
  margin-bottom: 84px;
}
.ml-84 {
  margin-left: 84px;
}
.p-84 {
  padding: 84px;
}
.pt-84 {
  padding-top: 84px;
}
.pr-84 {
  padding-right: 84px;
}
.pb-84 {
  padding-bottom: 84px;
}
.pl-84 {
  padding-left: 84px;
}
.gap-84 {
  gap: 84px;
}
.row-gap-84 {
  row-gap: 84px;
}
.column-gap-84 {
  column-gap: 84px;
}
.mt-88 {
  margin-top: 88px;
}
.mr-88 {
  margin-right: 88px;
}
.mb-88 {
  margin-bottom: 88px;
}
.ml-88 {
  margin-left: 88px;
}
.p-88 {
  padding: 88px;
}
.pt-88 {
  padding-top: 88px;
}
.pr-88 {
  padding-right: 88px;
}
.pb-88 {
  padding-bottom: 88px;
}
.pl-88 {
  padding-left: 88px;
}
.gap-88 {
  gap: 88px;
}
.row-gap-88 {
  row-gap: 88px;
}
.column-gap-88 {
  column-gap: 88px;
}
.mt-92 {
  margin-top: 92px;
}
.mr-92 {
  margin-right: 92px;
}
.mb-92 {
  margin-bottom: 92px;
}
.ml-92 {
  margin-left: 92px;
}
.p-92 {
  padding: 92px;
}
.pt-92 {
  padding-top: 92px;
}
.pr-92 {
  padding-right: 92px;
}
.pb-92 {
  padding-bottom: 92px;
}
.pl-92 {
  padding-left: 92px;
}
.gap-92 {
  gap: 92px;
}
.row-gap-92 {
  row-gap: 92px;
}
.column-gap-92 {
  column-gap: 92px;
}
.mt-96 {
  margin-top: 96px;
}
.mr-96 {
  margin-right: 96px;
}
.mb-96 {
  margin-bottom: 96px;
}
.ml-96 {
  margin-left: 96px;
}
.p-96 {
  padding: 96px;
}
.pt-96 {
  padding-top: 96px;
}
.pr-96 {
  padding-right: 96px;
}
.pb-96 {
  padding-bottom: 96px;
}
.pl-96 {
  padding-left: 96px;
}
.gap-96 {
  gap: 96px;
}
.row-gap-96 {
  row-gap: 96px;
}
.column-gap-96 {
  column-gap: 96px;
}
.mt-100 {
  margin-top: 100px;
}
.mr-100 {
  margin-right: 100px;
}
.mb-100 {
  margin-bottom: 100px;
}
.ml-100 {
  margin-left: 100px;
}
.p-100 {
  padding: 100px;
}
.pt-100 {
  padding-top: 100px;
}
.pr-100 {
  padding-right: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pl-100 {
  padding-left: 100px;
}
.gap-100 {
  gap: 100px;
}
.row-gap-100 {
  row-gap: 100px;
}
.column-gap-100 {
  column-gap: 100px;
}
.mt-104 {
  margin-top: 104px;
}
.mr-104 {
  margin-right: 104px;
}
.mb-104 {
  margin-bottom: 104px;
}
.ml-104 {
  margin-left: 104px;
}
.p-104 {
  padding: 104px;
}
.pt-104 {
  padding-top: 104px;
}
.pr-104 {
  padding-right: 104px;
}
.pb-104 {
  padding-bottom: 104px;
}
.pl-104 {
  padding-left: 104px;
}
.gap-104 {
  gap: 104px;
}
.row-gap-104 {
  row-gap: 104px;
}
.column-gap-104 {
  column-gap: 104px;
}
.mt-108 {
  margin-top: 108px;
}
.mr-108 {
  margin-right: 108px;
}
.mb-108 {
  margin-bottom: 108px;
}
.ml-108 {
  margin-left: 108px;
}
.p-108 {
  padding: 108px;
}
.pt-108 {
  padding-top: 108px;
}
.pr-108 {
  padding-right: 108px;
}
.pb-108 {
  padding-bottom: 108px;
}
.pl-108 {
  padding-left: 108px;
}
.gap-108 {
  gap: 108px;
}
.row-gap-108 {
  row-gap: 108px;
}
.column-gap-108 {
  column-gap: 108px;
}
.mt-112 {
  margin-top: 112px;
}
.mr-112 {
  margin-right: 112px;
}
.mb-112 {
  margin-bottom: 112px;
}
.ml-112 {
  margin-left: 112px;
}
.p-112 {
  padding: 112px;
}
.pt-112 {
  padding-top: 112px;
}
.pr-112 {
  padding-right: 112px;
}
.pb-112 {
  padding-bottom: 112px;
}
.pl-112 {
  padding-left: 112px;
}
.gap-112 {
  gap: 112px;
}
.row-gap-112 {
  row-gap: 112px;
}
.column-gap-112 {
  column-gap: 112px;
}
.mt-116 {
  margin-top: 116px;
}
.mr-116 {
  margin-right: 116px;
}
.mb-116 {
  margin-bottom: 116px;
}
.ml-116 {
  margin-left: 116px;
}
.p-116 {
  padding: 116px;
}
.pt-116 {
  padding-top: 116px;
}
.pr-116 {
  padding-right: 116px;
}
.pb-116 {
  padding-bottom: 116px;
}
.pl-116 {
  padding-left: 116px;
}
.gap-116 {
  gap: 116px;
}
.row-gap-116 {
  row-gap: 116px;
}
.column-gap-116 {
  column-gap: 116px;
}
.mt-120 {
  margin-top: 120px;
}
.mr-120 {
  margin-right: 120px;
}
.mb-120 {
  margin-bottom: 120px;
}
.ml-120 {
  margin-left: 120px;
}
.p-120 {
  padding: 120px;
}
.pt-120 {
  padding-top: 120px;
}
.pr-120 {
  padding-right: 120px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pl-120 {
  padding-left: 120px;
}
.gap-120 {
  gap: 120px;
}
.row-gap-120 {
  row-gap: 120px;
}
.column-gap-120 {
  column-gap: 120px;
}
/*# sourceMappingURL=src/assets/css/main.css.map */