.paginator
  width: 100%
  display: flex
  justify-content: center
  align-items: center

  &__item
  &__prev
  &__next
    width: 40px
    height: 40px

  &__item
    color: #a2a9b4
    border: 1px solid transparent
    border-radius: 4px
    transition: all .3s ease
    &.is-active
      color: #000

  &__prev
  &__next
    display: flex
    justify-content: center
    align-items: center
    transition: all .3s ease
    &:disabled
      opacity 0.5
    &:before
      content: ''
      width: 0
      height: 0
      border-top: 7px solid transparent
      border-bottom: 7px solid transparent
      border-left: 7px solid

  &__prev
    transform: rotate(180deg)
