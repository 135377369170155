$Montserrat = 'Montserrat', sans-serif
$Avenir = 'AvenirNext', sans-serif
$AdamantXBold = 'AdamantXBold', sans-serif
$PTSans = 'PT Sans', sans-serif

colorBlack = #323232

colorBtnTransparentBorder = #E4E9F1
colorBtnTransparentBgDisabled = #f6f9fe
colorBtnTransparentBgHover = #E4E9F1
colorBtnTransparentColor = #323232
colorBtnTransparentColorDisabled = #798496
colorBtnDangerBg = #ef4444
colorBtnDangerBgDisabled = #ff9a9a
colorBtnDangerBgHover = #dc2626
colorBtnDangerColor = #fff

colorPrimary = #1DA2FF
colorPrimaryHover = #1891e5
colorDanger = #ef4444
colorDangerHover = #dc2626
colorLightBlue = #a8b7cf

colorBorder = #E4E9F1
colorBorderHover = #c9ced5
colorItemBgHoverBlue = #f1f8ff

colorText = #323232
colorIcon = #A8B7CF

colorGray = #e4e9f1
colorDarkGray = #d6dbe4
colorDarkerGray = #798496
colorBgGray = #fcfcff

:root
  --containerSidePadding: 20px
  --headerHeight: 70px

  --height-control-xxs: 20px
  --height-control-xs: 24px
  --height-control-s: 32px
  --height-control-m: 40px
  --height-control-l: 50px

:root
  // Bg
  --colorBg: #FCFCFF
  --colorBgSecondary: #fff
  --colorBgGray: #EFF3FB
  // Text
  --colorText: #323232
  --colorTextSecondary: #717E93
  --colorTextTertiary: #A8B7CF
  // Primary color
  --colorPrimary: #1DA2FF
  --colorPrimary-07: alpha(#1DA2FF, .07)
  --colorPrimary-10: alpha(#1DA2FF, .1)
  --colorPrimary-20: alpha(#1DA2FF, .2)
  --colorPrimary--hover: #1891e5
  --colorDanger: #ef4444
  --colorDanger-10: alpha(#ef4444, .1)
  --colorDanger--hover: #dc2626
  --colorSuccess: #22c55e
  --colorSuccess-10: alpha(#22c55e, .1)
  --colorGray: #717E93
  --colorGray-20: alpha(#717E93, .2)
  --colorGray-10: alpha(#717E93, .1)
  --colorGrayLight: #ACB9CD
  --colorGrayLight-15: alpha(#ACB9CD, .15)
  --colorGrayLight-35: alpha(#ACB9CD, .35)
  --colorGrayLight-55: alpha(#ACB9CD, .55)
  --colorGrayDark: #465164
  // Global
  --colorBorder: #E4E9F1
  // Controls
  --colorControlColor: var(--colorText)
  --colorControlColor--disabled: #717E93
  --colorControlPlaceholderColor: #A8B7CF
  --colorControlBg: #fff
  --colorControlBg--disabled: #fff
  --colorControlBorder: #E4E9F1
  --colorControlBorder--hover: #c9ced5
  --colorControlBorder--focus: var(--colorPrimary)
  --colorControlBorder--disabled: #E4E9F1
  --colorControlIconColor: #000
  --colorControlChevronColor: #717E93
  --colorPopoverBg: #fff
  --colorPopoverItemBg--hover: #F4FAFF
  // Controls sizes
  --sizeControlHeightDefault: 40px
  --sizeControlHeightSmall: 32px
  // Shadows
  --shadowPopover: 0px 16px 31px rgba(10, 35, 94, 0.24), 0px 3px 5px rgba(10, 35, 94, 0.1)
  // Btns
  --colorBtnPrimaryText: #fff
  --colorBtnPrimaryText--hover: #fff
  --colorBtnPrimaryText--disabled: #fff
  --colorBtnPrimaryBg: var(--colorPrimary)
  --colorBtnPrimaryBg--hover: var(--colorPrimary--hover)
  --colorBtnPrimaryBg--disabled: #79c8ff
  --colorBtnPrimaryBorder: transparent
  --colorBtnPrimaryBorder--hover: transparent
  --colorBtnPrimaryBorder--disabled: transparent

  --colorBtnSecondaryText: var(--colorTextSecondary)
  --colorBtnSecondaryText--hover: var(--colorTextSecondary)
  --colorBtnSecondaryText--disabled: var(--colorTextSecondary)
  --colorBtnSecondaryBg: transparent
  --colorBtnSecondaryBg--hover: #EFF3FB
  --colorBtnSecondaryBg--disabled: alpha(#EFF3FB, .3)
  --colorBtnSecondaryBorder: var(--colorControlBorder)
  --colorBtnSecondaryBorder--hover: var(--colorControlBorder--hover)
  --colorBtnSecondaryBorder--disabled: var(--colorControlBorder)

  --colorBtnSecondaryBlueText: var(--colorPrimary)
  --colorBtnSecondaryBlueText--hover: var(--colorPrimary)
  --colorBtnSecondaryBlueText--disabled: #A8B7CF
  --colorBtnSecondaryBlueBg: transparent
  --colorBtnSecondaryBlueBg--hover: #d9ecf9
  --colorBtnSecondaryBlueBg--disabled: alpha(#d9ecf9, .3)
  --colorBtnSecondaryBlueBorder: var(--colorPrimary)
  --colorBtnSecondaryBlueBorder--hover: var(--colorPrimary)
  --colorBtnSecondaryBlueBorder--disabled: #A8B7CF

  --colorBtnDangerText: #fff
  --colorBtnDangerText--hover: #fff
  --colorBtnDangerText--disabled: #fff
  --colorBtnDangerBg: var(--colorDanger)
  --colorBtnDangerBg--hover: var(--colorDanger--hover)
  --colorBtnDangerBg--disabled: #f48484
  --colorBtnDangerBorder: transparent
  --colorBtnDangerBorder--hover: transparent
  --colorBtnDangerBorder--disabled: transparent


// FINALLY NEW VARIABLES

// Btns, badges, tags ... etc
:root
  --primary: #1DA2FF
  --primary-hover: #1891e5
  --primary-active: #1480cc
  --primary-text-inside: #fff
  --primary-alpha-40: alpha(#1DA2FF, .4)

  --semitransparent-blue: alpha(#1DA2FF, .2)
  --semitransparent-blue-hover: alpha(#1DA2FF, .4)
  --semitransparent-blue-active: alpha(#1DA2FF, .6)
  --semitransparent-blue-text-inside: #1DA2FF
  --semitransparent-blue-alpha-40: alpha(#1DA2FF, .4)

  --secondary: #f1f5f9
  --secondary-hover: #e2e8f0
  --secondary-active: #cbd5e1
  --secondary-text-inside: #475569
  --secondary-alpha-60: alpha(#f1f5f9, .60)

  --secondaryBlue: #edf8ff
  --secondaryBlue-hover: #d9ecf9
  --secondaryBlue-active: #c8e8fe
  --secondaryBlue-text-inside: #1DA2FF
  --secondaryBlue-alpha-80: alpha(#edf8ff, .8)

  --green: #0F9D58
  --green-hover: #0F9D58
  --green-active: #0F9D58
  --green-text-inside: #fff
  --green-alpha-40: alpha(#0F9D58, .4)

  --ghost: transparent
  --ghost-hover: #e2e8f0
  --ghost-active: #cbd5e1
  --ghost-text-inside: var(--colorText)
  --ghost-alpha-40: alpha(#e2e8f0, .40)

  --ghost-secondary-text-inside: var(--colorTextSecondary)

  --success: #22c55e
  --success-hover: #16a34a
  --success-active: #15803d
  --success-text-inside: #fff
  --success-alpha-40: alpha(#22c55e, .4)

  --info: #0ea5e9
  --info-hover: #0284c7
  --info-active: #0369a1
  --info-text-inside: #fff
  --info-alpha-40: alpha(#0ea5e9, .4)

  --warning: #f7a835
  --warning-hover: #f79e1d
  --warning-active: #f59812
  --warning-text-inside: #fff
  --warning-alpha-10: alpha(#f7a835, .1)
  --warning-alpha-40: alpha(#f7a835, .4)

  --help: #a855f7
  --help-hover: #9333ea
  --help-active: #7e22ce
  --help-text-inside: #fff
  --help-alpha-40: alpha(#a855f7, .4)

  --danger: #ef4444
  --danger-hover: #dc2626
  --danger-active: #b91c1c
  --danger-text-inside: #fff
  --danger-alpha-40: alpha(#ef4444, .4)

  --contrast: #020617
  --contrast-hover: #1e293b
  --contrast-active: #334155
  --contrast-text-inside: #fff
  --contrast-alpha-40: alpha(#020617, .4)

// Text
:root
  --font-size-heading-xxs: 12px
  --font-size-heading-xs: 16px
  --font-size-heading-s: 20px
  --font-size-heading-m: 24px
  --font-size-heading-l: 28px
  --font-size-heading-xl: 32px
  --font-size-heading-xxl: 36px
  --font-size-heading-xxxl: 40px
  --font-size-text-xxs: 10px
  --font-size-text-xs: 12px
  --font-size-text-s: 14px
  --font-size-text-m: 16px
  --font-size-text-l: 18px
  --font-size-text-xl: 20px
  --font-size-text-xxl: 24px
  --font-size-text-xxxl: 28px

// Spacers
  :root
    --spacer-0: 0
    --spacer-2: 2px
    --spacer-4: 4px
    --spacer-8: 8px
    --spacer-12: 12px
    --spacer-16: 16px
    --spacer-20: 20px
    --spacer-24: 24px
    --spacer-28: 28px
    --spacer-32: 32px
    --spacer-36: 36px
    --spacer-40: 40px
    --spacer-44: 44px
    --spacer-48: 48px
    --spacer-52: 52px
    --spacer-56: 56px
    --spacer-60: 60px
    --spacer-64: 64px
    --spacer-68: 68px
    --spacer-72: 72px
    --spacer-76: 76px
    --spacer-80: 80px


headingXXS(weight = 600)
  font-size: var(--font-size-heading-xxs)
  line-height: 1
  font-weight: weight
headingXS(weight = 600)
  font-size: var(--font-size-heading-xs)
  line-height: 1.2
  font-weight: weight
headingS(weight = 600)
  font-size: var(--font-size-heading-s)
  line-height: 1.4
  font-weight: weight
headingM(weight = 600)
  font-size: var(--font-size-heading-m)
  line-height: 1.34
  font-weight: weight
headingL(weight = 600)
  font-size: var(--font-size-heading-l)
  line-height: 1.2
  font-weight: weight
headingXL(weight = 600)
  font-size: var(--font-size-heading-xl)
  line-height: 1.2
  font-weight: weight
headingXXL(weight = 600)
  font-size: var(--font-size-heading-xxl)
  line-height: 1.3
  font-weight: weight
headingXXXL(weight = 600)
  font-size: var(--font-size-heading-xxxl)
  line-height: 1.4
  font-weight: weight


textXXS(weight = 400)
  font-size: var(--font-size-text-xxs)
  line-height: 1.1
  font-weight: weight
textXS(weight = 400)
  font-size: var(--font-size-text-xs)
  line-height: 1.3
  font-weight: weight
textS(weight = 400)
  font-size: var(--font-size-text-s)
  line-height: 1.4
  font-weight: weight
textM(weight = 400)
  font-size: var(--font-size-text-m)
  line-height: 1.5
  font-weight: weight
textL(weight = 400)
  font-size: var(--font-size-text-l)
  line-height: 1.5
  font-weight: weight
textXL(weight = 400)
  font-size: var(--font-size-text-xl)
  line-height: 1.5
  font-weight: weight
textXXL(weight = 400)
  font-size: var(--font-size-text-xxl)
  line-height: 1.5
  font-weight: weight
textXXXL(weight = 400)
  font-size: var(--font-size-text-xxxl)
  line-height: 1.5
  font-weight: weight
