.el-input
  // Main
  --inputHeight: var(--sizeControlHeightDefault)
  --inputLeftPadding: 10px
  --inputRightPadding: 10px
  --inputBgColor: var(--colorControlBg)
  --inputBgColorDisabled: var(--colorControlBg--disabled)
  // --inputOutlineColorFocus: var(--colorControlOutlineFocus)
  --inputTransition: all .2s
  // Prefix icon
  --inputIconColor: var(--colorControlIconColor)
  --inputPrefixIconWidth: 24px
  --inputPrefixIconSize: 24px
  --inputPrefixIconRightIndent: 8px
  --inputPrefixIconLeftIndent: 10px
  // Suffix icon
  --inputSuffixIconWidth: 24px
  --inputSuffixIconSize: 24px
  --inputSuffixIconRightIndent: 10px
  --inputSuffixIconLeftIndent: 8px
  // Clear btn
  --inputClearBtnSize: 24px
  --inputClearBtnColor: var(--colorControlIconColor)
  --inputClearBtnColorHover: var(--colorDanger)
  // Text
  --inputFS: 16px
  --inputLH: normal
  --inputFW: 400
  --inputTextAlign: left
  --inputTextColor: var(--colorControlColor)
  --inputTextColorDisabled: var(--colorControlColor--disabled)
  --inputPlaceholderFS: 16px
  --inputPlaceholderLH: normal
  --inputPlaceholderFW: 400
  --inputPlaceholderColor: var(--colorControlPlaceholderColor)
  // Border
  --inputBorderColor: var(--colorControlBorder)
  --inputBorder: 1px solid var(--inputBorderColor)
  --inputBorderColorDisabled: var(--colorControlBorder--disabled)
  --inputBorderColorHover: var(--colorControlBorder--hover)
  --inputBorderColorFocus: var(--colorControlBorder--focus)
  --inputBRadiusTLeft: 4px
  --inputBRadiusTRight: 4px
  --inputBRadiusBRight: 4px
  --inputBRadiusBLeft: 4px
  // Size small
  --smallInputHeight: var(--sizeControlHeightSmall)
  --smallInputLeftPadding: 10px
  --smallInputRightPadding: 10px
  --smallInputFS: 14px
  --smallInputLH: normal
  --smallInputPlaceholderFS: 14px
  --smallInputPlaceholderLH: normal
  --smallInputSuffixIconRightIndent: 8px

.el-input

  &__inner
    position: relative
    width: 100%
    height: var(--inputHeight)
    padding: 0 var(--inputRightPadding) 0 var(--inputLeftPadding)
    font-size: var(--inputFS)
    line-height: var(--inputLH)
    font-weight: var(--inputFW)
    color: var(--inputTextColor)
    text-align: var(--inputTextAlign)
    background-color: var(--inputBgColor)
    border: var(--inputBorder)
    border-radius: var(--inputBRadiusTLeft) var(--inputBRadiusTRight) var(--inputBRadiusBRight) var(--inputBRadiusBLeft)
    transition: var(--inputTransition)
    &::placeholder
      font-size: var(--inputPlaceholderFS)
      line-height: var(--inputPlaceholderLH)
      font-weight: var(--inputPlaceholderFW)
      color: var(--inputPlaceholderColor)
    &:not(:focus):hover
      z-index: 1
      border-color: var(--inputBorderColorHover)
    &:focus
      z-index: 2
      border-color: var(--inputBorderColorFocus)
      // outline: 2px solid var(--inputOutlineColorFocus)

    ~/.is-disabled &
      border-color: var(--inputBorderColorDisabled)
      color: var(--inputTextColorDisabled)
      background-color: var(--inputBgColorDisabled)
    ~/.has-double-suffix &
      padding-right: calc( var(--inputSuffixIconLeftIndent) + var(--inputSuffixIconWidth)*2 + var(--inputSuffixIconRightIndent) )

  .el-input__clear
    font-size: var(--inputClearBtnSize)
    color: var(--inputClearBtnColor)
    &:hover
      color: var(--inputClearBtnColorHover)

  &__suffix
  &__prefix
    z-index: 2
    // z-index: 1
    display: flex
    align-items: center
    justify-content: center
    color: var(--inputIconColor)

  &__prefix
    pointer-events: none
    left: var(--inputPrefixIconLeftIndent)
    .ui-icon
      --iconSize: var(--inputPrefixIconSize)
  &--prefix .el-input__inner
    padding-left: calc( var(--inputPrefixIconLeftIndent) + var(--inputPrefixIconWidth) + var(--inputPrefixIconRightIndent) )

  &__suffix
    pointer-events: none
    right: var(--inputSuffixIconRightIndent)
    .ui-icon
      --iconSize: var(--inputSuffixIconSize)
  &--suffix:not(.has-double-suffix) .el-input__inner
    padding-right: calc( var(--inputSuffixIconLeftIndent) + var(--inputSuffixIconWidth) + var(--inputSuffixIconRightIndent) )

  &__suffix-inner
    pointer-events: none
    display: flex
    align-items: center
    // ~/.is-disabled &
    //   pointer-events: none

  &__icon
    width: var(--inputPrefixIconWidth)
    height: auto
    font-size: var(--inputPrefixIconSize)
    line-height: normal
    &:not(.el-icon-circle-close)
      pointer-events: none
    &.el-icon-circle-close
      pointer-events: all
      &:hover
        color: var(--inputClearBtnColorHover)
      ~/.is-disabled &
        pointer-events: none

  &--small
    .el-input
      &__inner
        height: var(--smallInputHeight)
        padding-left: var(--smallInputLeftPadding)
        padding-right: var(--smallInputRightPadding)
        font-size: var(--smallInputFS)
        line-height: var(--smallInputLH)
        &::placeholder
          font-size: var(--smallInputPlaceholderFS)
          line-height: var(--smallInputPlaceholderLH)
      &__suffix
        right: var(--smallInputSuffixIconRightIndent)
      &__icon
        line-height: normal
