@import 'mixins.styl'

.widget-mode-dissalowed .errorPage
  position: relative
  height: auto
  padding: 80px 24px

.errorPage
  position: fixed
  top: 0
  left: 0
  z-index: 99999
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100vh
  background-color: #FCFCFF
  animation: manage-error-appearence .3s ease
  &.widget-mode-dissalowed
    position: relative
    height: auto
    padding: 80px 24px

  &__logo
    cursor pointer
    svg
      width: 120px
      height: 90px

  &__container
    position: relative
    padding: 0 20px
    text-align: center

  &__title
    margin-top: -80px
    font-family: 'AdamantXBold', sans-serif
    font-size: 55px
    line-height: 65px
    color: #6B7085
    margin-bottom: 35px
    +tablet-max()
      margin-bottom: 20px
      margin-top: -40px
      font-size: 30px
      line-height: 40px

  &__number
    font-family: 'AdamantXBold', sans-serif
    font-size: 144px
    line-height: 144px
    color: #ECECEC
    +tablet-max()
      font-size: 70px
      line-height: 70px

  &__paragraph
    font-family: 'AvenirNext', sans-serif
    font-size: 16px
    line-height: 30px
    color: #A8B7CF
    a
      color: #5AA7FF
    +tablet-max()
      font-size: 14px
      line-height: 24px

  &__home-link
    display: inline-block
    margin-top: 50px
    padding: 14px 22px
    font-size: 16px
    line-height: 30px
    font-weight: 500
    color: #8CDCFF
    border-radius: 4px
    border: 1px solid currentColor
    transition: all .3s ease
    +tablet-max()
      margin-top: 30px
    &:hover
      color: #5AA7FF


.container_sheet-error
  margin: 40px auto
  max-width: 650px
  h2
    margin-bottom: 28px
  p
    line-height: 24px
    margin-bottom: 12px
  ul
    list-style-type: auto
    margin-left: 20px
    line-height: 28px

  a
    color: #1da2ff
  .container_sheet-error-button
    font-size: 16px
    font-weight: 600
    color: #fff
    text-transform: uppercase
    background-color: #1da2ff
    border-radius: 4px
    padding: 12px 20px
    margin: 20px 0 20px
    display: block
    min-width: 250px
    min-height: 44px
  .container_sheet-error-text
    margin: 20px 0 20px
  & .spread-form
    margin-top: 32px
  & .spread-form__form-elements
    margin-left 0
    max-width: 100%