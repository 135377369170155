.nps-popup
  position: fixed
  bottom: 24px
  right: 100px
  width: 390px
  padding: 16px 20px
  border-radius: 4px
  background-color: var(--colorBgSecondary)
  box-shadow: var(--shadowPopover)

  &__head
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 12px

  &__title-wrap
    display: flex
    align-items: flex-start
    justify-content: space-between
    gap: 8px
    width: 100%

  &__close-btn
    transform: translateY(3px)

  &__title
    textL(700)

  &__subtitle
    textS(500)

  &__form
    width: 100%
    margin-top: 24px

  &__form-item
    width: 100%

  &__form-item-label-wrap
    display: flex
    align-items: center
    justify-content: space-between
    gap: 8px
    margin-top: 4px

  &__form-item-label
    textXS(500)
    color: var(--colorTextSecondary)

  &__form-submit-btn
    margin-top: 24px

  &__radio-group
    display: flex
    justify-content: space-between
    gap: 4px

  &__radio
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: var(--height-control-s)
    textL(500)
    color: var(--secondary-text-inside)
    text-align: center
    background-color: var(--secondary)
    border-radius: 4px
    transition: all .2s
    +mediaHover()
      &:not(.is-active):hover
        background-color: var(--secondary-hover)
    &:not(.is-active):active
      background-color: var(--secondary-active)
    &.is-active
      background-color: var(--primary)
      color: var(--primary-text-inside)


.nps-popup-enter-active, .nps-popup-leave-active
  transition: all .3s ease-in-out
.nps-popup-enter, .nps-popup-leave-to
  opacity: 0
  transform: translateY(100px)
