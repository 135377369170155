.viewer-bar
  position: relative
  display: flex
  align-items: center
  justify-content: space-between
  gap: 16px
  flex: none
  width: 100%
  height: 40px
  padding: 0 20px
  background-color: var(--colorBg)
  border-radius: 4px 4px 0 0

  &__circles
    display: flex
    align-items: center
    flex: none

  &__circle
    width: 12px
    height: 12px
    margin-right: 8px
    background-color: colorDarkGray
    border-radius: 50%
    &:last-of-type
      margin-right: 0

  &__field
    position relative
    display: flex
    align-items: center
    flex: 1
    height: 28px
    padding: 0 15px
    font-size: 14px
    background-color: #fff
    border: 1px solid var(--colorBorder)
    border-radius: 4px
    overflow: hidden
    ~/.is-mobile-view &
        padding: 0 8px

  &__input-wrap
    display: flex
    align-items: center
    min-width: 0

  &__input-prepend
    max-width: 100%
    color: var(--colorPrimary)
    font-size: 14px
    font-weight: 500
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    ~/.is-mobile-view &
      font-size: 10px
    &--blur
      filter: blur(2px)
      user-select: none

  &__input
    font-size: inherit
    color: var(--colorText)

  &__input-append
    padding: 1px 5px
    color: var(--colorPrimary)
    border-radius: 4px
    background-color: rgba(177, 220, 255, 0.2)

  &__submit
    position absolute
    top: 50%
    right: 5px
    transform: translateY(-50%)
    padding: 2px 14px
    border-radius: 2px
    color: white
    text-transform: uppercase
    font-size: 12px
    font-weight: 600
    background-color: var(--colorPrimary)
    transition: all 0.3s ease
    ~/.is-mobile-view &
        width: calc(100% - 10px)
    &:hover
      background-color: colorPrimaryHover

  &__switchers
    display: flex
    align-items: center
    flex: none
    gap: 4px

  &__switcher
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: 28px
    height: 28px
    font-size: 18px
    padding-left: 1px
    color: var(--colorTextSecondary)
    border-radius: 4px
    transition: all .2s
    input
      display: none
    &:hover
      background-color: var(--colorPrimary-10)
      // color: var(--colorGrayDark)
    &.is-active
      background-color: var(--colorPrimary)
      color: #fff
