.code-editor-form
  width: 100%
  padding: 24px 24px 32px

  &__item
    width: 100%

  &__btns
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 12px
    margin-top: 24px
