.available-websites
  width: 100%
  color: #323232
  font-family: 'Avenir Next Cyr'

  &__section
    margin-top: 16px
    padding: 16px 0

  &__section-title
    font-size: 16px
    line-height: 20px
    font-weight: 600

  &__section-info
    padding: 16px 0
    &--regular
      font-weight: 600
    &--archived
      color: #717E93
      font-weight: 400

  &__section-progress-bar
    height: 8px
    background-color: #E4E9F1
    border-radius: 4px
    overflow: hidden
    margin-bottom: 16px
    &-fill
      height: inherit
      background-color: #1DA2FF
    &-fulfilled
      background-color: #FF4D4D
  &__section-warning
    display: flex
    justify-content: flex-start
    align-items: center
    &-icon
      margin-right: 10px
    &-message
      font-size: 12px
      color: #465164
