.iframe-code
  width: 100%
  padding: 0 30px 30px;

  &__header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 13px

  &__label
    font-size: 12px
    line-height: 18px
    font-weight: 500
    color: #465164

  &__footer
    display: flex
    justify-content: flex-end

  &__copy-button
    display: flex
    align-items: center
    font-size: 14px
    line-height: 14px
    font-weight: 500
    color: colorLightBlue
    transition: all .3s ease
    margin-top: 10px
    &:hover
      color: colorPrimary

  &__copy-button-icon
    margin-left: 8px
    font-size: 14px
    line-height: 1

  &__textarea
    display: block
    width: 100%
    height: 90px
    padding: 8px
    font-size: 14px
    line-height: 20px
    font-family monospace
    line-height: normal
    color: #3b3b3b
    background-color: #f6f8fa
    border: 1px solid colorGray
    border-radius: 4px
    resize: none
    outline: none

  &__settings
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 17px
    padding: 0 40px 20px

  &__settings-img
    position: absolute
    top: calc(100% - 15px)
    left: 70px
    width: calc(100% - 140px)

  &__setting-field
    display: flex
    align-items: center
    font-size: 14px
    line-height: normal
    font-weight: 500
    color: colorDarkerGray

  &__setting-field-icon
    font-size: 12px
    color: colorLightBlue
    line-height: normal
    &.icon-direct-arrow-horizontal
      margin-left: -10px
      font-size: 7px

  &__setting-field-input
    width: 42px
    font-size: 14px
    line-height: normal
    font-weight: 500
    color: currentColor
    text-align: center
