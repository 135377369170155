.aside-control
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 100%
  min-height: 75px
  padding: 0 5px
  color: colorLightBlue
  border-top: 1px solid transparent
  border-bottom: 1px solid transparent
  background-color: transparent
  transition: all .3s ease

  &:hover
    color: colorPrimary

  &.active
    color: colorPrimary
    border-bottom: 1px solid colorBorder
    border-top: 1px solid colorBorder
    & .aside-control__border
      background-color: colorBgGray

  &__title
    margin-top: 2px
    font-size: 12px
    line-height: 14px
    font-weight: 500

  &__icon
    font-size: 25px

  &__border
    position: absolute
    top: 0
    right: -1px
    display: block
    width: 1px
    height: 100%
    background-color: colorGray
    transition: all .3s ease
