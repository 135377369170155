.spreadview
  position: relative
  border-radius: 4px
  background-color: #fff
  box-shadow: 0px 16px 31px rgba(10, 35, 94, 0.12), 0px 3px 5px rgba(10, 35, 94, 0.06)
  &.is-active
    z-index: 12

  // &:hover
  // &.hover
  //   .spreadview__dropdown.for-overlay
  //   .spreadview__dropdown.for-manage-plans
  //   .spreadview__edit-btn
  //     opacity: 1
  //   .spreadview__overlay
  //     background-color: alpha(#1C1F22, .8)

  &__manage-plans-btn
    display: flex
    align-items: center
    gap: 4px
    width: fit-content
    height: 26px
    padding: 0 10px
    font-size: 12px
    line-height: 14px
    font-weight: 500
    color: #fff !important
    background-color: #444C58
    border-radius: 4px
    transition: all .2s
    i
      font-size: 14px
    &:hover
    &.is-menu-opened
      background-color: colorPrimary

  &__plan-tag
    margin-bottom: 6px

  &__img
    position: relative
    width: 100%
    height: 200px
    border-radius: 4px 4px 0 0
    background-size: cover
    background-position: top center
    background-repeat: no-repeat
    overflow: hidden
    &.is-archived
      .spreadview__dropdown.for-overlay
        opacity: 1
      .spreadview__overlay
        background-color: alpha(#2f6295, .9)
        &:after
          content: '\e179'
          absCenter()
          font-family: 'Material Icons'
          color: #fff
          font-size: 64px
        // &:before
        //   content: 'Item is archived'
        //   absTL()
        //   font-size: 12px
        //   color: #fff

  &__upgrade-btn
    position: absolute
    top: 16px
    left: 20px
    z-index: 1
    display: flex
    align-items: center
    width: fit-content
    height: 26px
    padding: 0 10px
    font-size: 12px
    line-height: 14px
    font-weight: 500
    color: #fff !important
    background-color: #444C58
    border-radius: 4px
    transition: all .2s
    &:before
      content: '\e95d'
      font-family: 'icomoon'
      font-size: 12px
      margin-right: 8px
    &:hover
      background-color: colorPrimary

  &__overlay
    position: absolute
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    background-color: alpha(#1C1F22, .8)
    background-color: rgba(107,138,167,.5)
    backdrop-filter: blur(4px)
    opacity: 0
    transition: all .2s
    ~/:hover &
    ~/.hover &
      opacity: 1

  &__edit-btn
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    width: fit-content
    height: 40px
    padding: 0 20px
    font-size: 14px
    line-height: 20px
    font-weight: 600
    color: #fff
    text-transform: uppercase
    text-align: center
    border-radius: 4px
    background-color: colorPrimary
    transition: all .2s
    &:hover
      background-color: colorPrimaryHover

  &__dropdown
    &.for-overlay
      position: absolute
      top: 16px
      right: 16px
    &.for-manage-plans
      position: absolute
      top: 16px
      left: 16px

  &__dropdown-trigger
    width: 26px
    height: 26px
    display: flex
    align-items: center
    justify-content: center
    font-size: 16px
    color: #fff
    border-radius: 4px
    transition: all .2s
    &:not(.for-tag-list)
      &:hover
      &.is-menu-opened
        background-color: rgba(255,255,255,.3)
        // background-color: #616A78
    // &.for-tag-list
    //   color: #A8B7CF
    //   &:hover
    //     color: #616A78
    //     background-color: #D5E5F5

  &__dropdown-menu.el-dropdown-menu
    display: flex
    flex-direction: column
    align-items: flex-start
    width: 240px
    --dropdownItemPadding: 0
    .popper__arrow
      display: none
    &.for-tag-list
      width: 140px
    &.for-manage-plans
      width: 190px

  &__dropdown-menu-divider
    width: 100%
    height: 1px
    margin: 8px 0
    background-color: #e4e9f1

  &__dropdown-item
    display: block
    width: 100%
    padding: 10px
    text-align: left
    color: inherit
    &.subscribe__button
      margin-right: 0
      font: inherit
      color: #323232 !important

  &__body
    display: flex
    flex-direction: column
    align-items: flex-start
    width: 100%
    padding: 16px 20px

  &__title.title-editor
    --font-size: 16px
    --line-height: 22px
    --font-weight: 500
    --field-height: 22px
    --icon-size: 14px
    --icon-color: var(--colorText)
    --icon-color--hover: var(--colorPrimary)
    width: 100%
    margin-bottom: 6px
    &.is-editing .title-editor__form
      border-bottom-color: var(--colorPrimary)
    .title-editor__form
      border-bottom: 1px dashed transparent
    .title-editor__error
      position: static
      margin-top: 2px
    &:not(.is-editing)
      .title-editor__btn-edit
        opacity: 0
      &:hover .title-editor__btn-edit
        opacity: 1

  &__title-link
    cursor: pointer
    transition: color .2s
    &.is-disabled
      cursor: not-allowed
    +mediaHover()
      &:not(.is-disabled):hover
        color: var(--colorPrimary)

  &__link
    display: flex
    align-items: center
    max-width: 100%
    color: #000
    transition: color .2s
    &:hover
      color: colorPrimary

  &__link-title
    max-width: 100%
    font-size: 12px
    line-height: 14px
    font-weight: 500
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  &__link-icon
    flex: none
    margin-left: 6px

  &__indicator
    font-size: 12px
    line-height: 14px
    font-weight: 500
    color: #A8B7CF

  &__footer
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    margin-top: 24px

  &__tags-wrap
    display: flex
    align-items: center
    width: 100%
    overflow: hidden
    &.is-archived
      opacity: .5

  &__tags
    display: flex
    align-items: center
    max-width: 100%
    overflow: hidden

  &__tags-icon
    margin-right: 8px
    color: #a8b7cf

  &__tag
    max-width: 100%
    padding: 4px 8px
    margin-right: 8px
    font-size: 12px
    line-height: normal
    font-weight: 500
    color: #616A78
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    border-radius: 2px
    background-color: #EFF3FB

  &__tags-add-btn
    flex: none
    display: flex
    align-items: center
    justify-content: center
    width: 23px
    height: 23px
    font-size: 10px
    color: #616A78
    white-space: nowrap
    border: 1px solid #E4E9F1
    border-radius: 2px
    transition: all .2s
    &:not(.is-counter):hover
      background-color: #E4E9F1
      border-color: #E4E9F1
    &.is-counter
      width: auto
      padding: 0 8px
      font-size: 12px
      font-weight: 500
      background-color: #EFF3FB
      border-color: #EFF3FB
      &:hover
        background-color: colorPrimary
        border-color: colorPrimary
        color: #fff

  &__tag-popover-menu
    width: 240px
    padding: 12px 0
    max-height: 345px
    overflow-y: auto
    overflow-x: hidden
    scrollbar-width: thin
    scrollbar-color: #d9dfe9 #fff
    -webkit-overflow-scrolling: touch
    &::-webkit-scrollbar
      width: 8px
    &::-webkit-scrollbar-track
      background-color: #fff
    &::-webkit-scrollbar-thumb
      background-color: #d9dfe9
      border-radius: 20px
      border: 2px solid #fff
    // scrollbar-width: none
    // -ms-overflow-style: none
    // &::-webkit-scrollbar
    //   display: none

  &__tag-popover-item
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    transition: all .2s
    &:hover .spreadview__dropdown
      opacity: 1

  &__tag-popover-item-checkbox.el-checkbox
    width: 100%
    padding: 10px 14px
    overflow: hidden
    transition: all .2s
    &:hover
      background-color: colorItemBgHoverBlue
    .el-checkbox__label
      max-width: 100%
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap

  &__tag-popover-search
    width: 100%
    padding: 0 14px
    margin-bottom: 10px

  &__tag-popover-search-msg
    font-size: 10px
    line-height: 16px
    color: colorDanger

  &__tag-popover-search-input
    width: 100%
    height: 40px
    padding: 0 12px
    font-size: 16px
    line-height: 40px
    font-weight: 400
    border-radius: 4px
    border: 1px solid colorBorder
    background-color: #fff
    transition: all .2s
    &::placeholder
      color: #A8B7CF
    &:not(:focus):hover
      border-color: colorBorderHover
    &:focus
      border-color: colorPrimary

  &__tag-popover-add-item
    width: 100%
    padding: 10px 14px
    font-size: 16px
    font-weight: 400
    color: colorText
    text-align: left
    transition: all .2s
    &:disabled
      cursor: not-allowed
      opacity: .4
    span
      color: colorPrimary
      font-weight: 500
      word-break: break-word
    &:not(:disabled):hover
      background-color: colorItemBgHoverBlue

  &__revision-indicator
    --avatarSize: 24px
    --avatarFS: 10px
    margin-left: 8px
