.avatar
  --avatarSize: 36px
  --avatarFS: 20px

  display: flex
  align-items: center
  justify-content: center
  flex: none
  width: var(--avatarSize)
  height: var(--avatarSize)
  font-size: var(--avatarFS)
  font-weight: 400
  color: #fff
  text-transform: uppercase
  border-radius: 50%
  background-color: #AE4EAA
