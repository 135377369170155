.sv-md-editor.v-md-editor
  position: relative
  box-shadow: none
  background-color: transparent

  & .v-md-editor
    font-family: $Avenir

    &__toolbar
      padding: 0
      background-color: transparent
      border: none

    &__toolbar-left
      margin-bottom: -4px

    &__toolbar-right
      display: none

    &__toolbar-item
      display: flex
      align-items: center
      justify-content: center
      font-family: $Avenir !important
      margin-right: 4px
      margin-bottom: 4px
      font-family: inherit
      color: #59677F
      border: 1px solid #E4E9F1
      &:not(.v-md-editor__toolbar-item--menu)
        padding: 0 5px
      &:not(:first-child)
        margin-left: 0
      &:before
        font-family: 'v-md-iconfont' !important
      &.text-plus-icon
        padding-left: 10px
        padding-right: 10px
        font-size: 14px
        font-weight: 500
        &.icon-notion:before
          margin-right: 7px
          font-family: 'icomoon' !important
          font-size: 16px
          color: #000
      &.icon-cart-to-list:before
        font-family: 'icomoon' !important
        font-size: 22px
        margin: 0 0px 0 2px

    &__toolbar-divider
      margin-left: 6px

    &__menu
      left: auto !important
      right: 0 !important

    &__main
      margin-top: 5px
      border: 1px solid #E4E9F1
      border-radius: 4px
      // background-color: #fff

  & .v-md-textarea-editor pre, .v-md-textarea-editor textarea
    padding: 10px 15px 25px
    font-family: inherit
    font-size: 14px
    line-height: 24px
    color: #323232
    background-color: transparent
    &::placeholder
      font-size: 16px
      line-height: 22px
      color: #ACB9CD
