.tag
  display: inline-flex
  align-items: center
  width: fit-content
  font-weight: 500
  text-transform: capitalize
  border-radius: 4px
  border: 1px solid transparent
  white-space: nowrap
  &--clickable
    cursor: pointer

  &--color-success
    color: var(--colorSuccess)
    background-color: var(--colorSuccess-10)
    // border-color: var(--colorSuccess)
  &--color-primary
    color: var(--colorPrimary)
    background-color: var(--colorPrimary-10)
    // border-color: var(--colorPrimary)
  &--color-secondary
    color: var(--colorGray)
    background-color: var(--colorGrayLight-15)
    // border-color: var(--colorGrayLight)
  &--color-danger
    color: var(--colorDanger)
    background-color: var(--colorDanger-10)
    // border-color: var(--colorDanger)

  &--size-xxs
    height: var(--height-control-xxs)
    padding: 0 5px
    font-size: 12px
    line-height: 14px
  &--size-xs
    height: var(--height-control-xs)
    padding: 0 8px
    font-size: 14px
    line-height: 18px
  &--size-s
    height: var(--height-control-s)
    padding: 0 8px
    font-size: 14px
    line-height: 16px
  &--size-m
    height: var(--height-control-m)
    padding: 0 10px
    font-size: 16px
    line-height: 18px
