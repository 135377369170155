.ui-helper
  display: inline-flex
  align-items: center
  gap: 4px
  width: fit-content

  &--direction-row
    flex-direction: row
    text-align: left

  &--direction-row-reverse
    flex-direction: row-reverse
    text-align: right

  &__label
    textS()
    color: #465164

  &__popover-wrap
    display: inline-flex
    align-items: center
    .el-popover__reference-wrapper
      display: inline-flex
      align-items: center

  &__popover-trigger
  &__icon
    font-size: 18px
    color: var(--colorTextTertiary)

  &__popover-trigger
    cursor: pointer
    outline: none !important
    transition: color .2s
    &--highlighted
      color: var(--colorTextSecondary)
    +mediaHover()
      &:not(.ui-helper__popover-trigger--highlighted):hover
        color: var(--colorTextSecondary)

  &__popover
    &--type-tooltip
      max-width: 250px
    &--type-video
      --popoverPaddings: 0
      --popoverBorder: none
      max-width: 360px
      overflow: hidden
    // &--type-custom
    //   max-width: 360px

  &__popover-vieo-wrap
    width: 100%

  &__popover-video
    width: 100%
    min-height: 200px

  &__popover-video-meta
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 16px
    width: 100%
    padding: 16px

  &__popover-video-label
    textS()

  &__popover-video-btns
    display: flex
    align-items: center
    gap: 12px
    width: 100%
    >*
      flex: 1

  &__popover-close
    absTR(6px, 6px)
    display: flex
    align-items: center
    justify-content: center
    font-size: 16px
    width: var(--height-control-xs)
    height: var(--height-control-xs)
    border-radius: 4px
    background-color: alpha(#A8B7CF, .45)
    color: var(--colorText)
    // background-color: alpha(#1DA2FF, .15)
    // color: var(--primary)
    backdrop-filter: blur(3px)
    transition: all .2s
    +hover()
      background-color: alpha(#A8B7CF, .65)
    &:active
      background-color: alpha(#A8B7CF, .85)
