.page-video
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 100%
  height: 100vh
  padding: 32px
  overflow: hidden
  background-color: #98a4b9
  // background-color: var(--colorBgGray)
  // background-color: #041621

  &__container
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    max-width: 1440px
    min-height: 0
    flex: 1

  &__video
    width: auto
    max-width: 100%
    max-height: 100%
    border-radius: 8px
    box-shadow: var(--shadowPopover)
