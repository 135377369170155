a
  text-decoration: none
  color: inherit
  &:hover,
  &:active,
  &:focus,
  &:visited
    text-decoration: none
    outline: none

button
  cursor: pointer
  -webkit-appearance: none
  background-color: transparent
  outline: none
  border: none
  padding: 0
  cursor: pointer
  &:hover,
  &:active,
  &:focus,
  &:visited
    outline: none

input
  border: none
  background-color: transparent
  padding: 0
  -webkit-appearance: none
  &:hover,
  &:active,
  &:focus,
  &:visited
    outline: none

ul
  margin: 0
  padding: 0
  list-style-type: none
a
  text-decoration: none
  color: inherit
  &:hover,
  &:active,
  &:focus,
  &:visited
    text-decoration: none
    outline: none

button
  cursor: pointer
  -webkit-appearance: none
  background-color: transparent
  outline: none
  border: none
  padding: 0
  cursor: pointer
  &:hover,
  &:active,
  &:focus,
  &:visited
    outline: none

input
  border: none
  background-color: transparent
  padding: 0
  -webkit-appearance: none
  &:hover,
  &:active,
  &:focus,
  &:visited
    outline: none
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0
  &[type="number"]
    -moz-appearance: textfield
    appearance: textfield
    margin: 0

ul
  margin: 0
  padding: 0
  list-style-type: none

h1,h2,h3,h4,h5,h6,p
  margin: 0;
  font-weight: normal;

img
  max-width: 100%;
  max-height: 100%;

*
  box-sizing: border-box;

code
  font-size: inherit;
