.team-dialog
  --dialogMaxWidth: 620px
  --dialogTitlePaddingRight: 0
  &.is-countdown-view
    --dialogMaxWidth: 415px
    .el-dialog
      transition-delay: .2s
  .el-dialog
    transition: max-width .2s

  &__container
    display: flex
    flex-direction: column
    align-items: center
    &>*+*
      margin-top: 24px

  &__title
    font-size: 24px
    line-height: 34px
    font-weight: 700
    text-align: center

  &__users-wrap
    position: relative
    display: flex
    align-items: center
    height: 38px
    font-size 38px
    // &>*+*
    //   margin-left: 8px
    // &:after
    //   content: ''
    //   display: block
    //   // absTR(0,-25px)
    //   width: 36px
    //   height: 38px
    //   margin-left: -10px
    //   background-image: url(/img/wow-emoji.png)
    //   bgImgCenter()

  &__string
    font-size: 16px
    line-height: 22px
    &.align-center
      mw(351)
      text-align: center
    span
      font-weight: 600
      color: var(--colorPrimary)

  &__btns
    display: flex
    margin-top: 32px
    &>*+*
      margin-left: 8px

  &__timer
    position: relative
    display: flex
    align-items: center
    justify-content: center
    .value
      absCenter()
      font-size: 24px
      line-height: 45px
      font-weight: bold
      color: var(--colorPrimary)
    .el-progress__text
      display: none
