.el-select
  --selectChevronColor: var(--colorControlChevronColor)
  --selectBorderColor: var(--colorControlBorder)
  --selectBorderColorHover: var(--colorControlBorder--hover)
  --selectBorderColorFocus: var(--colorControlBorder--focus)
  --selectChevronSize: 20px

  --smallSelectRightPadding: 24px
  --smallSelectChevronSize: 16px

  --selectTagMargin: 2px
  --selectTagFS: 12px
  --selectTagLH: 27px
  --selectTagFW: 500
  --selectTagColor: var(--colorText)
  --selectTagBg: var(--colorBorder)
  --selectTagBorder: 1px solid transparent
  --selectTagBRadius: 4px
  --selectTagCloseFS: 16px
  --selectTagCloseColor: var(--colorTextSecondary)
  --selectTagCloseColorHover: var(--colorDanger)
  --selectTagCloseBg: transparent
  --selectTagCloseBgHover: transparent

.el-select
  width: 100%

  .el-input
    &:hover .el-input__inner
      border-color: var(--selectBorderColorHover)
    &.is-focus .el-input__inner
      border-color: var(--selectBorderColorFocus)
      // outline: var(--selectOutlineFocus)
      &:hover
        border-color: var(--selectBorderColorFocus)
    &__inner
      &:focus
        border-color: var(--selectBorderColor)
        // outline: none
        &:hover
          border-color: var(--selectBorderColorHover)

    .el-select__caret
      font-size: var(--selectChevronSize)
      color: var(--selectChevronColor)
      // &:before
      //   content: '\e90c'
      //   font-family: 'icomoon'
      // &:after
      //   content: none

  &--small
    .el-input--small.el-input

      .el-input__inner
        padding-right: var(--smallSelectRightPadding)

      .el-select__caret
        font-size: var(--smallSelectChevronSize)

  // &__caret
  //   &:before
  //     content: '\e90c'
  //     font-family: 'icomoon'

  &__tags
    padding-left: 10px
    z-index: 3
    &>span
      display: flex
      flex-wrap: wrap
      margin: -2px

  .el-tag
    margin: var(--selectTagMargin)
    font-size: var(--selectTagFS)
    line-height: var(--selectTagLH)
    font-weight:  var(--selectTagFW)
    color: var(--selectTagColor)
    background-color: var(--selectTagBg)
    border-radius: var(--selectTagBRadius)
    &.el-tag.el-tag--info
      border: var(--selectTagBorder)

    &--small
      height: 28px
      padding: 0 10px 0 6px

    &--mini
      height: 24px
      padding: 0 8px

    &__close.el-icon-close
      font-size: var(--selectTagCloseFS)
      color: var(--selectTagCloseColor)
      background-color: var(--selectTagCloseBg)
      &:hover
        color: var(--selectTagCloseColorHover)
        background-color: var(--selectTagCloseBgHover)
