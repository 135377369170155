.manage-header
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 11
  width 100%
  background-color: colorBgGray
  border-bottom: 1px solid colorGray
  box-shadow:  none
  transition: all .3s ease
  &.scrolled
    box-shadow:  0px 5px 5px 0px rgba(50, 50, 50, 0.05)

  &__container
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    width 100%
    max-width 100%
    min-height: 70px
    margin 0 auto
    padding 0 15px

  &__left-side
  &__right-side
    display: flex
    flex-direction: row
    align-items: center
    gap: 12px

  &__right-side-slot
    display: flex
    align-items: center
    gap: 12px

  &__logo
    display: flex
    align-items: flex-start

  &__controls
    display: flex
    align-items: center
    gap: 15px

  &__meta
    display: flex
    flex-direction: row
    align-items: center
    gap: 12px

  &__meta-badges
    display: flex
    align-items: center
    gap: 12px

  &__subscribe-cta
    position: relative
    cursor: pointer
    display: flex
    align-items: center
    padding: 0 12px 2px
    font-size: 12px
    height: var(--height-control-xxs)
    font-weight: 600
    color: var(--colorPrimary)
    background-color: var(--colorPrimary-10)
    border: 1px solid var(--colorPrimary)
    border-radius: 4px
    overflow: hidden
    transition: all .2s
    +hover()
      background-color: var(--colorPrimary-20)
    &:before
      content: ''
      position: absolute
      top: -40px
      left: -40px
      transform: rotate(-45deg)
      width: 100%
      height: 40px
      background-color: var(--colorPrimary)
      opacity: .1
      filter: blur(7px)
      animation: subscribe-cta-shimmer 1.5s linear infinite
    // color: var(--colorPrimary)
    // border-radius: 4px
    // transition: all .2s
    // cursor: pointer
    // +hover()
    //   background-color: var(--colorPrimary-10)
    // &:active
    //   background-color: var(--colorPrimary-20)

  &__auth
    position: relative
    margin-left: 30px
    padding: 3px 0
    font-size: 13px
    font-weight: 600
    color: #828282
    border-bottom: 1px solid transparent
    transition: all .3s ease
    &:hover
      border-color: rgba(0,0,0,.2)

  &__menu-btn
    cursor: pointer
    position: relative
    z-index: 11
    width: 16px
    height: 10px
    margin-left: 20px

    &:before
    &:after
      content: ''
      position: absolute
      left: 0
      width: 16px
      height: 2px
      border-radius: 1px
      background-color: colorBlack
      transition: all .3s ease
    &:before
      top: 0
    &:after
      bottom: 0

    &.menu-opened
      z-index: 11
      &:before
        top: 3px
        transform: rotate(-45deg)
      &:after
        bottom: 5px
        transform: rotate(45deg)


@keyframes subscribe-cta-shimmer
  0%
    top: -40px
    left: -40px
  50%
    top: 30px
    left: 50px
  100%
    top: 30px
    left: 50px
