.modal-pro-options
  color: colorBlack
  padding: 17px 30px 38px
  text-align: center;
  & p
    padding: 5px 0
    font-size: 14px
    line-height: 18px
    font-weight: 400
  & p a
    font-size: 14px
    line-height: 18px
    font-weight: 600
    color: colorPrimary

  &__button
    margin: 10px 10px 20px
    padding: 5px 20px
    font-size: 14px
    font-weight: 600
    line-height: 30px
    letter-spacing: 0.3px
    text-transform: uppercase
    color: #fff
    background-color: colorPrimary
    border-radius: 4px
    transition: background-color .3s ease
    &:disabled
      opacity: 0.5
      &:hover
        background-color: colorPrimary
    &:hover
      background-color: colorPrimaryHover
    &.negative
      color: #798496
      border: 1px solid #CAD3E0
      background-color: transparent
      &:hover
        color: #fff
        background-color: #CAD3E0

.subscribe__item-qty-text
  flex: none
  display inline-block
  vertical-align: top
  margin: 10px 5px 0
  line-height: 40px
  font-size: 14px
  font-weight 600

.subscribe__item-qty
  flex: none
  display inline-block
  vertical-align: top
  margin: 10px 10px 20px
  & .int-pm
    font-size: 14px
    font-weight: 600
    text-align: center
    border: 1px solid
    border-radius: 4px
    overflow: hidden
    border: 1px solid #CAD3E0

    & .int-pm-btn
    & .int-pm-value
      height: 40px
      padding: 0
      line-height: 40px
      border: none

    & .int-pm-increment
    & .int-pm-decrement
      position: relative
      width: 40px
      font-size: 0px

    & .int-pm-decrement:before
    & .int-pm-increment:before
      position: absolute
      top: 0
      left: 0
      z-index: 1
      width: 100%
      height: 100%
      font-family: 'icomoon'
      font-size: 16px
    & .int-pm-decrement:before
      content: '\e940'
    & .int-pm-increment:before
      content: '\e941'

    & .int-pm-value
      min-width: 40px
      outline: none

.subscribe__button
  white-space: nowrap
  margin: 0 20px 0 0
  font-size: 12px
  font-weight: 600
  color: colorPrimary
  &.btn
    margin: 25px 10px 20px
    padding: 5px 20px
    font-size: 14px
    font-weight: 600
    line-height: 30px
    letter-spacing: 0.3px
    text-transform: uppercase
    color: #fff
    background-color: colorPrimary
    border-radius: 4px
    transition: background-color .3s ease
    &:disabled
      opacity: 0.5
    &.display-block
      display: block
      margin: 20px auto 0
    &:hover
      background-color: colorPrimaryHover
    &.negative
      color: #798496
      // border: 1px solid #CAD3E0
      background-color: transparent
      &:hover
        color: #fff
        background-color: #CAD3E0

.manage-header
  & .subscribe__button
    margin: 0 0 0 20px
    font-size: 13px
    padding: 3px 0

.wrap_pro_option
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
