.el-checkbox
  --checkWidth: fit-content

  --checkInnerWidth: 20px
  --checkInnerHeight: 20px
  --checkInnerDisabledColor: var(--colorTextTertiary)
  --checkInnerBorder: 1px solid var(--colorGrayLight)
  --checkInnerBorder--hover: 1px solid var(--colorPrimary)
  // --checkInnerBorder--active: 1px solid var(--colorPrimary)
  --checkInnerBorder--active: 1px solid alpha(#000, .2)
  --checkInnerDisabledBorder: 1px solid var(--colorGrayLight)
  --checkInnerBorderRadius: 2px
  --checkInnerBg: transparent
  --checkInnerActiveBg: var(--colorPrimary)
  --checkInnerDisabbledBg: transparent

  --checkLabelLeftIndent: 10px
  --checkLabelFS: 14px
  --checkLabelLH: 18px
  --checkLabelFW: 400
  --checkLabelColor: var(--colorText)
  --checkLabelActiveColor: var(--colorText)
  --checkLabelHoverColor: var(--colorText)
  --checkLabelDisabledColor: var(--colorTextSecondary)
  --checkLabelTransition: color .2s
  +.el-checkbox
    margin: 0

.el-checkbox
  display: flex
  align-items: center
  width: var(--checkWidth)
  &:not(.is-disabled):hover
    .el-checkbox__input:not(.is-checked) .el-checkbox__inner
      border: var(--checkInnerBorder--hover) !important
    .el-checkbox__label
      color: var(--checkLabelHoverColor)
  &.is-disabled
    cursor: not-allowed

  &__label
    padding-left: var(--checkLabelLeftIndent)
    font-size: var(--checkLabelFS)
    line-height: var(--checkLabelLH)
    font-weight: var(--checkLabelFW)
    color: var(--checkLabelColor)
    transition: var(--checkLabelTransition)
    ~/.is-checked &
      color: var(--checkLabelActiveColor)

  &__inner
    display: block
    width: var(--checkInnerWidth)
    height: var(--checkInnerHeight)
    border-radius: var(--checkInnerBorderRadius)
    background-color: var(--checkInnerBg)
    border: var(--checkInnerBorder)
    &:after
      top: 2px
      left: 6px
      width: 4px
      height: 9px
      border-width: 2px

  &__input
    &.is-disabled
      .el-checkbox__inner
        border: var(--checkInnerDisabledBorder)
        background-color: var(--colorControlBg--disabled)
        &:after
          border-color: var(--checkInnerDisabledColor)
      +span.el-checkbox__label
        color: var(--checkLabelDisabledColor)
    &.is-checked
      .el-checkbox__inner
        background-color: var(--checkInnerActiveBg)
        border: var(--checkInnerBorder--active)
      &.is-focus
        .el-checkbox__inner
          border: var(--checkInnerBorder--active)
    &.is-focus
      .el-checkbox__inner
        border: var(--checkInnerBorder)
