.delete-modal
  text-align: center

  &__body
    padding: 24px 20px 48px

  &__paragraph
    max-width: 400px
    margin: 0 auto
    font-size: 16px
    line-height: 24px
    color: #323232
    text-align: center

  &__confirm-form
    width: 100%
    max-width: 400px
    margin: 20px auto 0
    text-align: left
    .delete-modal__field
      margin-top: 30px

  &__confirm-form-input
    width: 100%
    height: 42px
    padding: 0 12px
    font-size: 14px
    line-height: 42px
    font-weight: 500
    color: #323232
    border: 1px solid #E4E9F1
    background-color: #fff
    border-radius: 4px
    transition: all .2s
    &:not(:focus):hover
      border-color: #ced4df
    &:focus
      border-color: colorPrimary
    &::placeholder
      color: #A8B7CF

  &__field
    text-align: center
    width: 100%
    max-width: 450px
    margin: 10px auto 0
    font-size: 12px

  &__button
    height: 40px
    margin: 0 6px
    padding: 10px 23px 12px
    font-size: 14px
    color: #fff
    font-weight: 500
    text-transform: uppercase
    background-color: colorDanger
    background-size: 200%
    background-position: top left
    border: 1px solid transparent
    border-radius: 4px
    transition: all .3s ease
    &.disabled
      opacity: 0.5
    &:hover
      background-position: center
      background-color: #E14949
    &.negative
      color: #798496
      border: 1px solid #CAD3E0
      background-color: transparent
      &:hover
        color: #fff
        background-color: #CAD3E0
