.workspaces-popover
  display: block
  width: 100%

  &__user
    display: flex
    align-items: center
    width: 100%
    padding-right: 12px

  &__user-icon
    flex: none
    width: 40px
    height: 40px
    margin-right: 8px

  &__user-meta
    &>*+*
      margin-top: 3px

  &__user-title
    font-size: 16px
    line-height: 20px
    font-weight: 600
    user-select: none
    span
      font-size: 12px
      font-weight: 400

  &__user-subtitle
    font-size: 12px
    line-height: 14px
    font-weight: 400
    user-select: none

  &__trigger
    cursor: pointer
    display: flex
    align-items: center
    padding: 12px
    background-color: var(--colorBgSecondary)
    border: 1px solid var(--colorControlBorder)
    border-radius: 4px
    transition: all .2s
    &:hover
      border-color: var(--colorControlBorder--hover)

  &__trigger-chevron
    flex: none
    font-size: 12px
    transition: all .2s
    &.is-flipped
      transform: rotate(180deg)

  &__menu
    --popoverWidth: 260px
    --popoverPaddings: 4px 0

  &__menu-item
    cursor: pointer
    display: flex
    align-items: center
    padding: 12px
    transition: all .2s
    &.disabled
      pointer-events: none
      background-color: var(--colorPopoverItemBg--hover)
      opacity 0.5
    &:hover
      background-color: var(--colorPopoverItemBg--hover)

  &__menu-item-check
    flex: none
    font-size: 12px
    color: var(--colorPrimary)
