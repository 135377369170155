.btn
  position: relative
  display: flex
  align-items: center
  justify-content: center
  height: 40px
  padding: 0 20px
  font-size: 16px
  line-height: 1
  font-weight: 500
  border: 1px solid transparent
  border-radius: 4px
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden
  transition: color .2s ease, background-color .2s ease, border-color .2s ease

  &:not(.btn--transparent):not(.btn--text-only)
    background-color: colorPrimary
    border-color: colorPrimary
    color: #fff
    &:not(:disabled):not(.is-loading):hover
      background-color: colorPrimaryHover

  &.btn--transparent
    color: colorPrimary
    border-color: #E4E9F1
    &:not(:disabled):not(.is-loading):not(.btn--danger):hover
      background-color: #E4E9F1
    &.btn--danger
      border-color: colorDanger
      color: colorDanger
      &:hover
        background-color: colorDanger
        color: #fff

  &.btn--text-only
    width: auto
    height: auto
    padding: 0
    color: #798496
    text-transform: uppercase
    &:not(:disabled):not(.is-loading):hover
      color: colorPrimary

  &:disabled
    cursor: not-allowed
    opacity: .5

  &.is-loading
    cursor: wait
    opacity: .7

  &.btn--uppercase
    text-transform: uppercase

  &--prefix-icon
    &:before
      font-family: 'icomoon'
      margin-right: 9px
    // &.is-loading:before
    //   content: '\e921'
    //   font-size: 16px
    //   animation: btn-loading 1.5s linear infinite

  &--suffix-icon
    &:after
      font-family: 'icomoon'
      margin-left: 9px
    // &.is-loading:after
    //   content: '\e921'
    //   font-size: 16px
    //   animation: btn-loading 1.5s linear infinite

  &--icon-new-page
    &:before
      content: '\e954'
      font-size: 14px
  &--icon-link2
    &:before
      content: '\e934'
      font-size: 14px
  &--icon-cubics
    &:before
      content: '\e957'
      font-size: 14px
