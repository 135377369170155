.redirect-rules
  width: 100%

  &__item
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    padding: 10px 0
    +.redirect-rules__item
      border-top: 1px solid var(--colorBorder)
    // &:hover:before
    //   opacity: 1
    // &:before
    //   pointer-events: none
    //   content: ''
    //   position: absolute
    //   top: 0
    //   left: -30px
    //   z-index: 0
    //   width: calc(100% + 60px)
    //   height: 100%
    //   background-color: #E9F6FF
    //   opacity: 0
    //   transition: all .2s

  &__item-left
    position: relative
    display: flex
    align-items: center
    width: 100%
    padding-right: 10px
    overflow: hidden

  &__item-label
    font-size: 14px
    line-height: 1.5
    font-weight: 500
    color: var(--colorText)

  &__item-label-divider
    flex: none
    // width: 10px
    // height: 1px
    font-size: 12px
    margin: 0 10px
    // background-color: #A8B7CF
    color: #A8B7CF
    transform: scale(-1,1)

  &__item-label
    max-width: 100%
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

  &__item-status
    margin-left: 10px
    font-size: 12px
    font-weight: 600
    color: var(--colorTextSecondary)

  &__item-btn
    cursor: pointer
    flex: none
    position: relative
    display: flex
    align-items: center
    justify-content: center
    width: 24px
    height: 24px
    font-size: 18px
    color: #A8B7CF
    transition: all .2s
    &:hover
      color: colorPrimary
