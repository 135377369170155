.google-button
  display: flex
  align-items: center
  justify-content: center
  position: relative
  width: 100%
  max-width: 284px
  height: 50px
  padding: 0 20px
  margin: 0 auto
  font-size: 16px
  line-height: 1.88
  font-weight: 600
  color: #fff
  background-color: #1da2ff
  text-transform: uppercase
  border-radius: 4px
  border: 1px  solid colorPrimary
  cursor: pointer
  transition: all .3s ease
  &.loading
    pointer-events: none;
    opacity 0.5
  &:hover
    background-color: colorPrimaryHover
  & img
    margin-right: 15px
  & span
    line-height: 1

  &__logo
    position: relative
    border: 2px solid #fff
    border-radius: 50%
    background-color: #fff
