.billing
  position: relative

  &-modal
    padding-top: 20px

  &__title
    padding: 0 50px
    font-size: 24px
    line-height: 34px
    font-weight: 700
    text-align center

  &__desc
    padding: 0 50px
    margin-top: 15px
    font-size: 16px
    line-height: 26px
    text-align center
    & .is-highlighted
      color: colorPrimary
      font-weight: 500


  &__body
    padding: 0 30px 15px
    & .subscribe__button.btn
      margin: 25px auto 20px
      display block

  &__switcher-wrap
    display: flex
    align-items: center
    justify-content: center

  &__switcher-title
    cursor: pointer
    position: relative
    display: flex
    font-size: 16px
    line-height: 16px
    font-weight: 600
    color: #B3B3B5
    transition: all .3s ease
    &.is-active
      color: #313131

  &__switcher-title-label
    margin-left: 5px
    padding: 3px 4px
    font-size: 12px
    line-height: 12px
    font-weight: 600
    color: #fff
    background-color: colorDanger
    border-radius: 4px
    transform: translateY(-5px)

  &__switcher
    flex: none
    margin: 0 20px

  &__price-wrap
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 30px
    text-align: center

  &__price
    display: flex

  &__price-currency
    align-self: flex-start
    padding-right: 3px
    font-family: $PTSans
    font-size: 24px
    line-height: 24px
    font-weight: bold

  &__price-value
    font-family: $PTSans
    font-size: 54px
    line-height: 46px
    font-weight: bold

  &__price-period
    align-self: flex-end
    padding-left: 3px
    font-size: 16px
    line-height: 16px
    font-weight: 600

  &__price-label
    margin-top: 3px
    font-size: 16px
    line-height: 24px

  &__price-total
    font-size: 16px
    color: #0F9D58

  &__footnote
    display: flex
    align-items: center
    justify-content: center
    font-size: 12px
    color: #616A78
    & a
      text-decoration: underline
      &:hover
        text-decoration: none

  &__footnote-icon
    margin-right: 8px

  &__list-wrap
    padding: 30px 60px 40px
    background-color: #EFF3FB
    text-align: left

  &__list-title
    margin-bottom: 20px
    font-size: 16px
    font-weight: 600

  &__list
    display: flex
    flex-wrap: wrap

  &__list-item
    display: flex
    align-items: flex-start
    width: 50%
    padding-right: 10px
    &:nth-of-type(n+3)
      margin-top: 13px

  &__list-item-title
    margin-left: 10px
    font-size: 16px
    line-height: 26px

  &__grid-wrap
    margin-top: 24px
    margin-bottom: 32px

  &__btns
    display: flex
    justify-content: center
    gap: 16px
    margin-top: 40px
    margin-bottom: 40px
