.sv-content-block
  font-family: inherit

  & .v-md-editor.sv-md-editor
  // & .v-md-editor-preview.sv-md-editor
    background-color: transparent
    word-break: break-word

    & .v-md-editor-preview.github-markdown-body
    // &.v-md-editor-preview.github-markdown-body
      font-family: inherit
      color: #323232

      & .v-md-editor__todo-list-checkbox--checked::after
        top: 1px
        left: 4px

      & .sv-desc-btn
        cursor: pointer
        display inline-block
        width: fit-content
        max-width: 100%
        margin-right: 10px
        margin-bottom: 10px
        height: 40px
        padding: 0 30px
        line-height: 40px
        font-size: 14px
        font-weight: 500
        color: #fff
        text-align: center
        text-transform: uppercase
        background-color: #51535d
        border-radius: 3px
        border: none
        outline: none
        text-decoration: none
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        transition: all .3s ease
        &:hover
          color: #fff
          opacity: 0.85
          text-decoration: none
        &:visited
        &:active
        &:focus
          text-decoration: none
          color: #fff

      &>div
        &>h1
        &>h2
        &>h3
        &>h5
        &>h6
        &>p
        &>img
        &>table
        &>ul
        &>ol
        &>blockquote
        &>figure
        &>figcaption
          margin-top: 0

      & h1
      & h2
      & h3
      & h4
      & h5
      & h6
        border: none
        color: inherit

      & h1
        margin: 32px 0 32px
        font-size: 42px
        line-height: 52px
        font-weight: 700

      & h2
        margin: 25px 0
        font-size: 26px
        line-height: 34px
        font-weight: 600

      h3
        margin: 20px 0
        font-size: 18px
        line-height: 25px
        font-weight: 600

      h4
        margin: 15px 0
        font-size: 14px
        line-height: 20px
        font-weight: 600

      h5
        margin: 10px 0
        font-size: 12px
        line-height: 20px
        font-weight: 600

      h6
        margin: 10px 0
        font-size: 10px
        line-height: 18px
        font-weight: 600

      // h3
      // h4
      // h5
      // h6
      //   margin: 10px 0
      //   font-size: 20px
      //   line-height: 28px
      //   font-weight: 600

      & p
        margin: 10px 0 18px
        font-size: 18px
        line-height: 28px
        &:only-of-type
        &:last-of-type
          margin: 0
        & a
          color: #1da2ff

      & blockquote
        position: relative
        padding: 0 0 0 25px
        margin: 10px 0 20px
        font-size: 20px
        line-height: normal
        font-weight: 500
        color: inherit
        border-left: none
        &:before
          content: ''
          position: absolute
          top: 0
          left: 0
          width: 3px
          height: 100%
          background-color: #323232

      & hr
        position: relative
        display: block
        width: 100%
        height: 1px
        margin: 30px auto
        background-color: #E4E9F1
        border: none
        overflow: visible

      & img
        box-sizing: border-box
        display: block
        margin: 0 0 20px
        max-width: 100%
        border-style: none
        background-color: transparent

      & figure
        margin: 10px 0 20px
        & img
          margin: 0

      & figcaption
        margin-top: 8px
        font-size: 12px
        line-height: 14px
        color: #798496
        text-align: left

      & input
        -webkit-appearance: auto

      & ul
        list-style: disc

      & ul
      & ol
        padding-left: 20px
        margin: 10px 0 18px
        & li
          font-size: 18px
          line-height: 28px
          &:nth-of-type(n+2)
            margin-top: 5px
          & p
            margin: 0
          & ul
            margin: 0

      & table
        // display: block
        width: 100%
        max-width: 100%
        margin: 10px 0 20px
        border-spacing: 0
        // overflow: scroll
        & caption
          margin-bottom: 5px
          font-size: 12px
          line-height: 14px
          color: #798496
          text-align: left
        & th
        & td
          max-width: 100%
          overflow: hidden
          white-space: nowrap
          text-overflow: ellipsis
          padding: 5px 8px
        & th
          font-size: 16px
          font-weight: 500
          color: #798496
          text-align: left
          border: 1px solid #E4E9F1
          &:nth-of-type(n+2)
            border-left: none
        & td
          font-size: 14px
          font-weight: 400
          color: #323232
          text-align: left
          border: 1px solid #E4E9F1
          border-top: none
          &:nth-of-type(n+2)
            border-left: none
      & iframe
        border 0
        margin-bottom 18px


// .sv-content-block
//   font-family: inherit

//   & .v-md-editor.sv-md-editor
//     background-color: transparent

//     & .v-md-editor-preview.github-markdown-body
//       font-family: inherit
//       color: #323232

//       & .v-md-editor__todo-list-checkbox--checked::after
//         top: 1px
//         left: 4px

//       &>div
//         &>h1
//         &>h2
//         &>h3
//         &>h5
//         &>h6
//         &>p
//         &>img
//         &>table
//         &>ul
//         &>ol
//         &>blockquote
//         &>figure
//         &>figcaption
//           margin-top: 0

//       & h1
//       & h2
//       & h3
//       & h4
//       & h5
//       & h6
//         border: none
//         color: inherit

//       & h1
//         margin: 32px 0 32px
//         font-size: 42px
//         line-height: 52px
//         font-weight: 700

//       & h2
//         margin: 25px 0
//         font-size: 26px
//         line-height: 34px
//         font-weight: 600

//       h3
//       h4
//       h5
//       h6
//         margin: 10px 0
//         font-size: 20px
//         line-height: 28px
//         font-weight: 600

//       & p
//         margin: 10px 0 18px
//         font-size: 18px
//         line-height: 28px
//         & a
//           color: colorPrimary

//       & blockquote
//         position: relative
//         padding: 0 0 0 25px
//         margin: 10px 0 20px
//         font-size: 20px
//         line-height: normal
//         font-weight: 500
//         color: inherit
//         border-left: none
//         &:before
//           content: ''
//           position: absolute
//           top: 0
//           left: 0
//           width: 3px
//           height: 100%
//           background-color: #323232

//       & hr
//         position: relative
//         display: block
//         width: 100%
//         height: 1px
//         margin: 30px auto
//         background-color: #E4E9F1
//         border: none
//         overflow: visible

//       & img
//         box-sizing: border-box
//         display: block
//         margin: 0 0 20px
//         max-width: 100%
//         border-style: none
//         background-color: transparent

//       & figure
//         margin: 10px 0 20px
//         & img
//           margin: 0

//       & figcaption
//         margin-top: 8px
//         font-size: 12px
//         line-height: 14px
//         color: #798496
//         text-align: left

//       & input
//         -webkit-appearance: auto

//       & ul
//         list-style: disc

//       & ul
//       & ol
//         padding-left: 20px
//         margin: 10px 0 18px
//         & li
//           font-size: 18px
//           line-height: 28px
//           &:nth-of-type(n+2)
//             margin-top: 5px
//           & p
//             margin: 0
//           & ul
//             margin: 0

//       & table
//         // display: block
//         width: 100%
//         max-width: 100%
//         margin: 10px 0 20px
//         border-spacing: 0
//         // overflow: scroll
//         & caption
//           margin-bottom: 5px
//           font-size: 12px
//           line-height: 14px
//           color: #798496
//           text-align: left
//         & th
//         & td
//           max-width: 100%
//           overflow: hidden
//           white-space: nowrap
//           text-overflow: ellipsis
//           padding: 5px 8px
//         & th
//           font-size: 16px
//           font-weight: 500
//           color: #798496
//           text-align: left
//           border: 1px solid #E4E9F1
//           &:nth-of-type(n+2)
//             border-left: none
//         & td
//           font-size: 14px
//           font-weight: 400
//           color: #323232
//           text-align: left
//           border: 1px solid #E4E9F1
//           border-top: none
//           &:nth-of-type(n+2)
//             border-left: none
//       & iframe
//         border 0
//         margin-bottom 18px
