.unsplash__btn
  margin-top: 20px
.unsplash__copyright
  display: block
  width: 100%
  text-align right
  padding: 0 10px
  font-size: 12px
  a
    color: colorPrimary
.unsplash-chips
  display: flex
  flex-wrap: wrap
  margin: 16px 5px
  &.disabled
    pointer-events: none
    opacity: 0.5
  li
    font-size: 14px
    line-height: 14px
    border-radius: 4px
    font-weight: 500
    color: #323232
    background-color: #eff1f7
    padding: 6px 8px
    margin: 5px
    cursor pointer
    &:hover
      background-color #d8d9dc

.unsplash-modal
  &__body
    padding: 24px 20px
  &__search
    display: flex
    margin: 0 10px 16px
    input
      height: 40px
      padding: 0 16px
      border: 1px solid colorPrimary
      border-radius: 4px
      font-size: 12px
      flex: 1 0
      &::placeholder
        opacity 0.5
    button
      margin-left 15px
      padding: 10px 23px
      font-size: 14px
      color: #fff
      font-weight: 500
      text-transform: uppercase
      background-color: colorPrimary
      border-radius: 4px
      -webkit-transition: all 0.3s ease
      transition: all 0.3s ease
      &.loading
        opacity 0.5
        pointer-events: none
  &__results
    display: flex
    justify-content: flex-start
    flex-wrap: wrap
  &__result-item
    border-radius: 5px
    background-color #BFBFBF
    width: calc(33.333% - 20px)
    height: 120px
    margin: 0 10px 20px
    position relative
    cursor: pointer
    transition: all 0.25s ease
    &-inner
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-position: center
      background-size cover
      background-repeat: no-repeat
    &:hover
      transform: scale(1.03)
    a
      max-width: 100%
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      position: absolute
      bottom: 0
      color: #fff
      font-size: 10px
      padding: 2px 5px
      background: rgba(0,0,0,0.5)
      width: 100%
      &:hover
        color: colorPrimary

  &__download-section
    padding: 24px
    background-color #f7f7f8
    &-title
      text-align center
      color: colorDarkerGray
      font-size: 14px
      font-weight: 400
      margin-bottom 16px
    & form
      max-width: 200px
      margin: auto
    & .uploader__btn
      background-color #fff
