.ui-btn
  // --${type}-btn-${Property}: #404040
  // --${type}-btn-${Property}--hover: #404040
  // --${type}-btn-${Property}--active: #404040
  // --${type}-btn-${Property}--focus: #404040
  // --${type}-btn-${Property}--disabled: #404040
  // --${type}-btn-${Property}--loading: #404040
  --primary-btn-Color: var(--primary-text-inside)
  --primary-btn-Bg: var(--primary)
  --primary-btn-Bg--hover: var(--primary-hover)
  --primary-btn-Bg--active: var(--primary-active)
  --primary-btn-BorderColor: transparent
  --primary-btn-ShadowColor: transparent
  --primary-btn-ShadowColor--focus: var(--primary-alpha-40)

  --semitransparent-blue-btn-Color: var(--semitransparent-blue-text-inside)
  --semitransparent-blue-btn-Bg: var(--semitransparent-blue)
  --semitransparent-blue-btn-Bg--hover: var(--semitransparent-blue-hover)
  --semitransparent-blue-btn-Bg--active: var(--semitransparent-blue-active)
  --semitransparent-blue-btn-BorderColor: transparent
  --semitransparent-blue-btn-ShadowColor: transparent
  --semitransparent-blue-btn-ShadowColor--focus: var(--semitransparent-blue-alpha-40)

  --secondary-btn-Color: var(--secondary-text-inside)
  --secondary-btn-Bg: var(--secondary)
  --secondary-btn-Bg--hover: var(--secondary-hover)
  --secondary-btn-Bg--active: var(--secondary-active)
  --secondary-btn-BorderColor: var(--colorControlBorder)
  --secondary-btn-BorderColor--hover: var(--colorControlBorder--hover)
  --secondary-btn-BorderColor--active: var(--colorControlBorder--hover)
  --secondary-btn-ShadowColor: transparent
  --secondary-btn-ShadowColor--focus: var(--secondary-alpha-60)

  --secondaryBlue-btn-Color: var(--secondaryBlue-text-inside)
  --secondaryBlue-btn-Bg: transparent
  --secondaryBlue-btn-Bg--hover: var(--secondaryBlue-hover)
  --secondaryBlue-btn-Bg--active: var(--secondaryBlue-active)
  --secondaryBlue-btn-BorderColor: #D2DAE7
  --secondaryBlue-btn-BorderColor--hover: #D2DAE7
  --secondaryBlue-btn-BorderColor--active: #D2DAE7
  --secondaryBlue-btn-ShadowColor: transparent
  --secondaryBlue-btn-ShadowColor--focus: var(--secondaryBlue-alpha-80)

  --secondaryTextBlue-btn-Color: var(--secondaryBlue-text-inside)
  --secondaryTextBlue-btn-Bg: transparent
  --secondaryTextBlue-btn-Bg--hover: var(--secondaryBlue-hover)
  --secondaryTextBlue-btn-Bg--active: var(--secondaryBlue-active)
  --secondaryTextBlue-btn-BorderColor: var(--colorBorder)
  --secondaryTextBlue-btn-BorderColor--hover: var(--colorBorder)
  --secondaryTextBlue-btn-BorderColor--active: var(--colorBorder)
  --secondaryTextBlue-btn-ShadowColor: transparent
  --secondaryTextBlue-btn-ShadowColor--focus: var(--secondaryBlue-alpha-80)

  --green-btn-Color: var(--green-text-inside)
  --green-btn-Bg: var(--green)
  --green-btn-Bg--hover: var(--green-hover)
  --green-btn-Bg--active: var(--green-active)
  --green-btn-BorderColor: transparent
  --green-btn-ShadowColor: transparent
  --green-btn-ShadowColor--focus: var(--green-alpha-40)

  --ghost-btn-Color: var(--ghost-text-inside)
  --ghost-btn-Bg: var(--ghost)
  --ghost-btn-Bg--hover: var(--ghost-hover)
  --ghost-btn-Bg--active: var(--ghost-active)
  --ghost-btn-BorderColor: transparent
  --ghost-btn-ShadowColor: transparent
  --ghost-btn-ShadowColor--focus: var(--ghost-alpha-40)

  --ghostSecondary-btn-Color: var(--ghost-secondary-text-inside)
  --ghostSecondary-btn-Color--hover: var(--colorText)
  --ghostSecondary-btn-Color--active: var(--colorText)
  --ghostSecondary-btn-Bg: var(--ghost)
  --ghostSecondary-btn-Bg--hover: var(--ghost-hover)
  --ghostSecondary-btn-Bg--active: var(--ghost-active)
  --ghostSecondary-btn-BorderColor: transparent
  --ghostSecondary-btn-ShadowColor: transparent
  --ghostSecondary-btn-ShadowColor--focus: var(--ghost-alpha-40)

  --success-btn-Color: var(--success-text-inside)
  --success-btn-Bg: var(--success)
  --success-btn-Bg--hover: var(--success-hover)
  --success-btn-Bg--active: var(--success-active)
  --success-btn-BorderColor: transparent
  --success-btn-ShadowColor: transparent
  --success-btn-ShadowColor--focus: var(--success-alpha-40)

  --info-btn-Color: var(--info-text-inside)
  --info-btn-Bg: var(--info)
  --info-btn-Bg--hover: var(--info-hover)
  --info-btn-Bg--active: var(--info-active)
  --info-btn-BorderColor: transparent
  --info-btn-ShadowColor: transparent
  --info-btn-ShadowColor--focus: var(--info-alpha-40)

  --warning-btn-Color: var(--warning-text-inside)
  --warning-btn-Bg: var(--warning)
  --warning-btn-Bg--hover: var(--warning-hover)
  --warning-btn-Bg--active: var(--warning-active)
  --warning-btn-BorderColor: transparent
  --warning-btn-ShadowColor: transparent
  --warning-btn-ShadowColor--focus: var(--warning-alpha-40)

  --help-btn-Color: var(--help-text-inside)
  --help-btn-Bg: var(--help)
  --help-btn-Bg--hover: var(--help-hover)
  --help-btn-Bg--active: var(--help-active)
  --help-btn-BorderColor: transparent
  --help-btn-ShadowColor: transparent
  --help-btn-ShadowColor--focus: var(--help-alpha-40)

  --danger-btn-Color: var(--danger-text-inside)
  --danger-btn-Bg: var(--danger)
  --danger-btn-Bg--hover: var(--danger-hover)
  --danger-btn-Bg--active: var(--danger-active)
  --danger-btn-BorderColor: transparent
  --danger-btn-ShadowColor: transparent
  --danger-btn-ShadowColor--focus: var(--danger-alpha-40)

  --contrast-btn-Color: var(--contrast-text-inside)
  --contrast-btn-Bg: var(--contrast)
  --contrast-btn-Bg--hover: var(--contrast-hover)
  --contrast-btn-Bg--active: var(--contrast-active)
  --contrast-btn-BorderColor: transparent
  --contrast-btn-ShadowColor: transparent
  --contrast-btn-ShadowColor--focus: var(--contrast-alpha-40)

.ui-btn
  // Size
  --btnWidth: fit-content
  --btnHeight: 1em
  --btnPaddingLeft: .5em
  --btnPaddingRight: .5em
  // Font
  --btnFF: var(--ffMain)
  // --btnFS: .5em
  --btnFS: .4em
  --btnLH: normal
  --btnFW: 500
  --btnLetterSpacing: 0
  // Border
  --btnBorderWidth: 1px
  --btnBorder: var(--btnBorderWidth) solid var(--btnBorderColor)
  --btnBorderRadius: 6px
  // Box Shadow
  --btnBoxShadowColor: transparent
  // Other
  --btnOpacityDisabled: .6
  // --btnOpacityLoading: 1
  --btnTransition: all .2s
  // Icons
  --btnPrependSize: .425em
  // --btnPrependSize: .625em
  --btnPrependMargin: .25em
  //
  --btnAppendSize: .425em
  // --btnAppendSize: .625em
  --btnAppendMargin: .25em
  //
  --btnLoadingIconSize: .425em
  --btnLoadingIconMargin: .25em
  //
  --btnBadgeMargin: .6em
  //
  --btnSingleIconSize: .5em
  --btnIconColor: inherit

.ui-btn
  cursor: pointer
  position: relative
  display: flex
  align-items: center
  justify-content: center
  flex: none
  width: var(--btnWidth)
  height: var(--btnHeight)
  padding-left: var(--btnPaddingLeft)
  padding-right: var(--btnPaddingRight)
  font-family: var(--btnFF)
  font-size: var(--btnSize)
  color: var(--btnColor)
  background-color: var(--btnBg)
  border-radius: var(--btnBorderRadius)
  border: var(--btnBorder)
  box-shadow: 0 0 0 3px var(--btnShadowColor)
  transition: var(--btnTransition)

  &:disabled
  &.ui-btn--disabled
    cursor: not-allowed
    opacity: var(--btnOpacityDisabled)
    // &:not(.ui-btn--loading)
    &[href]
      pointer-events: none
  &.ui-btn--loading
    cursor: wait
    // opacity: var(--btnOpacityLoading)

  // &.ui-btn--has-prepend
  //   --btnPaddingLeft: .25em
  // &.ui-btn--has-append
  //   --btnPaddingRight: .25em

  // Sizes
  // &.ui-btn--size-xs
  //   --btnSize: var(--height-control-xs)
  //   --btnBorderRadius: 4px
  &.ui-btn--size-xs
    --btnSize: var(--height-control-xs)
    --btnBorderRadius: 4px
  &.ui-btn--size-s
    --btnSize: var(--height-control-s)
    --btnBorderRadius: 4px
  &.ui-btn--size-m
    --btnSize: var(--height-control-m)
    --btnBorderRadius: 4px
  &.ui-btn--size-l
    --btnSize: var(--height-control-l)
    --btnFS: .36em
    --btnBorderRadius: 6px
  // &.ui-btn--size-xl
  //   --btnSize: var(--height-control-xl)
  //   --btnBorderRadius: 6px

  &.ui-btn--icon-only
    --btnWidth: var(--btnHeight)
    --btnPaddingLeft: 0
    --btnPaddingRight: 0
    --btnLoadingIconMargin: 0

  // Types
  &.ui-btn--type-primary
    --btnColor: var(--primary-btn-Color)
    --btnBg: var(--primary-btn-Bg)
    --btnBorderColor: var(--primary-btn-BorderColor)
    --btnShadowColor: var(--primary-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--primary-btn-Bg--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--primary-btn-Bg--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--primary-btn-ShadowColor--focus)

  &.ui-btn--type-semitransparent-blue
    --btnColor: var(--semitransparent-blue-btn-Color)
    --btnBg: var(--semitransparent-blue-btn-Bg)
    --btnBorderColor: var(--semitransparent-blue-btn-BorderColor)
    --btnShadowColor: var(--semitransparent-blue-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--semitransparent-blue-btn-Bg--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--semitransparent-blue-btn-Bg--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--semitransparent-blue-btn-ShadowColor--focus)

  &.ui-btn--type-secondary
    --btnColor: var(--secondary-btn-Color)
    --btnBg: var(--secondary-btn-Bg)
    --btnBorderColor: var(--secondary-btn-BorderColor)
    --btnShadowColor: var(--secondary-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--secondary-btn-Bg--hover)
        --btnBorderColor: var(--secondary-btn-BorderColor--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--secondary-btn-Bg--active)
      --btnBorderColor: var(--secondary-btn-BorderColor--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--secondary-btn-ShadowColor--focus)

  &.ui-btn--type-secondary-blue
    --btnColor: var(--secondaryBlue-btn-Color)
    --btnBg: var(--secondaryBlue-btn-Bg)
    --btnBorderColor: var(--secondaryBlue-btn-BorderColor)
    --btnShadowColor: var(--secondaryBlue-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--secondaryBlue-btn-Bg--hover)
        --btnBorderColor: var(--secondaryBlue-btn-BorderColor--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--secondaryBlue-btn-Bg--active)
      --btnBorderColor: var(--secondaryBlue-btn-BorderColor--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--secondaryBlue-btn-ShadowColor--focus)

  &.ui-btn--type-secondary-text-blue
    --btnColor: var(--secondaryTextBlue-btn-Color)
    --btnBg: var(--secondaryTextBlue-btn-Bg)
    --btnBorderColor: var(--secondaryTextBlue-btn-BorderColor)
    --btnShadowColor: var(--secondaryTextBlue-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--secondaryTextBlue-btn-Bg--hover)
        --btnBorderColor: var(--secondaryTextBlue-btn-BorderColor--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--secondaryTextBlue-btn-Bg--active)
      --btnBorderColor: var(--secondaryTextBlue-btn-BorderColor--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--secondaryTextBlue-btn-ShadowColor--focus)

  &.ui-btn--type-green
    --btnColor: var(--green-btn-Color)
    --btnBg: var(--green-btn-Bg)
    --btnBorderColor: var(--green-btn-BorderColor)
    --btnShadowColor: var(--green-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--green-btn-Bg--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--green-btn-Bg--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--green-btn-ShadowColor--focus)

  &.ui-btn--type-ghost
    --btnColor: var(--ghost-btn-Color)
    --btnBg: var(--ghost-btn-Bg)
    --btnBorderColor: var(--ghost-btn-BorderColor)
    --btnShadowColor: var(--ghost-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--ghost-btn-Bg--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--ghost-btn-Bg--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--ghost-btn-ShadowColor--focus)

  &.ui-btn--type-ghost-secondary
    --btnColor: var(--ghostSecondary-btn-Color)
    --btnBg: var(--ghostSecondary-btn-Bg)
    --btnBorderColor: var(--ghostSecondary-btn-BorderColor)
    --btnShadowColor: var(--ghostSecondary-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--ghostSecondary-btn-Bg--hover)
        --btnColor: var(--ghostSecondary-btn-Color--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--ghostSecondary-btn-Bg--active)
      --btnColor: var(--ghostSecondary-btn-Color--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--ghostSecondary-btn-ShadowColor--focus)

  &.ui-btn--type-success
    --btnColor: var(--success-btn-Color)
    --btnBg: var(--success-btn-Bg)
    --btnBorderColor: var(--success-btn-BorderColor)
    --btnShadowColor: var(--success-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--success-btn-Bg--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--success-btn-Bg--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--success-btn-ShadowColor--focus)

  &.ui-btn--type-info
    --btnColor: var(--info-btn-Color)
    --btnBg: var(--info-btn-Bg)
    --btnBorderColor: var(--info-btn-BorderColor)
    --btnShadowColor: var(--info-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--info-btn-Bg--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--info-btn-Bg--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--info-btn-ShadowColor--focus)

  &.ui-btn--type-warning
    --btnColor: var(--warning-btn-Color)
    --btnBg: var(--warning-btn-Bg)
    --btnBorderColor: var(--warning-btn-BorderColor)
    --btnShadowColor: var(--warning-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--warning-btn-Bg--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--warning-btn-Bg--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--warning-btn-ShadowColor--focus)

  &.ui-btn--type-help
    --btnColor: var(--help-btn-Color)
    --btnBg: var(--help-btn-Bg)
    --btnBorderColor: var(--help-btn-BorderColor)
    --btnShadowColor: var(--help-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--help-btn-Bg--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--help-btn-Bg--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--help-btn-ShadowColor--focus)

  &.ui-btn--type-danger
    --btnColor: var(--danger-btn-Color)
    --btnBg: var(--danger-btn-Bg)
    --btnBorderColor: var(--danger-btn-BorderColor)
    --btnShadowColor: var(--danger-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--danger-btn-Bg--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--danger-btn-Bg--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--danger-btn-ShadowColor--focus)

  &.ui-btn--type-contrast
    --btnColor: var(--contrast-btn-Color)
    --btnBg: var(--contrast-btn-Bg)
    --btnBorderColor: var(--contrast-btn-BorderColor)
    --btnShadowColor: var(--contrast-btn-ShadowColor)
    +mediaHover()
      &:not(.ui-btn--disabled):not(.ui-btn--loading):hover
        --btnBg: var(--contrast-btn-Bg--hover)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):active
    &:not(.ui-btn--disabled):not(.ui-btn--loading).is-highlighted
      --btnBg: var(--contrast-btn-Bg--active)
    &:not(.ui-btn--disabled):not(.ui-btn--loading):focus-visible
      --btnShadowColor: var(--contrast-btn-ShadowColor--focus)

  // Modifiers
  &.ui-btn--full-width
    width: 100%
  &.ui-btn--round
    --btnBorderRadius: 50%
  &.ui-btn--inline
    display: inline-flex
  &.ui-btn--wide
    --btnPaddingLeft: .75em
    --btnPaddingRight: .75em
  &.ui-btn--uppercase
    .ui-btn__text
      text-transform: uppercase

  &__loading-icon
    flex: none
    // width: var(--btnLoadingIconSize)
    // height: var(--btnLoadingIconSize)
    font-size: var(--btnLoadingIconSize)
    margin-right: var(--btnLoadingIconMargin)
    animation: ui-btn-loader-spin 1.5s linear infinite

  &__prepend-icon.ui-icon
    --iconSize: var(--btnPrependSize)
    flex: none
    margin-right: var(--btnPrependMargin)
    color: var(--btnIconColor)

  &__append-icon.ui-icon
    --iconSize: var(--btnAppendSize)
    flex: none
    margin-left: var(--btnAppendMargin)
    color: var(--btnIconColor)

  &__append-img
    margin-left: var(--btnAppendMargin)

  &__single-icon.ui-icon
    --iconSize: var(--btnSingleIconSize)
    display: block
    flex: none
    font-size: var(--btnSingleIconSize)
    color: var(--btnIconColor)

  &__text
    font-size: var(--btnFS)
    line-height: var(--btnLH)
    font-weight: var(--btnFW)
    letter-spacing: var(--btnLetterSpacing)
    ellipsis()

@keyframes ui-btn-loader-spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
