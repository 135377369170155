.loader__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #fcfcff;
}
.loader__wrapper.sv-is-dark {
  background-color: #3b414c;
}
.loader__container {
  width: 100%;
  max-width: 432px;
  padding: 0 16px;
  text-align: center;
}
.loader__container img {
  width: 170px;
}
.loader__string {
  min-height: 63px;
  font-size: 14px;
  line-height: 1.5;
}
.loader__progress {
  width: 100%;
  height: 8px;
  margin-bottom: 24px;
  background-color: #e4e9f1;
  border-radius: 100px;
  overflow: hidden;
}
.loader__progress-bar {
  height: 100%;
  background-color: #1da2ff;
  border-radius: 100px;
  transition: width 0.8s linear;
  will-change: width;
}
.loader__layer {
  display: block;
  position: absolute;
  height: 50px;
  width: 50px;
  box-shadow: 3px 3px 2px rgba(0,0,0,0.2);
  transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg);
}
.loader__layer:nth-of-type(1) {
  background: #534a47;
  margin-top: calc(50px / 2);
  animation: movedown 1.8s cubic-bezier(0.39, 0.575, 0.565, 1) 0.9s infinite normal;
}
.loader__layer:nth-of-type(1):before {
  content: '';
  position: absolute;
  width: 85%;
  height: 85%;
  background: #37332f;
}
.loader__layer:nth-of-type(2) {
  background: $green;
  margin-top: calc(50px / 4);
}
.loader__layer:nth-of-type(3) {
  background: rgba(255,255,255,0.6);
  animation: moveup 1.8s cubic-bezier(0.39, 0.575, 0.565, 1) infinite normal;
}
@-moz-keyframes moveup {
  0%, 60%, 100% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(1em);
  }
}
@-webkit-keyframes moveup {
  0%, 60%, 100% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(1em);
  }
}
@-o-keyframes moveup {
  0%, 60%, 100% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(1em);
  }
}
@keyframes moveup {
  0%, 60%, 100% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(1em);
  }
}
@-moz-keyframes movedown {
  0%, 60%, 100% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(-1em);
  }
}
@-webkit-keyframes movedown {
  0%, 60%, 100% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(-1em);
  }
}
@-o-keyframes movedown {
  0%, 60%, 100% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(-1em);
  }
}
@keyframes movedown {
  0%, 60%, 100% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0);
  }
  25% {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(-1em);
  }
}
/*# sourceMappingURL=../@shared/preloader-component/src/loader.css.map */