.cta-constructor
  position: relative

  &__trigger
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 50px
    padding: 0 20px
    line-height: 50px
    font-size: 14px
    font-weight: 500
    color: #fff
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    text-align: center
    border-radius: 3px
    transition: all 0.3s ease
    &.is-secondary
      border: 1px solid
      background-color: transparent

  // &__trigger-label
  //   display: flex
  //   align-items: center
  //   font-size: 16px
  //   font-weight: 600

  // &__trigger-icon
  //   font-size: 16px
  //   margin: 0 5px 2px 0
  //   transition: transform .2s ease

  //   &.icon-arrow-down
  //     font-weight: 900

  //   &.is-rotated
  //     transform: rotate(180deg)

  &__menu
    position: absolute
    top: calc(100% + 12px)
    left: 0
    z-index: 2
    width: 100%
    padding: 20px 20px 30px 20px
    border-radius: 2px
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15)
    border: 1px solid colorGray
    background-color: #fff

    &:after
      content: ''
      position: absolute
      top: -6px
      left: 50%
      transform: translateX(-50%) rotate(-45deg)
      width: 10px
      height: 10px
      border-right: 1px solid colorGray
      border-top: 1px solid colorGray
      background-color: #fff

  &__menu-title
    font-size: 14px
    line-height: 18px
    font-weight: 600
    color: colorBlack

  &__input
    width: 100%
    height: 40px
    border: 1px solid #e4e9f1
    border-radius: 4px
    padding: 0 13px
    color: colorDarkerGray
    &::placeholder
      opacity: .4

  &__input-wrap
    position: relative

  &__color-picker
    position: absolute
    top: 11px
    right: 10px
    & .color-picker
      position: static

  &__radio-wrap
    // display: flex
    // align-items: center
    // justify-content: space-between
    margin-top: 25px

  &__radio
    cursor: pointer
    display: flex
    align-items: center
    padding-right: 5px
    margin-bottom: 8px

    &:hover .cta-constructor__radio-indicator
      border-color: colorPrimary

    &.active
      & .cta-constructor__radio-indicator
        background-color: rgba(29, 162, 255, 0.3)
        border-color: transparent
        &:after
          opacity: 1
      &:hover .cta-constructor__radio-indicator
        border-color: transparent

  &__radio-indicator
    position: relative
    width: 16px
    height: 16px
    margin-right: 11px
    border-radius: 50%
    border: 1px solid rgba(168, 183, 207, 0.51)
    transition: all .3s ease

    &:after
      content: ''
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: 8px
      height: 8px
      background-color: colorPrimary
      border-radius: 50%
      transition: all .3s ease
      opacity: 0

  &__radio-icon
    margin-right: 10px
    font-size: 16px
    color: colorDarkerGray

  &__radio-label
    font-size: 16px
    line-height: 18px
    color: colorBlack

  &__select-wrap
    margin-top: 25px

  &__apply-btn
    display: block
    width: 100%
    height: 40px
    margin: 25px auto 0
    font-size: 14px
    line-height: 14px
    font-weight: 600
    color: #fff
    background-color: colorPrimary
    border-radius: 4px
    transition: all .3s ease
    &:hover
      background-color: colorPrimaryHover

  &__email-field
    margin-top: 25px
    &__link
      color: colorPrimary
      cursor pointer

  &__label-error
    margin-top: 25px
    font-size: 10px
    line-height: 15px
    font-weight: 500
    color: colorDanger
