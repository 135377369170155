.settings-form

  &__field-label
    font-size: 14px
    line-height: 20px
    font-weight: 600
    color: colorBlack

  &__name-wrap
    margin-bottom: 50px

  &__iframe-wrap
    margin-bottom: 30px

  &__analytics-wrap
    margin-bottom: 30px

    & p
      font-size: 14px
      line-height: 20px
      font-weight: 600
      color: colorBlack
      margin-bottom: 8px

  &__title
    font-size: 16px
    line-height: 24px
    font-weight: 700
    color: colorBlack
    margin-bottom: 10px

  &__settings-wrap
    margin-bottom: 30px
    position relative
    &.disabled
      opacity: 0.5
      pointer-events: none

    &__input
      width: 100%
      height: 40px
      padding: 0 13px
      font-size: 14px
      color: colorDarkerGray
      border: 1px solid #e4e9f1
      border-radius: 4px
      &::placeholder
        opacity: .4
        font-size: 14px


    &__label
      font-size: 14px
      line-height: 20px
      font-weight: 600
      color: colorBlack
      margin-bottom: 8px

    & .form-field__error
      position: absolute
      top: 100%
      left: 0
      width: 100%
      padding-top: 5px
      font-size: 10px
      line-height: 15px
      font-weight: 500
      color: colorDanger
      animation: collapsable-field-error-appearence .5s ease


  &__textarea
    display: block
    width: 100%
    height: 160px
    padding: 16px
    font-size: 12px
    line-height: normal
    color: colorBlack
    background-color: transparent
    border: 1px solid colorGray
    border-radius: 2px
    resize: none
    outline: none
    font-family monospace
