.page-create
  position: relative
  padding-top: 70px
  padding: 70px var(--containerSidePadding) 0
  .spread-form__form-elements
    max-width: none

  &__decor-img
    position: absolute
    z-index: -1
    pointer-events: none
    &.is-left
      top: 130px
      left: 20px
    &.is-bottom
      bottom: 0
      right: 0

  &__head
    display: flex
    flex-direction: column
    align-items: center
    gap: 48px
    mw(960)
    margin: 0 auto 50px

  &__meta
    text-align: center
    >*+*
      margin-top: 24px

  &__meta-title
    font-family: $Avenir
    font-size: 36px
    line-height: 45px
    font-weight: bold

  &__meta-string
    font-size: 16px
    line-height: 1.5
    color: var(--colorText)
    >*+*
      margin-top: 12px
    b
      font-weight: bold

  &__nav
    display: flex
    width: fit-content
    border: 1px solid var(--colorPrimary)
    border-radius: 4px
    overflow: hidden

  &__nav-item
    cursor pointer
    display: flex
    align-items: center
    gap: 6px
    width: fit-content
    height: 38px
    padding: 0 16px
    font-size: 14px
    font-weight: 600
    text-transform: uppercase
    color: var(--colorText)
    transition: all .2s
    &.is-active
      color: #fff
      background-color: var(--colorPrimary)
    &:not(.is-active):hover
      background-color: var(--colorPrimary-10)
    &:not(.is-active):active
      background-color: var(--colorPrimary-20)
    &:not(:last-child)
      border-right: 1px solid var(--colorPrimary)

  &__nav-item-icon
    color: var(--colorPrimary)

  &__form-wrap
    mw(960)
    margin: 0 auto

  &__cards-wrap
    display: grid
    grid-template-columns: 240px 1fr
    gap: 40px
    mw(1280)
    margin: 0 auto

  &__ai-wrap
    mw(600)
    margin: 0 auto

  &__try-ai-btn
    vertical-align: middle
    transform: translateY(-2px)
