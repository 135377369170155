.ai-creator-flow
  position: relative
  display: flex
  align-items: flex-end
  flex: 1
  min-height: 0
  width: 100%
  padding: 24px
  &:before
    content: ''
    pointer-events: none
    position: absolute
    top: 0
    left: 0
    z-index: 2
    width: 100%
    height: 50%
    // background-color: alpha(#1E9DFF, .5)
    background: linear-gradient(to bottom, #1e9dff 5%, transparent 50%)
    opacity: .8

  &__test-btns
    absTR(12px, 12px)
    z-index: 3
    button
      padding: 8px
      cursor: pointer
      +hover()
        background-color: rgba(255,255,255,.6)

  &__msgs
    display: flex
    flex-direction: column
    gap: 16px

  &__msg
    display: flex
    align-items: flex-start
    gap: 8px
    width: fit-content
    padding: 12px 24px 16px 16px
    background-color: #fff
    border-radius: 4px
    box-shadow: 0 4px 16px 0 rgba(#0A235E, .08)

  &__msg-icon
    font-size: 16px
    line-height: 1
    margin-top: 4px

  &__msg-body
    display: flex
    flex-direction: column
    gap: 8px

  &__msg-title
    textM(500)

  &__msg-string
    textM(600)

  &__msg-img
    width: 240px
    aspect-ratio: 16/9
    bgImgCenter()
    +fallback('aspect-ratio: 1')
      height: 150px

  &__msg-color
    display: flex
    align-items: center
    gap: 6px

  &__msg-color-preview
    width: 20px
    height: 20px
    border-radius: 2px

  &__msg-color-title
    textM(600)

.ai-creator-msgs-move,
.ai-creator-msgs-enter-active,
.ai-creator-msgs-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.ai-creator-msgs-enter,
.ai-creator-msgs-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

.ai-creator-msgs-leave-active {
  position: absolute;
}
