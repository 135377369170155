.filters
  width: 100%

  &__search
    --inputPrefixIconWidth: 16px
    --inputPrefixIconSize: 16px

  &__section
    +.filters__section
      padding-top: 16px
      margin-top: 16px
      border-top: 1px solid var(--colorBorder)

  &__section-title
    margin-bottom: 20px
    textM(500)

  &__section-item
    .el-checkbox__label
      display: flex
      align-items: center
      gap: 6px
    &.is-list-view
      display: flex
      flex-direction: column
      align-items: flex-start
      gap: 20px

  &__footer
    margin-top: 32px
