.ai-creator-result
  display: flex
  flex-direction: column
  align-items: center
  gap: 24px
  width: 100%
  height: 100%
  padding: 24px

  &__radio-wrap
    flex: none
    display: flex
    align-items: center
    gap: 4px
    width: fit-content
    padding: 4px
    background-color: #E4E9F1
    border-radius: 5px

  &__radio
    cursor: pointer
    display: flex
    align-items: center
    gap: 8px
    padding: 4px 32px
    // color: #fff
    border-radius: 5px
    transition: all .2s
    &:not(.is-active):hover
      background-color: rgba(255,255,255,.4)
    &.is-active
      background-color: #fff
      // color: var(--colorText)

  &__radio-label
    textM(600)

  &__radio-icon
    font-size: 20px
    color: #798496
    transition: all .2s

  &__website
    width: 100%
    flex: 1

  &__sheet-wrap
    --pricingTableNameColWidth: 320px
    --pricingTableBorderColor: #D2DAE7
    --pricingTableBorder: 1px solid #D2DAE7
    --pricingTableCellPadding: 8px 10px
    --pricingTableDecorSize: 24px
    // --pricingTableHeadHeight: 145px
    --pricingTableScrollSize: 4px
    position: relative
    max-width: 100%
    flex: 1
    &:after
      content: ''
      absBR(0,0)
      width: var(--pricingTableScrollSize)
      height: var(--pricingTableScrollSize)
      background-color: var(--pricingTableBorderColor)

  &__sheet-scroll-container
    position: relative
    width: 100%
    max-width: 100%
    height: 100%
    // max-height: 70vh
    overflow: scroll
    will-change: scroll-position
    // border-right: var(--pricingTableBorder)
    // border-bottom: var(--pricingTableBorder)
    box-shadow: 0px 6px 16px 0 rgba(29, 36, 56, 0.08), 0px 6px 16px 0px rgba(0, 0, 0, 0.05)
    background-color: #fff
    // background-color: var(--pricingTableBorderColor)

    // scrollbar-width: thin
    -webkit-overflow-scrolling: touch
    &::-webkit-scrollbar
      -webkit-appearance: none
      width: var(--pricingTableScrollSize)
      height: var(--pricingTableScrollSize)

    &::-webkit-scrollbar-thumb
      border-radius: 20px
      background-color: #717E93
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5)
      // border: 2px solid var(--pricingTableBorderColor)

    &::-webkit-scrollbar-track
      background-color: var(--pricingTableBorderColor)
      -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.1) inset

    &::-webkit-scrollbar-button
      display: none

  &__sheet
    // width: max-content
    // table-layout: auto
    table-layout: fixed
    font-size: 12px
    line-height: 16px
    font-weight: 500
    border-collapse: separate
    border-spacing: 0
    th
    td
      width: 100%
      border-right: var(--pricingTableBorder)
      border-bottom: var(--pricingTableBorder)
      white-space: nowrap
      // white-space: normal
      // word-break: break-word
      &:last-of-type
        border-right: none
      &:first-of-type
        width: var(--pricingTableDecorSize)
        min-width: var(--pricingTableDecorSize)
        max-width: var(--pricingTableDecorSize)
        padding: 0
        font-size: 10px
        line-height: 1.4
        font-weight: 400
        text-align: center
        background-color: #E9F6FF
        border-left: var(--pricingTableBorder)

    th
      position: sticky
      // top: var(--headerHeight)
      top: 0
      z-index: 3
      // height: var(--pricingTableHeadHeight)
      padding: 0
      vertical-align: top
      background-color: #fff
      border-top: var(--pricingTableBorder)
      &:nth-of-type(1)
        position: sticky
        left: 0
        z-index: 4
      &:nth-of-type(1)
        left: 0
      // +tablet-max()
      //   top: 0
      //   z-index: 3
      //   &:nth-of-type(1),
      //   &:nth-of-type(2)
      //     position: sticky
      //     left: 0
      //     z-index: 4
      //   &:nth-of-type(1)
      //     left: 0
      //   &:nth-of-type(2)
      //     left: var(--pricingTableDecorSize)
      >div
        display: flex
        flex-direction: column
        align-items: flex-start
        height: 100%
        >div
          width: 100%
      &:first-of-type
        text-align: center

    td
      padding: var(--pricingTableCellPadding)
      // text-align: center
      background-color: #fff
      &:nth-of-type(1)
        position: sticky
        left: 0
        z-index: 2
      &:nth-of-type(1)
        left: 0
      // +tablet-max()
      //   &:nth-of-type(1),
      //   &:nth-of-type(2)
      //     position: sticky
      //     left: 0
      //     z-index: 2
      //   &:nth-of-type(1)
      //     left: 0
      //   &:nth-of-type(2)
      //     left: var(--pricingTableDecorSize)
      //     background-color: #fff


  &__sheet-head-decor
    display: flex
    align-items: center
    justify-content: center
    height: var(--pricingTableDecorSize)
    font-size: 10px
    line-height: 1.4
    font-weight: 400
    text-align: center
    background-color: #E9F6FF
    border-bottom: var(--pricingTableBorder)

  &__sheet-head-cell
    display: flex
    flex-direction: column
    // align-items: center
    justify-content: center
    flex: 1
    padding: var(--pricingTableCellPadding)
    text-align: start
    &.is-decor
      padding: 9px 8px
    p
      font-weight: bold
      color: var(--colorPrimary)
