.theme-picker
  width: 34px
  height: 34px
  margin-right: 15px
  border: 1px solid transparent
  border-radius: 50%
  cursor: pointer
  position: relative
  &:last-of-type
    margin-right: 0
  &:before
    content: '\e930'
    position: absolute
    top: -5px
    left: -6px
    font-family: 'icomoon'
    font-size: 44px
    color: #798496
    opacity: 0
    transition: all .3s ease

  &.sv-is-dark
    color: colorBlack
    background: currentColor

  &.sv-is-blue
    background: currentColor
    color: colorPrimary

  &.sv-is-light
    color: #f6f8fb
    background: currentColor
    border-color: colorLightBlue

  &.active:before
    opacity: 1

  &__input
    display: none
