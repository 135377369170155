// Headings

.heading--size-xxs
  headingXXS()
.heading--size-xs
  headingXS()
.heading--size-s
  headingS()
.heading--size-m
  headingM()
.heading--size-l
  headingL()
.heading--size-xl
  headingXL()
.heading--size-xxl
  headingXXL()
.heading--size-xxxl
  headingXXXL()

// Paragraphs

.text--size-xxs
  textXXS()
.text--size-xs
  textXS()
.text--size-s
  textS()
.text--size-m
  textM()
.text--size-l
  textL()
.text--size-xl
  textXL()
.text--size-xxl
  textXXL()
.text--size-xxxl
  textXXXL()


// Shortcuts
.text--align-center
  text-align: center
.text--align-left
  text-align: left
.text--align-right
  text-align: right

.text--weight-900
  font-weight: 900
.text--weight-800
  font-weight: 800
.text--weight-700
  font-weight: 700
.text--weight-600
  font-weight: 600
.text--weight-500
  font-weight: 500
.text--weight-400
  font-weight: 400
.text--weight-300
  font-weight: 300
.text--weight-200
  font-weight: 200
.text--weight-100
  font-weight: 100

.text--color-default
  color: var(--colorText)
.text--color-primary
  color: var(--primary)
.text--color-secondary
  color: var(--colorTextSecondary)
.text--color-tertiary
  color: var(--colorTextTertiary)
.text--color-danger
  color: var(--colorDanger)
.text--color-success
  color: var(--colorSuccess)
