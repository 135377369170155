.spreadviews
  display: flex

  &__container
    display: flex
    width: 100%
    max-width: 1460px
    margin: 0 auto
    padding: 75px 20px 0

  &__left
    flex: none
    width: 320px
    padding-right: 60px
    &>*+*
      margin-top: 16px

  &__right
    width: 100%

  &__search
    position: relative
    width: 100%

  &__search-input
    width: 100%
    height: 40px
    padding: 0 12px 0 40px
    font-size: 16px
    line-height: 40px
    font-weight: 400
    border-radius: 4px
    border: 1px solid colorGray
    background-color: #fff
    transition: all .2s
    &::placeholder
      color: #A8B7CF
    &:not(:focus):hover
      border-color: #c9ced5
    &:focus
      border-color: colorPrimary

  &__search-btn
    position: absolute
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 40px
    font-size: 14px
    transition: all .2s
    &:not(:disabled):hover
      color: colorPrimary
    &:disabled
      cursor: not-allowed
      opacity: .5

  // &__team
  //   margin-top: 16px

  // &__filters
  //   margin-top: 50px

  &__left-workspaces-manage
    margin-bottom: 32px
    &>*+*
      margin-top: 16px

  &__list-head
    display: flex
    align-items: center
    justify-content: space-between

  &__title
    font-size: 32px
    line-height: 40px
    font-weight: 600
    color: #323232

  &__select
    width: 150px
    .el-input__inner
      height: 30px
      padding-right: 32px
      font-size: 16px
      line-height: 30px
      font-weight: 500
      color: #323232
      text-align: right
      background-color: transparent
      border: none
    .el-input__suffix
      display: flex
      align-items: center
      right: 0
    .el-input__suffix-inner
      display: flex
      align-items: center
    .el-input .el-select__caret
      height: auto
      color: #323232

  &__select-menu.el-select-dropdown
    border: 1px solid colorGray
    border-radius: 4px
    box-shadow: 0px 16px 31px rgba(10, 35, 94, 0.24), 0px 3px 5px rgba(10, 35, 94, 0.1)
    .popper__arrow
      display: none
    .el-select-dropdown__item
      width: 100%
      height: auto
      padding: 10px 14px
      font-size: 16px
      line-height: 20px
      font-weight: 400
      color: #323232
      text-align: left
      white-space: normal
      transition: all .2s
      &:not(.is-disabled).hover
      &:not(.is-disabled):hover
        background-color: #f1f8ff
      &.is-disabled
        color: colorGray
      &.selected
        position: relative
        font-weight: 400
        color: colorPrimary

  &__list
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
    gap: 36px
    // display: flex
    // flex-wrap: wrap
    width: 100%
    margin-top: 50px
    &-archive
      margin-top: 60px

  &__list-item
    position: relative
    // flexGrid(3,36,36)
    &--archived
      opacity: 0.5

  &__add
    cursor: pointer
    position: relative
    display: flex
    align-items: center
    justify-content: center
    min-height: 326px
    height: 100%
    border-radius: 4px
    overflow: hidden
    &:hover .spreadviews__add-circle
      transform: scale(15)
      background-color: colorGray
      transition-delay: .2s
    &:hover .spreadviews__add-icon
      color: #717E93
      transform: translate(-50%, -50%) scale(3)
      transition-delay: .2s

  &__add-circle
    pointer-events: none
    position: absolute
    top: 50%
    left: 50%
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 40px
    margin: -20px 0 0 -20px
    font-size: 12px
    color: #fff
    border-radius: 50%
    background-color: colorLightBlue
    transition: all .5s cubic-bezier(0.67,-0.25, 0.11, 1.22)

  &__add-icon
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    display: inline-block
    color: #fff
    font-size: 12px
    transition: all .5s cubic-bezier(0.67,-0.25, 0.11, 1.22)

  &__add-bg
    position: absolute
    top: 0
    left: 0

  &__add-title
    position: relative
    margin-top: 80px
    font-size: 16px
    font-weight: 500
    color: #465164

  &__bg
    position: fixed
    top: 0
    left: 0
    z-index: 11
    width: 100%
    height: 100%
    background-color: alpha(#1C1F22,.85)

  &__try-ai-btn-wrap
    absTR()
    padding: 16px 12px 0 0

.spreadviews-add-title-animation-enter-active, .spreadviews-add-title-animation-leave-active
  transition: all .35s ease
.spreadviews-add-title-animation-enter, .spreadviews-add-title-animation-leave-to /* .fade-leave-active до версии 2.1.8 */
  opacity: 0
  transform: scale(0)

.spreadviews
  &__short-list
    display: block
    margin-bottom: 36px
    width: 100%
    margin-top: 50px
    &-container
      max-width: 540px
      margin: 0 auto
      padding: 0
    &-no-sites
      --font-size: 16px
      --line-height: 22px
      --font-weight: 500
      --field-height: 22px
      --icon-size: 14px
      --icon-color: var(--colorText)
      --icon-color--hover: var(--colorPrimary)
      width: 100%
      margin-bottom: 20px


  &__short-list-item
    position: relative
    display: flex
    margin-bottom: 36px
    &-img
      min-width: 120px
      height: 100%

    &-content
      display: flex
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -ms-flex-direction: column
      flex-direction: column
      -webkit-box-align: start
      -ms-flex-align: start
      align-items: flex-start
      width: 100%
      padding: 16px 20px

    &-action
      display: flex
      flex-direction: column
      align-items: flex-end
      width: 100%
      padding: 16px 20px
      & .ui-btn
        transform: rotateY(180deg)

.spreadview__shortcode
  font-size: 16px
  line-height: 22px
  font-weight: 500
  color: #323232
  text-align: left
  background-color: transparent
