.unsubscribe-modal
  padding: 10px 30px 30px
  color: #323232
  text-align: left

  &__step
    font-size: 14px
    font-weight: 500
    color: #979797

  &__content-wrap
    font-size: 14px
    line-height: 1.5
    .text-color-secondary
      color: var(--colorTextSecondary)
    >*+*
      margin-top: 12px
    p
      font-size: 14px
      line-height: 1.5
      font-weight: 400
    strong
    b
      font-weight: 600
    ul
      padding: 0 0 0 15px
      list-style-type: disc
      li+li
        margin-top: 5px

  &__form
    display: flex
    flex-direction: column
    align-items: flex-start
    margin-top: 25px
    &-paragraph
      padding: 10px 0

  &__form-title
    margin-bottom: 15px
    font-size: 18px
    font-weight: 500

  &__form-item
    width: 100%
    &+.unsubscribe-modal__form-item
      margin-top: 15px

  &__form-item-textarera
    width: 100%
    height: 80px
    margin-top: 10px
    padding: 5px 10px
    font-size: 14px
    line-height: 22px
    color: #323232
    border: 1px solid colorGray
    border-radius: 2px
    resize: none
    &:focus
      outline: none
    &::placeholder
      color: #798496

  &__footer
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    margin-top: 30px

  &__error-msg
    padding-right: 40px
    font-size: 14px
    line-height: 1.5
    color: var(--colorDanger)

  &__btns
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 16px
    margin-top: 24px
    &.justify-end
      display: flex
      justify-content: flex-end
    &.justify-center
      display: flex
      justify-content: center

  &__transparent-btn
    font-size: 16px
    font-weight: 600
    color: #798496

  &__btn
    display: flex
    align-items: center
    justify-content: center
    font-size: 16px
    font-weight: 600
    transition: all .2s ease
    &--disabled
      display: flex
      align-items: center
      justify-content: center
      font-size: 16px
      font-weight: 600
      transition: all .2s ease
      background-color: #E0E0E0;
      height: 44px
      padding: 0 20px
      border-radius: 4px
      color: #fff
      text-transform: uppercase
      margin-left: 20px
    &:not(.is-transparent)
      height: 44px
      padding: 0 20px
      border-radius: 4px
      background-color: colorPrimary
      color: #fff
      text-transform: uppercase
      &:hover
        background-color: colorPrimaryHover
    &.is-transparent
      color: #798496
      &:hover
        color: colorPrimary
    &+.unsubscribe-modal__btn
      margin-left: 20px
