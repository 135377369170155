.loader

  &__wrapper
    position: fixed
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    z-index: 10
    background-color: #fcfcff
    &.sv-is-dark
      background-color: #3b414c

  &__container
    width: 100%
    max-width: 432px
    padding: 0 16px
    text-align: center
    & img
      width: 170px

  &__string
    min-height: 63px
    font-size: 14px
    line-height: 1.5

  &__progress
    width: 100%
    height: 8px
    margin-bottom: 24px
    background-color: #E4E9F1
    border-radius: 100px
    overflow: hidden

  &__progress-bar
    height: 100%
    background-color: #1DA2FF
    border-radius: 100px
    transition: width .8s linear
    will-change: width

  &__layer
    display: block
    position: absolute
    height: 50px
    width: 50px
    box-shadow: 3px 3px 2px rgba(0,0,0,.2)
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg)

    &:nth-of-type(1)
      background: #534a47
      margin-top: calc(50px / 2)
      animation: movedown 1.8s cubic-bezier(0.39, 0.575, 0.565, 1) .9s infinite normal
      &:before
        content: ''
        position: absolute
        width: 85%
        height: 85%
        background: #37332f

    &:nth-of-type(2)
      background: $green
      margin-top: calc(50px / 4)

    &:nth-of-type(3)
      background: rgba(255,255,255,.6)
      animation: moveup 1.8s cubic-bezier(0.39, 0.575, 0.565, 1) infinite normal

@keyframes moveup
  0%, 60%, 100%
    transform:rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0)

  25%
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(1em)

@keyframes movedown
  0%, 60%, 100%
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(0)

  25%
    transform: rotateX(50deg) rotateY(0deg) rotateZ(45deg) translateZ(-1em)
