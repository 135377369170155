.page-cta
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  &__container
    display: flex
    flex-direction: column
    align-items: center
    mw(500)
    text-align: center
    &>*+*
      margin-top: 24px

  &__title
    font-size: 24px
    line-height: 40px
    font-weight: bold
    letter-spacing: -0.8px
    margin-top: 0

  &__subtitle
    font-size: 24px
    line-height: 40px
    margin-top: 2px
    letter-spacing: -0.8px

  &__string
    font-size: 16px
    line-height: 24px
    color: var(--colorTextSecondary)

  &__error-msg
    margin-top: 12px
    font-size: 12px
    line-height: 18px
    color: var(--colorDanger)
