@keyframes spread-form-message-appearence
  from
    opacity: 0
  to
    opacity: 1

@keyframes manage-error-appearence
  from
    opacity: 0
  to
    opacity: 1

@keyframes manage-modal-appearence
  from
    opacity: 0
  to
    opacity: 1
@keyframes collapsable-field-error-appearence
  from
    opacity: 0
  to
    opacity: 1

@keyframes selected-tag-appearence
  from
    opacity: 0
  to
    opacity: 1

@keyframes tile-wrapp-appearence
  from
    opacity: 0
  to
    opacity: 1

@keyframes tile-image-preloader-back
  from
    opacity: 0
  to
    opacity: 1

@keyframes tile-image-preloader
  from
    transform: rotate(0deg)
  to
    transform: rotate(365deg)

@keyframes disabling-section-appear
  0%
    z-index: 1
    opacity: .7
  99%
    z-index: 1
    opacity: .01
  100%
    opacity: 0
    z-index: -1

@keyframes loading-blink
  0%
    opacity: .7
  50%
    opacity: .3
  100%
    opacity: .7

@keyframes loader-rotation
  from
    transform: rotate(0)
  to
    transform: rotate(360deg)

.fade1-enter-active, .fade1-leave-active
  transition: opacity .15s ease
.fade1-enter, .fade1-leave-to /* .fade-leave-active до версии 2.1.8 */
  opacity: 0

.fade3-enter-active, .fade3-leave-active
  transition: opacity .1s ease
.fade3-enter, .fade3-leave-to /* .fade-leave-active до версии 2.1.8 */
  opacity: 0

.fade-enter-active, .fade-leave-active
  transition: opacity .5s ease
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
  opacity: 0

.customize-window-appear-enter-active, .customize-window-appear-leave-active
  transition: opacity .5s ease
.customize-window-appear-enter, .customize-window-appear-leave-to /* .fade-leave-active до версии 2.1.8 */
  opacity: 0

.form-field-error-fade-enter-active, .form-field-error-fade-leave-active
  transition: opacity .3s ease
.form-field-error-fade-enter, .form-field-error-fade-leave-to /* .fade-leave-active до версии 2.1.8 */
  opacity: 0
