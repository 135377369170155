.cover-layout
  cursor: pointer
  position: relative
  margin-right: 11px
  opacity: .6
  transition: all .3s ease

  &:last-of-type
    margin-right: 0

  &:before
    content: '\e939'
    position: absolute
    top: -3px
    left: -3px
    font-family: 'icomoon'
    font-size: 47px
    line-height: 1
    color: colorDarkerGray
    opacity: 0
    transition: opacity .3s ease

  &.active
    opacity: 1
    &:before
      opacity: 1

  &:hover
    opacity: 1

  &.is-fixedheight
    & .cover-layout__icon
      font-size: 39px
    &:before
      top: -4px
      left: -4px

  &__icon
    font-size: 41px
    color: colorLightBlue
