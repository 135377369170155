.modal-video
  padding: 17px 30px 38px

  &__video
    width: 100%
    max-height: 255px
    border-radius: 5px

  &__button
    display: block
    margin: 25px auto 0
    padding: 5px 20px
    font-size: 14px
    font-weight: 600
    line-height: 30px
    letter-spacing: 0.3px
    text-transform: uppercase
    color: #fff
    background-color: colorPrimary
    border-radius: 4px
    transition: background-color .3s ease
    &:hover
      background-color: colorPrimaryHover
