.el-popover
  // Menu
  --popoverWidth: fit-content
  --popoverMinWidth: auto
  --popoverPaddings: 8px
  --popoverFS: 12px
  --popoverLH: 16px
  --popoverFW: 400
  --popoverColor: var(--colorText)
  --popoverTextAlign: left
  --popoverWordBreak: break-word
  --popoverBg: var(--colorPopoverBg)
  --popoverBorderRadius: 4px
  --popoverBorderColor: var(--colorBorder)
  --popoverBorder: 1px solid var(--popoverBorderColor)
  --popoverShadow: var(--shadowPopover)

.el-popover
  width: var(--popoverWidth) !important
  min-width: var(--popoverMinWidth)
  padding: var(--popoverPaddings)
  font-size: var(--popoverFS)
  line-height: var(--popoverLH)
  font-weight: var(--popoverFW)
  color: var(--popoverColor)
  text-align: var(--popoverTextAlign)
  word-break: var(--popoverWordBreak)
  border: var(--popoverBorder)
  border-radius: var(--popoverBorderRadius)
  background: var(--popoverBg)
  box-shadow: var(--popoverShadow)
