.layout-manage
  position: relative

  &.clear
    overflow: hidden
    & .manage-header
      transform: translateY(-100%)
      z-index: -1
      pointer-events: none
      opacity: 0
    & .aside__controls
      left: calc(100% - 70px)
    & .manage__body
      padding-top: 0

  &__restore-button
    z-index: 2
    position: fixed
    top: -30px
    right: -30px
    width: 80px
    height: 80px
    padding: 20px 20px 0 0
    font-size: 14px
    color: #fff
    background: #7cc6ff
    border-radius: 50%
    transform-origin: top right
    transition: all .3s ease
    &:hover
      background-color: colorDanger
      transform: scale(1.05)
