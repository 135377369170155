.pro-option
  position relative

  &__wrapper
    position relative
    &.is-active
      cursor pointer

  &.is-not-pro
    pointer-events: none
    & textarea
    & button
    & input
      pointer-events: none
    &:after
      content: ''
      font-weight: 500;
      font-size: 16px
      line-height: 18px
      font-family: $Avenir
      color: #323232
      position: absolute
      top: 0
      left: -10px
      width: calc(100% + 20px)
      height: 100%
      background-color: colorBgGray
      background-image: url('/img/lock.svg')
      background-repeat: no-repeat
      background-position: center
      background-size: 36px
      opacity: 0.6
      transition: all .2s
.cta-pro
  & .pro-option
    &.is-not-pro
      &:after
        background-color: white
        background-position: -2px -1px
        background-size: 20px
        padding-left: 24px
        font-size: 16px
        line-height: 18px
        font-weight: 400
        font-family: $Avenir
.domain-modal__field
  & .pro-option
    &.is-not-pro
      &:after
        background-color: white
        background-position: -3px -3px
        background-size: 26px
        padding-left: 24px
        font-size: 16px
        line-height: 18px
        font-weight: 400
        font-family: $Avenir
.page-form__uploader
  & .pro-option
    &.is-not-pro
      &:after
        background-size: 36px
