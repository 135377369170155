.manage
  display: flex
  height: 100vh
  padding-top: 70px
  // background-color: #E8ECF2
  background-color: #EFF4FA

  &__viewer-side
    position: relative
    width: 100%
    max-width: 100%
    height: 100%
    padding: 25px 20px 15px
    overflow: hidden

  &__integrations-wrap
    width: 100%
    overflow: scroll
    -webkit-overflow-scrolling: touch
    scrollbar-width: none // for Firefox
    -ms-overflow-style: none // for IE +10, Edge
    &::-webkit-scrollbar // for Chrome, Safari, Opera
      display: none

  &__viewer-wrap
    display: flex
    flex-direction: column
    width: 100%
    height: 100%
    margin: 0 auto
    border-radius: 4px
    overflow: hidden
    background-color: #fff
    box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.2)
    &.is-desktop-view
      max-width: 1080px
    &.is-mobile-view
      max-width: 425px
    // & .sv-viewer-intro.is-fullscreen
    //   min-height: 500px
    // & .sv-viewer.viewer-lg .viewer-header.is-fullscreen
    //   min-height: 450px
    // & .sv-viewer.viewer-md .viewer-header.is-fullscreen
    //   min-height: 400px
    // & .sv-viewer.viewer-sm .viewer-header.is-fullscreen
    //   min-height: 400px
    // & .sv-viewer-modal__wrapp
    //   position: absolute
    //   top: 86px
    //   left: 50%
    //   transform: translateX(-50%)
    //   width: calc(100% - 30px)
    //   height: calc(100% - 101px)
    //   max-width: 1080px
    // & .sv-product
    //   position: absolute
    //   top: 86px
    //   left: 50%
    //   transform: translateX(-50%)
    //   width: calc(100% - 30px)
    //   height: calc(100% - 101px)
    //   max-width: 1080px
    // & .sv-viewer__cart-wrap
    //   position: static !important
    // & .sv-viewer__cart
    //   position: relative
    //   top: auto
    //   right: auto
    //   left: auto
    //   bottom: auto
    //   z-index: 0
    // & .vue-sticky-placeholder
    //   display: none
