.spread-form
  position: relative
  width: 100%
  +mobile()
    flex-direction: column

  &__title
    font-family: $AdamantXBold
    font-size: 36px
    line-height: 46px
    +tablet-max()
      font-size: 24px
      line-height: 34px

  &__paragraph
    margin-top: 30px
    font-size: 16px
    line-height: 26px
    +tablet-max()
      margin-top: 15px
      font-size: 14px
      line-height: 24px

  &__form-elements
    position: relative
    display: flex
    width: 100%
    max-width: 625px
    margin: 0 auto
    &.for-add-page
      height: 50px

  &__input
    width: 100%
    height: 50px
    padding: 0 20px
    font-size: 16px
    line-height: 30px
    font-weight: 400
    color: colorBlack
    border: 1px solid colorGray
    background-color: #fff
    border-right: none
    border-radius: 4px 0 0 4px
    text-overflow: ellipsis
    &::placeholder
      font-size: 16px
      line-height: 30px
      font-weight: 400
      color: colorLightBlue
      // opacity: 0

  &__sidebar-input
    & input
      padding-right: 60px

  &__add-button
    flex: none
    width: 140px
    font-size: 16px
    font-weight: 600
    color: #fff
    text-transform: uppercase
    background-color: colorPrimary
    border-radius: 0 4px 4px 0
    transition: all .3s ease
    &:hover
      background-color: colorPrimaryHover
    &.loading
      pointer-events: none
    &:disabled
      opacity: .2
      pointer-events: none

  .icon-loader
    font-size: 24px
    animation: loader-rotation 2s linear infinite

  &__sidebar-add-btn
    position: absolute
    top: 7px
    right: 0
    font-size: 14px
    font-weight: 500
    color: colorLightBlue
    text-transform: uppercase
    transition: all .3s ease
    &:hover
      color: colorPrimary

  &__messages-wrapp
    display: flex
    flex-direction: column
    align-items: flex-start
    width: 100%
    max-width: 625px
    margin: 4px auto 0

  &__message
    margin-bottom: 5px
    font-size: 13px
    line-height: 18px
    color: #7CC6FF
    &.error
      color: colorDanger
