.content-form

  &__tiles-constructor-wrap
    margin-bottom: 48px

  &__switchers
    margin-bottom: 70px

  &__switch-field
    margin-bottom: 20px

  &__switcher-wrap
    display: flex
    align-items: center
    justify-content: space-between

  &__switch-hidden-field
    margin-top: 20px

  &__switcher-label
    // width: 65%
    padding-right: 10px
    font-size: 12px
    line-height: 18px
    font-weight: 500
    color: #465164


  &__content-tabs
    &__header
      background: #E4E9F1
      border-radius: 4px
      display: flex
      padding: 2px
      margin-bottom: 30px
      &-title
        font-weight: 500
        font-size: 14px
        line-height: 19px
        text-align: center
        width 50%
        padding: 4px
        cursor: pointer
        &.active
          background: #FFFFFF
          border-radius: 3px
        &-invalid-icon
          margin-left 4px
          display inline-block
          width: 18px
          height: 18px
          line-height: 17px
          color: red
          border: 1px solid red
          border-radius: 30px

  &__highlighted
    background:white
    padding: 10px
    margin-left:-10px
    width:calc(100% + 20px)
    border-radius:5px
    border: 1px solid #e4e9f1
