.publish-modal
  text-align: center

  &__body
    padding: 24px 20px

  &__helper
    font-size: 12px
    line-height: 20px
    color: #A8B7CF

  &__field
    display: flex
    align-items: flex-start
    width: 100%
    max-width: 520px
    margin: 10px auto 0
    font-size: 12px

  &__input-side
    width: 100%
    text-align: left

  &__input-wrapp
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    height: 40px
    padding: 0 13px 0 8px
    border: 1px solid colorPrimary
    border-radius: 4px

  &__input
    width: 100%
    margin-right: 20px
    padding: 3px 8px
    border-radius: 4px
    background-color: #E9F3FF
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    color: colorBlack

  &__copy-button
    display: flex
    align-items: center
    font-size: 14px
    line-height: 14px
    color: colorPrimary
    text-transform: uppercase
    transition: all .3s ease
    &:hover
      color: colorPrimaryHover

  &__copy-button-icon
    margin-left: 5px
    font-size: 13px
    line-height: 1

  &__button
    display: flex
    align-items: center
    justify-content: center
    height: 40px
    min-width 90px
    margin-left: 16px
    padding: 11px 23px
    font-size: 14px
    color: #fff
    font-weight: 500
    text-transform: uppercase
    text-align: center
    background: colorPrimary
    background-size: 200%
    background-position: top left
    border-radius: 4px
    transition: all .3s ease
    &:hover
      background-position: center
      background-color: colorPrimaryHover
    &.for-published
      margin-left: 0
      margin-top: 30px
    &.disabled
      pointer-events: none
      opacity 0.5
    .icon-loader
      font-size: 20px
      animation: loader-rotation 1.5s linear infinite

  &__back-step
    margin-top: 10px
    font-size: 10px
    line-height: 14px
    color: #A8B7CF

  &__back-step-button
    font-weight: 500
    color: colorPrimary
    border-bottom: 1px dashed currentColor
    transition: all .3s ease
    &:hover
      color: colorPrimaryHover

  &__footer
    width: 100%
    height: 75px
    padding: 28px 0 0
    background-color: #7CC6FF
    transition: all .3s ease
    &.opened
      height: 192px

  &__footer-title
    font-size: 12px
    line-height: 14px
    color: #fff

  &__footer-open-button
    color: currentColor
    font-weight: 600
    & span
      border-bottom: 1px solid currentColor

  &__footer-open-button-icon
    display: inline-block
    margin-left: 8px
    font-size: 8px
    transition: all .3s ease
    &.rotated
      transform: rotate(180deg)

  &__iframe-code-wrapp
    max-width: 450px
    margin: 30px auto 0

  &__published-state
    display: flex
    flex-direction: column
    align-items: center
    padding: 35px 60px 35px

  &__result-field
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    padding-bottom: 8px
    border-bottom: 1px solid #E4E9F1

  &__result-field-url
    width: 100%
    font-size: 16px
    line-height: 16px
    color: #323232
    text-align: center
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  // &__result-field-input
  //   display: none

  &__result-field-copy
    flex: none
    margin-left: 10px
    font-size: 16px
    color: #A8B7CF
    transition: color .2s ease
    &:hover
      color: colorPrimary

  &__status-message
    text-align: left
    font-size: 12px
    margin: 10px 0
    &-button
      font-weight: 500
      color: colorPrimary
      border-bottom: 1px dashed currentColor
      transition: all .3s ease
      &:hover
        color: colorPrimaryHover
      &:disabled
        color: #323232
    li
      padding-left: 30px
      position relative
      &.no-icon
        padding-left: 0
        &:before
          content: none
      &:before
        content: '!'
        position: absolute
        top: 0
        left: 0
        width: 16px
        height: 16px
        border-radius: 40px
        background-color: colorPrimary
        color: white
        text-align: center
        font-size: 12px
        line-height: 16px

  &__notice
    padding: 10px
    border-radius: 5px
    background: #f7f7f8
    text-align: left
