.route__quiz
  .header
    background-color: transparent

.page-quiz
  display: flex
  align-items: flex-start
  justify-content: center
  width: 100%
  min-height: 100vh
  padding: 0
  background-color: var(--colorBgGray)
  overflow: auto

  &__container
    width: 100%
    max-width: 960px
    padding: var(--headerHeight) var(--containerSidePadding) 88px

  &__title
    font-family: $PTSans
    margin-bottom: 30px
    headingL()
    text-align: center

  &__form
    width: 100%

  &__form-section
    padding: 24px 30px
    background-color: var(--colorBgSecondary)
    border-radius: 4px

  &__form-item
    width: 100%
    +.page-quiz__form-item
      margin-top: 36px

  &__form-item-title
    margin-bottom: 20px
    textL(600)

  &__form-item-control
    width: 100%
    gap: 24px 16px
    +.page-quiz__form-item-control
      margin-top: 20px
    &.is-grid-view
      display: grid
      grid-template-columns: repeat(4, 1fr)
    &.is-list-view
      display: flex
      flex-direction: column
      align-items: flex-start

  &__radio
    position: relative
    cursor: pointer
    display: flex
    align-items: center
    gap: 8px
    input
      position: absolute
      top: 0
      left: 0
      width: 0
      height: 0
    &:not(.is-checked):hover .page-quiz__radio-indicator
      border-color: var(--colorPrimary)
    &.is-checked .page-quiz__radio-indicator
      border-color: var(--colorPrimary)
      &:after
        opacity: 1
        transform: scale(1)

  &__radio-indicator
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: 20px
    height: 20px
    border-radius: 50%
    border: 1px solid var(--colorGrayLight)
    transition: border-color .2s
    &:after
      content: ''
      display: block
      width: 10px
      height: 10px
      background-color: var(--colorPrimary)
      border-radius: 50%
      opacity: 0
      transform: scale(0)
      transition: all .2s

  &__radio-title
    textS()

  &__form-btns
    display: flex
    align-items: center
    justify-content: center
    gap: 20px
    margin-top: 30px
