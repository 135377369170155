.collapsable-block

  &__head
    cursor: pointer
    user-select: none
    display: flex
    align-items: center
    justify-content: space-between

  &__title
    margin-right: 4px
    font-size: 14px
    line-height: 18px
    font-weight: 600
    color: #323232
    text-transform: uppercase

  &__lock-icon
    display: inline-block
    color: var(--colorTextTertiary)
    transform: translateY(2px)

  &__head-icon
    color: var(--colorTextTertiary)
    &.is-flipped
      transform: rotate(180deg)

  &__body
    display: none
    padding-top: 20px
    // user-select: none
    &.is-active
      display: block
