.timer-popup
  position: fixed
  // top: 12px
  // left: 50%
  bottom: 16px
  left: 16px
  width: 320px
  padding: 16px 16px
  background-color: #323232
  color: #fff
  border-radius: 12px
  // transform: translateX(-50%)
  box-shadow: 0px 16px 31px rgba(10, 35, 94, 0.24), 0px 3px 5px rgba(10, 35, 94, 0.1)
  // backdrop-filter: blur(10px)
  z-index 1

  &__title
    padding-right: 30px
    margin-bottom: 10px
    font-size: 18px
    line-height: 24px
    font-weight: 500
    color: #fff
    a
      color: var(--colorPrimary)
      text-decoration: underline
      &:hover
        text-decoration: none

  &__percent
    color: var(--colorDanger)

  &__timer
    display: block
    height: 60px
    margin-bottom: 16px
    color: #fff
    font-size: 45px
    letter-spacing: -3px
    font-weight: 500

  &__close-btn
    position: absolute
    top: -10px
    left: -10px
    display: flex
    align-items: center
    justify-content: center
    width: 24px
    height: 24px
    font-size: 10px
    border-radius: 50%
    background-color: var(--colorDanger)
    color: #fff
    box-shadow: 0px 16px 31px rgba(10, 35, 94, 0.24), 0px 3px 5px rgba(10, 35, 94, 0.1)
