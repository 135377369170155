.el-dropdown-menu
  // Menu
  --dropdownPaddings: 6px 0
  --dropdownBg: #fff
  --dropdownBorderRadius: 4px
  --dropdownBorderColor: var(--colorBorder)
  --dropdownBorder: 1px solid var(--dropdownBorderColor)
  --dropdownBoxShadow: 0px 16px 31px rgba(10, 35, 94, 0.24), 0px 3px 5px rgba(10, 35, 94, 0.1)
  // Placement
  --dropdownPlacementBottomIndent: 10px
  --dropdownPlacementTopIndent: 10px
  --dropdownPlacementLeftIndent: 5px
  --dropdownPlacementRightIndent: 5px
  // Item
  --dropdownItemWidth: 100%
  --dropdownItemHeight: auto
  --dropdownItemMargin: 0
  --dropdownItemPadding: 8px 12px
  --dropdownItemFS: 16px
  --dropdownItemLH: 20px
  // --dropdownItemFW: 400
  --dropdownItemFW: 500
  --dropdownItemColor: var(--colorText)
  --dropdownItemTextAlign: left
  --dropdownItemTransition: all .2s
  // Item states
  --dropdownItemDividedBorder: 1px solid var(--colorBorder)
  --dropdownItemHoverBg: var(--colorPopoverItemBg--hover)
  --dropdownItemHoverColor: var(--colorText)
  --dropdownItemDisabledColor: var(--colorTextTertiary)
  --dropdownItemDangerColor: var(--colorDanger)
  --dropdownItemDangerHoverBg: var(--colorPopoverItemBg--hover)
  --dropdownItemDangerHoverColor: var(--colorDanger)
  --dropdownItemSecondaryColor: var(--colorTextSecondary)
  --dropdownItemSecondaryHoverColor: var(--colorTextSecondary)

.el-dropdown-menu
  padding: var(--dropdownPaddings)
  background-color: var(--dropdownBg)
  border-radius: var(--dropdownBorderRadius)
  border: var(--dropdownBorder)
  box-shadow: var(--dropdownBoxShadow)

  &[x-placement^=bottom]
    margin-top: var(--dropdownPlacementBottomIndent)
    .popper__arrow
      border-bottom-color: var(--dropdownBorderColor)
      &:after
        border-bottom-color: var(--dropdownBg)
  &[x-placement^=top]
    margin-bottom: var(--dropdownPlacementTopIndent)
    .popper__arrow
      border-top-color: var(--dropdownBorderColor)
      &:after
        border-top-color: var(--dropdownBg)
  &[x-placement^=left]
    margin-right: var(--dropdownPlacementLeftIndent)
    .popper__arrow
      right: -7px
      border-left-color: var(--dropdownBorderColor)
      &:after
        border-left-color: var(--dropdownBg)
  &[x-placement^=right]
    margin-left: var(--dropdownPlacementRightIndent)
    .popper__arrow
      left: -7px
      border-right-color: var(--dropdownBorderColor)
      &:after
        border-right-color: var(--dropdownBg)

  .popper__arrow
    display: none

  &__item
    width: var(--dropdownItemWidth)
    height: var(--dropdownItemHeight)
    margin: var(--dropdownItemMargin)
    padding: var(--dropdownItemPadding)
    font-size: var(--dropdownItemFS)
    line-height: var(--dropdownItemLH)
    font-weight: var(--dropdownItemFW)
    color: var(--dropdownItemColor)
    text-align: var(--dropdownItemTextAlign)
    transition: var(--dropdownItemTransition)

    &:focus
    &:not(.is-disabled):hover
      background-color: var(--dropdownItemHoverBg)
      color: var(--dropdownItemHoverColor)

    &.is-danger
      color: var(--dropdownItemDangerColor)
      &:hover
        background-color: var(--dropdownItemDangerHoverBg)
        color: var(--dropdownItemDangerHoverColor)

    &.is-secondary
      color: var(--dropdownItemSecondaryColor)
      &:hover
        color: var(--dropdownItemSecondaryHoverColor)

    &.is-disabled
      pointer-events: all
      cursor: not-allowed
      color: var(--dropdownItemDisabledColor)
      > *
        pointer-events: none
        // cursor: not-allowed

    &.is-wrapper
      padding: 0
      >button
      >a
        display: block
        width: 100%
        font-size: inherit
        line-height: inherit
        font-weight: inherit
        color: inherit
        text-align: inherit
        padding: var(--dropdownItemPadding)

    &--divided
      border-top: var(--dropdownItemDividedBorder)
      &:before
        content: none
