.msg-dialog
  --dialogMaxWidth: 500px
  --dialogTitlePaddingRight: 0
  text-align: center

  &__body
    &>*+*
      margin-top: 24px

  &__title
    font-size: 24px
    line-height: 32px
    font-weight: bold

  &__string
    font-size: 16px
    line-height: 22px
    span
      font-weight: 600
      color: var(--colorPrimary)

  &__btns
    display: flex
    align-items: center
    justify-content: center
    margin-top: 32px
    &>*+*
      margin-left: 8px
