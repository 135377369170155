.ui-flex
  display: flex

  &--none
    flex: none

  &--wrap
    flex-wrap: wrap

  &--direction-column
    flex-direction: column

  &--items-start
    align-items: flex-start
  &--items-end
    align-items: flex-end
  &--items-center
    align-items: center
  &--items-stretch
    align-items: stretch

  &--justify-start
    justify-content: flex-start
  &--justify-end
    justify-content: flex-end
  &--justify-center
    justify-content: center
  &--justify-space-around
    justify-content: space-around
  &--justify-space-between
    justify-content: space-between
