.domain-field
  display: flex
  // align-items: center
  gap: 2px
  width: 100%
  height: var(--height-control-m)
  padding: 0 12px
  font-size: 12px
  line-height: 18px
  font-weight: 500
  border-radius: 4px
  border: 1px solid var(--colorPrimary)
  transition: all .2s
  &.is-disabled
    cursor: not-allowed
    opacity 0.3
    // filter: grayscale(1)
    input
      cursor: not-allowed
  +mediaHover()
    &:not(.is-disabled):hover
      border-color: var(--colorPrimary)


  &__prepend
  &__append
    cursor: not-allowed
    align-self: center
    flex: none
    padding: 3px 5px
    color: var(--colorPrimary)
    background-color: rgba(177,220,255,.2)
    border-radius: 4px

  &__input
    width: 100%
    &::placeholder
      color: var(--colorControlPlaceholderColor)
      transition: opacity .2s
    &:focus::placeholder
      opacity: 0
