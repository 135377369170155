.web-preview
  display: flex
  flex-direction: column
  align-items: flex-start
  width: 100%
  border-radius: 8px
  border: 1px solid #E4E9F1
  overflow: hidden
  box-shadow: 0px 6px 16px 0 rgba(29,36,56,0.05), 0px 6px 16px 0px rgba(0,0,0,0.03)
  &.is-mobile
    max-width: 420px

  &__topbar
    display: flex
    align-items: center
    justify-content: space-between
    gap: 16px
    flex: none
    width: 100%
    height: 40px
    padding: 0 16px
    background-color: #EFF3FB

  &__topbar-decor
    display: flex
    align-items: center
    flex: none
    gap: 8px

  &__topbar-decor-item
    flex: none
    width: 12px
    height: 12px
    border-radius: 50%
    background-color: #A8B7CF

  &__topbar-field
    display: flex
    align-items: center
    gap: 4px
    width: 100%
    // max-width: 500px
    padding: 0 12px
    height: 28px
    border-radius: 4px
    border: 1px solid #E4E9F1
    background-color: #fff

  &__topbar-field-prepend
    textS(500)
    color: #A8B7CF

  &__topbar-field-value
    mw(230)
    textS(500)
    border-radius: 4px
    &.is-hidden
      height: 20px
      background-color: #E4E9F1

  &__topbar-switcher-wrap
    display: flex
    align-items: center
    flex: none
    gap: 2px

  &__topbar-switcher
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: 28px
    height: 28px
    font-size: 20px
    border-radius: 4px
    transition: all .2s
    +hover()
      background-color: #E4E9F1
    &:active
      background-color: #d4dbe5
    &.is-active
      color: var(--colorPrimary)

  &__body
    width: 100%
    flex: 1
    &.is-zoomed iframe
      zoom: 60%
    >iframe
      width: 100%
      height: 100%
