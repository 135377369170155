.new-view-page
  display: flex
  align-items: center
  // min-height: calc(100vh - 200px)
  +tablet()
    padding: 100px 0 0
  +mobile()
    padding-top: 50px

  &__sides
    display: flex
    align-items: center
    justify-content: space-between
    width 100%
    max-width: 1280px
    padding 0 20px
    margin 0 auto
    +tablet-max()
      display: block

  &__form-side
    position: relative
    width: 600px
    padding-right: 70px
    +laptop()
      flex-shrink: 0
      width: 50%
    +tablet-max()
      width: 100%
      padding-right: 0

  &__form-side-dots
    position: absolute
    top: -160px
    left: -250px

  &__help-side
    position: relative
    +laptop()
      width: 50%
    +tablet-max()
      width: 100%
      padding-top: 70px
    &:before
      content: ''
      position: absolute
      top: 85px
      left: -100px
      z-index: -1
      width: 900px
      height: 800px
      background-color: #F7F7F9
      +tablet-max()
        content: none

  &__video-wrapp
    position: relative
    width: 600px
    margin: 88px 0 -180px -200px
    border-radius: 4px
    +tablet-max()
      width: 100%
      margin: 50px 0 0

  &__video
    position: relative
    z-index: 1

  &__video-wrapp-dots
    position: absolute
    top: -40px
    right: -130px
