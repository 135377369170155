.color-picker
  cursor: pointer
  position: relative
  z-index: 1
  // position: absolute
  // top: 11px
  // right: 10px
  width: 18px
  height: 18px
  border: 1px solid colorLightBlue
  border-radius: 2px
  &.for-mailchimp .vc-chrome
    top: auto
    bottom: 100%
    right: 100%
    // left: 100%
