.modal
  position: relative
  width: 100%
  max-width: var(--maxWidth)
  margin: var(--top) auto auto
  padding-top: var(--modalPaddingTop)
  border-radius: 4px
  background-color: #fff
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.5)

  &__wrapp
    --maxWidth: 620px
    --top: 10vh
    --modalPaddingTop: 40px
    position: fixed
    top: 0
    left: 0
    z-index: 100
    display: flex
    // align-items: center
    width: 100%
    height: 100%
    padding: 40px 20px
    background-color: rgba(55, 59, 70, .7)
    -webkit-overflow-scrolling: touch
    overflow: scroll
    animation: manage-modal-appearence .5s ease
    &.is-wide .modal
      max-width: 800px
    &.is-whatsup-setup>.modal
      max-width: 450px
      padding-bottom: 16px
    &--fullscreen
      display: flex
      flex-direction: column
      overflow: hidden
      .modal
        flex: 1
        min-height: 0
        margin-top: 0
        overflow: auto
    +mobile()
      padding: 0 5px

  &__close
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: absolute
    top: 10px
    right: 10px
    width: 33px
    height: 33px
    border-radius: 50%
    transition: all .3s ease
    &:hover
      background-color: #f2f2f7
      & .icon
        color: #798496
    & .icon
      font-size: 13px
      // color: #CAD3E0
      color: #a4b4cb
      transition: color .3s ease

  &__title
    padding: 0 30px
    font-size: 23px
    line-height: 35px
    font-weight: 700
    color: #323232
    text-align: center
    &.align-left
      text-align: left

  &__about
    padding: 0 20px
    margin-top: 20px
    text-align: center
    h1
    h2
    h3
    h4
    h5
    h6
      border: none
      color: inherit
    h1
      margin: 32px 0 32px
      font-size: 42px
      line-height: 52px
      font-weight: 700
    h2
      margin: 25px 0
      font-size: 26px
      line-height: 34px
      font-weight: 600
    h3
      margin: 20px 0
      font-size: 18px
      line-height: 25px
      font-weight: 600
    h4
      margin: 15px 0
      font-size: 14px
      line-height: 20px
      font-weight: 600
    h5
      margin: 10px 0
      font-size: 12px
      line-height: 20px
      font-weight: 600
    h6
      margin: 10px 0
      font-size: 10px
      line-height: 18px
      font-weight: 600
    p
      margin: 10px 0 18px
      font-size: 16px
      line-height: 24px
      &:only-of-type
      &:last-of-type
        margin: 0
      a
        color: #1da2ff
    ul
      list-style: disc
    ul
    ol
      padding-left: 20px
      margin: 10px 0 18px
      li
        font-size: 18px
        line-height: 28px
        &:nth-of-type(n+2)
          margin-top: 5px
        p
          margin: 0
        ul
          margin: 0

  &__error-message
    display: block
    color: #ff0000
    text-align: center
