.radio
  cursor: pointer

  &__input
    display: none
    &:checked+.radio__indicator
      border-color: #1DA2FF
      &:after
        opacity: 1

  &__indicator
    display: flex
    align-items: center
    justify-content: center
    width: 16px
    height: 16px
    border: 2px solid #A8B7CF
    border-radius: 50%
    transition: all .2s
    &:hover
      border-color: #1DA2FF
    &:after
      content: ''
      width: 8px
      height: 8px
      border-radius: 50%
      background-color: #1DA2FF
      opacity: 0
      transition: opacity .2s
